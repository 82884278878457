export const allProfessionsMap = {
  brute: {
    name: 'brute',
    iconName: '01.brute.png',
    defaultPcName: 'Skullcrusher',
    hpCategory: 'high',
  },
  tinkerer: {
    name: 'tinkerer',
    iconName: '02.tinkerer.png',
    defaultPcName: 'Hackwrench',
    hpCategory: 'medium',
  },
  spellweaver: {
    name: 'spellweaver',
    iconName: '03.spellweaver.png',
    defaultPcName: 'Mystery',
    hpCategory: 'low',
  },
  scoundrel: {
    name: 'scoundrel',
    iconName: '04.scoundrel.png',
    defaultPcName: 'Raven',
    hpCategory: 'medium',
  },
  cragheart: {
    name: 'cragheart',
    iconName: '05.cragheart.png',
    defaultPcName: 'Rocky',
    hpCategory: 'high',
  },
  mindthief: {
    name: 'mindthief',
    iconName: '06.mindthief.png',
    defaultPcName: 'Skweel',
    hpCategory: 'low',
  },
  sun: {
    name: 'sun',
    iconName: '07.sun.png',
    defaultPcName: 'Luz',
    hpCategory: 'high',
  },
};

export const allProfessions = Object.keys(allProfessionsMap);

const hp = {
  low: [6, 7, 8, 9, 10, 11, 12, 13, 14],
  medium: [8, 9, 11, 12, 14, 15, 17, 18, 20],
  high: [10, 12, 14, 16, 18, 20, 22, 24, 26],
};

export function getPcMaxHp(profession, level) {
  const definition = allProfessionsMap[profession];
  if (!definition) {
    console.eror(`Unrecognized profession: '${profession}'`);
    return 11;
  }
  const category = definition.hpCategory;
  return hp[category][level - 1];
}
