function descriptor(obj) {
  const defaults = {
    flippable: false,
    hasLabel: false,
    hasPattern: false,
    imageStorageMethod: 'same-dir',
    kindForAdding: 'add-token',
    nonClonable: false,
    offsetPolicy: 'one-hex',
    positioning: 'hex-positioning',
    rotatable: false,
    isDynamic: false,
    isFloor: false,
    isDoor: false,
    isEntrance: false,
    isGridRelative: false,
  };
  return Object.assign(defaults, obj);
}

export const thingTypeDescriptors = {
  chest: descriptor({
    imageStorageMethod: 'flat-and-pointy',
    kindForAdding: 'add-token',
    hasLabel: true,
  }),
  coin: descriptor({
    kindForAdding: 'add-token',
  }),
  corridor: descriptor({
    rotatable: true,
    kindForAdding: 'add-token',
    isFloor: true,
    isGridRelative: true,
  }),
  'corridor-long': descriptor({
    rotatable: true,
    offsetPolicy: 'long',
    kindForAdding: 'add-token',
    isFloor: true,
    isGridRelative: true,
  }),
  'pressure-plate': descriptor({
    rotatable: true,
    kindForAdding: 'add-token',
    isGridRelative: true,
  }),
  door: descriptor({
    rotatable: true,
    kindForAdding: 'add-token',
    isDoor: true,
    isGridRelative: true,
  }),
  monster: descriptor({
    hasPattern: true,
    imageStorageMethod: 'flat-and-pointy',
    kindForAdding: 'add-monster',
  }),
  entrance: descriptor({
    imageStorageMethod: 'flat-and-pointy',
    kindForAdding: 'add-token',
    isEntrance: true,
  }),
  obstacle: descriptor({
    rotatable: true,
    kindForAdding: 'add-token',
    isGridRelative: true,
  }),
  'obstacle-long': descriptor({
    rotatable: true,
    offsetPolicy: 'long',
    kindForAdding: 'add-token',
    isGridRelative: true,
  }),
  'obstacle-triangle': descriptor({
    rotatable: true,
    offsetPolicy: 'triangle',
    kindForAdding: 'add-token',
    isGridRelative: true,
  }),
  'custom-red-circle': descriptor({
    kindForAdding: 'add-token',
    isFloor: true,
    hasRedCircleLabel: true,
  }),
  'red-circle': descriptor({
    kindForAdding: 'add-token',
    isFloor: true,
  }),
  'difficult-terrain': descriptor({
    rotatable: true,
    kindForAdding: 'add-token',
    isFloor: true,
    isGridRelative: true,
  }),
  'difficult-terrain-long': descriptor({
    rotatable: true,
    kindForAdding: 'add-token',
    offsetPolicy: 'long',
    isFloor: true,
    isGridRelative: true,
  }),
  'hazardous-terrain': descriptor({
    rotatable: true,
    kindForAdding: 'add-token',
    isFloor: true,
    isGridRelative: true,
  }),
  tile: descriptor({
    rotatable: true,
    flippable: true,
    nonClonable: true,
    offsetPolicy: 'lettered',
    imageStorageMethod: 'in-tile-dir',
    kindForAdding: 'add-tile',
    isFloor: true,
    isGridRelative: true,
  }),
  'tile-freeform': descriptor({
    positioning: 'freeform',
    rotatable: true,
    flippable: true,
    nonClonable: true,
    imageStorageMethod: 'in-tile-dir',
    kindForAdding: 'add-tile-freeform',
  }),
  trap: descriptor({
    rotatable: true,
    kindForAdding: 'add-token',
    isGridRelative: true,
    hasTrapEffects: true,
  }),
  'live-room': descriptor({
    positioning: 'none',
    isRevealable: true,
  }),
  'live-chest': descriptor({
    imageStorageMethod: 'flat-and-pointy',
    imageDir: 'chest',
    hasLabel: true,
  }),
  'live-coin': descriptor({
    kindForAdding: 'add-live',
    hasAmount: true,
    isDynamic: true,
    imageDir: 'coin',
  }),
  'live-door': descriptor({
    hasIsOpen: true,
    imageDir: 'door',
    rotatable: true,
    isDynamic: false,
    isGridRelative: true,
  }),
  'live-obstacle': descriptor({
    rotatable: true,
    isDynamic: true,
    kindForAdding: 'add-live',
    imageDir: 'obstacle',
    isGridRelative: true,
  }),
  'live-obstacle-long': descriptor({
    rotatable: true,
    offsetPolicy: 'long',
    isDynamic: true,
    imageDir: 'obstacle-long',
    isGridRelative: true,
  }),
  'live-obstacle-triangle': descriptor({
    rotatable: true,
    offsetPolicy: 'triangle',
    isDynamic: true,
    imageDir: 'obstacle-triangle',
    isGridRelative: true,
  }),
  'live-monster': descriptor({
    imageStorageMethod: 'flat-and-pointy',
    hasFigureConditions: true,
    hasHp: true,
    hasVersion: true,
    hasStandeeNumber: true,
    imageDir: 'monster',
    isDynamic: true,
    kindForAdding: 'add-live',
  }),
  'live-summons': descriptor({
    hasFigureConditions: true,
    hasHp: true,
    imageDir: 'summons',
    isDynamic: true,
    kindForAdding: 'add-live',
  }),
  'live-pc': descriptor({
    imageStorageMethod: 'flat-and-pointy',
    kindForAdding: 'add-pc',
    isDynamic: true,
    hasFigureConditions: true,
    hasHp: true,
    hasXp: true,
    hasCoins: true,
    imageDir: 'pc',
  }),
  'live-trap': descriptor({
    rotatable: true,
    kindForAdding: 'add-live',
    hasTrapEffects: true,
    // Exact amount of damage
    hasHp: true,
    imageDir: 'trap',
    isDynamic: true,
    isGridRelative: true,
  }),
};

const bigMax = 10000;
const smallMax = 100;

const HEX_POSITION_SETTINGS = [
  {
    attribute: 'q',
    label: 'Hex q',
    min: -smallMax,
    max: smallMax,
    defaultValue: 0,
    type: 'number',
  },
  {
    attribute: 'r',
    label: 'Hex r',
    min: -smallMax,
    max: smallMax,
    defaultValue: 0,
    type: 'number',
  },
];

const FREEFORM_POSITION_SETTINGS = [
  {
    attribute: 'left',
    label: 'Left',
    min: -bigMax,
    max: bigMax,
    defaultValue: 100,
    type: 'number',
  },
  {
    attribute: 'top',
    label: 'Top',
    min: -bigMax,
    max: bigMax,
    defaultValue: 100,
    type: 'number',
  },
];

const settingsByTag = {
  rotatable: [
    {
      attribute: 'rotation',
      label: 'Rotation',
      min: 0,
      max: 5,
      defaultValue: 0,
      type: 'number',
    },
  ],
  hasPattern: [{ attribute: 'pattern', label: 'Pattern', type: 'pattern' }],
  hasLabel: [
    {
      attribute: 'label',
      label: 'Label',
      type: 'string',
      defaultValue: 'lootme',
    },
  ],
  isFloor: [
    {
      attribute: 'roomLink',
      label: 'Room link',
      type: 'string',
      readOnly: true,
    },
  ],
  isRevealable: [
    {
      attribute: 'isRevealed',
      label: 'Revealed',
      type: 'bool',
      readOnly: true,
    },
    {
      attribute: '_reveal',
      label: 'Reveal',
      type: 'button',
      action: 'REVEAL_ROOM',
    },
  ],
  hasIsOpen: [{ attribute: 'isOpen', label: 'Open', type: 'bool' }],
  hasAmount: [
    { attribute: 'amount', label: 'Amount', type: 'number', min: 0, max: 100 },
  ],
  hasVersion: [
    {
      attribute: 'version',
      label: 'Version',
      type: 'choice',
      options: [
        { value: 'normal', label: 'Normal' },
        { value: 'elite', label: 'Elite' },
      ],
    },
  ],
  hasStandeeNumber: [
    {
      attribute: 'standeeNumber',
      label: 'Standee',
      type: 'number',
      min: 1,
      max: 10,
      defaultValue: 1,
    },
  ],
  hasHp: [
    { attribute: 'hp', label: 'Hit points', type: 'number', min: 0, max: 100 },
  ],
  hasXp: [
    { attribute: 'xp', label: 'Experience', type: 'number', min: 0, max: 100 },
  ],
  hasCoins: [
    { attribute: 'coins', label: 'Coins', type: 'number', min: 0, max: 100 },
  ],
  hasTrapEffects: [
    { attribute: 'withDamage', label: 'Damage', type: 'bool' },
    { attribute: 'withPoison', label: 'Poison', type: 'bool' },
    { attribute: 'withWound', label: 'Wound', type: 'bool' },
    { attribute: 'withImmobilize', label: 'Immobilize', type: 'bool' },
    { attribute: 'withDisarm', label: 'Disarm', type: 'bool' },
    { attribute: 'withStun', label: 'Stun', type: 'bool' },
    { attribute: 'withMuddle', label: 'Muddle', type: 'bool' },
  ],
  hasFigureConditions: [
    { attribute: 'withPoison', label: 'Poison', type: 'bool' },
    { attribute: 'withWound', label: 'Wound', type: 'bool' },
    { attribute: 'withImmobilize', label: 'Immobilize', type: 'bool' },
    { attribute: 'withDisarm', label: 'Disarm', type: 'bool' },
    { attribute: 'withStun', label: 'Stun', type: 'bool' },
    { attribute: 'withMuddle', label: 'Muddle', type: 'bool' },
    { attribute: 'withInvisible', label: 'Invisible', type: 'bool' },
    { attribute: 'withStrengthen', label: 'Strengthen', type: 'bool' },
  ],
  hasRedCircleLabel: [
    {
      attribute: 'redCircleLabel',
      label: 'Label',
      type: 'string',
      defaultValue: 'a',
    },
  ],
};

const tags = [
  // rotatable is handled differently (not allowed to rotate in playthrough)
  // isFloor is handled differently (enabled only in playthrough)
  'hasPattern',
  'hasLabel',
  'isRevealable',
  'hasIsOpen',
  'hasAmount',
  'hasVersion',
  'hasStandeeNumber',
  'hasHp',
  'hasXp',
  'hasCoins',
  'hasTrapEffects',
  'hasFigureConditions',
  'hasRedCircleLabel',
];

export function getSettingsForThingType(thingType, mode = 'editor') {
  const thingTypeDescriptor = thingTypeDescriptors[thingType];
  const allowDynamicProps = !(
    mode === 'gameplay' && !thingTypeDescriptor.isDynamic
  );

  const settings = [];
  if (thingTypeDescriptor.positioning === 'freeform') {
    settings.push(...FREEFORM_POSITION_SETTINGS);
  } else if (thingTypeDescriptor.positioning === 'none') {
    // do nothing
  } else if (allowDynamicProps) {
    settings.push(...HEX_POSITION_SETTINGS);
  }

  if (thingTypeDescriptor.rotatable && allowDynamicProps) {
    settings.push(...settingsByTag.rotatable);
  }

  if (thingTypeDescriptor.isFloor && mode === 'gameplay') {
    settings.push(...settingsByTag.isFloor);
  }

  for (const tag of tags) {
    if (thingTypeDescriptor[tag]) {
      settings.push(...settingsByTag[tag]);
    }
  }

  return settings;
}
