import {
  all,
  select,
  takeEvery,
  call,
  put,
  debounce,
} from 'redux-saga/effects';
import {
  setThingSetting,
  setSettingsOfThings,
  removeThing,
  addThing,
  addSeveralThings,
  startAddingThing,
  applyThingChanger,
  initScenarioViewer,
  activateTabByIndex,
  doneExportPng,
  pdfDataAvailable,
  startGeneratingPdf,
  showCannotChangeTile,
} from './ScenarioViewer/actions';
import {
  thingTypeDescriptors,
  getSettingsForThingType,
} from './data/thingTypes';
import { setSubtitle } from './common';
import {
  getHexIdsCoveredByThing,
  getHexCoordsFromId,
  getCurrentDayIsoFormatted,
  getThingsPlacedOnHexes,
  getHexId,
} from './utils';
import {
  rotateHexVector,
  rotateHexCoordsAroundAnotherHex,
  setOfAllGridHexIds,
  hexes,
} from './algorithms/hexUtils';
import { getMonsterMaxHp } from './data/monsters';
import { preparePlaythrough } from './ScenarioViewer/preparePlaythrough';
import { generateSingleScenarioPdf } from './pdf/pdfGenerator';
import { createPictureIntermediary } from './ScenarioViewer/createPictureIntermediary';
import { createPngBlob } from './png/generatePng';
import fileDownload from 'js-file-download';
import { defaultScenarioTitle } from './data/scenario';
import { changerDefinitions } from './data/changers';
import {
  selectScenarioViewer,
  selectThingConfig,
  selectSelectedTid,
  selectSelectedThingChangers,
  selectTidToConfig,
  selectScenarioToPersist,
  selectPlaythroughToPersist,
  selectMode,
  selectOrientation,
  selectExtent,
} from './ScenarioViewer/selectors';
import { saveScenarioInStorage } from './storage';

// helpers

function createTidWithNextNumber(tidToConfig, thingName, start = 1) {
  let tid;
  for (let i = start; ; ++i) {
    tid = `${thingName}:${i}`;
    if (!tidToConfig[tid]) {
      break;
    }
  }
  return tid;
}

// doers and watchers

function* persistScenarioViewerState() {
  const mode = yield select(selectMode);
  if (mode === 'editor') {
    const scenario = yield select(selectScenarioToPersist);
    saveScenarioInStorage(scenario);
  } else if (mode === 'gameplay') {
    const playthrough = yield select(selectPlaythroughToPersist);
    const key = `playthrough:${playthrough.id}`;
    localStorage.setItem(key, JSON.stringify(playthrough));
  } else {
    console.warn('Unknown ScenarioViewer mode:', mode);
  }
}

const DEBOUNCE_MS = 1000;

function* watchPersistScenarioViewerState() {
  yield debounce(
    DEBOUNCE_MS,
    [
      'SET_THING_SETTING',
      'SET_SEVERAL_THING_SETTINGS',
      'SET_SETTINGS_OF_THINGS',
      'PERFORM_THING_ACTION',
      'SET_SCENARIO_SETTING',
      'SET_PLAYTHROUGH_SETTING',
      'ADD_TILE',
      'ADD_THING',
      'REMOVE_THING',
      'PC_AREA_SUBACTION',
    ],
    persistScenarioViewerState
  );
}

function* persistGlobalState() {
  const scenarioViewer = yield select(selectScenarioViewer);
  const { global } = scenarioViewer;
  localStorage.setItem('global', JSON.stringify(global));
}

function createScenarioExportFileName(scenario, extension) {
  const day = getCurrentDayIsoFormatted();
  const { shortReference, title } = scenario;

  const refPart = shortReference ? `${shortReference} - ` : '';
  const titlePart = title || defaultScenarioTitle;

  const result = `${day} - ${refPart}${titlePart}.${extension}`;
  return result;
}

function* exportJsonSaga() {
  const scenario = yield select(selectScenarioToPersist);
  const text = JSON.stringify(scenario, undefined, 2);
  const fileName = createScenarioExportFileName(scenario, 'json');
  fileDownload(text, fileName, 'application/json');
}

function createSetWithSetDifference(bigSet, smallSet) {
  const result = new Set();
  for (const item of bigSet) {
    if (smallSet.has(item)) {
      continue;
    }
    result.add(item);
  }
  return result;
}

function transformSetOfHexIds(setOfHexIds, f) {
  const result = new Set();
  for (const hexId of setOfHexIds) {
    const [q, r] = getHexCoordsFromId(hexId);
    const [qq, rr] = f(q, r);
    const newId = getHexId(qq, rr);
    result.add(newId);
  }
  return result;
}

function setsOverlap(aSet, bSet) {
  for (const item of aSet) {
    if (bSet.has(item)) {
      return true;
    }
  }
  for (const item of bSet) {
    if (aSet.has(item)) {
      return true;
    }
  }
  return false;
}

function setIsOutsideOfAnotherSet(smallSet, bigSet) {
  for (const item of smallSet) {
    if (!bigSet.has(item)) {
      return true;
    }
  }
  return false;
}

function* hexMove(tid, dq, dr) {
  const config = yield select(selectThingConfig, tid);
  const orientation = yield select(selectOrientation);

  // The key- and icon-based directional movement defines the visual vector; we
  // need to rotate it to the canonical grid vector, which is the opposite of
  // the orientation, with poity and flat having the same vectors (hence
  // division by 2).

  const n = 6 - Math.floor(orientation / 2);
  const [qq, rr] = rotateHexVector(dq, dr, n);

  const transform = (q, r) => [q + qq, r + rr];

  const [newQ, newR] = transform(config.q, config.r);
  const tidToPropToValue = {
    [tid]: { q: newQ, r: newR },
  };

  const { thingType } = config;
  if (thingType === 'tile') {
    const extent = yield select(selectExtent);
    const { tileCoveredHexIds } = extent;

    const myHexIds = getHexIdsCoveredByThing(config);
    const myHexIdsAfter = transformSetOfHexIds(myHexIds, transform);

    if (setIsOutsideOfAnotherSet(myHexIdsAfter, setOfAllGridHexIds)) {
      yield put(showCannotChangeTile(tid));
      return;
    }

    const otherHexIds = createSetWithSetDifference(tileCoveredHexIds, myHexIds);

    // XXX here and elsewhere, when tile overlap is checked, we should consider
    // partial hexes taken by the tiles, because checking only the full floor
    // hexes leads to visually (and physically) incorrect overlaps on the
    // sides.

    if (setsOverlap(otherHexIds, myHexIdsAfter)) {
      yield put(showCannotChangeTile(tid));
      return;
    }

    const tidToConfig = yield select(selectTidToConfig);
    const tidsOnTop = getThingsPlacedOnHexes(myHexIds, tidToConfig);

    for (const thatTid of tidsOnTop) {
      const thatConfig = tidToConfig[thatTid];
      const [newQ, newR] = transform(thatConfig.q, thatConfig.r);
      tidToPropToValue[thatTid] = {
        q: newQ,
        r: newR,
      };
    }
  }

  yield put(setSettingsOfThings(tidToPropToValue));
}

function getNextRotation(rotation, dRotation) {
  return (rotation + (dRotation % 6) + 6) % 6;
}

function* rotate(tid, dRotation) {
  const config = yield select(selectThingConfig, tid);
  const { rotation } = config;
  const newRotation = getNextRotation(rotation, dRotation);

  const tidToPropToValue = {
    [tid]: { rotation: newRotation },
  };

  const { thingType } = config;
  if (thingType === 'tile') {
    const extent = yield select(selectExtent);
    const { tileCoveredHexIds } = extent;

    const { q: pivotQ, r: pivotR } = config;
    const transform = (q, r) => {
      return rotateHexCoordsAroundAnotherHex(q, r, dRotation, pivotQ, pivotR);
    };

    const myHexIds = getHexIdsCoveredByThing(config);
    const myHexIdsAfter = transformSetOfHexIds(myHexIds, transform);

    if (setIsOutsideOfAnotherSet(myHexIdsAfter, setOfAllGridHexIds)) {
      yield put(showCannotChangeTile(tid));
      return;
    }

    const otherHexIds = createSetWithSetDifference(tileCoveredHexIds, myHexIds);

    if (setsOverlap(otherHexIds, myHexIdsAfter)) {
      yield put(showCannotChangeTile(tid));
      return;
    }

    const tidToConfig = yield select(selectTidToConfig);
    const tidsOnTop = getThingsPlacedOnHexes(myHexIds, tidToConfig);

    for (const thatTid of tidsOnTop) {
      const thatConfig = tidToConfig[thatTid];
      const [newQ, newR] = transform(thatConfig.q, thatConfig.r);
      const propToValue = {
        q: newQ,
        r: newR,
      };
      const thingTypeDescriptor = thingTypeDescriptors[thatConfig.thingType];
      if (thingTypeDescriptor.rotatable) {
        propToValue.rotation = getNextRotation(thatConfig.rotation, dRotation);
      }
      tidToPropToValue[thatTid] = propToValue;
    }
  }

  yield put(setSettingsOfThings(tidToPropToValue));
}

function* cloneThing(tid) {
  const scenarioViewer = yield select(selectScenarioViewer);
  const { tidToConfig } = scenarioViewer;
  const config = tidToConfig[tid];

  const match = /^(.*):(\d+)$/.exec(tid);
  let newTid;
  if (match) {
    const currentNumber = Number(match[2]);
    newTid = createTidWithNextNumber(tidToConfig, match[1], currentNumber);
  } else {
    const { thingType } = config;
    newTid = thingType + ':1';
  }

  const newConfig = { ...config };

  // XXX check if hex-positioned instead of presence of attribute
  if (config.q !== undefined) {
    newConfig.q = config.q + 1;
  }
  yield put(addThing(newTid, newConfig));
}

function* flipTile(tid) {
  const scenarioViewer = yield select(selectScenarioViewer);

  const index = scenarioViewer.tids.indexOf(tid);

  if (index === -1) {
    console.error('Cannot flip tile because it is not found in tids:', tid);
    return;
  }

  const regexp = /^(..)([ab])$/;
  const match = regexp.exec(tid);
  if (!match) {
    console.error('Cannot flip tile because of wrong format of tile tid:', tid);
    return;
  }
  const newTid = match[1] + (match[2] === 'a' ? 'b' : 'a');

  const config = yield select(selectThingConfig, tid);
  const newConfig = { ...config };
  newConfig.imageName = newTid;

  yield put(removeThing(tid));
  yield put(addThing(newTid, newConfig, { indexInTids: index }));
}

function* applyThingChangerSaga(action) {
  const { tid, changer } = action;

  switch (changer) {
    case 'flat-move-nw':
    case 'pointy-move-w':
      yield call(hexMove, tid, -1, 0);
      break;
    case 'flat-move-n':
    case 'pointy-move-nw':
      yield call(hexMove, tid, 0, -1);
      break;
    case 'flat-move-ne':
    case 'pointy-move-ne':
      yield call(hexMove, tid, 1, -1);
      break;
    case 'flat-move-se':
    case 'pointy-move-e':
      yield call(hexMove, tid, 1, 0);
      break;
    case 'flat-move-s':
    case 'pointy-move-se':
      yield call(hexMove, tid, 0, 1);
      break;
    case 'flat-move-sw':
    case 'pointy-move-sw':
      yield call(hexMove, tid, -1, 1);
      break;
    case 'rotate-r':
      yield call(rotate, tid, 1);
      break;
    case 'rotate-l':
      yield call(rotate, tid, -1);
      break;
    case 'remove':
      yield put(removeThing(tid));
      break;
    case 'clone':
      yield call(cloneThing, tid);
      break;
    case 'flip':
      yield call(flipTile, tid);
      break;
    default: {
      console.warn('Unrecognized changer:', changer);
      break;
    }
  }
}

function* addThingAutoSaga(action) {
  const { thing } = action;

  const { thingType, name, ...rest } = thing;

  const scenarioViewer = yield select(selectScenarioViewer);
  const { tidToConfig } = scenarioViewer;

  const tid =
    thingType === 'tile' ? name : createTidWithNextNumber(tidToConfig, name);

  const defaults = {};
  const settings = getSettingsForThingType(thingType);
  for (const setting of settings) {
    defaults[setting.attribute] = setting.defaultValue;
  }

  const config = {
    ...defaults,
    thingType,
    imageName: name,
    ...rest,
  };

  const thingTypeDescriptor = thingTypeDescriptors[thingType];
  if (thingType === 'tile') {
    const extent = yield select(selectExtent);
    const { tileCoveredHexIds } = extent;
    for (const hex of hexes) {
      const { q, r } = hex;
      if (!(0 <= q && 0 <= r)) {
        continue;
      }
      config.q = q;
      config.r = r;
      const hexIds = getHexIdsCoveredByThing(config);
      if (!setsOverlap(hexIds, tileCoveredHexIds)) {
        break;
      }
    }
  } else if (thingTypeDescriptor.positioning === 'hex-positioning') {
    const { selectedTid } = scenarioViewer;

    let found = false;
    if (selectedTid) {
      const selectedConfig = tidToConfig[selectedTid];
      const hexes = getHexIdsCoveredByThing(selectedConfig);

      // hexes is a set, so to get the first element, we do an iteration.
      let hex;
      for (let x of hexes) {
        hex = x;
        break;
      }
      if (hex) {
        const [q, r] = getHexCoordsFromId(hex);
        config.q = q;
        config.r = r;
        found = true;
      }
    }

    if (!found) {
      config.q = 0;
      config.r = 0;
    }
  }

  yield put(addThing(tid, config));
}

// XXX shortcuts duplicated with addButtonDescriptors
const keyHandlers = {
  common: {},
  editor: {
    A: {
      type: 'action',
      action: startAddingThing('add-tile'),
    },
    T: {
      type: 'action',
      action: startAddingThing('add-token'),
    },
    M: {
      type: 'action',
      action: startAddingThing('add-monster'),
    },
  },
  gameplay: {
    A: {
      type: 'action',
      action: startAddingThing('add-live'),
    },
    1: { type: 'action', action: activateTabByIndex(0) },
    2: { type: 'action', action: activateTabByIndex(1) },
    3: { type: 'action', action: activateTabByIndex(2) },
    4: { type: 'action', action: activateTabByIndex(3) },
    5: { type: 'action', action: activateTabByIndex(4) },
  },
  gameplayMap: {
    x: { type: 'action', action: { type: 'FOCUS_HITPOINTS' } },
  },
  gameplayPcArea: {
    j: {
      type: 'pcAreaSubaction',
      subaction: {
        part: 'am',
        type: 'DRAW_ONE_WITH_ALL_ROLLING',
      },
    },
    k: {
      type: 'pcAreaSubaction',
      subaction: {
        part: 'am',
        type: 'DRAW_TWO_NEW',
      },
    },
    L: {
      type: 'pcAreaSubaction',
      subaction: {
        part: 'am',
        type: 'DISCARD',
      },
    },
    l: {
      type: 'pcAreaSubaction',
      subaction: {
        part: 'am',
        type: 'SHUFFLE',
      },
    },
    J: {
      type: 'pcAreaSubaction',
      subaction: {
        part: 'am',
        type: 'ADD_BLESS',
      },
    },
    K: {
      type: 'pcAreaSubaction',
      subaction: {
        part: 'am',
        type: 'ADD_CURSE',
      },
    },
  },
};

for (const [changerId, definition] of Object.entries(changerDefinitions)) {
  const { shortcut } = definition;
  let handler = keyHandlers.common[shortcut];
  if (!handler) {
    handler = keyHandlers.common[shortcut] = {
      type: 'changer',
      changers: [],
    };
  }
  handler.changers.push(changerId);
}

function getActiveTabName(scenarioViewer) {
  return scenarioViewer.global.activeTabName;
}

function findKeyHandler(scenarioViewer, key) {
  let attempt = keyHandlers.common[key];
  if (attempt) {
    return attempt;
  }

  const { mode } = scenarioViewer;

  attempt = keyHandlers[scenarioViewer.mode][key];
  if (attempt) {
    return attempt;
  }

  if (mode === 'gameplay') {
    const tabName = getActiveTabName(scenarioViewer);
    if (tabName === 'map') {
      attempt = keyHandlers.gameplayMap[key];
      if (attempt) {
        return attempt;
      }
    } else {
      attempt = keyHandlers.gameplayPcArea[key];
      if (attempt) {
        return {
          ...attempt,
          pcId: tabName,
        };
      }
    }
  }
  return null;
}

function* handleScenarioViewerKeyPressedSaga(action) {
  const { key } = action;

  const scenarioViewer = yield select(selectScenarioViewer);
  // TODO handle open Global, Scenario screens; start selecting by tid

  const handler = findKeyHandler(scenarioViewer, key);
  if (!handler) {
    return;
  }

  if (handler.type === 'changer') {
    const selectedTid = yield select(selectSelectedTid);
    const activeChangers = yield select(selectSelectedThingChangers);
    const potentialChangers = handler.changers;

    if (!potentialChangers || !activeChangers) {
      return;
    }

    const changer = potentialChangers.find((it) => activeChangers.has(it));
    if (changer === undefined) {
      return;
    }

    yield put(applyThingChanger(selectedTid, changer));
  } else if (handler.type === 'action') {
    yield put(handler.action);
  } else if (handler.type === 'pcAreaSubaction') {
    const { pcId, subaction } = handler;
    yield put({
      type: 'PC_AREA_SUBACTION',
      pcId,
      subaction,
    });
  } else {
    console.error('Unrecognized key handler type: ' + handler.type);
    return;
  }
}

function* setTitleByScenarioViewer() {
  const scenarioViewer = yield select(selectScenarioViewer);
  const { mode, scenario, playthrough } = scenarioViewer;

  let title;
  if (mode === 'editor') {
    title = scenario.title;
  } else if (mode === 'gameplay') {
    title = playthrough.title;
  } else {
    console.warn('Unknown ScenarioViewer mode:', mode);
    return;
  }
  setSubtitle(title);
}

function haveSharedHexes(ar1, ar2) {
  let s = new Set(ar1);
  for (const h of ar2) {
    if (s.has(h)) {
      return true;
    }
  }
  return false;
}

function* revealRoomSaga(roomTid) {
  const addedTids = [];
  const addedTidToConfig = {};

  const scenarioViewer = yield select(selectScenarioViewer);

  const { tidToConfig } = scenarioViewer;
  const { party, level } = scenarioViewer.playthrough;

  const roomConfig = tidToConfig[roomTid];

  function add(tid, config) {
    addedTids.push(tid);
    addedTidToConfig[tid] = config;
  }

  for (const [tid, config] of Object.entries(
    scenarioViewer.scenario.tidToConfig
  )) {
    // Skip if thing by this tid is already present (e.g. a two-hex
    // obstacle covering an already-revealed room and a newly-revealed
    // one.)
    if (tidToConfig[tid]) {
      continue;
    }

    const hexes = getHexIdsCoveredByThing(config);

    if (!haveSharedHexes(roomConfig.hexes, hexes)) {
      continue;
    }

    const { thingType } = config;
    if (thingType === 'trap') {
      add(tid, {
        ...config,
        thingType: 'live-trap',
      });
    } else if (thingType === 'chest') {
      add(tid, {
        ...config,
        thingType: 'live-chest',
      });
    } else if (thingType === 'coin') {
      add(tid, {
        ...config,
        thingType: 'live-coin',
        amount: 1,
      });
    } else if (thingType === 'chest') {
      add(tid, {
        ...config,
      });
    } else if (thingType === 'obstacle') {
      add(tid, {
        ...config,
        thingType: 'live-obstacle',
      });
    } else if (thingType === 'obstacle-long') {
      add(tid, {
        ...config,
        thingType: 'live-obstacle-long',
      });
    } else if (thingType === 'obstacle-triangle') {
      add(tid, {
        ...config,
        thingType: 'live-obstacle-triangle',
      });
    } else if (thingType === 'monster') {
      const partySize = party.pcs.length;

      const { imageName: monsterType, pattern } = config;
      const patternLetter = pattern[partySize - 2];
      let shouldPlace = true;
      let version;
      if (patternLetter === undefined) {
        console.warn(
          `No patternLetter for party size ${partySize}, going with normal`,
          config.pattern,
          config
        );
        version = 'normal';
      } else if (patternLetter === 'b') {
        shouldPlace = false;
      } else if (patternLetter === 'w') {
        version = 'normal';
      } else if (patternLetter === 'y') {
        version = 'elite';
      } else {
        console.warn(
          `Unrecognized pattern letter '${patternLetter}', going with normal`,
          config.pattern,
          config
        );
        version = 'normal';
      }

      if (!shouldPlace) {
        continue;
      }

      // XXX detect properly from max count and existing monsters
      const standeeNumber = Math.floor(Math.random() * 6) + 1;

      const monsterConfig = {
        ...config,
        thingType: 'live-monster',
        version,
        hp: getMonsterMaxHp(monsterType, version, level),
        standeeNumber,
      };
      delete monsterConfig.pattern;
      add(tid, monsterConfig);
    }
  }

  yield put(addSeveralThings(addedTids, addedTidToConfig));
  yield put(setThingSetting(roomTid, 'isRevealed', true));
}

function* performThingActionSaga(action) {
  const { tid, payload } = action;
  if (payload === 'REVEAL_ROOM') {
    yield call(revealRoomSaga, tid);
  } else {
    console.error('Unrecognized thing action:', tid, payload);
  }
}

function* resetPlaythroughSaga() {
  const state = yield select(selectScenarioViewer);
  const { playthrough } = state;
  const startedPlaythrough = preparePlaythrough(playthrough);
  const { tids, tidToConfig } = startedPlaythrough;
  const initValue = {
    mode: state.mode,
    scenario: state.scenario,
    playthrough: startedPlaythrough,
    tids,
    tidToConfig,
    global: state.global,
  };
  yield put(initScenarioViewer(initValue));
}

function* generatePngSaga(action) {
  const scenarioViewer = yield select(selectScenarioViewer);

  const intermediary = createPictureIntermediary(scenarioViewer);

  const pngData = yield call(createPngBlob, intermediary);

  yield put(doneExportPng());

  const fileName = createScenarioExportFileName(scenarioViewer.scenario, 'png');
  fileDownload(pngData, fileName, 'image/png');
}

function* generatePdfSaga() {
  const scenario = yield select(selectScenarioToPersist);

  yield put(startGeneratingPdf());
  // TODO different ui to reach generateCampaignPdf
  const pdfData = yield call(generateSingleScenarioPdf, scenario);

  yield put(pdfDataAvailable(pdfData));
}

function* downloadPdfSaga() {
  const scenarioViewer = yield select(selectScenarioViewer);
  const { pdfData } = scenarioViewer;
  const fileName = createScenarioExportFileName(scenarioViewer.scenario, 'pdf');
  const blob = pdfData && pdfData.blob;
  if (!blob) {
    console.warn('Trying to download pdf data which is not generated yet');
    return;
  }
  fileDownload(blob, fileName, 'application/pdf');
}

export function* rootSaga() {
  yield all([
    watchPersistScenarioViewerState(),
    takeEvery(
      ['SET_GLOBAL_SETTING', 'ACTIVATE_TAB_BY_NAME', 'ACTIVATE_TAB_BY_INDEX'],
      persistGlobalState
    ),
    takeEvery('EXPORT_JSON', exportJsonSaga),
    takeEvery('APPLY_THING_CHANGER', applyThingChangerSaga),
    takeEvery('ADD_THING_AUTO', addThingAutoSaga),
    takeEvery('ON_KEY_PRESSED', handleScenarioViewerKeyPressedSaga),
    takeEvery('INIT_SCENARIO_VIEWER', setTitleByScenarioViewer),
    debounce(DEBOUNCE_MS, 'SET_SCENARIO_SETTING', setTitleByScenarioViewer),
    takeEvery('PERFORM_THING_ACTION', performThingActionSaga),
    takeEvery('RESET_PLAYTHROUGH', resetPlaythroughSaga),
    takeEvery('START_EXPORT_PNG', generatePngSaga),
    debounce(DEBOUNCE_MS, 'REGENERATE_PDF', generatePdfSaga),
    takeEvery('SHOW_PDF_MODAL', generatePdfSaga),
    takeEvery('DOWNLOAD_PDF', downloadPdfSaga),
  ]);
}
