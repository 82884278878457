const X = 'X';
export const abilityCards = {
  brute: [
    {
      imageName: 'trample',
      title: 'Trample',
      level: 1,
      initiative: 72,
      number: 1,
    },
    {
      imageName: 'eye-for-an-eye',
      title: 'Eye for an Eye',
      level: 1,
      initiative: 18,
      number: 2,
    },
    {
      imageName: 'sweeping-blow',
      title: 'Sweeping Blow',
      level: 1,
      initiative: 64,
      number: 3,
    },
    {
      imageName: 'provoking-roar',
      title: 'Provoking Roar',
      level: 1,
      initiative: 10,
      number: 4,
    },
    {
      imageName: 'overwhelming-assault',
      title: 'Overwhelming Assault',
      level: 1,
      initiative: 61,
      number: 5,
    },
    {
      imageName: 'grab-and-go',
      title: 'Grab and Go',
      level: 1,
      initiative: 87,
      number: 6,
    },
    {
      imageName: 'warding-strength',
      title: 'Warding Strength',
      level: 1,
      initiative: 32,
      number: 7,
    },
    {
      imageName: 'shield-bash',
      title: 'Shield Bash',
      level: 1,
      initiative: 15,
      number: 8,
    },
    {
      imageName: 'leaping-cleave',
      title: 'Leaping Cleave',
      level: 1,
      initiative: 54,
      number: 9,
    },
    {
      imageName: 'spare-dagger',
      title: 'Spare Dagger',
      level: 1,
      initiative: 27,
      number: 10,
    },
    {
      imageName: 'skewer',
      title: 'skewer',
      level: 'X',
      initiative: 35,
      number: 11,
    },
    {
      imageName: 'balanced-measure',
      title: 'Balanced Measure',
      level: 'X',
      initiative: 77,
      number: 12,
    },
    {
      imageName: 'wall-of-doom',
      title: 'wall-of-doom',
      level: 'X',
      initiative: 20,
      number: 13,
    },
    {
      imageName: 'fatal-advance',
      title: 'Fatal Advance',
      level: 2,
      initiative: 40,
      number: 14,
    },
    {
      imageName: 'juggernaut',
      title: 'Juggernaut',
      level: 2,
      initiative: 34,
      number: 15,
    },
    {
      imageName: 'hook-and-chain',
      title: 'Hook and Chain',
      level: 3,
      initiative: 42,
      number: 16,
    },
    {
      imageName: 'brute-force',
      title: 'Brute Force',
      level: 3,
      initiative: 51,
      number: 17,
    },
    {
      imageName: 'unstoppable-charge',
      title: 'Unstoppable Charge',
      level: 4,
      initiative: 86,
      number: 18,
    },
    {
      imageName: 'devastating-hack',
      title: 'Devastating Hack',
      level: 4,
      initiative: 27,
      number: 19,
    },
    {
      imageName: 'whirlwind',
      title: 'Whirlwind',
      level: 5,
      initiative: 28,
      number: 20,
    },
    {
      imageName: 'skirmishing-maneuver',
      title: 'Skirmishing Maneuver',
      level: 5,
      initiative: 29,
      number: 21,
    },
    {
      imageName: 'quietus',
      title: 'Quietus',
      level: 6,
      initiative: 57,
      number: 22,
    },
    {
      imageName: 'immovable-phalanx',
      title: 'Immovable Phalanx',
      level: 6,
      initiative: 17,
      number: 23,
    },
    {
      imageName: 'defensive-tactics',
      title: 'Defensive Tactics',
      level: 7,
      initiative: 26,
      number: 24,
    },
    {
      imageName: 'crippling-offensive',
      title: 'Crippling Offensive',
      level: 7,
      initiative: 33,
      number: 25,
    },
    {
      imageName: 'frenzied-onslaught',
      title: 'Frenzied Onslaught',
      level: 8,
      initiative: 41,
      number: 26,
    },
    {
      imageName: 'selfish-retribution',
      title: 'Selfish Retribution',
      level: 8,
      initiative: 12,
      number: 27,
    },
    {
      imageName: 'king-of-the-hill',
      title: 'King of the Hill',
      level: 9,
      initiative: 39,
      number: 28,
    },
    {
      imageName: 'face-your-end',
      title: 'Face Your End',
      level: 9,
      initiative: 67,
      number: 29,
    },
  ],
  cragheart: [
    {
      title: 'Avalanche',
      imageName: 'avalanche',
      level: 1,
      initiative: 75,
      number: 118,
    },
    {
      title: 'Backup Ammunition',
      imageName: 'backup-ammunition',
      level: 1,
      initiative: 77,
      number: 121,
    },
    {
      title: 'Blind Destruction',
      imageName: 'blind-destruction',
      level: 9,
      initiative: 74,
      number: 144,
    },
    {
      title: 'Blunt Force',
      imageName: 'blunt-force',
      level: 3,
      initiative: 21,
      number: 133,
    },
    {
      title: 'Brutal Momentum',
      imageName: 'brutal-momentum',
      level: 7,
      initiative: 52,
      number: 141,
    },
    {
      title: 'Cataclysm',
      imageName: 'cataclysm',
      level: 6,
      initiative: 26,
      number: 139,
    },
    {
      title: 'Clear the Way',
      imageName: 'clear-the-way',
      level: 3,
      initiative: 43,
      number: 132,
    },
    {
      title: 'Crater',
      imageName: 'crater',
      level: 1,
      initiative: 61,
      number: 124,
    },
    {
      title: 'Crushing Grasp',
      imageName: 'crushing-grasp',
      level: 1,
      initiative: 35,
      number: 117,
    },
    {
      title: 'Dig Pit',
      imageName: 'dig-pit',
      level: 6,
      initiative: 78,
      number: 138,
    },
    {
      title: 'Dirt Tornado',
      imageName: 'dirt-tornado',
      level: 1,
      initiative: 82,
      number: 125,
    },
    {
      title: 'Earthen Clod',
      imageName: 'earthen-clod',
      level: 1,
      initiative: 38,
      number: 126,
    },
    {
      title: 'Explosive Punch',
      imageName: 'explosive-punch',
      level: 2,
      initiative: 28,
      number: 130,
    },
    {
      title: 'Forceful Storm',
      imageName: 'forceful-storm',
      level: X,
      initiative: 53,
      number: 128,
    },
    {
      title: 'Heaving Swing',
      imageName: 'heaving-swing',
      level: X,
      initiative: 57,
      number: 127,
    },
    {
      title: 'Kinetic Assult',
      imageName: 'kinetic-assault',
      level: 4,
      initiative: 19,
      number: 135,
    },
    {
      title: 'Lumbering Bash',
      imageName: 'lumbering-bash',
      level: 8,
      initiative: 85,
      number: 143,
    },
    {
      title: 'Massive Boulder',
      imageName: 'massive-boulder',
      level: 1,
      initiative: 87,
      number: 120,
    },
    {
      title: 'Meteor',
      imageName: 'meteor',
      level: 7,
      initiative: 23,
      number: 140,
    },
    {
      title: "Nature's Lift",
      imageName: 'natures-lift',
      level: X,
      initiative: 64,
      number: 129,
    },
    {
      title: 'Opposing Strike',
      imageName: 'opposing-strike',
      level: 1,
      initiative: 46,
      number: 116,
    },
    {
      title: 'Petrify',
      imageName: 'petrify',
      level: 5,
      initiative: 47,
      number: 136,
    },
    {
      title: 'Pulverize',
      imageName: 'pulverize',
      level: 9,
      initiative: 31,
      number: 145,
    },
    {
      title: 'Rock Slide',
      imageName: 'rock-slide',
      level: 4,
      initiative: 81,
      number: 134,
    },
    {
      title: 'Rock Tunnel',
      imageName: 'rock-tunnel',
      level: 1,
      initiative: 41,
      number: 122,
    },
    {
      title: 'Rocky End',
      imageName: 'rocky-end',
      level: 8,
      initiative: 37,
      number: 142,
    },
    {
      title: 'Rumbling Advance',
      imageName: 'rumbling-advance',
      level: 1,
      initiative: 29,
      number: 119,
    },
    {
      title: 'Sentient Growth',
      imageName: 'sentient-growth',
      level: 2,
      initiative: 78,
      number: 131,
    },
    {
      title: 'Stone Pummel',
      imageName: 'stone-pummel',
      level: 5,
      initiative: 32,
      number: 137,
    },
    {
      title: 'Unstable Upheaval',
      imageName: 'unstable-upheaval',
      level: 1,
      initiative: 13,
      number: 123,
    },
  ],
  mindthief: [
    {
      title: 'Brain Leech',
      imageName: 'brain-leech',
      level: 3,
      initiative: 16,
      number: 161,
    },
    {
      title: 'Corrupting Embrace',
      imageName: 'corrupting-embrace',
      level: 6,
      initiative: 39,
      number: 167,
    },
    {
      title: 'Cranium Overload',
      imageName: 'cranium-overload',
      level: 4,
      initiative: 5,
      number: 164,
    },
    {
      title: 'Dark Frenzy',
      imageName: 'dark-frenzy',
      level: 6,
      initiative: 10,
      number: 168,
    },
    {
      title: 'Domination',
      imageName: 'domination',
      level: 8,
      initiative: 13,
      number: 172,
    },
    {
      title: 'Empathetic Assault',
      imageName: 'empathetic-assault',
      level: 1,
      initiative: 11,
      number: 155,
    },
    {
      title: 'Fearsome Blade',
      imageName: 'fearsome-blade',
      level: 1,
      initiative: 27,
      number: 148,
    },
    {
      title: 'Feedback Loop',
      imageName: 'feedback-loop',
      level: 1,
      initiative: 79,
      number: 149,
    },
    {
      title: 'Frigid Apparition',
      imageName: 'frigid-apparition',
      level: X,
      initiative: 29,
      number: 158,
    },
    {
      title: 'Frozen Mind',
      imageName: 'frozen-mind',
      level: 5,
      initiative: 81,
      number: 166,
    },
    {
      title: 'Gnawing Horde',
      imageName: 'gnawing-horde',
      level: 1,
      initiative: 82,
      number: 150,
    },
    {
      title: 'Hostile Takeover',
      imageName: 'hostile-takeover',
      level: 2,
      initiative: 9,
      number: 160,
    },
    {
      title: 'Into the Night',
      imageName: 'into-the-night',
      level: 1,
      initiative: 14,
      number: 147,
    },
    {
      title: 'Many as One',
      imageName: 'many-as-one',
      level: 9,
      initiative: 91,
      number: 173,
    },
    {
      title: 'Mass Hysteria',
      imageName: 'mass-hysteria',
      level: 5,
      initiative: 12,
      number: 165,
    },
    {
      title: 'Parasitic Influence',
      imageName: 'parasitic-influence',
      level: 1,
      initiative: 71,
      number: 152,
    },
    {
      title: 'Perversive Edge',
      imageName: 'perverse-edge',
      level: 1,
      initiative: 8,
      number: 154,
    },
    {
      title: 'Phantasmal Killer',
      imageName: 'phantasmal-killer',
      level: 9,
      initiative: 67,
      number: 174,
    },
    {
      title: 'Pilfer',
      imageName: 'pilfer',
      level: 4,
      initiative: 68,
      number: 163,
    },
    {
      title: 'Possession',
      imageName: 'possession',
      level: X,
      initiative: 51,
      number: 157,
    },
    {
      title: 'Psychic Projection',
      imageName: 'psychic-projection',
      level: 7,
      initiative: 92,
      number: 170,
    },
    {
      title: 'Scurry',
      imageName: 'scurry',
      level: 1,
      initiative: 20,
      number: 153,
    },
    {
      title: 'Shared Nightmare',
      imageName: 'shared-nightmare',
      level: 8,
      initiative: 7,
      number: 171,
    },
    {
      title: 'Silent Scream',
      imageName: 'silent-scream',
      level: 3,
      initiative: 73,
      number: 162,
    },
    {
      title: 'Submissive Affliction',
      imageName: 'submissive-affliction',
      level: 1,
      initiative: 48,
      number: 146,
    },
    {
      title: "The Mind's Weakness",
      imageName: 'the-minds-weakness',
      level: 1,
      initiative: 75,
      number: 151,
    },
    {
      title: 'Vicious Blood',
      imageName: 'vicious-blood',
      level: 7,
      initiative: 83,
      number: 169,
    },
    {
      title: 'Withering Claw',
      imageName: 'withering-claw',
      level: X,
      initiative: 77,
      number: 156,
    },
    {
      title: 'Wretched Creature',
      imageName: 'wretched-creature',
      level: 2,
      initiative: 84,
      number: 159,
    },
  ],
  scoundrel: [
    {
      title: 'Backstab',
      imageName: 'backstab',
      level: 1,
      initiative: 6,
      number: 91,
    },
    {
      title: 'Burning Oil',
      imageName: 'burning-oil',
      level: 6,
      initiative: 95,
      number: 109,
    },
    {
      title: 'Crippling Poison',
      imageName: 'crippling-poison',
      level: 6,
      initiative: 30,
      number: 108,
    },
    {
      title: 'Cull the Weak',
      imageName: 'cull-the-weak',
      level: 5,
      initiative: 16,
      number: 106,
    },
    {
      title: "Duelist's Advance",
      imageName: 'duelists-advance',
      level: 3,
      initiative: 16,
      number: 103,
    },
    {
      title: 'Flanking Strike',
      imageName: 'flanking-strike',
      level: 1,
      initiative: 4,
      number: 89,
    },
    {
      title: 'Flitlock',
      imageName: 'flintlock',
      level: 2,
      initiative: 90,
      number: 101,
    },
    {
      title: 'Flurry of Blades',
      imageName: 'flurry-of-blades',
      level: 4,
      initiative: 3,
      number: 104,
    },
    {
      title: 'Gruesome Advantage',
      imageName: 'gruesome-advantage',
      level: 4,
      initiative: 62,
      number: 105,
    },
    {
      title: 'Hidden Daggers',
      imageName: 'hidden-daggers',
      level: 3,
      initiative: 85,
      number: 102,
    },
    {
      title: 'Long Con',
      imageName: 'long-con',
      level: 9,
      initiative: 2,
      number: 114,
    },
    {
      title: 'Open Wound',
      imageName: 'open-wound',
      level: 2,
      initiative: 11,
      number: 100,
    },
    {
      title: "Pain's End",
      imageName: 'pains-end',
      level: 8,
      initiative: 38,
      number: 113,
    },
    {
      title: 'Quick Hands',
      imageName: 'quick-hands',
      level: 1,
      initiative: 64,
      number: 95,
    },
    {
      title: 'Single Out',
      imageName: 'single-out',
      level: 1,
      initiative: 86,
      number: 88,
    },
    {
      title: 'Sinister Opportunity',
      imageName: 'sinister-opportunity',
      level: X,
      initiative: 93,
      number: 97,
    },
    {
      title: 'Smoke Bomb',
      imageName: 'smoke-bomb',
      level: 1,
      initiative: 12,
      number: 90,
    },
    {
      title: 'Special Mixture',
      imageName: 'special-mixture',
      level: 1,
      initiative: 33,
      number: 96,
    },
    {
      title: 'Spring the Trap',
      imageName: 'spring-the-trap',
      level: 7,
      initiative: 13,
      number: 111,
    },
    {
      title: 'Stick to the Shadows',
      imageName: 'stick-to-the-shadows',
      level: 7,
      initiative: 26,
      number: 110,
    },
    {
      title: 'Stiletto Storm',
      imageName: 'stiletto-storm',
      level: 8,
      initiative: 80,
      number: 112,
    },
    {
      title: 'Swift Bow',
      imageName: 'swift-bow',
      level: X,
      initiative: 36,
      number: 99,
    },
    {
      title: "Thief's Knack",
      imageName: 'thiefs-knack',
      level: 1,
      initiative: 23,
      number: 92,
    },
    {
      title: 'Throwing Knives',
      imageName: 'throwing-knives',
      level: 1,
      initiative: 10,
      number: 94,
    },
    {
      title: "Trickster's Reversal",
      imageName: 'tricksters-reversal',
      level: X,
      initiative: 9,
      number: 98,
    },
    {
      title: 'Venom Shiv',
      imageName: 'venom-shiv',
      level: 1,
      initiative: 60,
      number: 93,
    },
    {
      title: 'Visage of the Inevitable',
      imageName: 'visage-of-the-inevitable',
      level: 5,
      initiative: 88,
      number: 107,
    },
    {
      title: 'Watch It Burn',
      imageName: 'watch-it-burn',
      level: 9,
      initiative: 98,
      number: 115,
    },
  ],
  spellweaver: [
    {
      title: 'Aid from the Ether',
      imageName: 'aid-from-the-ether',
      level: X,
      initiative: 91,
      number: 71,
    },
    {
      title: 'Black Hole',
      imageName: 'black-hole',
      level: 9,
      initiative: 41,
      number: 86,
    },
    {
      title: 'Chromatic Explosion',
      imageName: 'chromatic-explosion',
      level: 5,
      initiative: 71,
      number: 78,
    },
    {
      title: 'Cold Fire',
      imageName: 'cold-fire',
      level: 3,
      initiative: 67,
      number: 74,
    },
    {
      title: 'Cold Front',
      imageName: 'cold-front',
      level: 8,
      initiative: 61,
      number: 85,
    },
    {
      title: 'Crackling Air',
      imageName: 'crackling-air',
      level: X,
      initiative: 25,
      number: 69,
    },
    {
      title: 'Elemental Aid',
      imageName: 'elemental-aid',
      level: 3,
      initiative: 84,
      number: 75,
    },
    {
      title: 'Engulfed in Flames',
      imageName: 'engulfed-in-flames',
      level: 5,
      initiative: 44,
      number: 79,
    },
    {
      title: 'Fire Orbs',
      imageName: 'fire-orbs',
      level: 1,
      initiative: 67,
      number: 61,
    },
    {
      title: 'Flame Strike',
      imageName: 'flame-strike',
      level: 1,
      initiative: 36,
      number: 67,
    },
    {
      title: 'Flashing Burst',
      imageName: 'flashing-burst',
      level: 2,
      initiative: 26,
      number: 72,
    },
    {
      title: 'Forked Beam',
      imageName: 'forked-beam',
      level: 4,
      initiative: 20,
      number: 77,
    },
    {
      title: 'Freezing Nova',
      imageName: 'freezing-nova',
      level: 1,
      initiative: 21,
      number: 64,
    },
    {
      title: 'Frost Armor',
      imageName: 'frost-armor',
      level: 1,
      initiative: 20,
      number: 66,
    },
    {
      title: 'Frozen Night',
      imageName: 'frozen-night',
      level: 6,
      initiative: 46,
      number: 81,
    },
    {
      title: 'Hardened Spikes',
      imageName: 'hardened-spikes',
      level: X,
      initiative: 26,
      number: 70,
    },
    {
      title: 'Icy Blast',
      imageName: 'icy-blast',
      level: 2,
      initiative: 66,
      number: 73,
    },
    {
      title: 'Impaling Eruption',
      imageName: 'impaling-eruption',
      level: 1,
      initiative: 70,
      number: 62,
    },
    {
      title: 'Inferno',
      imageName: 'inferno',
      level: 9,
      initiative: 19,
      number: 87,
    },
    {
      title: 'Living Torch',
      imageName: 'living-torch',
      level: 6,
      initiative: 96,
      number: 80,
    },
    {
      title: 'Mana Bolt',
      imageName: 'mana-bolt',
      level: 1,
      initiative: 7,
      number: 65,
    },
    {
      title: 'Reviving Ether',
      imageName: 'reviving-ether',
      level: 1,
      initiative: 80,
      number: 63,
    },
    {
      title: 'Ride the Wind',
      imageName: 'ride-the-wind',
      level: 1,
      initiative: 83,
      number: 68,
    },
    {
      title: 'Spirit of Doom',
      imageName: 'spirit-of-doom',
      level: 4,
      initiative: 81,
      number: 76,
    },
    {
      title: 'Stone Fists',
      imageName: 'stone-fists',
      level: 7,
      initiative: 62,
      number: 83,
    },
    {
      title: 'Twin Restoration',
      imageName: 'twin-restoration',
      level: 7,
      initiative: 75,
      number: 82,
    },
    {
      title: 'Zephyr Wings',
      imageName: 'zephyr-wings',
      level: 8,
      initiative: 85,
      number: 84,
    },
  ],
  tinkerer: [
    {
      title: 'Auto Turret',
      imageName: 'auto-turret',
      level: 6,
      initiative: 34,
      number: 54,
    },
    {
      title: 'Chimeric Formula',
      imageName: 'chimeric-formula',
      level: 9,
      initiative: 43,
      number: 60,
    },
    {
      title: 'Crank Bow',
      imageName: 'crank-bow',
      level: 3,
      initiative: 66,
      number: 48,
    },
    {
      title: 'Curative Aerosol',
      imageName: 'curative-aerosol',
      level: 7,
      initiative: 21,
      number: 56,
    },
    {
      title: 'Dangerous Contraption',
      imageName: 'dangerous-contraption',
      level: 4,
      initiative: 84,
      number: 49,
    },
    {
      title: 'Disintegrating Beam',
      imageName: 'disintegration-beam',
      level: 5,
      initiative: 36,
      number: 52,
    },
    {
      title: 'Disorienting Flash',
      imageName: 'disorienting-flash',
      level: 2,
      initiative: 73,
      number: 46,
    },
    {
      title: 'Energizing Tonic',
      imageName: 'energizing-tonic',
      level: 1,
      initiative: 16,
      number: 39,
    },
    {
      title: 'Enhancement Field',
      imageName: 'enhancement-field',
      level: 1,
      initiative: 61,
      number: 40,
    },
    {
      title: 'Flamethrower',
      imageName: 'flamethrower',
      level: 1,
      initiative: 47,
      number: 32,
    },
    {
      title: 'Gas Canister',
      imageName: 'gas-canister',
      level: 6,
      initiative: 71,
      number: 53,
    },
    {
      title: 'Harmless Contraption',
      imageName: 'harmless-contraption',
      level: 1,
      initiative: 74,
      number: 31,
    },
    {
      title: 'Harsh Stimulants',
      imageName: 'harsh-stimulants',
      level: 8,
      initiative: 27,
      number: 58,
    },
    {
      title: 'Hook Gun',
      imageName: 'hook-gun',
      level: 1,
      initiative: 72,
      number: 33,
    },
    {
      title: 'Ink Bomb',
      imageName: 'ink-bomb',
      level: 1,
      initiative: 74,
      number: 34,
    },
    {
      title: 'Jet Propulsion',
      imageName: 'jet-propulsion',
      level: 8,
      initiative: 38,
      number: 57,
    },
    {
      title: 'Lethal Injection',
      imageName: 'lethal-injection',
      level: 9,
      initiative: 85,
      number: 59,
    },
    {
      title: 'Micro Bots',
      imageName: 'micro-bots',
      level: 4,
      initiative: 22,
      number: 50,
    },
    {
      title: 'Murderous Contraption',
      imageName: 'murderous-contraption',
      level: 7,
      initiative: 94,
      number: 55,
    },
    {
      title: 'Net Shooter',
      imageName: 'net-shooter',
      level: 1,
      initiative: 19,
      number: 35,
    },
    {
      title: 'Noxious Vials',
      imageName: 'noxious-vials',
      level: 5,
      initiative: 75,
      number: 51,
    },
    {
      title: 'Potent Potables',
      imageName: 'potent-potables',
      level: X,
      initiative: 46,
      number: 44,
    },
    {
      title: 'Proximity Mine',
      imageName: 'proximity-mine',
      level: 1,
      initiative: 62,
      number: 30,
    },
    {
      title: 'Reinvigorating Elixir',
      imageName: 'reinvigorating-elixir',
      level: 1,
      initiative: 37,
      number: 37,
    },
    {
      title: 'Restorative Mist',
      imageName: 'restorative-mist',
      level: 1,
      initiative: 17,
      number: 38,
    },
    {
      title: 'Reviving Shock',
      imageName: 'reviving-shock',
      level: X,
      initiative: 34,
      number: 42,
    },
    {
      title: 'Stamina Booster',
      imageName: 'stamina-booster',
      level: 2,
      initiative: 48,
      number: 45,
    },
    {
      title: 'Stun Shot',
      imageName: 'stun-shot',
      level: 1,
      initiative: 20,
      number: 36,
    },
    {
      title: "Tinkerer's Tools",
      imageName: 'tinkerers-tools',
      level: 3,
      initiative: 26,
      number: 47,
    },
    {
      title: 'Toxic Bolt',
      imageName: 'toxic-bolt',
      level: 1,
      initiative: 18,
      number: 41,
    },
    {
      title: 'Volatile Concoction',
      imageName: 'volatile-concoction',
      level: X,
      initiative: 76,
      number: 43,
    },
  ],
};

export const abilityCidToCard = {};
const professionToCids = {};
for (const [profession, cards] of Object.entries(abilityCards)) {
  professionToCids[profession] = [];

  for (const card of cards) {
    const cid = `${profession}/${card.imageName}`;
    abilityCidToCard[cid] = card;

    professionToCids[profession].push(cid);
  }
}

export function getAllCidsOfProfession(profession) {
  return professionToCids[profession];
}

const isStarting = (cid) => abilityCidToCard[cid].level === 1;

export function getDefaultAbilityCids(profession) {
  const cids = professionToCids[profession];
  const result = cids.filter(isStarting);
  return result;
}
