export function selectThing(tid) {
  return {
    type: 'SELECT_THING',
    tid,
  };
}

export function unselectThing() {
  return {
    type: 'UNSELECT_THING',
  };
}

export function setThingSetting(tid, attribute, newValue) {
  return {
    type: 'SET_THING_SETTING',
    tid,
    attribute,
    newValue,
  };
}

export function setSeveralThingSettings(tid, propToValue) {
  return {
    type: 'SET_SEVERAL_THING_SETTINGS',
    tid,
    propToValue,
  };
}

export function setSettingsOfThings(tidToPropToValue) {
  return {
    type: 'SET_SETTINGS_OF_THINGS',
    tidToPropToValue,
  };
}

export function performThingAction(tid, payload) {
  return {
    type: 'PERFORM_THING_ACTION',
    tid,
    payload,
  };
}

export function setScenarioSetting(attribute, newValue) {
  return {
    type: 'SET_SCENARIO_SETTING',
    attribute,
    newValue,
  };
}

export function setPlaythroughSetting(attribute, newValue) {
  return {
    type: 'SET_PLAYTHROUGH_SETTING',
    attribute,
    newValue,
  };
}

export function setGlobalSetting(attribute, newValue) {
  return {
    type: 'SET_GLOBAL_SETTING',
    attribute,
    newValue,
  };
}

export function initScenarioViewer(payload) {
  return {
    type: 'INIT_SCENARIO_VIEWER',
    payload,
  };
}

export function closeScenarioViewer() {
  return {
    type: 'CLOSE_SCENARIO_VIEWER',
  };
}

export function startAddingThing(kindForAdding) {
  return {
    type: 'START_ADDING_THING',
    kindForAdding,
  };
}

export function addThingAuto(thing) {
  return {
    type: 'ADD_THING_AUTO',
    thing,
  };
}

export function closeModal() {
  return {
    type: 'CLOSE_MODAL',
  };
}

export function exportJson() {
  return {
    type: 'EXPORT_JSON',
  };
}

export function applyThingChanger(tid, changer) {
  return {
    type: 'APPLY_THING_CHANGER',
    tid,
    changer,
  };
}

export function removeThing(tid) {
  return {
    type: 'REMOVE_THING',
    tid,
  };
}

export function addThing(tid, config, options = {}) {
  return {
    type: 'ADD_THING',
    tid,
    config,
    options,
  };
}

export function addSeveralThings(addedTids, addedTidToConfig) {
  return {
    type: 'ADD_SEVERAL_THINGS',
    addedTids,
    addedTidToConfig,
  };
}

export function onKeyPressed(key) {
  return {
    type: 'ON_KEY_PRESSED',
    key,
  };
}

export function showModal(modal) {
  return {
    type: 'SHOW_MODAL',
    modal,
  };
}

export function activateTabByName(tabName) {
  return {
    type: 'ACTIVATE_TAB_BY_NAME',
    tabName,
  };
}

export function activateTabByIndex(index) {
  return {
    type: 'ACTIVATE_TAB_BY_INDEX',
    index,
  };
}

export function showPdfModal() {
  return {
    type: 'SHOW_PDF_MODAL',
  };
}

export function doneExportPng() {
  return { type: 'DONE_EXPORT_PNG' };
}

export function pdfDataAvailable(pdfData) {
  return {
    type: 'PDF_DATA_AVAILABLE',
    pdfData,
  };
}

export function startExportPng() {
  return {
    type: 'START_EXPORT_PNG',
  };
}

export function regeneratePdf() {
  return {
    type: 'REGENERATE_PDF',
  };
}

export function startGeneratingPdf() {
  return {
    type: 'START_GENERATING_PDF',
  };
}

export function downloadPdf() {
  return {
    type: 'DOWNLOAD_PDF',
  };
}

export function showCannotChangeTile(tid, show = true) {
  return {
    type: 'SHOW_CANNOT_CHANGE_TILE',
    tid,
    show,
  };
}
