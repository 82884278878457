export const defaultScenarioTitle = 'Dark Dungeon';

export const defaultScenarioBody = `%green Introduction:

%blue Special Rules:

%rule 1

%red Conclusion:

%brown Reward:

%box

Some reward.

%endbox
`;
