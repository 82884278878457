export const tileNames = [
  'A1a',
  'A1b',
  'A2a',
  'A2b',
  'A3a',
  'A3b',
  'A4a',
  'A4b',
  'B1a',
  'B1b',
  'B2a',
  'B2b',
  'B3a',
  'B3b',
  'B4a',
  'B4b',
  'C1a',
  'C1b',
  'C2a',
  'C2b',
  'D1a',
  'D1b',
  'D2a',
  'D2b',
  'E1a',
  'E1b',
  'F1a',
  'F1b',
  'G1a',
  'G1b',
  'G2a',
  'G2b',
  'H1a',
  'H1b',
  'H2a',
  'H2b',
  'H3a',
  'H3b',
  'I1a',
  'I1b',
  'I2a',
  'I2b',
  'J1a',
  'J1b',
  'J2a',
  'J2b',
  'K1a',
  'K1b',
  'K2a',
  'K2b',
  'L1a',
  'L1b',
  'L2a',
  'L2b',
  'L3a',
  'L3b',
  'M1a',
  'M1b',
  'N1a',
  'N1b',
];
