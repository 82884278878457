export const hexNames = {
  '-37,-37': 'hob',
  '-37,-36': 'loose',
  '-37,-35': 'llano',
  '-37,-34': 'sea',
  '-37,-33': 'climb',
  '-37,-32': 'anti',
  '-37,-31': 'halve',
  '-37,-30': 'groom',
  '-37,-29': 'urine',
  '-37,-28': 'begot',
  '-37,-27': 'lead',
  '-37,-26': 'boot',
  '-37,-25': 'case',
  '-37,-24': 'spun',
  '-37,-23': 'welt',
  '-37,-22': 'vodka',
  '-37,-21': 'lucre',
  '-37,-20': 'wryly',
  '-37,-19': 'savor',
  '-37,-18': 'niece',
  '-37,-17': 'taunt',
  '-37,-16': 'them',
  '-37,-15': 'aural',
  '-37,-14': 'merge',
  '-37,-13': 'sued',
  '-37,-12': 'sexed',
  '-37,-11': 'vow',
  '-37,-10': 'zilch',
  '-37,-9': 'oboe',
  '-37,-8': 'bar',
  '-37,-7': 'judo',
  '-37,-6': 'tome',
  '-37,-5': 'wade',
  '-37,-4': 'safer',
  '-37,-3': 'oxen',
  '-37,-2': 'gelid',
  '-37,-1': 'hug',
  '-37,0': 'clog',
  '-37,1': 'polio',
  '-37,2': 'fetch',
  '-37,3': 'clod',
  '-37,4': 'fiend',
  '-37,5': 'peppy',
  '-37,6': 'navel',
  '-37,7': 'dared',
  '-37,8': 'scamp',
  '-37,9': 'hide',
  '-37,10': 'ware',
  '-37,11': 'bole',
  '-37,12': 'yea',
  '-37,13': 'sandy',
  '-37,14': 'dough',
  '-37,15': 'stilt',
  '-37,16': 'spicy',
  '-37,17': 'inept',
  '-37,18': 'acing',
  '-37,19': 'mossy',
  '-37,20': 'slew',
  '-37,21': 'batik',
  '-37,22': 'handy',
  '-37,23': 'dined',
  '-37,24': 'endue',
  '-37,25': 'toe',
  '-37,26': 'under',
  '-37,27': 'hedge',
  '-37,28': 'meow',
  '-37,29': 'god',
  '-37,30': 'teary',
  '-37,31': 'faker',
  '-37,32': 'snuck',
  '-37,33': 'sis',
  '-37,34': 'yack',
  '-37,35': 'math',
  '-37,36': 'miss',
  '-37,37': 'sage',
  '-36,-37': 'mayo',
  '-36,-36': 'bling',
  '-36,-35': 'meld',
  '-36,-34': 'hay',
  '-36,-33': 'rebel',
  '-36,-32': 'edged',
  '-36,-31': 'chute',
  '-36,-30': 'cant',
  '-36,-29': 'stank',
  '-36,-28': 'sky',
  '-36,-27': 'zebu',
  '-36,-26': 'hobby',
  '-36,-25': 'rigid',
  '-36,-24': 'lest',
  '-36,-23': 'pilau',
  '-36,-22': 'sappy',
  '-36,-21': 'dolly',
  '-36,-20': 'park',
  '-36,-19': 'wight',
  '-36,-18': 'gage',
  '-36,-17': 'cusp',
  '-36,-16': 'debit',
  '-36,-15': 'lure',
  '-36,-14': 'bench',
  '-36,-13': 'petal',
  '-36,-12': 'pulpy',
  '-36,-11': 'logon',
  '-36,-10': 'cries',
  '-36,-9': 'duff',
  '-36,-8': 'inert',
  '-36,-7': 'ajar',
  '-36,-6': 'ingot',
  '-36,-5': 'shlep',
  '-36,-4': 'creak',
  '-36,-3': 'jinn',
  '-36,-2': 'worth',
  '-36,-1': 'sibyl',
  '-36,0': 'sense',
  '-36,1': 'cache',
  '-36,2': 'wipe',
  '-36,3': 'what',
  '-36,4': 'peck',
  '-36,5': 'seem',
  '-36,6': 'elder',
  '-36,7': 'money',
  '-36,8': 'snag',
  '-36,9': 'stow',
  '-36,10': 'tease',
  '-36,11': 'thymi',
  '-36,12': 'boast',
  '-36,13': 'oak',
  '-36,14': 'arc',
  '-36,15': 'poser',
  '-36,16': 'manna',
  '-36,17': 'tat',
  '-36,18': 'cocky',
  '-36,19': 'draw',
  '-36,20': 'garb',
  '-36,21': 'deify',
  '-36,22': 'corn',
  '-36,23': 'faze',
  '-36,24': 'lapse',
  '-36,25': 'libel',
  '-36,26': 'semi',
  '-36,27': 'fuss',
  '-36,28': 'twin',
  '-36,29': 'revel',
  '-36,30': 'gook',
  '-36,31': 'art',
  '-36,32': 'speck',
  '-36,33': 'apply',
  '-36,34': 'level',
  '-36,35': 'demur',
  '-36,36': 'cub',
  '-36,37': 'noisy',
  '-35,-37': 'snort',
  '-35,-36': 'swum',
  '-35,-35': 'gaffe',
  '-35,-34': 'pawn',
  '-35,-33': 'hie',
  '-35,-32': 'fest',
  '-35,-31': 'dance',
  '-35,-30': 'flash',
  '-35,-29': 'fang',
  '-35,-28': 'cake',
  '-35,-27': 'wryer',
  '-35,-26': 'robe',
  '-35,-25': 'shoal',
  '-35,-24': 'fancy',
  '-35,-23': 'cagy',
  '-35,-22': 'pasta',
  '-35,-21': 'servo',
  '-35,-20': 'craw',
  '-35,-19': 'café',
  '-35,-18': 'far',
  '-35,-17': 'lout',
  '-35,-16': 'bated',
  '-35,-15': 'spray',
  '-35,-14': 'wand',
  '-35,-13': 'apter',
  '-35,-12': 'cat',
  '-35,-11': 'boo',
  '-35,-10': 'wino',
  '-35,-9': 'apple',
  '-35,-8': 'skied',
  '-35,-7': 'tepee',
  '-35,-6': 'bleat',
  '-35,-5': 'brake',
  '-35,-4': 'tend',
  '-35,-3': 'rick',
  '-35,-2': 'sour',
  '-35,-1': 'knife',
  '-35,0': 'brand',
  '-35,1': 'per',
  '-35,2': 'hoot',
  '-35,3': 'haze',
  '-35,4': 'gumbo',
  '-35,5': 'fit',
  '-35,6': 'condo',
  '-35,7': 'awing',
  '-35,8': 'sold',
  '-35,9': 'aged',
  '-35,10': 'width',
  '-35,11': 'bogey',
  '-35,12': 'gypsy',
  '-35,13': 'lope',
  '-35,14': 'tenth',
  '-35,15': 'omen',
  '-35,16': 'purse',
  '-35,17': 'plied',
  '-35,18': 'lire',
  '-35,19': 'close',
  '-35,20': 'trend',
  '-35,21': 'miked',
  '-35,22': 'ferry',
  '-35,23': 'rapt',
  '-35,24': 'exile',
  '-35,25': 'cavil',
  '-35,26': 'smit',
  '-35,27': 'coyer',
  '-35,28': 'scow',
  '-35,29': 'airy',
  '-35,30': 'spire',
  '-35,31': 'goof',
  '-35,32': 'yaw',
  '-35,33': 'bah',
  '-35,34': 'paced',
  '-35,35': 'levee',
  '-35,36': 'moose',
  '-35,37': 'dug',
  '-34,-37': 'icily',
  '-34,-36': 'force',
  '-34,-35': 'month',
  '-34,-34': 'mavin',
  '-34,-33': 'lieu',
  '-34,-32': 'fused',
  '-34,-31': 'ooze',
  '-34,-30': 'numb',
  '-34,-29': 'allay',
  '-34,-28': 'yucca',
  '-34,-27': 'palm',
  '-34,-26': 'claim',
  '-34,-25': 'stint',
  '-34,-24': 'jamb',
  '-34,-23': 'racy',
  '-34,-22': 'scald',
  '-34,-21': 'hexed',
  '-34,-20': 'donor',
  '-34,-19': 'bison',
  '-34,-18': 'self',
  '-34,-17': 'isle',
  '-34,-16': 'bin',
  '-34,-15': 'clan',
  '-34,-14': 'fixes',
  '-34,-13': 'alibi',
  '-34,-12': 'bared',
  '-34,-11': 'fondu',
  '-34,-10': 'scene',
  '-34,-9': 'dross',
  '-34,-8': 'aside',
  '-34,-7': 'gusty',
  '-34,-6': 'womb',
  '-34,-5': 'gown',
  '-34,-4': 'dusk',
  '-34,-3': 'tool',
  '-34,-2': 'styli',
  '-34,-1': 'nigga',
  '-34,0': 'ankh',
  '-34,1': 'chock',
  '-34,2': 'nark',
  '-34,3': 'help',
  '-34,4': 'wife',
  '-34,5': 'share',
  '-34,6': 'skew',
  '-34,7': 'trap',
  '-34,8': 'sneer',
  '-34,9': 'flung',
  '-34,10': 'guava',
  '-34,11': 'muss',
  '-34,12': 'bunk',
  '-34,13': 'snare',
  '-34,14': 'wine',
  '-34,15': 'shade',
  '-34,16': 'limb',
  '-34,17': 'bulky',
  '-34,18': 'finny',
  '-34,19': 'heave',
  '-34,20': 'imam',
  '-34,21': 'wen',
  '-34,22': 'jay',
  '-34,23': 'globe',
  '-34,24': 'cried',
  '-34,25': 'baton',
  '-34,26': 'crave',
  '-34,27': 'dig',
  '-34,28': 'celli',
  '-34,29': 'peep',
  '-34,30': 'valet',
  '-34,31': 'ape',
  '-34,32': 'baa',
  '-34,33': 'blued',
  '-34,34': 'slat',
  '-34,35': 'drown',
  '-34,36': 'anal',
  '-34,37': 'piing',
  '-33,-37': 'grin',
  '-33,-36': 'inane',
  '-33,-35': 'broke',
  '-33,-34': 'tract',
  '-33,-33': 'trail',
  '-33,-32': 'momma',
  '-33,-31': 'vote',
  '-33,-30': 'may',
  '-33,-29': 'atlas',
  '-33,-28': 'room',
  '-33,-27': 'jump',
  '-33,-26': 'ethos',
  '-33,-25': 'kink',
  '-33,-24': 'ounce',
  '-33,-23': 'doll',
  '-33,-22': 'twine',
  '-33,-21': 'shell',
  '-33,-20': 'cog',
  '-33,-19': 'vomit',
  '-33,-18': 'eave',
  '-33,-17': 'movie',
  '-33,-16': 'cobra',
  '-33,-15': 'avian',
  '-33,-14': 'ague',
  '-33,-13': 'dike',
  '-33,-12': 'stool',
  '-33,-11': 'gape',
  '-33,-10': 'lymph',
  '-33,-9': 'fleck',
  '-33,-8': 'grown',
  '-33,-7': 'daze',
  '-33,-6': 'barf',
  '-33,-5': 'bigot',
  '-33,-4': 'leech',
  '-33,-3': 'book',
  '-33,-2': 'array',
  '-33,-1': 'flunk',
  '-33,0': 'curie',
  '-33,1': 'left',
  '-33,2': 'hunt',
  '-33,3': 'dose',
  '-33,4': 'ream',
  '-33,5': 'whore',
  '-33,6': 'jog',
  '-33,7': 'skate',
  '-33,8': 'fumed',
  '-33,9': 'slid',
  '-33,10': 'ember',
  '-33,11': 'lady',
  '-33,12': 'drag',
  '-33,13': 'hunch',
  '-33,14': 'fawn',
  '-33,15': 'linen',
  '-33,16': 'fury',
  '-33,17': 'laud',
  '-33,18': 'menu',
  '-33,19': 'gayer',
  '-33,20': 'heron',
  '-33,21': 'prod',
  '-33,22': 'grebe',
  '-33,23': 'campy',
  '-33,24': 'owe',
  '-33,25': 'zone',
  '-33,26': 'barb',
  '-33,27': 'lift',
  '-33,28': 'elite',
  '-33,29': 'pawed',
  '-33,30': 'peace',
  '-33,31': 'amiss',
  '-33,32': 'rangy',
  '-33,33': 'purer',
  '-33,34': 'very',
  '-33,35': 'echo',
  '-33,36': 'balsa',
  '-33,37': 'wooed',
  '-32,-37': 'psych',
  '-32,-36': 'world',
  '-32,-35': 'dryer',
  '-32,-34': 'slit',
  '-32,-33': 'balky',
  '-32,-32': 'slue',
  '-32,-31': 'ugh',
  '-32,-30': 'plume',
  '-32,-29': 'also',
  '-32,-28': 'tin',
  '-32,-27': 'bitch',
  '-32,-26': 'yet',
  '-32,-25': 'quid',
  '-32,-24': 'burg',
  '-32,-23': 'heard',
  '-32,-22': 'crate',
  '-32,-21': 'fall',
  '-32,-20': 'gleam',
  '-32,-19': 'shah',
  '-32,-18': 'gnash',
  '-32,-17': 'mushy',
  '-32,-16': 'beard',
  '-32,-15': 'cuter',
  '-32,-14': 'sport',
  '-32,-13': 'beg',
  '-32,-12': 'dime',
  '-32,-11': 'oiled',
  '-32,-10': 'gibed',
  '-32,-9': 'five',
  '-32,-8': 'inlay',
  '-32,-7': 'stile',
  '-32,-6': 'suit',
  '-32,-5': 'filed',
  '-32,-4': 'kitty',
  '-32,-3': 'taupe',
  '-32,-2': 'tent',
  '-32,-1': 'spare',
  '-32,0': 'yeast',
  '-32,1': 'error',
  '-32,2': 'align',
  '-32,3': 'bosom',
  '-32,4': 'chore',
  '-32,5': 'yum',
  '-32,6': 'input',
  '-32,7': 'rush',
  '-32,8': 'lust',
  '-32,9': 'curt',
  '-32,10': 'direr',
  '-32,11': 'musk',
  '-32,12': 'bebop',
  '-32,13': 'bush',
  '-32,14': 'twist',
  '-32,15': 'hole',
  '-32,16': 'honey',
  '-32,17': 'riced',
  '-32,18': 'coot',
  '-32,19': 'skyed',
  '-32,20': 'lode',
  '-32,21': 'sale',
  '-32,22': 'oozed',
  '-32,23': 'pol',
  '-32,24': 'gory',
  '-32,25': 'trig',
  '-32,26': 'geed',
  '-32,27': 'evict',
  '-32,28': 'seven',
  '-32,29': 'wash',
  '-32,30': 'dare',
  '-32,31': 'fifth',
  '-32,32': 'bylaw',
  '-32,33': 'peg',
  '-32,34': 'duct',
  '-32,35': 'spoke',
  '-32,36': 'nape',
  '-32,37': 'bare',
  '-31,-37': 'giddy',
  '-31,-36': 'moan',
  '-31,-35': 'three',
  '-31,-34': 'mes',
  '-31,-33': 'lynch',
  '-31,-32': 'pane',
  '-31,-31': 'regal',
  '-31,-30': 'stall',
  '-31,-29': 'foul',
  '-31,-28': 'curio',
  '-31,-27': 'matzo',
  '-31,-26': 'lamer',
  '-31,-25': 'horse',
  '-31,-24': 'raid',
  '-31,-23': 'truer',
  '-31,-22': 'carpi',
  '-31,-21': 'wharf',
  '-31,-20': 'lute',
  '-31,-19': 'deity',
  '-31,-18': 'beck',
  '-31,-17': 'thyme',
  '-31,-16': 'wheat',
  '-31,-15': 'narc',
  '-31,-14': 'genus',
  '-31,-13': 'awe',
  '-31,-12': 'verb',
  '-31,-11': 'abyss',
  '-31,-10': 'rust',
  '-31,-9': 'wasp',
  '-31,-8': 'phial',
  '-31,-7': 'drank',
  '-31,-6': 'vulva',
  '-31,-5': 'afoul',
  '-31,-4': 'teat',
  '-31,-3': 'gob',
  '-31,-2': 'stir',
  '-31,-1': 'norm',
  '-31,0': 'pang',
  '-31,1': 'mesh',
  '-31,2': 'befit',
  '-31,3': 'zed',
  '-31,4': 'louse',
  '-31,5': 'glaze',
  '-31,6': 'borer',
  '-31,7': 'jute',
  '-31,8': 'zero',
  '-31,9': 'pence',
  '-31,10': 'piker',
  '-31,11': 'lab',
  '-31,12': 'fuze',
  '-31,13': 'crew',
  '-31,14': 'pupa',
  '-31,15': 'pedal',
  '-31,16': 'defy',
  '-31,17': 'wist',
  '-31,18': 'heap',
  '-31,19': 'rowdy',
  '-31,20': 'dolt',
  '-31,21': 'scuff',
  '-31,22': 'wide',
  '-31,23': 'taped',
  '-31,24': 'moss',
  '-31,25': 'van',
  '-31,26': 'cost',
  '-31,27': 'foist',
  '-31,28': 'ems',
  '-31,29': 'ova',
  '-31,30': 'curve',
  '-31,31': 'reed',
  '-31,32': 'doyen',
  '-31,33': 'tomb',
  '-31,34': 'salty',
  '-31,35': 'spank',
  '-31,36': 'maple',
  '-31,37': 'vaped',
  '-30,-37': 'diet',
  '-30,-36': 'peak',
  '-30,-35': 'album',
  '-30,-34': 'baggy',
  '-30,-33': 'plait',
  '-30,-32': 'soli',
  '-30,-31': 'vowel',
  '-30,-30': 'free',
  '-30,-29': 'drone',
  '-30,-28': 'poll',
  '-30,-27': 'scalp',
  '-30,-26': 'desk',
  '-30,-25': 'bowed',
  '-30,-24': 'heist',
  '-30,-23': 'grief',
  '-30,-22': 'stoic',
  '-30,-21': 'stick',
  '-30,-20': 'attar',
  '-30,-19': 'sake',
  '-30,-18': 'amen',
  '-30,-17': 'kilo',
  '-30,-16': 'gecko',
  '-30,-15': 'fogy',
  '-30,-14': 'fiche',
  '-30,-13': 'sally',
  '-30,-12': 'abaci',
  '-30,-11': 'incur',
  '-30,-10': 'jet',
  '-30,-9': 'treed',
  '-30,-8': 'damn',
  '-30,-7': 'drier',
  '-30,-6': 'lemon',
  '-30,-5': 'hued',
  '-30,-4': 'race',
  '-30,-3': 'fruit',
  '-30,-2': 'gloat',
  '-30,-1': 'coal',
  '-30,0': 'hawed',
  '-30,1': 'clip',
  '-30,2': 'sane',
  '-30,3': 'latex',
  '-30,4': 'hound',
  '-30,5': 'ridge',
  '-30,6': 'blank',
  '-30,7': 'sonny',
  '-30,8': 'scowl',
  '-30,9': 'sail',
  '-30,10': 'astir',
  '-30,11': 'ahoy',
  '-30,12': 'snipe',
  '-30,13': 'inbox',
  '-30,14': 'tuber',
  '-30,15': 'foil',
  '-30,16': 'bun',
  '-30,17': 'pulse',
  '-30,18': 'cash',
  '-30,19': 'patio',
  '-30,20': 'glib',
  '-30,21': 'medal',
  '-30,22': 'flip',
  '-30,23': 'thaw',
  '-30,24': 'faxed',
  '-30,25': 'rabbi',
  '-30,26': 'place',
  '-30,27': 'gulag',
  '-30,28': 'groin',
  '-30,29': 'blurt',
  '-30,30': 'lid',
  '-30,31': 'tacky',
  '-30,32': 'shake',
  '-30,33': 'tied',
  '-30,34': 'qua',
  '-30,35': 'quiet',
  '-30,36': 'loath',
  '-30,37': 'lit',
  '-29,-37': 'meat',
  '-29,-36': 'yup',
  '-29,-35': 'basin',
  '-29,-34': 'beat',
  '-29,-33': 'jewel',
  '-29,-32': 'write',
  '-29,-31': 'kayak',
  '-29,-30': 'guru',
  '-29,-29': 'eked',
  '-29,-28': 'vinyl',
  '-29,-27': 'caret',
  '-29,-26': 'ulnae',
  '-29,-25': 'ere',
  '-29,-24': 'cubit',
  '-29,-23': 'anime',
  '-29,-22': 'husky',
  '-29,-21': 'mummy',
  '-29,-20': 'butt',
  '-29,-19': 'graze',
  '-29,-18': 'filth',
  '-29,-17': 'toast',
  '-29,-16': 'blind',
  '-29,-15': 'aired',
  '-29,-14': 'tuxes',
  '-29,-13': 'bluer',
  '-29,-12': 'genii',
  '-29,-11': 'lept',
  '-29,-10': 'idol',
  '-29,-9': 'swell',
  '-29,-8': 'glued',
  '-29,-7': 'solo',
  '-29,-6': 'chat',
  '-29,-5': 'gland',
  '-29,-4': 'stare',
  '-29,-3': 'nonce',
  '-29,-2': 'acme',
  '-29,-1': 'nod',
  '-29,0': 'prey',
  '-29,1': 'tough',
  '-29,2': 'plunk',
  '-29,3': 'mirth',
  '-29,4': 'bused',
  '-29,5': 'pert',
  '-29,6': 'gaped',
  '-29,7': 'meaty',
  '-29,8': 'slick',
  '-29,9': 'axing',
  '-29,10': 'roil',
  '-29,11': 'mire',
  '-29,12': 'shod',
  '-29,13': 'pixie',
  '-29,14': 'kooky',
  '-29,15': 'apron',
  '-29,16': 'voted',
  '-29,17': 'tulip',
  '-29,18': 'goofy',
  '-29,19': 'hare',
  '-29,20': 'edger',
  '-29,21': 'drunk',
  '-29,22': 'pan',
  '-29,23': 'deft',
  '-29,24': 'flame',
  '-29,25': 'heed',
  '-29,26': 'novel',
  '-29,27': 'dis',
  '-29,28': 'vizor',
  '-29,29': 'covet',
  '-29,30': 'bout',
  '-29,31': 'lorry',
  '-29,32': 'tabby',
  '-29,33': 'memo',
  '-29,34': 'moral',
  '-29,35': 'hood',
  '-29,36': 'fling',
  '-29,37': 'dome',
  '-28,-37': 'mini',
  '-28,-36': 'shove',
  '-28,-35': 'lined',
  '-28,-34': 'agape',
  '-28,-33': 'saxes',
  '-28,-32': 'foxes',
  '-28,-31': 'beset',
  '-28,-30': 'whom',
  '-28,-29': 'visa',
  '-28,-28': 'flock',
  '-28,-27': 'label',
  '-28,-26': 'berg',
  '-28,-25': 'good',
  '-28,-24': 'phone',
  '-28,-23': 'jot',
  '-28,-22': 'shunt',
  '-28,-21': 'poi',
  '-28,-20': 'clove',
  '-28,-19': 'dye',
  '-28,-18': 'gal',
  '-28,-17': 'rod',
  '-28,-16': 'riper',
  '-28,-15': 'cadre',
  '-28,-14': 'lorn',
  '-28,-13': 'midge',
  '-28,-12': 'torch',
  '-28,-11': 'rap',
  '-28,-10': 'sward',
  '-28,-9': 'full',
  '-28,-8': 'sitar',
  '-28,-7': 'opium',
  '-28,-6': 'bent',
  '-28,-5': 'lewd',
  '-28,-4': 'bet',
  '-28,-3': 'vogue',
  '-28,-2': 'serge',
  '-28,-1': 'grub',
  '-28,0': 'usual',
  '-28,1': 'yak',
  '-28,2': 'panty',
  '-28,3': 'dingo',
  '-28,4': 'spool',
  '-28,5': 'mop',
  '-28,6': 'build',
  '-28,7': 'hubby',
  '-28,8': 'cow',
  '-28,9': 'dust',
  '-28,10': 'funky',
  '-28,11': 'bugle',
  '-28,12': 'blaze',
  '-28,13': 'frack',
  '-28,14': 'badge',
  '-28,15': 'sprat',
  '-28,16': 'oral',
  '-28,17': 'blot',
  '-28,18': 'leaf',
  '-28,19': 'twain',
  '-28,20': 'woozy',
  '-28,21': 'day',
  '-28,22': 'gave',
  '-28,23': 'foamy',
  '-28,24': 'scone',
  '-28,25': 'glove',
  '-28,26': 'strip',
  '-28,27': 'tact',
  '-28,28': 'heir',
  '-28,29': 'ruddy',
  '-28,30': 'whack',
  '-28,31': 'stork',
  '-28,32': 'shat',
  '-28,33': 'home',
  '-28,34': 'bob',
  '-28,35': 'misdo',
  '-28,36': 'decal',
  '-28,37': 'fiery',
  '-27,-37': 'tummy',
  '-27,-36': 'lowed',
  '-27,-35': 'bride',
  '-27,-34': 'ashen',
  '-27,-33': 'welch',
  '-27,-32': 'daisy',
  '-27,-31': 'opted',
  '-27,-30': 'levy',
  '-27,-29': 'thank',
  '-27,-28': 'row',
  '-27,-27': 'ship',
  '-27,-26': 'jazz',
  '-27,-25': 'amid',
  '-27,-24': 'fowl',
  '-27,-23': 'diver',
  '-27,-22': 'bask',
  '-27,-21': 'shiny',
  '-27,-20': 'amir',
  '-27,-19': 'soil',
  '-27,-18': 'easel',
  '-27,-17': 'sine',
  '-27,-16': 'tan',
  '-27,-15': 'jug',
  '-27,-14': 'smut',
  '-27,-13': 'blog',
  '-27,-12': 'steel',
  '-27,-11': 'moped',
  '-27,-10': 'chow',
  '-27,-9': 'flied',
  '-27,-8': 'coyly',
  '-27,-7': 'drip',
  '-27,-6': 'dived',
  '-27,-5': 'west',
  '-27,-4': 'stoke',
  '-27,-3': 'fussy',
  '-27,-2': 'awry',
  '-27,-1': 'trace',
  '-27,0': 'layer',
  '-27,1': 'care',
  '-27,2': 'ani',
  '-27,3': 'camp',
  '-27,4': 'quail',
  '-27,5': 'pat',
  '-27,6': 'riot',
  '-27,7': 'ivy',
  '-27,8': 'repay',
  '-27,9': 'savvy',
  '-27,10': 'dole',
  '-27,11': 'ruing',
  '-27,12': 'lilac',
  '-27,13': 'nomad',
  '-27,14': 'shelf',
  '-27,15': 'only',
  '-27,16': 'sherd',
  '-27,17': 'flee',
  '-27,18': 'sled',
  '-27,19': 'filly',
  '-27,20': 'cored',
  '-27,21': 'dwell',
  '-27,22': 'find',
  '-27,23': 'caulk',
  '-27,24': 'meek',
  '-27,25': 'nixes',
  '-27,26': 'retch',
  '-27,27': 'tap',
  '-27,28': 'steer',
  '-27,29': 'foxy',
  '-27,30': 'curvy',
  '-27,31': 'upon',
  '-27,32': 'niter',
  '-27,33': 'glide',
  '-27,34': 'wavy',
  '-27,35': 'rhea',
  '-27,36': 'wordy',
  '-27,37': 'attic',
  '-26,-37': 'civic',
  '-26,-36': 'opal',
  '-26,-35': 'flak',
  '-26,-34': 'tempo',
  '-26,-33': 'grimy',
  '-26,-32': 'pasty',
  '-26,-31': 'video',
  '-26,-30': 'laity',
  '-26,-29': 'babe',
  '-26,-28': 'perk',
  '-26,-27': 'rocky',
  '-26,-26': 'cool',
  '-26,-25': 'pond',
  '-26,-24': 'fared',
  '-26,-23': 'yeah',
  '-26,-22': 'biddy',
  '-26,-21': 'slave',
  '-26,-20': 'till',
  '-26,-19': 'hooch',
  '-26,-18': 'clone',
  '-26,-17': 'davit',
  '-26,-16': 'clad',
  '-26,-15': 'hen',
  '-26,-14': 'sable',
  '-26,-13': 'red',
  '-26,-12': 'tit',
  '-26,-11': 'late',
  '-26,-10': 'mark',
  '-26,-9': 'idiot',
  '-26,-8': 'amour',
  '-26,-7': 'spam',
  '-26,-6': 'favor',
  '-26,-5': 'shine',
  '-26,-4': 'broil',
  '-26,-3': 'nymph',
  '-26,-2': 'hung',
  '-26,-1': 'rodeo',
  '-26,0': 'dazed',
  '-26,1': 'noway',
  '-26,2': 'puff',
  '-26,3': 'hurt',
  '-26,4': 'toss',
  '-26,5': 'rude',
  '-26,6': 'midst',
  '-26,7': 'divot',
  '-26,8': 'poem',
  '-26,9': 'rook',
  '-26,10': 'verge',
  '-26,11': 'edict',
  '-26,12': 'lox',
  '-26,13': 'often',
  '-26,14': 'ratty',
  '-26,15': 'phony',
  '-26,16': 'fell',
  '-26,17': 'mum',
  '-26,18': 'motto',
  '-26,19': 'gene',
  '-26,20': 'julep',
  '-26,21': 'candy',
  '-26,22': 'vent',
  '-26,23': 'thy',
  '-26,24': 'deer',
  '-26,25': 'mamma',
  '-26,26': 'imbed',
  '-26,27': 'cower',
  '-26,28': 'arose',
  '-26,29': 'hoped',
  '-26,30': 'greed',
  '-26,31': 'eta',
  '-26,32': 'lover',
  '-26,33': 'naked',
  '-26,34': 'lack',
  '-26,35': 'farce',
  '-26,36': 'nook',
  '-26,37': 'moray',
  '-25,-37': 'feat',
  '-25,-36': 'ditch',
  '-25,-35': 'dewy',
  '-25,-34': 'mute',
  '-25,-33': 'mowed',
  '-25,-32': 'cage',
  '-25,-31': 'argue',
  '-25,-30': 'legal',
  '-25,-29': 'drill',
  '-25,-28': 'deli',
  '-25,-27': 'lock',
  '-25,-26': 'fly',
  '-25,-25': 'rake',
  '-25,-24': 'fade',
  '-25,-23': 'frier',
  '-25,-22': 'loaf',
  '-25,-21': 'soppy',
  '-25,-20': 'samba',
  '-25,-19': 'borne',
  '-25,-18': 'pshaw',
  '-25,-17': 'pole',
  '-25,-16': 'sloop',
  '-25,-15': 'affix',
  '-25,-14': 'joke',
  '-25,-13': 'budge',
  '-25,-12': 'kneed',
  '-25,-11': 'venom',
  '-25,-10': 'fauna',
  '-25,-9': 'able',
  '-25,-8': 'maxes',
  '-25,-7': 'bland',
  '-25,-6': 'spy',
  '-25,-5': 'boggy',
  '-25,-4': 'size',
  '-25,-3': 'roman',
  '-25,-2': 'magma',
  '-25,-1': 'snowy',
  '-25,0': 'fired',
  '-25,1': 'push',
  '-25,2': 'hived',
  '-25,3': 'away',
  '-25,4': 'pout',
  '-25,5': 'viola',
  '-25,6': 'irked',
  '-25,7': 'elfin',
  '-25,8': 'spied',
  '-25,9': 'dim',
  '-25,10': 'cold',
  '-25,11': 'hoop',
  '-25,12': 'smear',
  '-25,13': 'lived',
  '-25,14': 'ameer',
  '-25,15': 'final',
  '-25,16': 'gnaw',
  '-25,17': 'rut',
  '-25,18': 'club',
  '-25,19': 'shaft',
  '-25,20': 'golly',
  '-25,21': 'razz',
  '-25,22': 'theta',
  '-25,23': 'waxen',
  '-25,24': 'ducal',
  '-25,25': 'testy',
  '-25,26': 'ewe',
  '-25,27': 'adze',
  '-25,28': 'proof',
  '-25,29': 'froth',
  '-25,30': 'waded',
  '-25,31': 'pick',
  '-25,32': 'agree',
  '-25,33': 'style',
  '-25,34': 'ovum',
  '-25,35': 'the',
  '-25,36': 'drily',
  '-25,37': 'sowed',
  '-24,-37': 'nylon',
  '-24,-36': 'mas',
  '-24,-35': 'zippy',
  '-24,-34': 'sheep',
  '-24,-33': 'spook',
  '-24,-32': 'ram',
  '-24,-31': 'lusty',
  '-24,-30': 'task',
  '-24,-29': 'whoa',
  '-24,-28': 'act',
  '-24,-27': 'sties',
  '-24,-26': 'boar',
  '-24,-25': 'junco',
  '-24,-24': 'leery',
  '-24,-23': 'forum',
  '-24,-22': 'wild',
  '-24,-21': 'pekoe',
  '-24,-20': 'slack',
  '-24,-19': 'minim',
  '-24,-18': 'flail',
  '-24,-17': 'anion',
  '-24,-16': 'fresh',
  '-24,-15': 'cheap',
  '-24,-14': 'aware',
  '-24,-13': 'aline',
  '-24,-12': 'lax',
  '-24,-11': 'bozo',
  '-24,-10': 'moron',
  '-24,-9': 'dram',
  '-24,-8': 'cloy',
  '-24,-7': 'opera',
  '-24,-6': 'night',
  '-24,-5': 'recur',
  '-24,-4': 'had',
  '-24,-3': 'jar',
  '-24,-2': 'whale',
  '-24,-1': 'gash',
  '-24,0': 'toy',
  '-24,1': 'offer',
  '-24,2': 'cry',
  '-24,3': 'clime',
  '-24,4': 'once',
  '-24,5': 'swish',
  '-24,6': 'blame',
  '-24,7': 'riled',
  '-24,8': 'aging',
  '-24,9': 'rind',
  '-24,10': 'rank',
  '-24,11': 'beefy',
  '-24,12': 'veer',
  '-24,13': 'bad',
  '-24,14': 'trod',
  '-24,15': 'lowly',
  '-24,16': 'sough',
  '-24,17': 'zap',
  '-24,18': 'toque',
  '-24,19': 'paved',
  '-24,20': 'sob',
  '-24,21': 'dump',
  '-24,22': 'moire',
  '-24,23': 'laid',
  '-24,24': 'edema',
  '-24,25': 'mercy',
  '-24,26': 'tea',
  '-24,27': 'balmy',
  '-24,28': 'usage',
  '-24,29': 'niche',
  '-24,30': 'exude',
  '-24,31': 'drain',
  '-24,32': 'death',
  '-24,33': 'local',
  '-24,34': 'knot',
  '-24,35': 'bleed',
  '-24,36': 'podia',
  '-24,37': 'usher',
  '-23,-37': 'blue',
  '-23,-36': 'pokey',
  '-23,-35': 'aide',
  '-23,-34': 'veil',
  '-23,-33': 'optic',
  '-23,-32': 'led',
  '-23,-31': 'vend',
  '-23,-30': 'crock',
  '-23,-29': 'pudgy',
  '-23,-28': 'bible',
  '-23,-27': 'raspy',
  '-23,-26': 'weary',
  '-23,-25': 'tamer',
  '-23,-24': 'chef',
  '-23,-23': 'sin',
  '-23,-22': 'crop',
  '-23,-21': 'ale',
  '-23,-20': 'mango',
  '-23,-19': 'rely',
  '-23,-18': 'mover',
  '-23,-17': 'wag',
  '-23,-16': 'span',
  '-23,-15': 'angel',
  '-23,-14': 'sos',
  '-23,-13': 'typed',
  '-23,-12': 'slang',
  '-23,-11': 'karat',
  '-23,-10': 'lifer',
  '-23,-9': 'equip',
  '-23,-8': 'begat',
  '-23,-7': 'rivet',
  '-23,-6': 'lob',
  '-23,-5': 'seat',
  '-23,-4': 'still',
  '-23,-3': 'most',
  '-23,-2': 'hard',
  '-23,-1': 'fleet',
  '-23,0': 'gram',
  '-23,1': 'slate',
  '-23,2': 'girl',
  '-23,3': 'slued',
  '-23,4': 'ink',
  '-23,5': 'gulf',
  '-23,6': 'scar',
  '-23,7': 'outed',
  '-23,8': 'rein',
  '-23,9': 'blent',
  '-23,10': 'bust',
  '-23,11': 'kin',
  '-23,12': 'happy',
  '-23,13': 'disco',
  '-23,14': 'squad',
  '-23,15': 'riser',
  '-23,16': 'grope',
  '-23,17': 'weep',
  '-23,18': 'jeep',
  '-23,19': 'fixer',
  '-23,20': 'mourn',
  '-23,21': 'crown',
  '-23,22': 'tile',
  '-23,23': 'hang',
  '-23,24': 'fiber',
  '-23,25': 'sited',
  '-23,26': 'huffy',
  '-23,27': 'comma',
  '-23,28': 'dab',
  '-23,29': 'treat',
  '-23,30': 'viper',
  '-23,31': 'shill',
  '-23,32': 'slim',
  '-23,33': 'mixer',
  '-23,34': 'sooth',
  '-23,35': 'sheet',
  '-23,36': 'moot',
  '-23,37': 'peter',
  '-22,-37': 'ibex',
  '-22,-36': 'pushy',
  '-22,-35': 'floss',
  '-22,-34': 'space',
  '-22,-33': 'clipt',
  '-22,-32': 'idea',
  '-22,-31': 'nit',
  '-22,-30': 'rued',
  '-22,-29': 'eking',
  '-22,-28': 'old',
  '-22,-27': 'mania',
  '-22,-26': 'rare',
  '-22,-25': 'bow',
  '-22,-24': 'motif',
  '-22,-23': 'loci',
  '-22,-22': 'debt',
  '-22,-21': 'lumpy',
  '-22,-20': 'aping',
  '-22,-19': 'myna',
  '-22,-18': 'copy',
  '-22,-17': 'sank',
  '-22,-16': 'goat',
  '-22,-15': 'eased',
  '-22,-14': 'jut',
  '-22,-13': 'wall',
  '-22,-12': 'boss',
  '-22,-11': 'deb',
  '-22,-10': 'idiom',
  '-22,-9': 'root',
  '-22,-8': 'bray',
  '-22,-7': 'tuba',
  '-22,-6': 'cabal',
  '-22,-5': 'rugby',
  '-22,-4': 'angle',
  '-22,-3': 'inked',
  '-22,-2': 'gong',
  '-22,-1': 'gaunt',
  '-22,0': 'basis',
  '-22,1': 'crane',
  '-22,2': 'shout',
  '-22,3': 'scaly',
  '-22,4': 'eerie',
  '-22,5': 'tower',
  '-22,6': 'aery',
  '-22,7': 'juicy',
  '-22,8': 'use',
  '-22,9': 'poke',
  '-22,10': 'toke',
  '-22,11': 'tart',
  '-22,12': 'wiped',
  '-22,13': 'gases',
  '-22,14': 'prose',
  '-22,15': 'wad',
  '-22,16': 'kabob',
  '-22,17': 'pilaf',
  '-22,18': 'pupae',
  '-22,19': 'maxed',
  '-22,20': 'diode',
  '-22,21': 'bossy',
  '-22,22': 'bani',
  '-22,23': 'clomp',
  '-22,24': 'yelp',
  '-22,25': 'hear',
  '-22,26': 'frisk',
  '-22,27': 'friar',
  '-22,28': 'amply',
  '-22,29': 'whirr',
  '-22,30': 'combo',
  '-22,31': 'feel',
  '-22,32': 'mud',
  '-22,33': 'these',
  '-22,34': 'file',
  '-22,35': 'doubt',
  '-22,36': 'havoc',
  '-22,37': 'rowed',
  '-21,-37': 'amigo',
  '-21,-36': 'those',
  '-21,-35': 'bard',
  '-21,-34': 'sic',
  '-21,-33': 'trade',
  '-21,-32': 'brave',
  '-21,-31': 'diner',
  '-21,-30': 'child',
  '-21,-29': 'live',
  '-21,-28': 'macaw',
  '-21,-27': 'block',
  '-21,-26': 'jeez',
  '-21,-25': 'any',
  '-21,-24': 'quota',
  '-21,-23': 'gent',
  '-21,-22': 'pwn',
  '-21,-21': 'heath',
  '-21,-20': 'blood',
  '-21,-19': 'valor',
  '-21,-18': 'sawn',
  '-21,-17': 'loser',
  '-21,-16': 'brain',
  '-21,-15': 'tally',
  '-21,-14': 'slime',
  '-21,-13': 'hank',
  '-21,-12': 'peach',
  '-21,-11': 'third',
  '-21,-10': 'game',
  '-21,-9': 'plies',
  '-21,-8': 'diked',
  '-21,-7': 'argon',
  '-21,-6': 'xylem',
  '-21,-5': 'buy',
  '-21,-4': 'suede',
  '-21,-3': 'pact',
  '-21,-2': 'ebony',
  '-21,-1': 'mine',
  '-21,0': 'flow',
  '-21,1': 'serf',
  '-21,2': 'limp',
  '-21,3': 'moll',
  '-21,4': 'hyped',
  '-21,5': 'dodge',
  '-21,6': 'flax',
  '-21,7': 'heady',
  '-21,8': 'yearn',
  '-21,9': 'adieu',
  '-21,10': 'lance',
  '-21,11': 'tipsy',
  '-21,12': 'daily',
  '-21,13': 'silly',
  '-21,14': 'lever',
  '-21,15': 'info',
  '-21,16': 'slob',
  '-21,17': 'puree',
  '-21,18': 'satyr',
  '-21,19': 'knelt',
  '-21,20': 'acorn',
  '-21,21': 'newel',
  '-21,22': 'burnt',
  '-21,23': 'berm',
  '-21,24': 'tub',
  '-21,25': 'furze',
  '-21,26': 'bald',
  '-21,27': 'booed',
  '-21,28': 'decay',
  '-21,29': 'dud',
  '-21,30': 'jived',
  '-21,31': 'train',
  '-21,32': 'cynic',
  '-21,33': 'ended',
  '-21,34': 'leap',
  '-21,35': 'sheaf',
  '-21,36': 'ashed',
  '-21,37': 'ankle',
  '-20,-37': 'brusk',
  '-20,-36': 'feast',
  '-20,-35': 'abbey',
  '-20,-34': 'mull',
  '-20,-33': 'salad',
  '-20,-32': 'agave',
  '-20,-31': 'trait',
  '-20,-30': 'sixth',
  '-20,-29': 'drub',
  '-20,-28': 'fix',
  '-20,-27': 'gabby',
  '-20,-26': 'beech',
  '-20,-25': 'dine',
  '-20,-24': 'quasi',
  '-20,-23': 'pooh',
  '-20,-22': 'vary',
  '-20,-21': 'fend',
  '-20,-20': 'singe',
  '-20,-19': 'lam',
  '-20,-18': 'ebb',
  '-20,-17': 'curd',
  '-20,-16': 'chaff',
  '-20,-15': 'embed',
  '-20,-14': 'blare',
  '-20,-13': 'tzar',
  '-20,-12': 'pared',
  '-20,-11': 'snail',
  '-20,-10': 'mush',
  '-20,-9': 'cast',
  '-20,-8': 'urn',
  '-20,-7': 'rouge',
  '-20,-6': 'deem',
  '-20,-5': 'for',
  '-20,-4': 'pit',
  '-20,-3': 'never',
  '-20,-2': 'lamp',
  '-20,-1': 'bandy',
  '-20,0': 'toxic',
  '-20,1': 'oxbow',
  '-20,2': 'show',
  '-20,3': 'woman',
  '-20,4': 'tilt',
  '-20,5': 'lass',
  '-20,6': 'posed',
  '-20,7': 'eat',
  '-20,8': 'newer',
  '-20,9': 'mosey',
  '-20,10': 'ell',
  '-20,11': 'ogre',
  '-20,12': 'couch',
  '-20,13': 'milk',
  '-20,14': 'angry',
  '-20,15': 'gird',
  '-20,16': 'trust',
  '-20,17': 'fudge',
  '-20,18': 'pent',
  '-20,19': 'pant',
  '-20,20': 'vigor',
  '-20,21': 'flap',
  '-20,22': 'paler',
  '-20,23': 'overt',
  '-20,24': 'cola',
  '-20,25': 'bay',
  '-20,26': 'rail',
  '-20,27': 'lug',
  '-20,28': 'snafu',
  '-20,29': 'gotta',
  '-20,30': 'minus',
  '-20,31': 'tenor',
  '-20,32': 'smoky',
  '-20,33': 'sorer',
  '-20,34': 'gout',
  '-20,35': 'fray',
  '-20,36': 'lurch',
  '-20,37': 'bless',
  '-19,-37': 'comb',
  '-19,-36': 'bruin',
  '-19,-35': 'smile',
  '-19,-34': 'harry',
  '-19,-33': 'hided',
  '-19,-32': 'token',
  '-19,-31': 'right',
  '-19,-30': 'lace',
  '-19,-29': 'gasp',
  '-19,-28': 'china',
  '-19,-27': 'gruff',
  '-19,-26': 'kinky',
  '-19,-25': 'print',
  '-19,-24': 'warn',
  '-19,-23': 'sot',
  '-19,-22': 'gripe',
  '-19,-21': 'damp',
  '-19,-20': 'woke',
  '-19,-19': 'limy',
  '-19,-18': 'rarer',
  '-19,-17': 'harm',
  '-19,-16': 'asked',
  '-19,-15': 'rerun',
  '-19,-14': 'moldy',
  '-19,-13': 'miaow',
  '-19,-12': 'foam',
  '-19,-11': 'micra',
  '-19,-10': 'stave',
  '-19,-9': 'civil',
  '-19,-8': 'house',
  '-19,-7': 'whose',
  '-19,-6': 'laden',
  '-19,-5': 'men',
  '-19,-4': 'walk',
  '-19,-3': 'achy',
  '-19,-2': 'yoke',
  '-19,-1': 'oaken',
  '-19,0': 'fin',
  '-19,1': 'farm',
  '-19,2': 'vying',
  '-19,3': 'latch',
  '-19,4': 'sepia',
  '-19,5': 'bulge',
  '-19,6': 'rot',
  '-19,7': 'whiny',
  '-19,8': 'saved',
  '-19,9': 'bolt',
  '-19,10': 'oasis',
  '-19,11': 'zeal',
  '-19,12': 'vaunt',
  '-19,13': 'flora',
  '-19,14': 'newsy',
  '-19,15': 'feta',
  '-19,16': 'again',
  '-19,17': 'naive',
  '-19,18': 'save',
  '-19,19': 'sac',
  '-19,20': 'prate',
  '-19,21': 'fog',
  '-19,22': 'tempt',
  '-19,23': 'ladle',
  '-19,24': 'pled',
  '-19,25': 'reis',
  '-19,26': 'tonne',
  '-19,27': 'liken',
  '-19,28': 'saw',
  '-19,29': 'stood',
  '-19,30': 'tutor',
  '-19,31': 'hyper',
  '-19,32': 'murk',
  '-19,33': 'kudzu',
  '-19,34': 'ducat',
  '-19,35': 'chomp',
  '-19,36': 'glum',
  '-19,37': 'hook',
  '-18,-37': 'reran',
  '-18,-36': 'gear',
  '-18,-35': 'scoot',
  '-18,-34': 'aver',
  '-18,-33': 'gust',
  '-18,-32': 'aided',
  '-18,-31': 'flag',
  '-18,-30': 'altho',
  '-18,-29': 'wanna',
  '-18,-28': 'strut',
  '-18,-27': 'await',
  '-18,-26': 'coma',
  '-18,-25': 'sling',
  '-18,-24': 'liker',
  '-18,-23': 'waist',
  '-18,-22': 'tacit',
  '-18,-21': 'wagon',
  '-18,-20': 'glyph',
  '-18,-19': 'lash',
  '-18,-18': 'lousy',
  '-18,-17': 'dune',
  '-18,-16': 'exit',
  '-18,-15': 'node',
  '-18,-14': 'depot',
  '-18,-13': 'ecru',
  '-18,-12': 'sinew',
  '-18,-11': 'crone',
  '-18,-10': 'wreck',
  '-18,-9': 'tor',
  '-18,-8': 'tumid',
  '-18,-7': 'iris',
  '-18,-6': 'kiddo',
  '-18,-5': 'prune',
  '-18,-4': 'curse',
  '-18,-3': 'barge',
  '-18,-2': 'loth',
  '-18,-1': 'swath',
  '-18,0': 'jowl',
  '-18,1': 'jig',
  '-18,2': 'burn',
  '-18,3': 'grit',
  '-18,4': 'valve',
  '-18,5': 'leave',
  '-18,6': 'null',
  '-18,7': 'man',
  '-18,8': 'knead',
  '-18,9': 'fetus',
  '-18,10': 'clef',
  '-18,11': 'maker',
  '-18,12': 'sugar',
  '-18,13': 'dowry',
  '-18,14': 'pipit',
  '-18,15': 'rosy',
  '-18,16': 'dial',
  '-18,17': 'wrier',
  '-18,18': 'yest',
  '-18,19': 'they',
  '-18,20': 'antic',
  '-18,21': 'scold',
  '-18,22': 'dying',
  '-18,23': 'acrid',
  '-18,24': 'mealy',
  '-18,25': 'jumpy',
  '-18,26': 'probe',
  '-18,27': 'arbor',
  '-18,28': 'quite',
  '-18,29': 'obit',
  '-18,30': 'think',
  '-18,31': 'tip',
  '-18,32': 'mecca',
  '-18,33': 'were',
  '-18,34': 'rally',
  '-18,35': 'divan',
  '-18,36': 'swan',
  '-18,37': 'pease',
  '-17,-37': 'baron',
  '-17,-36': 'razor',
  '-17,-35': 'clerk',
  '-17,-34': 'dozen',
  '-17,-33': 'hero',
  '-17,-32': 'waver',
  '-17,-31': 'stain',
  '-17,-30': 'beer',
  '-17,-29': 'swear',
  '-17,-28': 'cinch',
  '-17,-27': 'event',
  '-17,-26': 'venue',
  '-17,-25': 'amp',
  '-17,-24': 'gruel',
  '-17,-23': 'paled',
  '-17,-22': 'deal',
  '-17,-21': 'parch',
  '-17,-20': 'queer',
  '-17,-19': 'belie',
  '-17,-18': 'warp',
  '-17,-17': 'keyed',
  '-17,-16': 'heavy',
  '-17,-15': 'shack',
  '-17,-14': 'exist',
  '-17,-13': 'mocha',
  '-17,-12': 'biker',
  '-17,-11': 'born',
  '-17,-10': 'has',
  '-17,-9': 'held',
  '-17,-8': 'adapt',
  '-17,-7': 'annoy',
  '-17,-6': 'piton',
  '-17,-5': 'toked',
  '-17,-4': 'panda',
  '-17,-3': 'slab',
  '-17,-2': 'geese',
  '-17,-1': 'dent',
  '-17,0': 'staph',
  '-17,1': 'wish',
  '-17,2': 'lofty',
  '-17,3': 'leg',
  '-17,4': 'viral',
  '-17,5': 'scurf',
  '-17,6': 'rhyme',
  '-17,7': 'ovoid',
  '-17,8': 'crash',
  '-17,9': 'chink',
  '-17,10': 'scan',
  '-17,11': 'milf',
  '-17,12': 'char',
  '-17,13': 'wider',
  '-17,14': 'audio',
  '-17,15': 'nab',
  '-17,16': 'haste',
  '-17,17': 'torus',
  '-17,18': 'dopy',
  '-17,19': 'chart',
  '-17,20': 'reel',
  '-17,21': 'blunt',
  '-17,22': 'turf',
  '-17,23': 'grove',
  '-17,24': 'unite',
  '-17,25': 'geeky',
  '-17,26': 'shrug',
  '-17,27': 'prone',
  '-17,28': 'ruder',
  '-17,29': 'sack',
  '-17,30': 'strop',
  '-17,31': 'oakum',
  '-17,32': 'rearm',
  '-17,33': 'flake',
  '-17,34': 'calve',
  '-17,35': 'haul',
  '-17,36': 'valid',
  '-17,37': 'yock',
  '-16,-37': 'scope',
  '-16,-36': 'scram',
  '-16,-35': 'espy',
  '-16,-34': 'tiger',
  '-16,-33': 'lair',
  '-16,-32': 'sises',
  '-16,-31': 'alto',
  '-16,-30': 'carry',
  '-16,-29': 'avid',
  '-16,-28': 'zit',
  '-16,-27': 'lanky',
  '-16,-26': 'query',
  '-16,-25': 'spoon',
  '-16,-24': 'erase',
  '-16,-23': 'here',
  '-16,-22': 'beget',
  '-16,-21': 'adobe',
  '-16,-20': 'thou',
  '-16,-19': 'mar',
  '-16,-18': 'later',
  '-16,-17': 'yuppy',
  '-16,-16': 'pwned',
  '-16,-15': 'nippy',
  '-16,-14': 'kapok',
  '-16,-13': 'cabin',
  '-16,-12': 'aroma',
  '-16,-11': 'flab',
  '-16,-10': 'snaky',
  '-16,-9': 'taper',
  '-16,-8': 'kook',
  '-16,-7': 'foal',
  '-16,-6': 'frump',
  '-16,-5': 'reset',
  '-16,-4': 'rusk',
  '-16,-3': 'mauve',
  '-16,-2': 'elide',
  '-16,-1': 'honk',
  '-16,0': 'gimpy',
  '-16,1': 'bathe',
  '-16,2': 'scion',
  '-16,3': 'model',
  '-16,4': 'edgy',
  '-16,5': 'sari',
  '-16,6': 'door',
  '-16,7': 'spiel',
  '-16,8': 'splat',
  '-16,9': 'swing',
  '-16,10': 'kith',
  '-16,11': 'truly',
  '-16,12': 'ruff',
  '-16,13': 'evoke',
  '-16,14': 'wimpy',
  '-16,15': 'goon',
  '-16,16': 'stage',
  '-16,17': 'penis',
  '-16,18': 'pose',
  '-16,19': 'slash',
  '-16,20': 'rupee',
  '-16,21': 'vixen',
  '-16,22': 'ocher',
  '-16,23': 'uvula',
  '-16,24': 'enjoy',
  '-16,25': 'wive',
  '-16,26': 'ruse',
  '-16,27': 'roué',
  '-16,28': 'fry',
  '-16,29': 'exam',
  '-16,30': 'slept',
  '-16,31': 'furor',
  '-16,32': 'wove',
  '-16,33': 'pew',
  '-16,34': 'paged',
  '-16,35': 'impel',
  '-16,36': 'shirr',
  '-16,37': 'emery',
  '-15,-37': 'grain',
  '-15,-36': 'nice',
  '-15,-35': 'nous',
  '-15,-34': 'deism',
  '-15,-33': 'sex',
  '-15,-32': 'weird',
  '-15,-31': 'named',
  '-15,-30': 'cubed',
  '-15,-29': 'nosed',
  '-15,-28': 'twang',
  '-15,-27': 'rip',
  '-15,-26': 'picky',
  '-15,-25': 'shawl',
  '-15,-24': 'butte',
  '-15,-23': 'reap',
  '-15,-22': 'siren',
  '-15,-21': 'diva',
  '-15,-20': 'wiper',
  '-15,-19': 'colic',
  '-15,-18': 'plane',
  '-15,-17': 'pesky',
  '-15,-16': 'doe',
  '-15,-15': 'jab',
  '-15,-14': 'guild',
  '-15,-13': 'mewed',
  '-15,-12': 'chess',
  '-15,-11': 'came',
  '-15,-10': 'yokel',
  '-15,-9': 'ford',
  '-15,-8': 'arrow',
  '-15,-7': 'fecal',
  '-15,-6': 'laded',
  '-15,-5': 'cycle',
  '-15,-4': 'clamp',
  '-15,-3': 'flier',
  '-15,-2': 'hag',
  '-15,-1': 'whirl',
  '-15,0': 'pub',
  '-15,1': 'stead',
  '-15,2': 'idler',
  '-15,3': 'quad',
  '-15,4': 'gnarl',
  '-15,5': 'rifle',
  '-15,6': 'soul',
  '-15,7': 'yogi',
  '-15,8': 'flay',
  '-15,9': 'faun',
  '-15,10': 'pasha',
  '-15,11': 'bring',
  '-15,12': 'deaf',
  '-15,13': 'peer',
  '-15,14': 'bongo',
  '-15,15': 'alas',
  '-15,16': 'come',
  '-15,17': 'today',
  '-15,18': 'bill',
  '-15,19': 'exact',
  '-15,20': 'kinda',
  '-15,21': 'entry',
  '-15,22': 'pouch',
  '-15,23': 'ads',
  '-15,24': 'dimer',
  '-15,25': 'agate',
  '-15,26': 'tasty',
  '-15,27': 'debar',
  '-15,28': 'ant',
  '-15,29': 'gone',
  '-15,30': 'fazed',
  '-15,31': 'largo',
  '-15,32': 'lag',
  '-15,33': 'cosy',
  '-15,34': 'dunno',
  '-15,35': 'twirl',
  '-15,36': 'blest',
  '-15,37': 'usurp',
  '-14,-37': 'glob',
  '-14,-36': 'heck',
  '-14,-35': 'stock',
  '-14,-34': 'atria',
  '-14,-33': 'said',
  '-14,-32': 'weed',
  '-14,-31': 'exult',
  '-14,-30': 'clack',
  '-14,-29': 'teed',
  '-14,-28': 'rajah',
  '-14,-27': 'grim',
  '-14,-26': 'dread',
  '-14,-25': 'soon',
  '-14,-24': 'smite',
  '-14,-23': 'timid',
  '-14,-22': 'gnawn',
  '-14,-21': 'frock',
  '-14,-20': 'lira',
  '-14,-19': 'waned',
  '-14,-18': 'twerk',
  '-14,-17': 'finer',
  '-14,-16': 'mutt',
  '-14,-15': 'knack',
  '-14,-14': 'geld',
  '-14,-13': 'strew',
  '-14,-12': 'ochre',
  '-14,-11': 'obey',
  '-14,-10': 'sash',
  '-14,-9': 'carol',
  '-14,-8': 'rube',
  '-14,-7': 'goose',
  '-14,-6': 'debut',
  '-14,-5': 'liar',
  '-14,-4': 'gnu',
  '-14,-3': 'psst',
  '-14,-2': 'tempi',
  '-14,-1': 'fuel',
  '-14,0': 'hater',
  '-14,1': 'surge',
  '-14,2': 'tidal',
  '-14,3': 'tromp',
  '-14,4': 'ulcer',
  '-14,5': 'hoe',
  '-14,6': 'hoax',
  '-14,7': 'rower',
  '-14,8': 'gale',
  '-14,9': 'rig',
  '-14,10': 'trike',
  '-14,11': 'guide',
  '-14,12': 'torn',
  '-14,13': 'sag',
  '-14,14': 'shied',
  '-14,15': 'drop',
  '-14,16': 'irk',
  '-14,17': 'tatty',
  '-14,18': 'crack',
  '-14,19': 'pixy',
  '-14,20': 'bawdy',
  '-14,21': 'pig',
  '-14,22': 'shape',
  '-14,23': 'lurid',
  '-14,24': 'clasp',
  '-14,25': 'float',
  '-14,26': 'getup',
  '-14,27': 'upped',
  '-14,28': 'sprig',
  '-14,29': 'flog',
  '-14,30': 'spate',
  '-14,31': 'lily',
  '-14,32': 'tam',
  '-14,33': 'yes',
  '-14,34': 'mind',
  '-14,35': 'edge',
  '-14,36': 'dint',
  '-14,37': 'eying',
  '-13,-37': 'stack',
  '-13,-36': 'stent',
  '-13,-35': 'frown',
  '-13,-34': 'boron',
  '-13,-33': 'fetal',
  '-13,-32': 'tweed',
  '-13,-31': 'vole',
  '-13,-30': 'bola',
  '-13,-29': 'pivot',
  '-13,-28': 'papal',
  '-13,-27': 'loved',
  '-13,-26': 'ides',
  '-13,-25': 'flume',
  '-13,-24': 'trice',
  '-13,-23': 'rye',
  '-13,-22': 'yield',
  '-13,-21': 'bumpy',
  '-13,-20': 'puma',
  '-13,-19': 'pixel',
  '-13,-18': 'godly',
  '-13,-17': 'mock',
  '-13,-16': 'muter',
  '-13,-15': 'bran',
  '-13,-14': 'emit',
  '-13,-13': 'yell',
  '-13,-12': 'car',
  '-13,-11': 'dun',
  '-13,-10': 'felt',
  '-13,-9': 'feral',
  '-13,-8': 'new',
  '-13,-7': 'rhino',
  '-13,-6': 'redid',
  '-13,-5': 'aft',
  '-13,-4': 'wiz',
  '-13,-3': 'among',
  '-13,-2': 'doze',
  '-13,-1': 'aura',
  '-13,0': 'lisle',
  '-13,1': 'biked',
  '-13,2': 'riff',
  '-13,3': 'lucid',
  '-13,4': 'novae',
  '-13,5': 'cam',
  '-13,6': 'fiord',
  '-13,7': 'sirup',
  '-13,8': 'media',
  '-13,9': 'empty',
  '-13,10': 'slot',
  '-13,11': 'trick',
  '-13,12': 'amity',
  '-13,13': 'rifer',
  '-13,14': 'their',
  '-13,15': 'bold',
  '-13,16': 'move',
  '-13,17': 'mayor',
  '-13,18': 'teeth',
  '-13,19': 'chick',
  '-13,20': 'lacy',
  '-13,21': 'glad',
  '-13,22': 'plain',
  '-13,23': 'atop',
  '-13,24': 'abode',
  '-13,25': 'shoo',
  '-13,26': 'spill',
  '-13,27': 'oldie',
  '-13,28': 'luck',
  '-13,29': 'boa',
  '-13,30': 'adz',
  '-13,31': 'tray',
  '-13,32': 'otter',
  '-13,33': 'slop',
  '-13,34': 'crawl',
  '-13,35': 'crick',
  '-13,36': 'voice',
  '-13,37': 'crape',
  '-12,-37': 'wear',
  '-12,-36': 'harem',
  '-12,-35': 'teal',
  '-12,-34': 'vile',
  '-12,-33': 'flesh',
  '-12,-32': 'team',
  '-12,-31': 'apse',
  '-12,-30': 'found',
  '-12,-29': 'synod',
  '-12,-28': 'aloud',
  '-12,-27': 'blitz',
  '-12,-26': 'seep',
  '-12,-25': 'rage',
  '-12,-24': 'fora',
  '-12,-23': 'nadir',
  '-12,-22': 'hype',
  '-12,-21': 'fig',
  '-12,-20': 'josh',
  '-12,-19': 'flew',
  '-12,-18': 'witty',
  '-12,-17': 'past',
  '-12,-16': 'marry',
  '-12,-15': 'flair',
  '-12,-14': 'plot',
  '-12,-13': 'melt',
  '-12,-12': 'cushy',
  '-12,-11': 'croak',
  '-12,-10': 'trill',
  '-12,-9': 'vetch',
  '-12,-8': 'edify',
  '-12,-7': 'trout',
  '-12,-6': 'flush',
  '-12,-5': 'gross',
  '-12,-4': 'bell',
  '-12,-3': 'boil',
  '-12,-2': 'irate',
  '-12,-1': 'veld',
  '-12,0': 'tinny',
  '-12,1': 'hewer',
  '-12,2': 'mist',
  '-12,3': 'wadi',
  '-12,4': 'mace',
  '-12,5': 'pike',
  '-12,6': 'bid',
  '-12,7': 'spud',
  '-12,8': 'scam',
  '-12,9': 'plum',
  '-12,10': 'dive',
  '-12,11': 'waxed',
  '-12,12': 'hazy',
  '-12,13': 'bagel',
  '-12,14': 'ably',
  '-12,15': 'dully',
  '-12,16': 'pap',
  '-12,17': 'lush',
  '-12,18': 'doing',
  '-12,19': 'anvil',
  '-12,20': 'beta',
  '-12,21': 'knit',
  '-12,22': 'lying',
  '-12,23': 'ill',
  '-12,24': 'trot',
  '-12,25': 'gall',
  '-12,26': 'osier',
  '-12,27': 'kneel',
  '-12,28': 'pooch',
  '-12,29': 'fax',
  '-12,30': 'clap',
  '-12,31': 'moody',
  '-12,32': 'sleek',
  '-12,33': 'snit',
  '-12,34': 'leapt',
  '-12,35': 'fret',
  '-12,36': 'sink',
  '-12,37': 'snack',
  '-11,-37': 'felon',
  '-11,-36': 'where',
  '-11,-35': 'atom',
  '-11,-34': 'acid',
  '-11,-33': 'grip',
  '-11,-32': 'road',
  '-11,-31': 'razed',
  '-11,-30': 'marsh',
  '-11,-29': 'unsay',
  '-11,-28': 'awed',
  '-11,-27': 'onion',
  '-11,-26': 'guest',
  '-11,-25': 'forty',
  '-11,-24': 'pas',
  '-11,-23': 'dork',
  '-11,-22': 'mint',
  '-11,-21': 'spell',
  '-11,-20': 'suing',
  '-11,-19': 'acre',
  '-11,-18': 'cart',
  '-11,-17': 'proud',
  '-11,-16': 'clunk',
  '-11,-15': 'ilk',
  '-11,-14': 'ledge',
  '-11,-13': 'bank',
  '-11,-12': 'each',
  '-11,-11': 'ray',
  '-11,-10': 'timer',
  '-11,-9': 'amber',
  '-11,-8': 'vial',
  '-11,-7': 'seed',
  '-11,-6': 'scrap',
  '-11,-5': 'bogie',
  '-11,-4': 'worm',
  '-11,-3': 'bloat',
  '-11,-2': 'tag',
  '-11,-1': 'state',
  '-11,0': 'err',
  '-11,1': 'caste',
  '-11,2': 'bluff',
  '-11,3': 'allow',
  '-11,4': 'grate',
  '-11,5': 'barer',
  '-11,6': 'con',
  '-11,7': 'futz',
  '-11,8': 'funk',
  '-11,9': 'pave',
  '-11,10': 'pricy',
  '-11,11': 'joyed',
  '-11,12': 'axial',
  '-11,13': 'match',
  '-11,14': 'gill',
  '-11,15': 'besom',
  '-11,16': 'ready',
  '-11,17': 'sever',
  '-11,18': 'took',
  '-11,19': 'topaz',
  '-11,20': 'stuff',
  '-11,21': 'hue',
  '-11,22': 'tike',
  '-11,23': 'cadge',
  '-11,24': 'wrack',
  '-11,25': 'tansy',
  '-11,26': 'toxin',
  '-11,27': 'grout',
  '-11,28': 'logic',
  '-11,29': 'helix',
  '-11,30': 'gonna',
  '-11,31': 'frond',
  '-11,32': 'tared',
  '-11,33': 'tamed',
  '-11,34': 'cell',
  '-11,35': 'hulk',
  '-11,36': 'bonus',
  '-11,37': 'tern',
  '-10,-37': 'rayon',
  '-10,-36': 'orbit',
  '-10,-35': 'alms',
  '-10,-34': 'clop',
  '-10,-33': 'jerky',
  '-10,-32': 'brim',
  '-10,-31': 'buyer',
  '-10,-30': 'muggy',
  '-10,-29': 'dingy',
  '-10,-28': 'gamer',
  '-10,-27': 'suite',
  '-10,-26': 'banjo',
  '-10,-25': 'doily',
  '-10,-24': 'winch',
  '-10,-23': 'dweeb',
  '-10,-22': 'with',
  '-10,-21': 'weave',
  '-10,-20': 'seedy',
  '-10,-19': 'loco',
  '-10,-18': 'trope',
  '-10,-17': 'fop',
  '-10,-16': 'tiff',
  '-10,-15': 'using',
  '-10,-14': 'deter',
  '-10,-13': 'thick',
  '-10,-12': 'gable',
  '-10,-11': 'pail',
  '-10,-10': 'unpin',
  '-10,-9': 'main',
  '-10,-8': 'balk',
  '-10,-7': 'aback',
  '-10,-6': 'did',
  '-10,-5': 'abuzz',
  '-10,-4': 'laxer',
  '-10,-3': 'frank',
  '-10,-2': 'lemur',
  '-10,-1': 'cramp',
  '-10,0': 'larch',
  '-10,1': 'juror',
  '-10,2': 'whit',
  '-10,3': 'tale',
  '-10,4': 'thorn',
  '-10,5': 'least',
  '-10,6': 'flit',
  '-10,7': 'forge',
  '-10,8': 'mused',
  '-10,9': 'berth',
  '-10,10': 'top',
  '-10,11': 'swine',
  '-10,12': 'boor',
  '-10,13': 'musky',
  '-10,14': 'faded',
  '-10,15': 'virus',
  '-10,16': 'dew',
  '-10,17': 'ketch',
  '-10,18': 'bury',
  '-10,19': 'coil',
  '-10,20': 'give',
  '-10,21': 'vale',
  '-10,22': 'punch',
  '-10,23': 'silt',
  '-10,24': 'sty',
  '-10,25': 'zest',
  '-10,26': 'cup',
  '-10,27': 'rim',
  '-10,28': 'why',
  '-10,29': 'are',
  '-10,30': 'award',
  '-10,31': 'dear',
  '-10,32': 'wok',
  '-10,33': 'hull',
  '-10,34': 'gushy',
  '-10,35': 'bled',
  '-10,36': 'nay',
  '-10,37': 'domed',
  '-9,-37': 'leash',
  '-9,-36': 'bite',
  '-9,-35': 'onto',
  '-9,-34': 'pawl',
  '-9,-33': 'cave',
  '-9,-32': 'jeer',
  '-9,-31': 'pylon',
  '-9,-30': 'adore',
  '-9,-29': 'fine',
  '-9,-28': 'igloo',
  '-9,-27': 'risky',
  '-9,-26': 'flank',
  '-9,-25': 'crust',
  '-9,-24': 'lath',
  '-9,-23': 'sting',
  '-9,-22': 'kid',
  '-9,-21': 'rout',
  '-9,-20': 'gag',
  '-9,-19': 'grad',
  '-9,-18': 'coach',
  '-9,-17': 'punt',
  '-9,-16': 'vein',
  '-9,-15': 'staid',
  '-9,-14': 'shoe',
  '-9,-13': 'party',
  '-9,-12': 'morn',
  '-9,-11': 'cuss',
  '-9,-10': 'pipe',
  '-9,-9': 'mommy',
  '-9,-8': 'glut',
  '-9,-7': 'rote',
  '-9,-6': 'sonar',
  '-9,-5': 'snob',
  '-9,-4': 'lease',
  '-9,-3': 'made',
  '-9,-2': 'quay',
  '-9,-1': 'runt',
  '-9,0': 'dowse',
  '-9,1': 'lime',
  '-9,2': 'bulb',
  '-9,3': 'weal',
  '-9,4': 'cater',
  '-9,5': 'adorn',
  '-9,6': 'guise',
  '-9,7': 'beef',
  '-9,8': 'amino',
  '-9,9': 'woe',
  '-9,10': 'peon',
  '-9,11': 'wind',
  '-9,12': 'smack',
  '-9,13': 'foray',
  '-9,14': 'iamb',
  '-9,15': 'raced',
  '-9,16': 'malt',
  '-9,17': 'sate',
  '-9,18': 'nap',
  '-9,19': 'nosey',
  '-9,20': 'exec',
  '-9,21': 'cling',
  '-9,22': 'hazel',
  '-9,23': 'town',
  '-9,24': 'flat',
  '-9,25': 'terse',
  '-9,26': 'fart',
  '-9,27': 'body',
  '-9,28': 'gist',
  '-9,29': 'bug',
  '-9,30': 'clay',
  '-9,31': 'grog',
  '-9,32': 'black',
  '-9,33': 'spew',
  '-9,34': 'wile',
  '-9,35': 'wrapt',
  '-9,36': 'table',
  '-9,37': 'image',
  '-8,-37': 'emu',
  '-8,-36': 'whew',
  '-8,-35': 'mucus',
  '-8,-34': 'adder',
  '-8,-33': 'natty',
  '-8,-32': 'raged',
  '-8,-31': 'lien',
  '-8,-30': 'plug',
  '-8,-29': 'licit',
  '-8,-28': 'briny',
  '-8,-27': 'tee',
  '-8,-26': 'both',
  '-8,-25': 'date',
  '-8,-24': 'lot',
  '-8,-23': 'chalk',
  '-8,-22': 'spat',
  '-8,-21': 'legit',
  '-8,-20': 'moo',
  '-8,-19': 'sit',
  '-8,-18': 'stud',
  '-8,-17': 'orate',
  '-8,-16': 'tom',
  '-8,-15': 'sigh',
  '-8,-14': 'swop',
  '-8,-13': 'fiver',
  '-8,-12': 'donut',
  '-8,-11': 'joint',
  '-8,-10': 'point',
  '-8,-9': 'freer',
  '-8,-8': 'boll',
  '-8,-7': 'rogue',
  '-8,-6': 'avow',
  '-8,-5': 'indue',
  '-8,-4': 'uric',
  '-8,-3': 'lamb',
  '-8,-2': 'tyke',
  '-8,-1': 'chime',
  '-8,0': 'wizes',
  '-8,1': 'shut',
  '-8,2': 'wafer',
  '-8,3': 'bop',
  '-8,4': 'tram',
  '-8,5': 'rule',
  '-8,6': 'ashy',
  '-8,7': 'work',
  '-8,8': 'exalt',
  '-8,9': 'tang',
  '-8,10': 'geode',
  '-8,11': 'vast',
  '-8,12': 'nudge',
  '-8,13': 'alert',
  '-8,14': 'pride',
  '-8,15': 'pus',
  '-8,16': 'brood',
  '-8,17': 'scale',
  '-8,18': 'junky',
  '-8,19': 'lasso',
  '-8,20': 'purr',
  '-8,21': 'rigor',
  '-8,22': 'spurn',
  '-8,23': 'cut',
  '-8,24': 'altar',
  '-8,25': 'crepe',
  '-8,26': 'have',
  '-8,27': 'urge',
  '-8,28': 'honed',
  '-8,29': 'toned',
  '-8,30': 'grist',
  '-8,31': 'widow',
  '-8,32': 'pithy',
  '-8,33': 'ranch',
  '-8,34': 'slide',
  '-8,35': 'cured',
  '-8,36': 'chop',
  '-8,37': 'rho',
  '-7,-37': 'cope',
  '-7,-36': 'muddy',
  '-7,-35': 'bend',
  '-7,-34': 'poach',
  '-7,-33': 'lore',
  '-7,-32': 'augur',
  '-7,-31': 'hied',
  '-7,-30': 'arm',
  '-7,-29': 'jinx',
  '-7,-28': 'facet',
  '-7,-27': 'ogled',
  '-7,-26': 'goop',
  '-7,-25': 'zoo',
  '-7,-24': 'beryl',
  '-7,-23': 'banal',
  '-7,-22': 'round',
  '-7,-21': 'royal',
  '-7,-20': 'alit',
  '-7,-19': 'mean',
  '-7,-18': 'itch',
  '-7,-17': 'mom',
  '-7,-16': 'umber',
  '-7,-15': 'hem',
  '-7,-14': 'hose',
  '-7,-13': 'maria',
  '-7,-12': 'tiled',
  '-7,-11': 'aeon',
  '-7,-10': 'wring',
  '-7,-9': 'poky',
  '-7,-8': 'achoo',
  '-7,-7': 'shirk',
  '-7,-6': 'occur',
  '-7,-5': 'forte',
  '-7,-4': 'mangy',
  '-7,-3': 'mkay',
  '-7,-2': 'pear',
  '-7,-1': 'stye',
  '-7,0': 'verse',
  '-7,1': 'auk',
  '-7,2': 'fount',
  '-7,3': 'yeses',
  '-7,4': 'woody',
  '-7,5': 'halo',
  '-7,6': 'fryer',
  '-7,7': 'timed',
  '-7,8': 'glint',
  '-7,9': 'seam',
  '-7,10': 'bicep',
  '-7,11': 'oven',
  '-7,12': 'mood',
  '-7,13': 'helot',
  '-7,14': 'posy',
  '-7,15': 'abbé',
  '-7,16': 'gelt',
  '-7,17': 'rum',
  '-7,18': 'wire',
  '-7,19': 'spacy',
  '-7,20': 'who',
  '-7,21': 'erect',
  '-7,22': 'jaw',
  '-7,23': 'etch',
  '-7,24': 'shall',
  '-7,25': 'woven',
  '-7,26': 'hip',
  '-7,27': 'bird',
  '-7,28': 'cede',
  '-7,29': 'daddy',
  '-7,30': 'grape',
  '-7,31': 'dogma',
  '-7,32': 'rusty',
  '-7,33': 'doted',
  '-7,34': 'gonad',
  '-7,35': 'emcee',
  '-7,36': 'sigma',
  '-7,37': 'pine',
  '-6,-37': 'chide',
  '-6,-36': 'pee',
  '-6,-35': 'woken',
  '-6,-34': 'euro',
  '-6,-33': 'hand',
  '-6,-32': 'fully',
  '-6,-31': 'gang',
  '-6,-30': 'waxes',
  '-6,-29': 'twee',
  '-6,-28': 'ice',
  '-6,-27': 'taxi',
  '-6,-26': 'yard',
  '-6,-25': 'amuck',
  '-6,-24': 'carbs',
  '-6,-23': 'rabid',
  '-6,-22': 'shalt',
  '-6,-21': 'fist',
  '-6,-20': 'lilt',
  '-6,-19': 'major',
  '-6,-18': 'spoof',
  '-6,-17': 'rehab',
  '-6,-16': 'alter',
  '-6,-15': 'peeve',
  '-6,-14': 'awl',
  '-6,-13': 'terry',
  '-6,-12': 'oases',
  '-6,-11': 'par',
  '-6,-10': 'steal',
  '-6,-9': 'poor',
  '-6,-8': 'fugue',
  '-6,-7': 'dyed',
  '-6,-6': 'note',
  '-6,-5': 'gunny',
  '-6,-4': 'land',
  '-6,-3': 'human',
  '-6,-2': 'reuse',
  '-6,-1': 'aglow',
  '-6,0': 'payer',
  '-6,1': 'judge',
  '-6,2': 'czar',
  '-6,3': 'secs',
  '-6,4': 'eager',
  '-6,5': 'slurp',
  '-6,6': 'leek',
  '-6,7': 'spume',
  '-6,8': 'hut',
  '-6,9': 'reek',
  '-6,10': 'union',
  '-6,11': 'calm',
  '-6,12': 'cox',
  '-6,13': 'bike',
  '-6,14': 'carat',
  '-6,15': 'bonny',
  '-6,16': 'yew',
  '-6,17': 'pith',
  '-6,18': 'lucky',
  '-6,19': 'inapt',
  '-6,20': 'jimmy',
  '-6,21': 'flirt',
  '-6,22': 'skimp',
  '-6,23': 'hot',
  '-6,24': 'coded',
  '-6,25': 'haiku',
  '-6,26': 'lipid',
  '-6,27': 'conga',
  '-6,28': 'doggy',
  '-6,29': 'pygmy',
  '-6,30': 'vexes',
  '-6,31': 'privy',
  '-6,32': 'cab',
  '-6,33': 'radar',
  '-6,34': 'that',
  '-6,35': 'mynah',
  '-6,36': 'soda',
  '-6,37': 'modal',
  '-5,-37': 'rover',
  '-5,-36': 'surf',
  '-5,-35': 'randy',
  '-5,-34': 'bidet',
  '-5,-33': 'cook',
  '-5,-32': 'whoop',
  '-5,-31': 'nurse',
  '-5,-30': 'rack',
  '-5,-29': 'noon',
  '-5,-28': 'tort',
  '-5,-27': 'sham',
  '-5,-26': 'kebab',
  '-5,-25': 'tuner',
  '-5,-24': 'conch',
  '-5,-23': 'urea',
  '-5,-22': 'roar',
  '-5,-21': 'goody',
  '-5,-20': 'lost',
  '-5,-19': 'wield',
  '-5,-18': 'wreak',
  '-5,-17': 'smoke',
  '-5,-16': 'nave',
  '-5,-15': 'elk',
  '-5,-14': 'lawn',
  '-5,-13': 'wiser',
  '-5,-12': 'fled',
  '-5,-11': 'coat',
  '-5,-10': 'slum',
  '-5,-9': 'mash',
  '-5,-8': 'khaki',
  '-5,-7': 'trio',
  '-5,-6': 'wont',
  '-5,-5': 'name',
  '-5,-4': 'greet',
  '-5,-3': 'data',
  '-5,-2': 'wrath',
  '-5,-1': 'snug',
  '-5,0': 'cabby',
  '-5,1': 'poise',
  '-5,2': 'jag',
  '-5,3': 'coup',
  '-5,4': 'going',
  '-5,5': 'sorta',
  '-5,6': 'nacho',
  '-5,7': 'stump',
  '-5,8': 'auto',
  '-5,9': 'crisp',
  '-5,10': 'since',
  '-5,11': 'play',
  '-5,12': 'ash',
  '-5,13': 'sans',
  '-5,14': 'cure',
  '-5,15': 'skunk',
  '-5,16': 'spade',
  '-5,17': 'yeti',
  '-5,18': 'mixed',
  '-5,19': 'gummy',
  '-5,20': 'dote',
  '-5,21': 'gilt',
  '-5,22': 'tuft',
  '-5,23': 'quake',
  '-5,24': 'more',
  '-5,25': 'saint',
  '-5,26': 'flaky',
  '-5,27': 'hack',
  '-5,28': 'stag',
  '-5,29': 'wet',
  '-5,30': 'scary',
  '-5,31': 'upset',
  '-5,32': 'lama',
  '-5,33': 'acted',
  '-5,34': 'lane',
  '-5,35': 'seek',
  '-5,36': 'tidy',
  '-5,37': 'theft',
  '-4,-37': 'sup',
  '-4,-36': 'hanky',
  '-4,-35': 'argot',
  '-4,-34': 'weft',
  '-4,-33': 'panic',
  '-4,-32': 'dizzy',
  '-4,-31': 'gulp',
  '-4,-30': 'mix',
  '-4,-29': 'lupus',
  '-4,-28': 'lingo',
  '-4,-27': 'vapor',
  '-4,-26': 'owner',
  '-4,-25': 'pot',
  '-4,-24': 'duo',
  '-4,-23': 'pigmy',
  '-4,-22': 'foyer',
  '-4,-21': 'zip',
  '-4,-20': 'tithe',
  '-4,-19': 'curst',
  '-4,-18': 'blush',
  '-4,-17': 'stony',
  '-4,-16': 'torsi',
  '-4,-15': 'lard',
  '-4,-14': 'ear',
  '-4,-13': 'blab',
  '-4,-12': 'tiny',
  '-4,-11': 'banns',
  '-4,-10': 'bingo',
  '-4,-9': 'nub',
  '-4,-8': 'grail',
  '-4,-7': 'avert',
  '-4,-6': 'mixes',
  '-4,-5': 'dotty',
  '-4,-4': 'nib',
  '-4,-3': 'lye',
  '-4,-2': 'into',
  '-4,-1': 'kiddy',
  '-4,0': 'raze',
  '-4,1': 'torte',
  '-4,2': 'newly',
  '-4,3': 'long',
  '-4,4': 'robot',
  '-4,5': 'hod',
  '-4,6': 'myrrh',
  '-4,7': 'sidle',
  '-4,8': 'goo',
  '-4,9': 'mild',
  '-4,10': 'mole',
  '-4,11': 'eider',
  '-4,12': 'wink',
  '-4,13': 'burly',
  '-4,14': 'urban',
  '-4,15': 'batty',
  '-4,16': 'buggy',
  '-4,17': 'tho',
  '-4,18': 'aptly',
  '-4,19': 'huh',
  '-4,20': 'basic',
  '-4,21': 'abuse',
  '-4,22': 'burst',
  '-4,23': 'hey',
  '-4,24': 'field',
  '-4,25': 'duchy',
  '-4,26': 'taxes',
  '-4,27': 'noose',
  '-4,28': 'delay',
  '-4,29': 'mill',
  '-4,30': 'sixty',
  '-4,31': 'erred',
  '-4,32': 'rink',
  '-4,33': 'toffy',
  '-4,34': 'derby',
  '-4,35': 'relic',
  '-4,36': 'middy',
  '-4,37': 'ump',
  '-3,-37': 'truth',
  '-3,-36': 'perky',
  '-3,-35': 'twit',
  '-3,-34': 'parry',
  '-3,-33': 'raft',
  '-3,-32': 'liner',
  '-3,-31': 'pinup',
  '-3,-30': 'pun',
  '-3,-29': 'muted',
  '-3,-28': 'lemma',
  '-3,-27': 'jade',
  '-3,-26': 'owing',
  '-3,-25': 'deed',
  '-3,-24': 'peed',
  '-3,-23': 'hex',
  '-3,-22': 'fer',
  '-3,-21': 'fewer',
  '-3,-20': 'loft',
  '-3,-19': 'tabu',
  '-3,-18': 'franc',
  '-3,-17': 'suds',
  '-3,-16': 'semen',
  '-3,-15': 'ate',
  '-3,-14': 'molar',
  '-3,-13': 'lend',
  '-3,-12': 'gorge',
  '-3,-11': 'lunar',
  '-3,-10': 'wood',
  '-3,-9': 'sired',
  '-3,-8': 'ago',
  '-3,-7': 'gloss',
  '-3,-6': 'ebbed',
  '-3,-5': 'hart',
  '-3,-4': 'cent',
  '-3,-3': 'olden',
  '-3,-2': 'quip',
  '-3,-1': 'beep',
  '-3,0': 'loon',
  '-3,1': 'halon',
  '-3,2': 'patsy',
  '-3,3': 'side',
  '-3,4': 'whorl',
  '-3,5': 'leak',
  '-3,6': 'bee',
  '-3,7': 'added',
  '-3,8': 'gybed',
  '-3,9': 'swam',
  '-3,10': 'sumac',
  '-3,11': 'cowl',
  '-3,12': 'roger',
  '-3,13': 'salon',
  '-3,14': 'maize',
  '-3,15': 'hobo',
  '-3,16': 'comet',
  '-3,17': 'rung',
  '-3,18': 'stein',
  '-3,19': 'ruby',
  '-3,20': 'lazed',
  '-3,21': 'darn',
  '-3,22': 'nerdy',
  '-3,23': 'yogin',
  '-3,24': 'fore',
  '-3,25': 'manic',
  '-3,26': 'pussy',
  '-3,27': 'beret',
  '-3,28': 'roved',
  '-3,29': 'bound',
  '-3,30': 'blast',
  '-3,31': 'mob',
  '-3,32': 'oleo',
  '-3,33': 'kill',
  '-3,34': 'posit',
  '-3,35': 'horn',
  '-3,36': 'booby',
  '-3,37': 'worn',
  '-2,-37': 'bunch',
  '-2,-36': 'boney',
  '-2,-35': 'box',
  '-2,-34': 'sniff',
  '-2,-33': 'gazed',
  '-2,-32': 'foxed',
  '-2,-31': 'fish',
  '-2,-30': 'racer',
  '-2,-29': 'fable',
  '-2,-28': 'limn',
  '-2,-27': 'nifty',
  '-2,-26': 'mousy',
  '-2,-25': 'magic',
  '-2,-24': 'hilt',
  '-2,-23': 'gusto',
  '-2,-22': 'lefty',
  '-2,-21': 'puke',
  '-2,-20': 'buoy',
  '-2,-19': 'ivies',
  '-2,-18': 'pecan',
  '-2,-17': 'shown',
  '-2,-16': 'saber',
  '-2,-15': 'sound',
  '-2,-14': 'boost',
  '-2,-13': 'medic',
  '-2,-12': 'hale',
  '-2,-11': 'whir',
  '-2,-10': 'rimed',
  '-2,-9': 'given',
  '-2,-8': 'viol',
  '-2,-7': 'elope',
  '-2,-6': 'serum',
  '-2,-5': 'swank',
  '-2,-4': 'hated',
  '-2,-3': 'nude',
  '-2,-2': 'brunt',
  '-2,-1': 'heart',
  '-2,0': 'crud',
  '-2,1': 'wooly',
  '-2,2': 'flue',
  '-2,3': 'grass',
  '-2,4': 'ninja',
  '-2,5': 'shoed',
  '-2,6': 'sissy',
  '-2,7': 'yucky',
  '-2,8': 'bale',
  '-2,9': 'doth',
  '-2,10': 'jack',
  '-2,11': 'flour',
  '-2,12': 'ceded',
  '-2,13': 'kazoo',
  '-2,14': 'tapir',
  '-2,15': 'dairy',
  '-2,16': 'skit',
  '-2,17': 'coop',
  '-2,18': 'lief',
  '-2,19': 'glitz',
  '-2,20': 'sized',
  '-2,21': 'mitt',
  '-2,22': 'fern',
  '-2,23': 'tire',
  '-2,24': 'shad',
  '-2,25': 'mucky',
  '-2,26': 'vista',
  '-2,27': 'waif',
  '-2,28': 'llama',
  '-2,29': 'vim',
  '-2,30': 'schwa',
  '-2,31': 'shyer',
  '-2,32': 'dream',
  '-2,33': 'hydra',
  '-2,34': 'rife',
  '-2,35': 'naval',
  '-2,36': 'fishy',
  '-2,37': 'herd',
  '-1,-37': 'lapel',
  '-1,-36': 'pull',
  '-1,-35': 'call',
  '-1,-34': 'salt',
  '-1,-33': 'thigh',
  '-1,-32': 'base',
  '-1,-31': 'form',
  '-1,-30': 'hertz',
  '-1,-29': 'nun',
  '-1,-28': 'cote',
  '-1,-27': 'don',
  '-1,-26': 'bogus',
  '-1,-25': 'onus',
  '-1,-24': 'slimy',
  '-1,-23': 'make',
  '-1,-22': 'raved',
  '-1,-21': 'gassy',
  '-1,-20': 'chair',
  '-1,-19': 'lee',
  '-1,-18': 'blond',
  '-1,-17': 'site',
  '-1,-16': 'nail',
  '-1,-15': 'tramp',
  '-1,-14': 'liver',
  '-1,-13': 'craft',
  '-1,-12': 'other',
  '-1,-11': 'badly',
  '-1,-10': 'cop',
  '-1,-9': 'mink',
  '-1,-8': 'ascot',
  '-1,-7': 'avast',
  '-1,-6': 'gooey',
  '-1,-5': 'pitch',
  '-1,-4': 'multi',
  '-1,-3': 'crook',
  '-1,-2': 'hope',
  '-1,-1': 'ovule',
  '-1,0': 'stark',
  '-1,1': 'sir',
  '-1,2': 'mussy',
  '-1,3': 'gala',
  '-1,4': 'lap',
  '-1,5': 'bone',
  '-1,6': 'apps',
  '-1,7': 'ailed',
  '-1,8': 'spelt',
  '-1,9': 'ripe',
  '-1,10': 'pink',
  '-1,11': 'horny',
  '-1,12': 'alley',
  '-1,13': 'tier',
  '-1,14': 'bong',
  '-1,15': 'sad',
  '-1,16': 'tony',
  '-1,17': 'war',
  '-1,18': 'bye',
  '-1,19': 'quote',
  '-1,20': 'swat',
  '-1,21': 'tarot',
  '-1,22': 'daft',
  '-1,23': 'tote',
  '-1,24': 'folio',
  '-1,25': 'ripen',
  '-1,26': 'meg',
  '-1,27': 'baker',
  '-1,28': 'tide',
  '-1,29': 'ocean',
  '-1,30': 'homer',
  '-1,31': 'fake',
  '-1,32': 'dyke',
  '-1,33': 'cane',
  '-1,34': 'but',
  '-1,35': 'oval',
  '-1,36': 'jib',
  '-1,37': 'too',
  '0,-37': 'murky',
  '0,-36': 'abet',
  '0,-35': 'buck',
  '0,-34': 'sway',
  '0,-33': 'piety',
  '0,-32': 'creek',
  '0,-31': 'beet',
  '0,-30': 'hath',
  '0,-29': 'caped',
  '0,-28': 'feed',
  '0,-27': 'mound',
  '0,-26': 'ante',
  '0,-25': 'now',
  '0,-24': 'taser',
  '0,-23': 'quoth',
  '0,-22': 'goal',
  '0,-21': 'sylph',
  '0,-20': 'denim',
  '0,-19': 'henna',
  '0,-18': 'hinge',
  '0,-17': 'spite',
  '0,-16': 'ally',
  '0,-15': 'yawed',
  '0,-14': 'offal',
  '0,-13': 'noddy',
  '0,-12': 'demon',
  '0,-11': 'milky',
  '0,-10': 'exes',
  '0,-9': 'loner',
  '0,-8': 'swab',
  '0,-7': 'mane',
  '0,-6': 'reedy',
  '0,-5': 'neigh',
  '0,-4': 'kebob',
  '0,-3': 'speed',
  '0,-2': 'ether',
  '0,-1': 'loyal',
  '0,0': 'fan',
  '0,1': 'nary',
  '0,2': 'hes',
  '0,3': 'humus',
  '0,4': 'brew',
  '0,5': 'hoard',
  '0,6': 'crow',
  '0,7': 'roach',
  '0,8': 'humid',
  '0,9': 'spiny',
  '0,10': 'sand',
  '0,11': 'abate',
  '0,12': 'shark',
  '0,13': 'pace',
  '0,14': 'issue',
  '0,15': 'iron',
  '0,16': 'caved',
  '0,17': 'eye',
  '0,18': 'thump',
  '0,19': 'snap',
  '0,20': 'flaw',
  '0,21': 'gush',
  '0,22': 'den',
  '0,23': 'sole',
  '0,24': 'bayou',
  '0,25': 'corps',
  '0,26': 'real',
  '0,27': 'tress',
  '0,28': 'japed',
  '0,29': 'water',
  '0,30': 'pound',
  '0,31': 'trued',
  '0,32': 'ups',
  '0,33': 'joule',
  '0,34': 'smog',
  '0,35': 'élan',
  '0,36': 'romp',
  '0,37': 'toed',
  '1,-37': 'pore',
  '1,-36': 'emo',
  '1,-35': 'aisle',
  '1,-34': 'nut',
  '1,-33': 'keel',
  '1,-32': 'prime',
  '1,-31': 'fey',
  '1,-30': 'liked',
  '1,-29': 'pelt',
  '1,-28': 'open',
  '1,-27': 'idyl',
  '1,-26': 'radio',
  '1,-25': 'limbo',
  '1,-24': 'ahead',
  '1,-23': 'steep',
  '1,-22': 'rep',
  '1,-21': 'quire',
  '1,-20': 'jell',
  '1,-19': 'via',
  '1,-18': 'story',
  '1,-17': 'elbow',
  '1,-16': 'drool',
  '1,-15': 'brace',
  '1,-14': 'pop',
  '1,-13': 'nerd',
  '1,-12': 'soap',
  '1,-11': 'vain',
  '1,-10': 'void',
  '1,-9': 'puppy',
  '1,-8': 'psalm',
  '1,-7': 'need',
  '1,-6': 'coral',
  '1,-5': 'nova',
  '1,-4': 'raise',
  '1,-3': 'horde',
  '1,-2': 'cocci',
  '1,-1': 'lithe',
  '1,0': 'quash',
  '1,1': 'pinto',
  '1,2': 'droop',
  '1,3': 'son',
  '1,4': 'cease',
  '1,5': 'messy',
  '1,6': 'rapid',
  '1,7': 'yap',
  '1,8': 'must',
  '1,9': 'veto',
  '1,10': 'agent',
  '1,11': 'dumpy',
  '1,12': 'great',
  '1,13': 'yawn',
  '1,14': 'caper',
  '1,15': 'crux',
  '1,16': 'maul',
  '1,17': 'tank',
  '1,18': 'carp',
  '1,19': 'belt',
  '1,20': 'join',
  '1,21': 'decaf',
  '1,22': 'ham',
  '1,23': 'chili',
  '1,24': 'due',
  '1,25': 'matt',
  '1,26': 'lower',
  '1,27': 'foe',
  '1,28': 'slung',
  '1,29': 'lyre',
  '1,30': 'eight',
  '1,31': 'siege',
  '1,32': 'baled',
  '1,33': 'try',
  '1,34': 'chi',
  '1,35': 'pizza',
  '1,36': 'ode',
  '1,37': 'syrup',
  '2,-37': 'leaky',
  '2,-36': 'chord',
  '2,-35': 'knew',
  '2,-34': 'talc',
  '2,-33': 'erg',
  '2,-32': 'plaid',
  '2,-31': 'loud',
  '2,-30': 'lick',
  '2,-29': 'trash',
  '2,-28': 'mired',
  '2,-27': 'credo',
  '2,-26': 'gated',
  '2,-25': 'few',
  '2,-24': 'chief',
  '2,-23': 'ninny',
  '2,-22': 'bade',
  '2,-21': 'pie',
  '2,-20': 'trial',
  '2,-19': 'sauna',
  '2,-18': 'cited',
  '2,-17': 'fie',
  '2,-16': 'locus',
  '2,-15': 'can',
  '2,-14': 'besot',
  '2,-13': 'aspic',
  '2,-12': 'muff',
  '2,-11': 'maim',
  '2,-10': 'mere',
  '2,-9': 'skid',
  '2,-8': 'ken',
  '2,-7': 'step',
  '2,-6': 'crass',
  '2,-5': 'gad',
  '2,-4': 'trim',
  '2,-3': 'rile',
  '2,-2': 'yacht',
  '2,-1': 'slag',
  '2,0': 'pack',
  '2,1': 'ewer',
  '2,2': 'oil',
  '2,3': 'harpy',
  '2,4': 'ten',
  '2,5': 'patty',
  '2,6': 'poxes',
  '2,7': 'duped',
  '2,8': 'about',
  '2,9': 'porno',
  '2,10': 'fair',
  '2,11': 'wave',
  '2,12': 'gray',
  '2,13': 'font',
  '2,14': 'putty',
  '2,15': 'piano',
  '2,16': 'ennui',
  '2,17': 'quart',
  '2,18': 'mica',
  '2,19': 'sweat',
  '2,20': 'daunt',
  '2,21': 'leach',
  '2,22': 'cheek',
  '2,23': 'penal',
  '2,24': 'hind',
  '2,25': 'zoom',
  '2,26': 'hiked',
  '2,27': 'wing',
  '2,28': 'natal',
  '2,29': 'ohm',
  '2,30': 'awake',
  '2,31': 'unset',
  '2,32': 'wroth',
  '2,33': 'genre',
  '2,34': 'twill',
  '2,35': 'grab',
  '2,36': 'sown',
  '2,37': 'sure',
  '3,-37': 'bash',
  '3,-36': 'grave',
  '3,-35': 'react',
  '3,-34': 'cask',
  '3,-33': 'boded',
  '3,-32': 'won',
  '3,-31': 'down',
  '3,-30': 'posh',
  '3,-29': 'udder',
  '3,-28': 'untie',
  '3,-27': 'cover',
  '3,-26': 'lager',
  '3,-25': 'ditto',
  '3,-24': 'eon',
  '3,-23': 'fuzed',
  '3,-22': 'jaunt',
  '3,-21': 'habit',
  '3,-20': 'inter',
  '3,-19': 'flack',
  '3,-18': 'ouch',
  '3,-17': 'dandy',
  '3,-16': 'got',
  '3,-15': 'fezes',
  '3,-14': 'tight',
  '3,-13': 'scuba',
  '3,-12': 'fraud',
  '3,-11': 'next',
  '3,-10': 'relay',
  '3,-9': 'hair',
  '3,-8': 'fed',
  '3,-7': 'drama',
  '3,-6': 'eater',
  '3,-5': 'wowed',
  '3,-4': 'gunk',
  '3,-3': 'tong',
  '3,-2': 'renal',
  '3,-1': 'barn',
  '3,0': 'saith',
  '3,1': 'ibis',
  '3,2': 'vied',
  '3,3': 'gluey',
  '3,4': 'enact',
  '3,5': 'admit',
  '3,6': 'cork',
  '3,7': 'joked',
  '3,8': 'map',
  '3,9': 'grand',
  '3,10': 'wilt',
  '3,11': 'bilk',
  '3,12': 'maid',
  '3,13': 'huge',
  '3,14': 'manga',
  '3,15': 'gully',
  '3,16': 'jeans',
  '3,17': 'been',
  '3,18': 'loop',
  '3,19': 'taco',
  '3,20': 'began',
  '3,21': 'clown',
  '3,22': 'swami',
  '3,23': 'oho',
  '3,24': 'look',
  '3,25': 'hoagy',
  '3,26': 'amass',
  '3,27': 'ploy',
  '3,28': 'died',
  '3,29': 'spec',
  '3,30': 'reign',
  '3,31': 'strum',
  '3,32': 'laze',
  '3,33': 'toyed',
  '3,34': 'hiker',
  '3,35': 'wedge',
  '3,36': 'half',
  '3,37': 'cloak',
  '4,-37': 'kudos',
  '4,-36': 'code',
  '4,-35': 'duded',
  '4,-34': 'torso',
  '4,-33': 'pomp',
  '4,-32': 'whisk',
  '4,-31': 'adult',
  '4,-30': 'vise',
  '4,-29': 'net',
  '4,-28': 'acute',
  '4,-27': 'tube',
  '4,-26': 'aquae',
  '4,-25': 'sift',
  '4,-24': 'drape',
  '4,-23': 'swap',
  '4,-22': 'wed',
  '4,-21': 'inner',
  '4,-20': 'list',
  '4,-19': 'gyro',
  '4,-18': 'calyx',
  '4,-17': 'sera',
  '4,-16': 'bevel',
  '4,-15': 'humor',
  '4,-14': 'until',
  '4,-13': 'dip',
  '4,-12': 'yodel',
  '4,-11': 'avoid',
  '4,-10': 'lean',
  '4,-9': 'large',
  '4,-8': 'stoop',
  '4,-7': 'moist',
  '4,-6': 'kit',
  '4,-5': 'bulk',
  '4,-4': 'value',
  '4,-3': 'moth',
  '4,-2': 'way',
  '4,-1': 'taffy',
  '4,0': 'endow',
  '4,1': 'wader',
  '4,2': 'spasm',
  '4,3': 'word',
  '4,4': 'stop',
  '4,5': 'ring',
  '4,6': 'cleat',
  '4,7': 'aorta',
  '4,8': 'dam',
  '4,9': 'booze',
  '4,10': 'mince',
  '4,11': 'holly',
  '4,12': 'primp',
  '4,13': 'sheen',
  '4,14': 'noted',
  '4,15': 'arise',
  '4,16': 'blurb',
  '4,17': 'grasp',
  '4,18': 'hock',
  '4,19': 'wrist',
  '4,20': 'miler',
  '4,21': 'sim',
  '4,22': 'title',
  '4,23': 'mesa',
  '4,24': 'wack',
  '4,25': 'toted',
  '4,26': 'temp',
  '4,27': 'dual',
  '4,28': 'salve',
  '4,29': 'pad',
  '4,30': 'bull',
  '4,31': 'bacon',
  '4,32': 'raked',
  '4,33': 'wax',
  '4,34': 'slut',
  '4,35': 'chose',
  '4,36': 'annex',
  '4,37': 'cap',
  '5,-37': 'pork',
  '5,-36': 'pilaw',
  '5,-35': 'hutch',
  '5,-34': 'poked',
  '5,-33': 'ask',
  '5,-32': 'ion',
  '5,-31': 'dry',
  '5,-30': 'gosh',
  '5,-29': 'sawed',
  '5,-28': 'riven',
  '5,-27': 'crypt',
  '5,-26': 'keep',
  '5,-25': 'lamed',
  '5,-24': 'circa',
  '5,-23': 'girth',
  '5,-22': 'piece',
  '5,-21': 'white',
  '5,-20': 'adman',
  '5,-19': 'sully',
  '5,-18': 'gouty',
  '5,-17': 'oar',
  '5,-16': 'clung',
  '5,-15': 'viler',
  '5,-14': 'flop',
  '5,-13': 'byte',
  '5,-12': 'smash',
  '5,-11': 'outgo',
  '5,-10': 'scant',
  '5,-9': 'minx',
  '5,-8': 'sober',
  '5,-7': 'mice',
  '5,-6': 'kind',
  '5,-5': 'tilde',
  '5,-4': 'along',
  '5,-3': 'padre',
  '5,-2': 'just',
  '5,-1': 'steak',
  '5,0': 'deuce',
  '5,1': 'feud',
  '5,2': 'press',
  '5,3': 'brawl',
  '5,4': 'ampul',
  '5,5': 'sulky',
  '5,6': 'dinky',
  '5,7': 'islet',
  '5,8': 'grunt',
  '5,9': 'spice',
  '5,10': 'plea',
  '5,11': 'twice',
  '5,12': 'spurt',
  '5,13': 'nigh',
  '5,14': 'plop',
  '5,15': 'peel',
  '5,16': 'rob',
  '5,17': 'gap',
  '5,18': 'gate',
  '5,19': 'vest',
  '5,20': 'spend',
  '5,21': 'ashes',
  '5,22': 'heal',
  '5,23': 'unwed',
  '5,24': 'blend',
  '5,25': 'tree',
  '5,26': 'grill',
  '5,27': 'hippy',
  '5,28': 'cooky',
  '5,29': 'taro',
  '5,30': 'his',
  '5,31': 'alias',
  '5,32': 'curry',
  '5,33': 'douse',
  '5,34': 'sect',
  '5,35': 'strep',
  '5,36': 'flan',
  '5,37': 'wiled',
  '6,-37': 'sumo',
  '6,-36': 'belay',
  '6,-35': 'foot',
  '6,-34': 'husk',
  '6,-33': 'user',
  '6,-32': 'fogey',
  '6,-31': 'glade',
  '6,-30': 'kilt',
  '6,-29': 'tardy',
  '6,-28': 'dung',
  '6,-27': 'umped',
  '6,-26': 'study',
  '6,-25': 'zany',
  '6,-24': 'froze',
  '6,-23': 'sorry',
  '6,-22': 'canal',
  '6,-21': 'feces',
  '6,-20': 'hick',
  '6,-19': 'aegis',
  '6,-18': 'risk',
  '6,-17': 'abeam',
  '6,-16': 'weir',
  '6,-15': 'axis',
  '6,-14': 'spoil',
  '6,-13': 'band',
  '6,-12': 'squat',
  '6,-11': 'crap',
  '6,-10': 'boned',
  '6,-9': 'enemy',
  '6,-8': 'paste',
  '6,-7': 'howl',
  '6,-6': 'sided',
  '6,-5': 'shtik',
  '6,-4': 'ail',
  '6,-3': 'blip',
  '6,-2': 'sower',
  '6,-1': 'vital',
  '6,0': 'mown',
  '6,1': 'ideal',
  '6,2': 'inn',
  '6,3': 'asset',
  '6,4': 'limo',
  '6,5': 'waved',
  '6,6': 'tone',
  '6,7': 'chum',
  '6,8': 'pimp',
  '6,9': 'theme',
  '6,10': 'duck',
  '6,11': 'steam',
  '6,12': 'cord',
  '6,13': 'crab',
  '6,14': 'lung',
  '6,15': 'film',
  '6,16': 'stale',
  '6,17': 'stake',
  '6,18': 'loll',
  '6,19': 'prim',
  '6,20': 'sung',
  '6,21': 'crazy',
  '6,22': 'awash',
  '6,23': 'say',
  '6,24': 'nervy',
  '6,25': 'vocal',
  '6,26': 'yule',
  '6,27': 'tog',
  '6,28': 'veep',
  '6,29': 'churl',
  '6,30': 'shaky',
  '6,31': 'puked',
  '6,32': 'swig',
  '6,33': 'pinky',
  '6,34': 'gum',
  '6,35': 'dire',
  '6,36': 'acne',
  '6,37': 'amok',
  '7,-37': 'runny',
  '7,-36': 'tore',
  '7,-35': 'boozy',
  '7,-34': 'jolt',
  '7,-33': 'faith',
  '7,-32': 'disc',
  '7,-31': 'nag',
  '7,-30': 'wick',
  '7,-29': 'she',
  '7,-28': 'fuzzy',
  '7,-27': 'peal',
  '7,-26': 'viva',
  '7,-25': 'yank',
  '7,-24': 'odd',
  '7,-23': 'haler',
  '7,-22': 'iced',
  '7,-21': 'slope',
  '7,-20': 'hall',
  '7,-19': 'ball',
  '7,-18': 'fib',
  '7,-17': 'rebut',
  '7,-16': 'pyxes',
  '7,-15': 'march',
  '7,-14': 'idled',
  '7,-13': 'clash',
  '7,-12': 'store',
  '7,-11': 'pro',
  '7,-10': 'lobed',
  '7,-9': 'jam',
  '7,-8': 'shady',
  '7,-7': 'nutty',
  '7,-6': 'alien',
  '7,-5': 'gibe',
  '7,-4': 'logo',
  '7,-3': 'warty',
  '7,-2': 'teen',
  '7,-1': 'digit',
  '7,0': 'drawn',
  '7,1': 'nixed',
  '7,2': 'clear',
  '7,3': 'seen',
  '7,4': 'quick',
  '7,5': 'bane',
  '7,6': 'cooed',
  '7,7': 'one',
  '7,8': 'lent',
  '7,9': 'tulle',
  '7,10': 'obese',
  '7,11': 'tax',
  '7,12': 'load',
  '7,13': 'dais',
  '7,14': 'harsh',
  '7,15': 'bred',
  '7,16': 'piled',
  '7,17': 'vapid',
  '7,18': 'wren',
  '7,19': 'duly',
  '7,20': 'fakir',
  '7,21': 'pair',
  '7,22': 'wake',
  '7,23': 'eyrie',
  '7,24': 'icy',
  '7,25': 'hop',
  '7,26': 'wise',
  '7,27': 'hat',
  '7,28': 'above',
  '7,29': 'tibia',
  '7,30': 'rue',
  '7,31': 'fined',
  '7,32': 'choke',
  '7,33': 'brawn',
  '7,34': 'plow',
  '7,35': 'get',
  '7,36': 'algae',
  '7,37': 'queen',
  '8,-37': 'epic',
  '8,-36': 'titan',
  '8,-35': 'pray',
  '8,-34': 'troll',
  '8,-33': 'surer',
  '8,-32': 'nappy',
  '8,-31': 'mead',
  '8,-30': 'abut',
  '8,-29': 'gain',
  '8,-28': 'bight',
  '8,-27': 'feted',
  '8,-26': 'fatty',
  '8,-25': 'pique',
  '8,-24': 'slink',
  '8,-23': 'wow',
  '8,-22': 'slam',
  '8,-21': 'this',
  '8,-20': 'trip',
  '8,-19': 'stung',
  '8,-18': 'dowdy',
  '8,-17': 'slow',
  '8,-16': 'bosh',
  '8,-15': 'soggy',
  '8,-14': 'south',
  '8,-13': 'skirt',
  '8,-12': 'view',
  '8,-11': 'bean',
  '8,-10': 'anew',
  '8,-9': 'puny',
  '8,-8': 'pause',
  '8,-7': 'icky',
  '8,-6': 'pen',
  '8,-5': 'motor',
  '8,-4': 'uncle',
  '8,-3': 'song',
  '8,-2': 'segue',
  '8,-1': 'stand',
  '8,0': 'wacky',
  '8,1': 'carom',
  '8,2': 'unbar',
  '8,3': 'stub',
  '8,4': 'aria',
  '8,5': 'slap',
  '8,6': 'myth',
  '8,7': 'spent',
  '8,8': 'ark',
  '8,9': 'rumba',
  '8,10': 'nth',
  '8,11': 'octal',
  '8,12': 'envy',
  '8,13': 'jiffy',
  '8,14': 'pill',
  '8,15': 'cue',
  '8,16': 'truce',
  '8,17': 'rug',
  '8,18': 'fain',
  '8,19': 'sweet',
  '8,20': 'gem',
  '8,21': 'angst',
  '8,22': 'gym',
  '8,23': 'loot',
  '8,24': 'swag',
  '8,25': 'outdo',
  '8,26': 'adopt',
  '8,27': 'gizmo',
  '8,28': 'goad',
  '8,29': 'aphid',
  '8,30': 'weedy',
  '8,31': 'crony',
  '8,32': 'used',
  '8,33': 'sire',
  '8,34': 'furl',
  '8,35': 'kelp',
  '8,36': 'like',
  '8,37': 'out',
  '9,-37': 'gaff',
  '9,-36': 'there',
  '9,-35': 'slur',
  '9,-34': 'vine',
  '9,-33': 'essay',
  '9,-32': 'brine',
  '9,-31': 'lake',
  '9,-30': 'first',
  '9,-29': 'miter',
  '9,-28': 'towed',
  '9,-27': 'dwelt',
  '9,-26': 'typo',
  '9,-25': 'end',
  '9,-24': 'eve',
  '9,-23': 'waft',
  '9,-22': 'stun',
  '9,-21': 'bore',
  '9,-20': 'roost',
  '9,-19': 'era',
  '9,-18': 'scout',
  '9,-17': 'sped',
  '9,-16': 'hymen',
  '9,-15': 'mote',
  '9,-14': 'faint',
  '9,-13': 'stamp',
  '9,-12': 'caw',
  '9,-11': 'prize',
  '9,-10': 'burp',
  '9,-9': 'would',
  '9,-8': 'crush',
  '9,-7': 'die',
  '9,-6': 'curer',
  '9,-5': 'gait',
  '9,-4': 'satin',
  '9,-3': 'gamut',
  '9,-2': 'voter',
  '9,-1': 'wily',
  '9,0': 'metro',
  '9,1': 'renew',
  '9,2': 'umbel',
  '9,3': 'polo',
  '9,4': 'tinge',
  '9,5': 'lathe',
  '9,6': 'cite',
  '9,7': 'vised',
  '9,8': 'urged',
  '9,9': 'wench',
  '9,10': 'shop',
  '9,11': 'lad',
  '9,12': 'aced',
  '9,13': 'item',
  '9,14': 'color',
  '9,15': 'enure',
  '9,16': 'web',
  '9,17': 'shone',
  '9,18': 'rift',
  '9,19': 'ski',
  '9,20': 'loan',
  '9,21': 'alarm',
  '9,22': 'reach',
  '9,23': 'toil',
  '9,24': 'core',
  '9,25': 'sizer',
  '9,26': 'papaw',
  '9,27': 'wetly',
  '9,28': 'slain',
  '9,29': 'older',
  '9,30': 'gazer',
  '9,31': 'poet',
  '9,32': 'guyed',
  '9,33': 'bass',
  '9,34': 'kale',
  '9,35': 'vice',
  '9,36': 'dusty',
  '9,37': 'oaf',
  '10,-37': 'eke',
  '10,-36': 'upper',
  '10,-35': 'atone',
  '10,-34': 'drink',
  '10,-33': 'cheep',
  '10,-32': 'food',
  '10,-31': 'slier',
  '10,-30': 'botch',
  '10,-29': 'diary',
  '10,-28': 'hokey',
  '10,-27': 'swoop',
  '10,-26': 'focal',
  '10,-25': 'swirl',
  '10,-24': 'gaze',
  '10,-23': 'sue',
  '10,-22': 'upend',
  '10,-21': 'coped',
  '10,-20': 'swim',
  '10,-19': 'woo',
  '10,-18': 'crept',
  '10,-17': 'booth',
  '10,-16': 'alpha',
  '10,-15': 'sing',
  '10,-14': 'cutup',
  '10,-13': 'wound',
  '10,-12': 'saner',
  '10,-11': 'fame',
  '10,-10': 'raga',
  '10,-9': 'total',
  '10,-8': 'lank',
  '10,-7': 'young',
  '10,-6': 'dwarf',
  '10,-5': 'poppy',
  '10,-4': 'stem',
  '10,-3': 'splay',
  '10,-2': 'ghoul',
  '10,-1': 'raven',
  '10,0': 'cape',
  '10,1': 'yummy',
  '10,2': 'stove',
  '10,3': 'test',
  '10,4': 'sieve',
  '10,5': 'ramp',
  '10,6': 'newt',
  '10,7': 'lade',
  '10,8': 'kite',
  '10,9': 'sheer',
  '10,10': 'peat',
  '10,11': 'lei',
  '10,12': 'add',
  '10,13': 'jury',
  '10,14': 'tuned',
  '10,15': 'arty',
  '10,16': 'meme',
  '10,17': 'thief',
  '10,18': 'shave',
  '10,19': 'visor',
  '10,20': 'folly',
  '10,21': 'depth',
  '10,22': 'adept',
  '10,23': 'djinn',
  '10,24': 'bias',
  '10,25': 'scad',
  '10,26': 'emend',
  '10,27': 'busby',
  '10,28': 'disk',
  '10,29': 'type',
  '10,30': 'lured',
  '10,31': 'flint',
  '10,32': 'hence',
  '10,33': 'mumps',
  '10,34': 'tush',
  '10,35': 'imbue',
  '10,36': 'quiz',
  '10,37': 'watch',
  '11,-37': 'steed',
  '11,-36': 'haft',
  '11,-35': 'dyer',
  '11,-34': 'liter',
  '11,-33': 'sun',
  '11,-32': 'law',
  '11,-31': 'thin',
  '11,-30': 'chain',
  '11,-29': 'miff',
  '11,-28': 'chino',
  '11,-27': 'excel',
  '11,-26': 'keg',
  '11,-25': 'frost',
  '11,-24': 'egret',
  '11,-23': 'rosin',
  '11,-22': 'krone',
  '11,-21': 'vague',
  '11,-20': 'onset',
  '11,-19': 'frog',
  '11,-18': 'mail',
  '11,-17': 'whiz',
  '11,-16': 'gel',
  '11,-15': 'scull',
  '11,-14': 'flyby',
  '11,-13': 'glass',
  '11,-12': 'dealt',
  '11,-11': 'deice',
  '11,-10': 'dupe',
  '11,-9': 'clank',
  '11,-8': 'shank',
  '11,-7': 'salvo',
  '11,-6': 'boy',
  '11,-5': 'spine',
  '11,-4': 'pall',
  '11,-3': 'dean',
  '11,-2': 'sauce',
  '11,-1': 'plumb',
  '11,0': 'polyp',
  '11,1': 'lazy',
  '11,2': 'scarf',
  '11,3': 'shot',
  '11,4': 'pile',
  '11,5': 'taker',
  '11,6': 'snide',
  '11,7': 'scour',
  '11,8': 'taste',
  '11,9': 'chip',
  '11,10': 'septa',
  '11,11': 'click',
  '11,12': 'tries',
  '11,13': 'duty',
  '11,14': 'chump',
  '11,15': 'elf',
  '11,16': 'solar',
  '11,17': 'gull',
  '11,18': 'japan',
  '11,19': 'egged',
  '11,20': 'sang',
  '11,21': 'coast',
  '11,22': 'swash',
  '11,23': 'kopek',
  '11,24': 'sox',
  '11,25': 'piped',
  '11,26': 'saucy',
  '11,27': 'elude',
  '11,28': 'sax',
  '11,29': 'coon',
  '11,30': 'ester',
  '11,31': 'jest',
  '11,32': 'dorky',
  '11,33': 'flu',
  '11,34': 'sleet',
  '11,35': 'spree',
  '11,36': 'meted',
  '11,37': 'retry',
  '12,-37': 'slay',
  '12,-36': 'utter',
  '12,-35': 'writ',
  '12,-34': 'nil',
  '12,-33': 'pony',
  '12,-32': 'split',
  '12,-31': 'gimme',
  '12,-30': 'thud',
  '12,-29': 'pager',
  '12,-28': 'tuna',
  '12,-27': 'shale',
  '12,-26': 'wormy',
  '12,-25': 'teach',
  '12,-24': 'geez',
  '12,-23': 'dank',
  '12,-22': 'sleep',
  '12,-21': 'luau',
  '12,-20': 'rich',
  '12,-19': 'delta',
  '12,-18': 'ought',
  '12,-17': 'sly',
  '12,-16': 'yolk',
  '12,-15': 'chid',
  '12,-14': 'palmy',
  '12,-13': 'swain',
  '12,-12': 'pure',
  '12,-11': 'snarl',
  '12,-10': 'peony',
  '12,-9': 'pita',
  '12,-8': 'ethic',
  '12,-7': 'gaily',
  '12,-6': 'bur',
  '12,-5': 'modem',
  '12,-4': 'sneak',
  '12,-3': 'rash',
  '12,-2': 'went',
  '12,-1': 'macro',
  '12,0': 'bear',
  '12,1': 'claw',
  '12,2': 'rest',
  '12,3': 'shrub',
  '12,4': 'skier',
  '12,5': 'caddy',
  '12,6': 'unity',
  '12,7': 'faked',
  '12,8': 'non',
  '12,9': 'yowl',
  '12,10': 'emoji',
  '12,11': 'doer',
  '12,12': 'brow',
  '12,13': 'foggy',
  '12,14': 'madly',
  '12,15': 'birth',
  '12,16': 'totem',
  '12,17': 'atoll',
  '12,18': 'xenon',
  '12,19': 'monk',
  '12,20': 'ardor',
  '12,21': 'hake',
  '12,22': 'herb',
  '12,23': 'azure',
  '12,24': 'opus',
  '12,25': 'fluky',
  '12,26': 'seize',
  '12,27': 'might',
  '12,28': 'patch',
  '12,29': 'nasty',
  '12,30': 'iota',
  '12,31': 'owl',
  '12,32': 'pity',
  '12,33': 'epoxy',
  '12,34': 'taut',
  '12,35': 'dicta',
  '12,36': 'shard',
  '12,37': 'raped',
  '13,-37': 'kick',
  '13,-36': 'pupal',
  '13,-35': 'wool',
  '13,-34': 'senna',
  '13,-33': 'pain',
  '13,-32': 'motel',
  '13,-31': 'spore',
  '13,-30': 'chimp',
  '13,-29': 'mask',
  '13,-28': 'hemp',
  '13,-27': 'brash',
  '13,-26': 'swung',
  '13,-25': 'eject',
  '13,-24': 'fatal',
  '13,-23': 'vexed',
  '13,-22': 'oink',
  '13,-21': 'apex',
  '13,-20': 'clue',
  '13,-19': 'groan',
  '13,-18': 'datum',
  '13,-17': 'vicar',
  '13,-16': 'run',
  '13,-15': 'boom',
  '13,-14': 'labor',
  '13,-13': 'glop',
  '13,-12': 'inky',
  '13,-11': 'moved',
  '13,-10': 'octet',
  '13,-9': 'grey',
  '13,-8': 'rang',
  '13,-7': 'broom',
  '13,-6': 'pious',
  '13,-5': 'fault',
  '13,-4': 'daffy',
  '13,-3': 'spit',
  '13,-2': 'chill',
  '13,-1': 'sago',
  '13,0': 'orgy',
  '13,1': 'taken',
  '13,2': 'alloy',
  '13,3': 'dirk',
  '13,4': 'gee',
  '13,5': 'hurl',
  '13,6': 'homy',
  '13,7': 'fond',
  '13,8': 'penny',
  '13,9': 'cruel',
  '13,10': 'conk',
  '13,11': 'built',
  '13,12': 'potty',
  '13,13': 'catty',
  '13,14': 'rumor',
  '13,15': 'stats',
  '13,16': 'bung',
  '13,17': 'fat',
  '13,18': 'ached',
  '13,19': 'take',
  '13,20': 'lose',
  '13,21': 'lark',
  '13,22': 'widen',
  '13,23': 'penes',
  '13,24': 'poled',
  '13,25': 'auger',
  '13,26': 'noise',
  '13,27': 'idyll',
  '13,28': 'tizzy',
  '13,29': 'cur',
  '13,30': 'duvet',
  '13,31': 'rant',
  '13,32': 'haled',
  '13,33': 'sunk',
  '13,34': 'ache',
  '13,35': 'bake',
  '13,36': 'sadly',
  '13,37': 'bit',
  '14,-37': 'taint',
  '14,-36': 'verve',
  '14,-35': 'duel',
  '14,-34': 'troy',
  '14,-33': 'revue',
  '14,-32': 'mad',
  '14,-31': 'capon',
  '14,-30': 'haunt',
  '14,-29': 'preen',
  '14,-28': 'prowl',
  '14,-27': 'showy',
  '14,-26': 'fight',
  '14,-25': 'pagan',
  '14,-24': 'infer',
  '14,-23': 'text',
  '14,-22': 'brat',
  '14,-21': 'iffy',
  '14,-20': 'gyp',
  '14,-19': 'polka',
  '14,-18': 'hooey',
  '14,-17': 'cello',
  '14,-16': 'ikon',
  '14,-15': 'ply',
  '14,-14': 'than',
  '14,-13': 'gore',
  '14,-12': 'arch',
  '14,-11': 'yawl',
  '14,-10': 'techs',
  '14,-9': 'nuke',
  '14,-8': 'liege',
  '14,-7': 'genie',
  '14,-6': 'coin',
  '14,-5': 'amble',
  '14,-4': 'binge',
  '14,-3': 'jinni',
  '14,-2': 'belch',
  '14,-1': 'appal',
  '14,0': 'coo',
  '14,1': 'hurry',
  '14,2': 'opine',
  '14,3': 'baud',
  '14,4': 'slump',
  '14,5': 'nuked',
  '14,6': 'hippo',
  '14,7': 'deck',
  '14,8': 'tonic',
  '14,9': 'term',
  '14,10': 'biped',
  '14,11': 'wacko',
  '14,12': 'swamp',
  '14,13': 'crag',
  '14,14': 'tusk',
  '14,15': 'chary',
  '14,16': 'coked',
  '14,17': 'snow',
  '14,18': 'cued',
  '14,19': 'plush',
  '14,20': 'weigh',
  '14,21': 'mend',
  '14,22': 'aster',
  '14,23': 'curly',
  '14,24': 'lied',
  '14,25': 'abhor',
  '14,26': 'kiln',
  '14,27': 'visit',
  '14,28': 'thru',
  '14,29': 'weer',
  '14,30': 'tumor',
  '14,31': 'mouth',
  '14,32': 'hunk',
  '14,33': 'bag',
  '14,34': 'vacua',
  '14,35': 'glory',
  '14,36': 'mope',
  '14,37': 'slant',
  '15,-37': 'buxom',
  '15,-36': 'break',
  '15,-35': 'axed',
  '15,-34': 'dour',
  '15,-33': 'halt',
  '15,-32': 'wanly',
  '15,-31': 'unfit',
  '15,-30': 'frame',
  '15,-29': 'super',
  '15,-28': 'laser',
  '15,-27': 'madam',
  '15,-26': 'ban',
  '15,-25': 'reorg',
  '15,-24': 'souse',
  '15,-23': 'flown',
  '15,-22': 'met',
  '15,-21': 'tell',
  '15,-20': 'whiff',
  '15,-19': 'smell',
  '15,-18': 'bode',
  '15,-17': 'skip',
  '15,-16': 'ride',
  '15,-15': 'lava',
  '15,-14': 'arena',
  '15,-13': 'mid',
  '15,-12': 'scud',
  '15,-11': 'tux',
  '15,-10': 'mold',
  '15,-9': 'opt',
  '15,-8': 'mode',
  '15,-7': 'trite',
  '15,-6': 'role',
  '15,-5': 'hoof',
  '15,-4': 'bile',
  '15,-3': 'shuck',
  '15,-2': 'cod',
  '15,-1': 'tost',
  '15,0': 'fir',
  '15,1': 'fuzz',
  '15,2': 'abide',
  '15,3': 'devil',
  '15,4': 'asp',
  '15,5': 'jerk',
  '15,6': 'spin',
  '15,7': 'oat',
  '15,8': 'inlet',
  '15,9': 'flare',
  '15,10': 'teak',
  '15,11': 'elm',
  '15,12': 'hate',
  '15,13': 'waked',
  '15,14': 'parse',
  '15,15': 'tour',
  '15,16': 'yam',
  '15,17': 'nodal',
  '15,18': 'axle',
  '15,19': 'fold',
  '15,20': 'phyla',
  '15,21': 'hired',
  '15,22': 'fire',
  '15,23': 'sexes',
  '15,24': 'flout',
  '15,25': 'meal',
  '15,26': 'loped',
  '15,27': 'bleep',
  '15,28': 'slush',
  '15,29': 'sooty',
  '15,30': 'gutsy',
  '15,31': 'spilt',
  '15,32': 'mien',
  '15,33': 'slunk',
  '15,34': 'gulch',
  '15,35': 'sexy',
  '15,36': 'plate',
  '15,37': 'tutu',
  '16,-37': 'sick',
  '16,-36': 'wired',
  '16,-35': 'clean',
  '16,-34': 'yahoo',
  '16,-33': 'finis',
  '16,-32': 'holed',
  '16,-31': 'undue',
  '16,-30': 'reeve',
  '16,-29': 'zoned',
  '16,-28': 'askew',
  '16,-27': 'dryly',
  '16,-26': 'rate',
  '16,-25': 'babel',
  '16,-24': 'beak',
  '16,-23': 'envoy',
  '16,-22': 'goner',
  '16,-21': 'dilly',
  '16,-20': 'bier',
  '16,-19': 'raw',
  '16,-18': 'veal',
  '16,-17': 'itchy',
  '16,-16': 'panel',
  '16,-15': 'fife',
  '16,-14': 'wuss',
  '16,-13': 'papa',
  '16,-12': 'muck',
  '16,-11': 'flea',
  '16,-10': 'lie',
  '16,-9': 'synch',
  '16,-8': 'manor',
  '16,-7': 'grid',
  '16,-6': 'caged',
  '16,-5': 'beam',
  '16,-4': 'brig',
  '16,-3': 'hared',
  '16,-2': 'paper',
  '16,-1': 'quark',
  '16,0': 'jock',
  '16,1': 'coke',
  '16,2': 'pica',
  '16,3': 'soya',
  '16,4': 'nine',
  '16,5': 'tired',
  '16,6': 'hire',
  '16,7': 'posse',
  '16,8': 'latte',
  '16,9': 'wean',
  '16,10': 'dirge',
  '16,11': 'kited',
  '16,12': 'high',
  '16,13': 'serve',
  '16,14': 'belle',
  '16,15': 'dicky',
  '16,16': 'hyena',
  '16,17': 'cud',
  '16,18': 'beige',
  '16,19': 'earth',
  '16,20': 'piked',
  '16,21': 'our',
  '16,22': 'copra',
  '16,23': 'last',
  '16,24': 'sear',
  '16,25': 'told',
  '16,26': 'odor',
  '16,27': 'flood',
  '16,28': 'fries',
  '16,29': 'roam',
  '16,30': 'demo',
  '16,31': 'triad',
  '16,32': 'hymn',
  '16,33': 'nose',
  '16,34': 'unto',
  '16,35': 'pecs',
  '16,36': 'truss',
  '16,37': 'flex',
  '17,-37': 'hell',
  '17,-36': 'edit',
  '17,-35': 'weest',
  '17,-34': 'fool',
  '17,-33': 'munch',
  '17,-32': 'lord',
  '17,-31': 'dead',
  '17,-30': 'wiki',
  '17,-29': 'mimed',
  '17,-28': 'sheik',
  '17,-27': 'ivory',
  '17,-26': 'cob',
  '17,-25': 'scoff',
  '17,-24': 'whey',
  '17,-23': 'win',
  '17,-22': 'jaded',
  '17,-21': 'camel',
  '17,-20': 'proxy',
  '17,-19': 'sock',
  '17,-18': 'gift',
  '17,-17': 'frat',
  '17,-16': 'ugly',
  '17,-15': 'bail',
  '17,-14': 'stoat',
  '17,-13': 'dope',
  '17,-12': 'brier',
  '17,-11': 'fever',
  '17,-10': 'rent',
  '17,-9': 'champ',
  '17,-8': 'fate',
  '17,-7': 'tun',
  '17,-6': 'hour',
  '17,-5': 'elect',
  '17,-4': 'chasm',
  '17,-3': 'drift',
  '17,-2': 'spar',
  '17,-1': 'fungi',
  '17,0': 'tawny',
  '17,1': 'her',
  '17,2': 'slake',
  '17,3': 'dacha',
  '17,4': 'rib',
  '17,5': 'mart',
  '17,6': 'maybe',
  '17,7': 'omega',
  '17,8': 'lain',
  '17,9': 'mambo',
  '17,10': 'pied',
  '17,11': 'haw',
  '17,12': 'tarp',
  '17,13': 'codex',
  '17,14': 'sades',
  '17,15': 'duh',
  '17,16': 'zeta',
  '17,17': 'annul',
  '17,18': 'palsy',
  '17,19': 'nick',
  '17,20': 'neath',
  '17,21': 'court',
  '17,22': 'flies',
  '17,23': 'hasty',
  '17,24': 'else',
  '17,25': 'radii',
  '17,26': 'front',
  '17,27': 'sated',
  '17,28': 'well',
  '17,29': 'rheum',
  '17,30': 'waive',
  '17,31': 'lice',
  '17,32': 'alb',
  '17,33': 'guilt',
  '17,34': 'orb',
  '17,35': 'gamin',
  '17,36': 'blimp',
  '17,37': 'clump',
  '18,-37': 'watt',
  '18,-36': 'chase',
  '18,-35': 'maven',
  '18,-34': 'abash',
  '18,-33': 'elves',
  '18,-32': 'geek',
  '18,-31': 'hexes',
  '18,-30': 'king',
  '18,-29': 'rub',
  '18,-28': 'oath',
  '18,-27': 'rid',
  '18,-26': 'hold',
  '18,-25': 'hint',
  '18,-24': 'afoot',
  '18,-23': 'set',
  '18,-22': 'nosh',
  '18,-21': 'dozed',
  '18,-20': 'coax',
  '18,-19': 'rat',
  '18,-18': 'read',
  '18,-17': 'year',
  '18,-16': 'fox',
  '18,-15': 'waken',
  '18,-14': 'fluke',
  '18,-13': 'earn',
  '18,-12': 'tribe',
  '18,-11': 'musty',
  '18,-10': 'scent',
  '18,-9': 'vex',
  '18,-8': 'fare',
  '18,-7': 'staff',
  '18,-6': 'tear',
  '18,-5': 'blah',
  '18,-4': 'sop',
  '18,-3': 'lemme',
  '18,-2': 'fence',
  '18,-1': 'order',
  '18,0': 'petty',
  '18,1': 'copse',
  '18,2': 'befog',
  '18,3': 'dopey',
  '18,4': 'fume',
  '18,5': 'refit',
  '18,6': 'dirt',
  '18,7': 'din',
  '18,8': 'yoga',
  '18,9': 'sharp',
  '18,10': 'sat',
  '18,11': 'allot',
  '18,12': 'buses',
  '18,13': 'yore',
  '18,14': 'zinc',
  '18,15': 'briar',
  '18,16': 'veldt',
  '18,17': 'shun',
  '18,18': 'cocoa',
  '18,19': 'clout',
  '18,20': 'fink',
  '18,21': 'ghost',
  '18,22': 'canon',
  '18,23': 'purl',
  '18,24': 'guano',
  '18,25': 'hew',
  '18,26': 'freak',
  '18,27': 'amaze',
  '18,28': 'whole',
  '18,29': 'jawed',
  '18,30': 'germ',
  '18,31': 'alive',
  '18,32': 'drawl',
  '18,33': 'safe',
  '18,34': 'perch',
  '18,35': 'snoot',
  '18,36': 'worse',
  '18,37': 'slyly',
  '19,-37': 'fact',
  '19,-36': 'apt',
  '19,-35': 'moat',
  '19,-34': 'vibe',
  '19,-33': 'ditty',
  '19,-32': 'brown',
  '19,-31': 'bobby',
  '19,-30': 'vase',
  '19,-29': 'vireo',
  '19,-28': 'quirk',
  '19,-27': 'spake',
  '19,-26': 'gold',
  '19,-25': 'shear',
  '19,-24': 'shire',
  '19,-23': 'croci',
  '19,-22': 'robed',
  '19,-21': 'ding',
  '19,-20': 'ins',
  '19,-19': 'wit',
  '19,-18': 'agile',
  '19,-17': 'while',
  '19,-16': 'softy',
  '19,-15': 'plan',
  '19,-14': 'sip',
  '19,-13': 'toot',
  '19,-12': 'maze',
  '19,-11': 'boxes',
  '19,-10': 'prude',
  '19,-9': 'roped',
  '19,-8': 'grew',
  '19,-7': 'uteri',
  '19,-6': 'pip',
  '19,-5': 'aught',
  '19,-4': 'host',
  '19,-3': 'ulna',
  '19,-2': 'risen',
  '19,-1': 'fagot',
  '19,0': 'whizz',
  '19,1': 'jehad',
  '19,2': 'bower',
  '19,3': 'wimp',
  '19,4': 'swipe',
  '19,5': 'whist',
  '19,6': 'hooky',
  '19,7': 'time',
  '19,8': 'crib',
  '19,9': 'dray',
  '19,10': 'gaudy',
  '19,11': 'awol',
  '19,12': 'flute',
  '19,13': 'hog',
  '19,14': 'worst',
  '19,15': 'boner',
  '19,16': 'decor',
  '19,17': 'arson',
  '19,18': 'puffy',
  '19,19': 'cube',
  '19,20': 'same',
  '19,21': 'brag',
  '19,22': 'whet',
  '19,23': 'none',
  '19,24': 'squid',
  '19,25': 'dude',
  '19,26': 'hump',
  '19,27': 'dale',
  '19,28': 'rend',
  '19,29': 'mooed',
  '19,30': 'juice',
  '19,31': 'minor',
  '19,32': 'oily',
  '19,33': 'yen',
  '19,34': 'rasp',
  '19,35': 'gamma',
  '19,36': 'shed',
  '19,37': 'skill',
  '20,-37': 'towel',
  '20,-36': 'onyx',
  '20,-35': 'dusky',
  '20,-34': 'wrap',
  '20,-33': 'tooth',
  '20,-32': 'holy',
  '20,-31': 'clock',
  '20,-30': 'liven',
  '20,-29': 'mama',
  '20,-28': 'shift',
  '20,-27': 'doped',
  '20,-26': 'dogie',
  '20,-25': 'grind',
  '20,-24': 'ore',
  '20,-23': 'shoot',
  '20,-22': 'neat',
  '20,-21': 'noun',
  '20,-20': 'aimed',
  '20,-19': 'skim',
  '20,-18': 'near',
  '20,-17': 'pulp',
  '20,-16': 'scrub',
  '20,-15': 'wrote',
  '20,-14': 'tiara',
  '20,-13': 'comer',
  '20,-12': 'wince',
  '20,-11': 'dash',
  '20,-10': 'macho',
  '20,-9': 'cozen',
  '20,-8': 'saki',
  '20,-7': 'molt',
  '20,-6': 'stole',
  '20,-5': 'gamey',
  '20,-4': 'chewy',
  '20,-3': 'cider',
  '20,-2': 'much',
  '20,-1': 'sore',
  '20,0': 'rite',
  '20,1': 'swift',
  '20,2': 'rove',
  '20,3': 'chaos',
  '20,4': 'cilia',
  '20,5': 'famed',
  '20,6': 'bomb',
  '20,7': 'wale',
  '20,8': 'how',
  '20,9': 'sofa',
  '20,10': 'dub',
  '20,11': 'cruet',
  '20,12': 'pale',
  '20,13': 'carve',
  '20,14': 'bat',
  '20,15': 'quill',
  '20,16': 'price',
  '20,17': 'whelp',
  '20,18': 'lobby',
  '20,19': 'hub',
  '20,20': 'plant',
  '20,21': 'rune',
  '20,22': 'prawn',
  '20,23': 'wheal',
  '20,24': 'mule',
  '20,25': 'clot',
  '20,26': 'soar',
  '20,27': 'worry',
  '20,28': 'dice',
  '20,29': 'nuder',
  '20,30': 'beaux',
  '20,31': 'want',
  '20,32': 'knell',
  '20,33': 'fro',
  '20,34': 'owed',
  '20,35': 'sperm',
  '20,36': 'slice',
  '20,37': 'erode',
  '21,-37': 'assay',
  '21,-36': 'pope',
  '21,-35': 'egis',
  '21,-34': 'hello',
  '21,-33': 'bushy',
  '21,-32': 'class',
  '21,-31': 'ease',
  '21,-30': 'which',
  '21,-29': 'mil',
  '21,-28': 'neck',
  '21,-27': 'some',
  '21,-26': 'divvy',
  '21,-25': 'cloth',
  '21,-24': 'nite',
  '21,-23': 'slaw',
  '21,-22': 'hit',
  '21,-21': 'burka',
  '21,-20': 'meet',
  '21,-19': 'rose',
  '21,-18': 'rock',
  '21,-17': 'lop',
  '21,-16': 'nerve',
  '21,-15': 'akin',
  '21,-14': 'undid',
  '21,-13': 'sewn',
  '21,-12': 'filmy',
  '21,-11': 'pug',
  '21,-10': 'port',
  '21,-9': 'area',
  '21,-8': 'elegy',
  '21,-7': 'furry',
  '21,-6': 'from',
  '21,-5': 'fated',
  '21,-4': 'gouge',
  '21,-3': 'outer',
  '21,-2': 'turn',
  '21,-1': 'burro',
  '21,0': 'wept',
  '21,1': 'ids',
  '21,2': 'vowed',
  '21,3': 'wolf',
  '21,4': 'vet',
  '21,5': 'saga',
  '21,6': 'silo',
  '21,7': 'bond',
  '21,8': 'dick',
  '21,9': 'boob',
  '21,10': 'aped',
  '21,11': 'crank',
  '21,12': 'card',
  '21,13': 'mike',
  '21,14': 'lame',
  '21,15': 'floor',
  '21,16': 'put',
  '21,17': 'tangy',
  '21,18': 'calk',
  '21,19': 'telex',
  '21,20': 'sahib',
  '21,21': 'prop',
  '21,22': 'coven',
  '21,23': 'homey',
  '21,24': 'age',
  '21,25': 'tipi',
  '21,26': 'lunge',
  '21,27': 'crowd',
  '21,28': 'alone',
  '21,29': 'covey',
  '21,30': 'shorn',
  '21,31': 'ovary',
  '21,32': 'eyed',
  '21,33': 'hatch',
  '21,34': 'glue',
  '21,35': 'sinus',
  '21,36': 'brute',
  '21,37': 'maw',
  '22,-37': 'prom',
  '22,-36': 'clew',
  '22,-35': 'canny',
  '22,-34': 'spry',
  '22,-33': 'alike',
  '22,-32': 'spear',
  '22,-31': 'noble',
  '22,-30': 'dill',
  '22,-29': 'conic',
  '22,-28': 'slosh',
  '22,-27': 'enter',
  '22,-26': 'piggy',
  '22,-25': 'armed',
  '22,-24': 'solid',
  '22,-23': 'chuck',
  '22,-22': 'gauge',
  '22,-21': 'scorn',
  '22,-20': 'loopy',
  '22,-19': 'alum',
  '22,-18': 'ire',
  '22,-17': 'jail',
  '22,-16': 'bided',
  '22,-15': 'sworn',
  '22,-14': 'over',
  '22,-13': 'mural',
  '22,-12': 'tape',
  '22,-11': 'life',
  '22,-10': 'huger',
  '22,-9': 'bait',
  '22,-8': 'fuse',
  '22,-7': 'repel',
  '22,-6': 'lite',
  '22,-5': 'gawky',
  '22,-4': 'tread',
  '22,-3': 'imp',
  '22,-2': 'ozone',
  '22,-1': 'deign',
  '22,0': 'mason',
  '22,1': 'shag',
  '22,2': 'wham',
  '22,3': 'cameo',
  '22,4': 'ruble',
  '22,5': 'cozy',
  '22,6': 'olive',
  '22,7': 'earl',
  '22,8': 'kicky',
  '22,9': 'evil',
  '22,10': 'agony',
  '22,11': 'prank',
  '22,12': 'after',
  '22,13': 'unman',
  '22,14': 'knock',
  '22,15': 'eagle',
  '22,16': 'pay',
  '22,17': 'rave',
  '22,18': 'group',
  '22,19': 'gavel',
  '22,20': 'karma',
  '22,21': 'ism',
  '22,22': 'bra',
  '22,23': 'hefty',
  '22,24': 'guess',
  '22,25': 'bed',
  '22,26': 'prove',
  '22,27': 'scat',
  '22,28': 'dunk',
  '22,29': 'sepal',
  '22,30': 'ting',
  '22,31': 'manse',
  '22,32': 'alga',
  '22,33': 'jazzy',
  '22,34': 'corm',
  '22,35': 'leafy',
  '22,36': 'fizz',
  '22,37': 'ifs',
  '23,-37': 'limed',
  '23,-36': 'fun',
  '23,-35': 'chafe',
  '23,-34': 'stiff',
  '23,-33': 'gaged',
  '23,-32': 'dawn',
  '23,-31': 'ameba',
  '23,-30': 'thus',
  '23,-29': 'née',
  '23,-28': 'beach',
  '23,-27': 'email',
  '23,-26': 'buddy',
  '23,-25': 'shyly',
  '23,-24': 'pluck',
  '23,-23': 'shim',
  '23,-22': 'mogul',
  '23,-21': 'toner',
  '23,-20': 'count',
  '23,-19': 'chic',
  '23,-18': 'extra',
  '23,-17': 'leer',
  '23,-16': 'gild',
  '23,-15': 'gawk',
  '23,-14': 'talon',
  '23,-13': 'tango',
  '23,-12': 'sewed',
  '23,-11': 'ahem',
  '23,-10': 'polar',
  '23,-9': 'qualm',
  '23,-8': 'delve',
  '23,-7': 'swill',
  '23,-6': 'rinse',
  '23,-5': 'start',
  '23,-4': 'path',
  '23,-3': 'fen',
  '23,-2': 'debug',
  '23,-1': 'tune',
  '23,0': 'arced',
  '23,1': 'whip',
  '23,2': 'extol',
  '23,3': 'mug',
  '23,4': 'screw',
  '23,5': 'glen',
  '23,6': 'fad',
  '23,7': 'spoor',
  '23,8': 'dos',
  '23,9': 'dog',
  '23,10': 'strap',
  '23,11': 'nacre',
  '23,12': 'paean',
  '23,13': 'light',
  '23,14': 'whelk',
  '23,15': 'cock',
  '23,16': 'send',
  '23,17': 'dally',
  '23,18': 'agog',
  '23,19': 'crimp',
  '23,20': 'odium',
  '23,21': 'stout',
  '23,22': 'tint',
  '23,23': 'pansy',
  '23,24': 'cliff',
  '23,25': 'brink',
  '23,26': 'exert',
  '23,27': 'cull',
  '23,28': 'smart',
  '23,29': 'gauze',
  '23,30': 'pal',
  '23,31': 'rag',
  '23,32': 'baaed',
  '23,33': 'tided',
  '23,34': 'axiom',
  '23,35': 'idle',
  '23,36': 'cough',
  '23,37': 'graft',
  '24,-37': 'drive',
  '24,-36': 'toga',
  '24,-35': 'gnome',
  '24,-34': 'snub',
  '24,-33': 'bayed',
  '24,-32': 'fusty',
  '24,-31': 'wail',
  '24,-30': 'billy',
  '24,-29': 'seer',
  '24,-28': 'scab',
  '24,-27': 'droll',
  '24,-26': 'sulk',
  '24,-25': 'meant',
  '24,-24': 'comic',
  '24,-23': 'shies',
  '24,-22': 'ado',
  '24,-21': 'skies',
  '24,-20': 'cult',
  '24,-19': 'pare',
  '24,-18': 'hogan',
  '24,-17': 'decoy',
  '24,-16': 'dory',
  '24,-15': 'ohs',
  '24,-14': 'creed',
  '24,-13': 'photo',
  '24,-12': 'thong',
  '24,-11': 'birch',
  '24,-10': 'weak',
  '24,-9': 'punk',
  '24,-8': 'prong',
  '24,-7': 'dimly',
  '24,-6': 'ruler',
  '24,-5': 'poker',
  '24,-4': 'pored',
  '24,-3': 'soup',
  '24,-2': 'hawk',
  '24,-1': 'poppa',
  '24,0': 'loam',
  '24,1': 'oft',
  '24,2': 'cargo',
  '24,3': 'notch',
  '24,4': 'lens',
  '24,5': 'baize',
  '24,6': 'letup',
  '24,7': 'pends',
  '24,8': 'melon',
  '24,9': 'pyre',
  '24,10': 'east',
  '24,11': 'quest',
  '24,12': 'blade',
  '24,13': 'known',
  '24,14': 'glare',
  '24,15': 'awful',
  '24,16': 'troop',
  '24,17': 'dirty',
  '24,18': 'ref',
  '24,19': 'irony',
  '24,20': 'parka',
  '24,21': 'cyst',
  '24,22': 'prick',
  '24,23': 'nest',
  '24,24': 'face',
  '24,25': 'bog',
  '24,26': 'ran',
  '24,27': 'rear',
  '24,28': 'futon',
  '24,29': 'cluck',
  '24,30': 'dumb',
  '24,31': 'waxy',
  '24,32': 'dodo',
  '24,33': 'firth',
  '24,34': 'bawl',
  '24,35': 'rated',
  '24,36': 'fob',
  '24,37': 'clam',
  '25,-37': 'spark',
  '25,-36': 'hover',
  '25,-35': 'tweak',
  '25,-34': 'mat',
  '25,-33': 'prow',
  '25,-32': 'labia',
  '25,-31': 'kept',
  '25,-30': 'homie',
  '25,-29': 'fear',
  '25,-28': 'doled',
  '25,-27': 'clued',
  '25,-26': 'john',
  '25,-25': 'emir',
  '25,-24': 'true',
  '25,-23': 'joker',
  '25,-22': 'colon',
  '25,-21': 'bully',
  '25,-20': 'okay',
  '25,-19': 'tine',
  '25,-18': 'dove',
  '25,-17': 'crest',
  '25,-16': 'index',
  '25,-15': 'loom',
  '25,-14': 'awoke',
  '25,-13': 'skiff',
  '25,-12': 'brr',
  '25,-11': 'yuk',
  '25,-10': 'putt',
  '25,-9': 'rummy',
  '25,-8': 'pinch',
  '25,-7': 'mare',
  '25,-6': 'bunny',
  '25,-5': 'curl',
  '25,-4': 'grade',
  '25,-3': 'bulgy',
  '25,-2': 'manly',
  '25,-1': 'knee',
  '25,0': 'sell',
  '25,1': 'puck',
  '25,2': 'bast',
  '25,3': 'offed',
  '25,4': 'throe',
  '25,5': 'and',
  '25,6': 'baste',
  '25,7': 'belly',
  '25,8': 'anode',
  '25,9': 'shook',
  '25,10': 'jihad',
  '25,11': 'cress',
  '25,12': 'livid',
  '25,13': 'sixes',
  '25,14': 'sight',
  '25,15': 'pewee',
  '25,16': 'pubic',
  '25,17': 'shirt',
  '25,18': 'defer',
  '25,19': 'junta',
  '25,20': 'wrest',
  '25,21': 'fag',
  '25,22': 'doom',
  '25,23': 'vouch',
  '25,24': 'drab',
  '25,25': 'thine',
  '25,26': 'tripe',
  '25,27': 'pea',
  '25,28': 'fief',
  '25,29': 'air',
  '25,30': 'gourd',
  '25,31': 'smith',
  '25,32': 'pint',
  '25,33': 'shame',
  '25,34': 'grant',
  '25,35': 'lurk',
  '25,36': 'oxide',
  '25,37': 'rope',
  '26,-37': 'joy',
  '26,-36': 'basso',
  '26,-35': 'ogle',
  '26,-34': 'prig',
  '26,-33': 'recta',
  '26,-32': 'bogy',
  '26,-31': 'trump',
  '26,-30': 'fluff',
  '26,-29': 'fez',
  '26,-28': 'bilge',
  '26,-27': 'fail',
  '26,-26': 'done',
  '26,-25': 'elate',
  '26,-24': 'woof',
  '26,-23': 'laced',
  '26,-22': 'waled',
  '26,-21': 'axon',
  '26,-20': 'tiro',
  '26,-19': 'soot',
  '26,-18': 'stuck',
  '26,-17': 'jilt',
  '26,-16': 'roomy',
  '26,-15': 'amuse',
  '26,-14': 'seal',
  '26,-13': 'nimbi',
  '26,-12': 'draft',
  '26,-11': 'frill',
  '26,-10': 'uncut',
  '26,-9': 'chapt',
  '26,-8': 'bus',
  '26,-7': 'cawed',
  '26,-6': 'flub',
  '26,-5': 'anted',
  '26,-4': 'aerie',
  '26,-3': 'jibed',
  '26,-2': 'eaten',
  '26,-1': 'tofu',
  '26,0': 'page',
  '26,1': 'dummy',
  '26,2': 'loin',
  '26,3': 'gored',
  '26,4': 'tenet',
  '26,5': 'prior',
  '26,6': 'scoop',
  '26,7': 'coif',
  '26,8': 'pleat',
  '26,9': 'tot',
  '26,10': 'plump',
  '26,11': 'hash',
  '26,12': 'clink',
  '26,13': 'enema',
  '26,14': 'all',
  '26,15': 'snore',
  '26,16': 'fête',
  '26,17': 'croup',
  '26,18': 'apart',
  '26,19': 'dries',
  '26,20': 'thrum',
  '26,21': 'spot',
  '26,22': 'warm',
  '26,23': 'hasp',
  '26,24': 'stern',
  '26,25': 'sump',
  '26,26': 'meh',
  '26,27': 'cram',
  '26,28': 'keen',
  '26,29': 'mulch',
  '26,30': 'egg',
  '26,31': 'owned',
  '26,32': 'waged',
  '26,33': 'bead',
  '26,34': 'fizzy',
  '26,35': 'pet',
  '26,36': 'begun',
  '26,37': 'amend',
  '27,-37': 'chug',
  '27,-36': 'cad',
  '27,-35': 'back',
  '27,-34': 'part',
  '27,-33': 'fluid',
  '27,-32': 'shore',
  '27,-31': 'four',
  '27,-30': 'mono',
  '27,-29': 'route',
  '27,-28': 'gloom',
  '27,-27': 'bony',
  '27,-26': 'wheel',
  '27,-25': 'dorm',
  '27,-24': 'usury',
  '27,-23': 'fiat',
  '27,-22': 'canoe',
  '27,-21': 'cadet',
  '27,-20': 'tried',
  '27,-19': 'bum',
  '27,-18': 'gravy',
  '27,-17': 'roast',
  '27,-16': 'hokum',
  '27,-15': 'plod',
  '27,-14': 'sloe',
  '27,-13': 'ego',
  '27,-12': 'sewer',
  '27,-11': 'nicer',
  '27,-10': 'six',
  '27,-9': 'cupid',
  '27,-8': 'tense',
  '27,-7': 'prism',
  '27,-6': 'river',
  '27,-5': 'mow',
  '27,-4': 'jabot',
  '27,-3': 'vigil',
  '27,-2': 'oops',
  '27,-1': 'ever',
  '27,0': 'head',
  '27,1': 'owlet',
  '27,2': 'pone',
  '27,3': 'boxer',
  '27,4': 'volt',
  '27,5': 'rape',
  '27,6': 'wan',
  '27,7': 'music',
  '27,8': 'poesy',
  '27,9': 'wager',
  '27,10': 'cute',
  '27,11': 'satay',
  '27,12': 'growl',
  '27,13': 'jumbo',
  '27,14': 'dado',
  '27,15': 'dell',
  '27,16': 'dregs',
  '27,17': 'pour',
  '27,18': 'blow',
  '27,19': 'suet',
  '27,20': 'yuck',
  '27,21': 'hum',
  '27,22': 'peek',
  '27,23': 'freed',
  '27,24': 'knoll',
  '27,25': 'ensue',
  '27,26': 'glee',
  '27,27': 'wig',
  '27,28': 'jello',
  '27,29': 'stash',
  '27,30': 'rice',
  '27,31': 'shy',
  '27,32': 'lotus',
  '27,33': 'eery',
  '27,34': 'anus',
  '27,35': 'fund',
  '27,36': 'jape',
  '27,37': 'bate',
  '28,-37': 'hewn',
  '28,-36': 'every',
  '28,-35': 'lisp',
  '28,-34': 'epoch',
  '28,-33': 'emote',
  '28,-32': 'idly',
  '28,-31': 'moor',
  '28,-30': 'pox',
  '28,-29': 'stay',
  '28,-28': 'dark',
  '28,-27': 'kiwi',
  '28,-26': 'lull',
  '28,-25': 'hoist',
  '28,-24': 'ward',
  '28,-23': 'dunce',
  '28,-22': 'know',
  '28,-21': 'nor',
  '28,-20': 'slily',
  '28,-19': 'deny',
  '28,-18': 'byway',
  '28,-17': 'cuff',
  '28,-16': 'finch',
  '28,-15': 'maxim',
  '28,-14': 'honor',
  '28,-13': 'mufti',
  '28,-12': 'hosed',
  '28,-11': 'suave',
  '28,-10': 'fairy',
  '28,-9': 'paddy',
  '28,-8': 'lynx',
  '28,-7': 'score',
  '28,-6': 'mete',
  '28,-5': 'hone',
  '28,-4': 'loss',
  '28,-3': 'two',
  '28,-2': 'wrong',
  '28,-1': 'sew',
  '28,0': 'could',
  '28,1': 'mew',
  '28,2': 'gayly',
  '28,3': 'mewl',
  '28,4': 'kiosk',
  '28,5': 'boxed',
  '28,6': 'vegan',
  '28,7': 'organ',
  '28,8': 'pier',
  '28,9': 'cairn',
  '28,10': 'hail',
  '28,11': 'bunt',
  '28,12': 'low',
  '28,13': 'heat',
  '28,14': 'stunt',
  '28,15': 'sign',
  '28,16': 'stink',
  '28,17': 'will',
  '28,18': 'gipsy',
  '28,19': 'merry',
  '28,20': 'quoit',
  '28,21': 'muse',
  '28,22': 'boat',
  '28,23': 'wee',
  '28,24': 'rode',
  '28,25': 'stalk',
  '28,26': 'trawl',
  '28,27': 'snuff',
  '28,28': 'odder',
  '28,29': 'raja',
  '28,30': 'cream',
  '28,31': 'koala',
  '28,32': 'golf',
  '28,33': 'aloha',
  '28,34': 'sassy',
  '28,35': 'oust',
  '28,36': 'sol',
  '28,37': 'stair',
  '29,-37': 'porch',
  '29,-36': 'vie',
  '29,-35': 'hotly',
  '29,-34': 'link',
  '29,-33': 'colt',
  '29,-32': 'flick',
  '29,-31': 'hill',
  '29,-30': 'mite',
  '29,-29': 'loge',
  '29,-28': 'prep',
  '29,-27': 'abase',
  '29,-26': 'bowl',
  '29,-25': 'threw',
  '29,-24': 'wend',
  '29,-23': 'zing',
  '29,-22': 'tying',
  '29,-21': 'broad',
  '29,-20': 'drove',
  '29,-19': 'noel',
  '29,-18': 'artsy',
  '29,-17': 'canto',
  '29,-16': 'blink',
  '29,-15': 'own',
  '29,-14': 'brook',
  '29,-13': 'below',
  '29,-12': 'ample',
  '29,-11': 'week',
  '29,-10': 'flask',
  '29,-9': 'job',
  '29,-8': 'hotel',
  '29,-7': 'minty',
  '29,-6': 'faced',
  '29,-5': 'smock',
  '29,-4': 'gig',
  '29,-3': 'pis',
  '29,-2': 'oaten',
  '29,-1': 'catch',
  '29,0': 'guy',
  '29,1': 'prosy',
  '29,2': 'hypo',
  '29,3': 'forgo',
  '29,4': 'coda',
  '29,5': 'baser',
  '29,6': 'zebra',
  '29,7': 'huff',
  '29,8': 'girt',
  '29,9': 'hazed',
  '29,10': 'let',
  '29,11': 'heel',
  '29,12': 'shock',
  '29,13': 'rainy',
  '29,14': 'quit',
  '29,15': 'login',
  '29,16': 'lobe',
  '29,17': 'mile',
  '29,18': 'thee',
  '29,19': 'borax',
  '29,20': 'hep',
  '29,21': 'horsy',
  '29,22': 'nobly',
  '29,23': 'roof',
  '29,24': 'shush',
  '29,25': 'evade',
  '29,26': 'beau',
  '29,27': 'admen',
  '29,28': 'sushi',
  '29,29': 'less',
  '29,30': 'rev',
  '29,31': 'tenon',
  '29,32': 'daub',
  '29,33': 'anger',
  '29,34': 'sync',
  '29,35': 'teeny',
  '29,36': 'wiry',
  '29,37': 'snout',
  '30,-37': 'navy',
  '30,-36': 'rump',
  '30,-35': 'dress',
  '30,-34': 'false',
  '30,-33': 'bowel',
  '30,-32': 'sunny',
  '30,-31': 'buzz',
  '30,-30': 'bloom',
  '30,-29': 'city',
  '30,-28': 'hying',
  '30,-27': 'prof',
  '30,-26': 'grime',
  '30,-25': 'berry',
  '30,-24': 'chew',
  '30,-23': 'dock',
  '30,-22': 'yoked',
  '30,-21': 'dicey',
  '30,-20': 'plank',
  '30,-19': 'beady',
  '30,-18': 'windy',
  '30,-17': 'tow',
  '30,-16': 'lay',
  '30,-15': 'peril',
  '30,-14': 'bevy',
  '30,-13': 'churn',
  '30,-12': 'fjord',
  '30,-11': 'gut',
  '30,-10': 'topic',
  '30,-9': 'wait',
  '30,-8': 'choir',
  '30,-7': 'balm',
  '30,-6': 'plus',
  '30,-5': 'bimbo',
  '30,-4': 'lupin',
  '30,-3': 'fanny',
  '30,-2': 'plaza',
  '30,-1': 'toady',
  '30,0': 'check',
  '30,1': 'miser',
  '30,2': 'twig',
  '30,3': 'tab',
  '30,4': 'pry',
  '30,5': 'batch',
  '30,6': 'squaw',
  '30,7': 'cheer',
  '30,8': 'payee',
  '30,9': 'roan',
  '30,10': 'icing',
  '30,11': 'butch',
  '30,12': 'cubic',
  '30,13': 'wooer',
  '30,14': 'mined',
  '30,15': 'burr',
  '30,16': 'gnat',
  '30,17': 'women',
  '30,18': 'basal',
  '30,19': 'brad',
  '30,20': 'smote',
  '30,21': 'jetty',
  '30,22': 'taxed',
  '30,23': 'loony',
  '30,24': 'brief',
  '30,25': 'fort',
  '30,26': 'tad',
  '30,27': 'tick',
  '30,28': 'snot',
  '30,29': 'post',
  '30,30': 'relax',
  '30,31': 'chant',
  '30,32': 'sow',
  '30,33': 'dad',
  '30,34': 'icier',
  '30,35': 'throb',
  '30,36': 'sudsy',
  '30,37': 'jelly',
  '31,-37': 'icon',
  '31,-36': 'army',
  '31,-35': 'sabre',
  '31,-34': 'soupy',
  '31,-33': 'brass',
  '31,-32': 'green',
  '31,-31': 'boon',
  '31,-30': 'ton',
  '31,-29': 'soft',
  '31,-28': 'sap',
  '31,-27': 'even',
  '31,-26': 'bread',
  '31,-25': 'wined',
  '31,-24': 'aha',
  '31,-23': 'stray',
  '31,-22': 'aloe',
  '31,-21': 'blob',
  '31,-20': 'aspen',
  '31,-19': 'hitch',
  '31,-18': 'chest',
  '31,-17': 'quaff',
  '31,-16': 'stunk',
  '31,-15': 'trunk',
  '31,-14': 'seamy',
  '31,-13': 'silk',
  '31,-12': 'skulk',
  '31,-11': 'begin',
  '31,-10': 'dhoti',
  '31,-9': 'sill',
  '31,-8': 'bath',
  '31,-7': 'line',
  '31,-6': 'realm',
  '31,-5': 'ravel',
  '31,-4': 'cross',
  '31,-3': 'haven',
  '31,-2': 'undo',
  '31,-1': 'dull',
  '31,0': 'wot',
  '31,1': 'ping',
  '31,2': 'pest',
  '31,3': 'promo',
  '31,4': 'toad',
  '31,5': 'being',
  '31,6': 'tail',
  '31,7': 'smelt',
  '31,8': 'naiad',
  '31,9': 'fast',
  '31,10': 'mall',
  '31,11': 'dosed',
  '31,12': 'tare',
  '31,13': 'lump',
  '31,14': 'skin',
  '31,15': 'guile',
  '31,16': 'then',
  '31,17': 'frizz',
  '31,18': 'skeet',
  '31,19': 'shin',
  '31,20': 'snip',
  '31,21': 'pined',
  '31,22': 'glean',
  '31,23': 'cloud',
  '31,24': 'skein',
  '31,25': 'saree',
  '31,26': 'chunk',
  '31,27': 'grow',
  '31,28': 'homed',
  '31,29': 'cleft',
  '31,30': 'nosy',
  '31,31': 'venal',
  '31,32': 'lone',
  '31,33': 'short',
  '31,34': 'storm',
  '31,35': 'sort',
  '31,36': 'snake',
  '31,37': 'chile',
  '32,-37': 'pate',
  '32,-36': 'north',
  '32,-35': 'rain',
  '32,-34': 'whim',
  '32,-33': 'needy',
  '32,-32': 'tar',
  '32,-31': 'skull',
  '32,-30': 'cot',
  '32,-29': 'redo',
  '32,-28': 'power',
  '32,-27': 'hoed',
  '32,-26': 'hussy',
  '32,-25': 'its',
  '32,-24': 'baked',
  '32,-23': 'mooch',
  '32,-22': 'wry',
  '32,-21': 'tweet',
  '32,-20': 'umiak',
  '32,-19': 'tubby',
  '32,-18': 'scare',
  '32,-17': 'spies',
  '32,-16': 'lubed',
  '32,-15': 'abed',
  '32,-14': 'frail',
  '32,-13': 'dart',
  '32,-12': 'infix',
  '32,-11': 'rural',
  '32,-10': 'fifty',
  '32,-9': 'truck',
  '32,-8': 'cols',
  '32,-7': 'swarm',
  '32,-6': 'cuing',
  '32,-5': 'limit',
  '32,-4': 'tryst',
  '32,-3': 'stone',
  '32,-2': 'unit',
  '32,-1': 'hah',
  '32,0': 'moon',
  '32,1': 'bud',
  '32,2': 'chirp',
  '32,3': 'sisal',
  '32,4': 'twerp',
  '32,5': 'guff',
  '32,6': 'toll',
  '32,7': 'forth',
  '32,8': 'croon',
  '32,9': 'gas',
  '32,10': 'chit',
  '32,11': 'tsar',
  '32,12': 'loxes',
  '32,13': 'glow',
  '32,14': 'radon',
  '32,15': 'goes',
  '32,16': 'board',
  '32,17': 'abbot',
  '32,18': 'tic',
  '32,19': 'erupt',
  '32,20': 'sum',
  '32,21': 'detox',
  '32,22': 'coed',
  '32,23': 'axe',
  '32,24': 'omit',
  '32,25': 'spout',
  '32,26': 'lunch',
  '32,27': 'equal',
  '32,28': 'dame',
  '32,29': 'tunic',
  '32,30': 'expo',
  '32,31': 'drake',
  '32,32': 'tunny',
  '32,33': 'pod',
  '32,34': 'jolly',
  '32,35': 'pilot',
  '32,36': 'gamy',
  '32,37': 'merit',
  '33,-37': 'aloof',
  '33,-36': 'agism',
  '33,-35': 'sedan',
  '33,-34': 'tout',
  '33,-33': 'creel',
  '33,-32': 'pup',
  '33,-31': 'based',
  '33,-30': 'serer',
  '33,-29': 'imply',
  '33,-28': 'when',
  '33,-27': 'ratio',
  '33,-26': 'voile',
  '33,-25': 'small',
  '33,-24': 'kaput',
  '33,-23': 'spike',
  '33,-22': 'oared',
  '33,-21': 'knave',
  '33,-20': 'bib',
  '33,-19': 'spiky',
  '33,-18': 'was',
  '33,-17': 'rouse',
  '33,-16': 'abaft',
  '33,-15': 'matte',
  '33,-14': 'ass',
  '33,-13': 'roe',
  '33,-12': 'tarry',
  '33,-11': 'craze',
  '33,-10': 'wrung',
  '33,-9': 'fork',
  '33,-8': 'bract',
  '33,-7': 'aunt',
  '33,-6': 'mimic',
  '33,-5': 'feet',
  '33,-4': 'reef',
  '33,-3': 'asses',
  '33,-2': 'calif',
  '33,-1': 'quack',
  '33,0': 'bind',
  '33,1': 'brick',
  '33,2': 'beast',
  '33,3': 'folk',
  '33,4': 'whine',
  '33,5': 'crier',
  '33,6': 'dryad',
  '33,7': 'ergo',
  '33,8': 'fixed',
  '33,9': 'cedar',
  '33,10': 'clang',
  '33,11': 'aqua',
  '33,12': 'bloc',
  '33,13': 'pyx',
  '33,14': 'touch',
  '33,15': 'reply',
  '33,16': 'crude',
  '33,17': 'nasal',
  '33,18': 'nabob',
  '33,19': 'tyro',
  '33,20': 'creep',
  '33,21': 'broth',
  '33,22': 'dated',
  '33,23': 'nexus',
  '33,24': 'surly',
  '33,25': 'learn',
  '33,26': 'wage',
  '33,27': 'lib',
  '33,28': 'gorse',
  '33,29': 'unzip',
  '33,30': 'eddy',
  '33,31': 'dried',
  '33,32': 'yon',
  '33,33': 'larva',
  '33,34': 'flyer',
  '33,35': 'cacti',
  '33,36': 'sunup',
  '33,37': 'ultra',
  '34,-37': 'bassi',
  '34,-36': 'aloft',
  '34,-35': 'hayed',
  '34,-34': 'hairy',
  '34,-33': 'swore',
  '34,-32': 'quilt',
  '34,-31': 'vat',
  '34,-30': 'paid',
  '34,-29': 'tame',
  '34,-28': 'inch',
  '34,-27': 'star',
  '34,-26': 'gauzy',
  '34,-25': 'howdy',
  '34,-24': 'rood',
  '34,-23': 'best',
  '34,-22': 'love',
  '34,-21': 'salsa',
  '34,-20': 'graph',
  '34,-19': 'paw',
  '34,-18': 'dense',
  '34,-17': 'trek',
  '34,-16': 'gybe',
  '34,-15': 'pearl',
  '34,-14': 'rebus',
  '34,-13': 'rawer',
  '34,-12': 'soled',
  '34,-11': 'bump',
  '34,-10': 'lip',
  '34,-9': 'recap',
  '34,-8': 'doc',
  '34,-7': 'drug',
  '34,-6': 'thug',
  '34,-5': 'zonal',
  '34,-4': 'fur',
  '34,-3': 'comfy',
  '34,-2': 'purge',
  '34,-1': 'rival',
  '34,0': 'tepid',
  '34,1': 'drum',
  '34,2': 'knob',
  '34,3': 'basil',
  '34,4': 'yip',
  '34,5': 'gamed',
  '34,6': 'helm',
  '34,7': 'crumb',
  '34,8': 'vamp',
  '34,9': 'fee',
  '34,10': 'youth',
  '34,11': 'metal',
  '34,12': 'tubed',
  '34,13': 'junk',
  '34,14': 'tuck',
  '34,15': 'fetid',
  '34,16': 'meter',
  '34,17': 'big',
  '34,18': 'ruled',
  '34,19': 'male',
  '34,20': 'filch',
  '34,21': 'nip',
  '34,22': 'robin',
  '34,23': 'breed',
  '34,24': 'aurae',
  '34,25': 'alder',
  '34,26': 'slog',
  '34,27': 'thumb',
  '34,28': 'tug',
  '34,29': 'gin',
  '34,30': 'pries',
  '34,31': 'wart',
  '34,32': 'smirk',
  '34,33': 'teem',
  '34,34': 'smug',
  '34,35': 'calf',
  '34,36': 'actor',
  '34,37': 'saver',
  '35,-37': 'porn',
  '35,-36': 'not',
  '35,-35': 'feign',
  '35,-34': 'slug',
  '35,-33': 'viand',
  '35,-32': 'sloth',
  '35,-31': 'agar',
  '35,-30': 'cacao',
  '35,-29': 'rared',
  '35,-28': 'swept',
  '35,-27': 'pep',
  '35,-26': 'deep',
  '35,-25': 'miner',
  '35,-24': 'tonal',
  '35,-23': 'cheat',
  '35,-22': 'gab',
  '35,-21': 'villa',
  '35,-20': 'shrew',
  '35,-19': 'piper',
  '35,-18': 'range',
  '35,-17': 'adage',
  '35,-16': 'leggy',
  '35,-15': 'ace',
  '35,-14': 'peso',
  '35,-13': 'wore',
  '35,-12': 'pram',
  '35,-11': 'unify',
  '35,-10': 'thing',
  '35,-9': 'pool',
  '35,-8': 'queue',
  '35,-7': 'lats',
  '35,-6': 'phlox',
  '35,-5': 'jibe',
  '35,-4': 'perm',
  '35,-3': 'cove',
  '35,-2': 'lyric',
  '35,-1': 'rowel',
  '35,0': 'abort',
  '35,1': 'rime',
  '35,2': 'scrod',
  '35,3': 'vivid',
  '35,4': 'curb',
  '35,5': 'tamp',
  '35,6': 'nanny',
  '35,7': 'stab',
  '35,8': 'bosun',
  '35,9': 'mouse',
  '35,10': 'dish',
  '35,11': 'straw',
  '35,12': 'speak',
  '35,13': 'lint',
  '35,14': 'track',
  '35,15': 'sub',
  '35,16': 'charm',
  '35,17': 'bliss',
  '35,18': 'loamy',
  '35,19': 'foci',
  '35,20': 'spa',
  '35,21': 'toddy',
  '35,22': 'sonic',
  '35,23': 'rise',
  '35,24': 'decry',
  '35,25': 'bleak',
  '35,26': 'abler',
  '35,27': 'waste',
  '35,28': 'wisp',
  '35,29': 'spur',
  '35,30': 'plead',
  '35,31': 'caked',
  '35,32': 'buff',
  '35,33': 'pock',
  '35,34': 'booty',
  '35,35': 'mime',
  '35,36': 'doff',
  '35,37': 'mast',
  '36,-37': 'joist',
  '36,-36': 'pried',
  '36,-35': 'spunk',
  '36,-34': 'mate',
  '36,-33': 'druid',
  '36,-32': 'easy',
  '36,-31': 'bored',
  '36,-30': 'faxes',
  '36,-29': 'bang',
  '36,-28': 'hush',
  '36,-27': 'firm',
  '36,-26': 'stew',
  '36,-25': 'apace',
  '36,-24': 'lion',
  '36,-23': 'hovel',
  '36,-22': 'audit',
  '36,-21': 'hos',
  '36,-20': 'you',
  '36,-19': 'chap',
  '36,-18': 'mod',
  '36,-17': 'fill',
  '36,-16': 'vault',
  '36,-15': 'aim',
  '36,-14': 'sod',
  '36,-13': 'neon',
  '36,-12': 'slyer',
  '36,-11': 'talk',
  '36,-10': 'welsh',
  '36,-9': 'floe',
  '36,-8': 'guppy',
  '36,-7': 'dowel',
  '36,-6': 'pupil',
  '36,-5': 'turd',
  '36,-4': 'drew',
  '36,-3': 'orc',
  '36,-2': 'arid',
  '36,-1': 'rill',
  '36,0': 'wane',
  '36,1': 'yep',
  '36,2': 'spay',
  '36,3': 'milch',
  '36,4': 'throw',
  '36,5': 'civet',
  '36,6': 'inure',
  '36,7': 'cable',
  '36,8': 'downy',
  '36,9': 'rough',
  '36,10': 'expel',
  '36,11': 'log',
  '36,12': 'taboo',
  '36,13': 'snoop',
  '36,14': 'cagey',
  '36,15': 'filet',
  '36,16': 'soy',
  '36,17': 'hike',
  '36,18': 'kiss',
  '36,19': 'off',
  '36,20': 'payed',
  '36,21': 'setup',
  '36,22': 'soak',
  '36,23': 'chin',
  '36,24': 'afar',
  '36,25': 'gun',
  '36,26': 'nope',
  '36,27': 'caned',
  '36,28': 'zombi',
  '36,29': 'hula',
  '36,30': 'cowed',
  '36,31': 'leper',
  '36,32': 'feint',
  '36,33': 'cigar',
  '36,34': 'cared',
  '36,35': 'weld',
  '36,36': 'vane',
  '36,37': 'hewed',
  '37,-37': 'pump',
  '37,-36': 'noes',
  '37,-35': 'hove',
  '37,-34': 'togae',
  '37,-33': 'blur',
  '37,-32': 'yarn',
  '37,-31': 'quell',
  '37,-30': 'brisk',
  '37,-29': 'dot',
  '37,-28': 'lodge',
  '37,-27': 'sedge',
  '37,-26': 'duet',
  '37,-25': 'lotto',
  '37,-24': 'phase',
  '37,-23': 'anon',
  '37,-22': 'sweep',
  '37,-21': 'grace',
  '37,-20': 'laxly',
  '37,-19': 'tall',
  '37,-18': 'guard',
  '37,-17': 'busy',
  '37,-16': 'cone',
  '37,-15': 'enrol',
  '37,-14': 'witch',
  '37,-13': 'crime',
  '37,-12': 'hid',
  '37,-11': 'oddly',
  '37,-10': 'gismo',
  '37,-9': 'klutz',
  '37,-8': 'bide',
  '37,-7': 'ninth',
  '37,-6': 'okra',
  '37,-5': 'ruin',
  '37,-4': 'wary',
  '37,-3': 'remit',
  '37,-2': 'sword',
  '37,-1': 'cased',
  '37,0': 'paint',
  '37,1': 'hilly',
  '37,2': 'fitly',
  '37,3': 'ritzy',
  '37,4': 'flux',
  '37,5': 'joust',
  '37,6': 'tie',
  '37,7': 'braid',
  '37,8': 'armor',
  '37,9': 'jive',
  '37,10': 'vape',
  '37,11': 'spawn',
  '37,12': 'viced',
  '37,13': 'femur',
  '37,14': 'soapy',
  '37,15': 'sent',
  '37,16': 'hardy',
  '37,17': 'bribe',
  '37,18': 'aid',
  '37,19': 'laugh',
  '37,20': 'early',
  '37,21': 'eel',
  '37,22': 'blown',
  '37,23': 'anise',
  '37,24': 'brush',
  '37,25': 'maced',
  '37,26': 'addle',
  '37,27': 'rotor',
  '37,28': 'harp',
  '37,29': 'khan',
  '37,30': 'hive',
  '37,31': 'hoary',
  '37,32': 'squab',
  '37,33': 'afire',
  '37,34': 'bark',
  '37,35': 'mated',
  '37,36': 'lube',
  '37,37': 'gofer',
};
