import { thingTypeDescriptors } from '../data/thingTypes';
import { roomNames } from '../data/roomNames';
import {
  getHexId,
  getHexCoordsFromId,
  getHexIdsCoveredByThing,
  shuffled,
} from '../utils';
import { getPcMaxHp } from '../data/professions';
import { CARD_IDS } from '../data/cardIds';
import { getDefaultAbilityCids } from '../data/abilityCards';

const offsets = [[-1, 0], [0, -1], [1, -1], [1, 0], [0, 1], [-1, 1]];

function determineRecommendedScenarioLevel(party) {
  const { pcs } = party;
  const sum = pcs.map((c) => c.level).reduce((a, b) => a + b);
  const avg = sum / party.pcs.length;
  return Math.ceil(avg / 2);
}

function preparePcAreas(playthrough) {
  const pcAreas = {};

  for (const pc of Object.values(playthrough.party.pcs)) {
    const deck = pc.combatDeck || CARD_IDS.amPlayerBase;
    const abilityHand = pc.abilityHand || getDefaultAbilityCids(pc.profession);
    pcAreas[pc.id] = {
      amDeck: shuffled(deck),
      amActive: [],
      amDiscard: [],
      abilityHand,
    };
  }
  return pcAreas;
}

function prepareReserve() {
  // TODO monster standees
  const { amPlayerBlesses, amPlayerCurses, amPlayerMinusOnes } = CARD_IDS;
  return {
    amPlayerBlesses,
    amPlayerCurses,
    amPlayerMinusOnes,
  };
}

export function preparePlaythrough(playthrough) {
  const { scenario, party } = playthrough;

  const { tids, tidToConfig } = scenario;
  const resultTids = [];
  const resultTidToConfig = {};

  const entrances = [];

  function addThing(tid, config) {
    resultTids.push(tid);
    resultTidToConfig[tid] = config;
  }

  const hexIdToData = {};
  function addHexAssociation(hexId, tid, moreKeys) {
    if (!hexIdToData[hexId]) {
      hexIdToData[hexId] = {
        tids: new Set(),
      };
    }

    const data = hexIdToData[hexId];
    data.tids.add(tid);

    if (moreKeys.isFloor) {
      data.isFloor = true;
    }

    if (moreKeys.isDoor) {
      data.isDoor = true;
    }

    if (moreKeys.tile) {
      if (data.tile) {
        console.warn(
          'The single hex is covered by two tiles, room detection will be unpredictable',
          hexId,
          moreKeys.tile,
          data.tile
        );
      }
      data.tile = moreKeys.tile;
    }
  }

  function canBeRoom(association) {
    return association.isFloor && !association.isDoor;
  }

  function onDifferentTiles(firstAssociation, secondAssociation) {
    return (
      firstAssociation.tile &&
      secondAssociation.tile &&
      firstAssociation.tile !== secondAssociation.tile
    );
  }

  function startFloodFill(startingHexId, roomName) {
    const [q, r] = getHexCoordsFromId(startingHexId);
    const starting = [q, r, startingHexId, hexIdToData[startingHexId]];

    const queue = [starting];
    while (queue.length > 0) {
      const [q, r, hexId, myData] = queue.shift();
      hexIdToData[hexId].room = roomName;
      for (const offset of offsets) {
        const qq = q + offset[0];
        const rr = r + offset[1];
        const neighborId = getHexId(qq, rr);

        const neighborData = hexIdToData[neighborId];
        if (!neighborData) {
          continue;
        }
        if (neighborData.room) {
          continue;
        }
        if (!canBeRoom(neighborData)) {
          continue;
        }
        if (onDifferentTiles(myData, neighborData)) {
          continue;
        }
        queue.push([qq, rr, neighborId, neighborData]);
      }
    }
  }

  // Find correspondence from hex id to room.
  for (const tid of tids) {
    const config = tidToConfig[tid];
    const { thingType } = config;
    const thingTypeDescriptor = thingTypeDescriptors[thingType];
    if (thingTypeDescriptor.isFloor) {
      const hexIds = getHexIdsCoveredByThing(config);
      for (const hexId of hexIds) {
        const moreKeys = { isFloor: true };
        if (thingType === 'tile') {
          moreKeys.tile = config.imageName;
        }
        addHexAssociation(hexId, tid, moreKeys);
      }
    } else if (thingTypeDescriptor.isDoor) {
      const hexIds = getHexIdsCoveredByThing(config);
      for (const hexId of hexIds) {
        addHexAssociation(hexId, tid, { isDoor: true });
      }
    }
  }

  let roomIndex = 0;
  for (const [hexId, data] of Object.entries(hexIdToData)) {
    if (data.room || !canBeRoom(data)) {
      continue;
    }
    const roomName = roomNames[roomIndex++] || 'Abyss';
    startFloodFill(hexId, roomName);
  }

  const hexIdToRoom = {};
  const tidToRoom = {};
  const roomToHexes = {};
  for (const [hexId, data] of Object.entries(hexIdToData)) {
    const roomName = data.room;
    if (roomName) {
      hexIdToRoom[hexId] = roomName;
      for (const tid of data.tids) {
        tidToRoom[tid] = roomName;
      }

      if (!roomToHexes[roomName]) {
        roomToHexes[roomName] = [];
      }
      roomToHexes[roomName].push(hexId);
    }
  }

  for (let index = 0; index < roomIndex; ++index) {
    const roomName = roomNames[index];
    const roomConfig = {
      thingType: 'live-room',
      isRevealed: false,
      hexes: roomToHexes[roomName],
    };
    addThing(roomName, roomConfig);
  }

  // Add floor things and entrances
  for (const tid of tids) {
    const config = tidToConfig[tid];
    const { thingType } = config;
    const thingTypeDescriptor = thingTypeDescriptors[thingType];
    if (thingTypeDescriptor.isFloor) {
      const floorConfig = {
        ...config,
        roomLink: tidToRoom[tid],
      };
      addThing(tid, floorConfig);
    } else if (thingTypeDescriptor.isDoor) {
      const doorConfig = {
        ...config,
        thingType: 'live-door',
        isOpen: false,
      };
      addThing(tid, doorConfig);
    } else if (thingTypeDescriptor.isEntrance) {
      addThing(tid, config);
      entrances.push(config);
    }
  }

  // Populate party
  for (const [i, pc] of party.pcs.entries()) {
    const { id, name, level, profession } = pc;
    const tid = id;

    let entrance;

    if (entrances.length === 0) {
      console.warn("Scenario doesn't have an entrance");
      entrance = { q: 1, r: 1 };
    } else if (i >= entrances.length) {
      console.warn('There are more characters than entrances, bunching a few');
      entrance = entrances[0];
    } else {
      entrance = entrances[i];
    }

    const pcConfig = {
      thingType: 'live-pc',
      name,
      imageName: profession,
      hp: getPcMaxHp(profession, level),
      q: entrance.q,
      r: entrance.r,
    };
    addThing(tid, pcConfig);
  }

  const reserve = prepareReserve(playthrough);
  const pcAreas = preparePcAreas(playthrough);
  const level = determineRecommendedScenarioLevel(party);
  return {
    ...playthrough,
    tids: resultTids,
    tidToConfig: resultTidToConfig,
    hexIdToRoom,
    level,
    pcAreas,
    reserve,
  };
}
