import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { setSubtitle, generateId, Button, BulmaModal } from '../common';
import { allProfessionsMap } from '../data/professions';
import { generatePartyTitle } from '../data/party';
import { AmZone, AbilityZone } from '../cardViews';
import { CARD_IDS } from '../data/cardIds';
import {
  abilityCidToCard,
  getAllCidsOfProfession,
  getDefaultAbilityCids,
} from '../data/abilityCards';

function sanitizeTitle(title) {
  return title.trim().replace(/\s+/g, ' ');
}

function Choice(props) {
  return (
    <div
      className={classNames('choice', { 'is-active': props.isActive })}
      onClick={props.onClick}
    >
      <img src={`${props.iconPath}`} title={props.name} alt={props.name} />
    </div>
  );
}

function ProfessionSelector(props) {
  return (
    <div className="ProfessionSelector">
      {Object.values(allProfessionsMap).map(({ name, iconName }) => (
        <Choice
          key={name}
          name={name}
          isActive={props.chosenProfession === name}
          onClick={() => props.onProfessionChosen(name)}
          iconPath={`/gh/class-icons/${iconName}`}
        />
      ))}
    </div>
  );
}

class PcEditor extends React.PureComponent {
  changePc = (delta) => {
    const pc = {
      ...this.props.pc,
      ...delta,
    };
    this.props.onChangePc(pc);
  };

  handleProfessionChosen = (profession) => {
    this.changePc({ profession });
  };

  handleNameChange = (event) => {
    this.changePc({ name: event.target.value });
  };

  setLevel = (level) => {
    this.changePc({ level });
  };

  render(props) {
    const { name, level, profession } = this.props.pc;
    return (
      <div className="PcEditor column is-narrow">
        <div className="box">
          <section className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Name:</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    value={name}
                    onChange={this.handleNameChange}
                  />
                </div>
              </div>
            </div>
          </section>
          <section>
            <ProfessionSelector
              onProfessionChosen={this.handleProfessionChosen}
              chosenProfession={profession}
            />
          </section>
          <section className="field is-horizontal">
            <div className="field-label">
              <label className="label">Level:</label>
            </div>
            <div className="field-body">
              <div className="buttons has-addons are-small">
                {[...Array(9).keys()]
                  .map((x) => x + 1)
                  .map((lvl) => (
                    <button
                      key={lvl}
                      className={classNames('button', {
                        'is-info is-selected': level === lvl,
                      })}
                      onClick={() => this.setLevel(lvl)}
                    >
                      {lvl}
                    </button>
                  ))}
              </div>
            </div>
          </section>
          <section className="buttons">
            <Button onClick={this.props.onEditAbilityHand}>Abilities</Button>
            <Button onClick={this.props.onEditCombatDeck}>Combat Deck</Button>
          </section>
          <section>
            <Button isDanger onClick={this.props.onRemovePc}>
              Remove
            </Button>
          </section>
        </div>
      </div>
    );
  }
}

class AbilityHandEditor extends React.PureComponent {
  constructor(props) {
    super(props);

    const { pc } = props;
    const { profession } = pc;

    const cids = getAllCidsOfProfession(profession);
    if (!cids) {
      console.error('Unrecognized profession for ability cards:', profession);
      this.state = {};
      return;
    }

    let selected, available;
    if (pc.abilityHand) {
      selected = pc.abilityHand;
      const theSet = new Set(selected);
      available = cids.filter((cid) => !theSet.has(cid));
    } else {
      selected = getDefaultAbilityCids(profession);
      const theSet = new Set(selected);
      // TODO only include (card.level === 'X' || card.level <= pc.level)
      available = cids.filter((cid) => !theSet.has(cid));
    }

    this.state = {
      available: this.sortedByNumber(available),
      selected: this.sortedByNumber(selected),
    };
  }

  sortedByNumber(cids) {
    // XXX cids is modified, but for now it doesn't matter, because we pass
    // only new arrays here.  Also it's inefficient to sort every time.
    return cids.sort(
      (a, b) => abilityCidToCard[a].number - abilityCidToCard[b].number
    );
  }

  addCard = (cid) => {
    this.setState((state) => ({
      available: this.sortedByNumber(
        state.available.filter((it) => it !== cid)
      ),
      selected: this.sortedByNumber([...state.selected, cid]),
    }));
  };

  removeCard = (cid) => {
    this.setState((state) => ({
      available: this.sortedByNumber([...state.available, cid]),
      selected: this.sortedByNumber(state.selected.filter((it) => it !== cid)),
    }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      const { pc } = this.props;
      const { selected } = this.state;
      this.props.onChange({
        ...pc,
        abilityHand: selected,
      });
    }
  }

  render() {
    const { selected, available } = this.state;
    if (!available) {
      return null;
    }

    return (
      <div className="columns">
        <div className="column">
          <section>
            <h2>Selected ability cards</h2>
            <aside className="message is-info">
              <div className="message-body">
                Select only what you bring on the next adventure.
              </div>
            </aside>
            <AbilityZone onCardClick={this.removeCard} cids={selected} />
          </section>
          <section className="buttons">
            <Button isSuccess onClick={this.props.onClose}>
              Done
            </Button>
            <Button isDanger onClick={this.handleReset}>
              Reset
            </Button>
          </section>
        </div>
        <div className="column">
          <h2>Available cards</h2>
          <AbilityZone onCardClick={this.addCard} cids={available} />
        </div>
      </div>
    );
  }
}

class CombatDeckEditor extends React.PureComponent {
  constructor(props) {
    super(props);

    const { pc } = props;
    const { profession } = pc;

    const base = [...CARD_IDS.amPlayerBase];

    let avBase = [];
    let avBonuses = [...CARD_IDS.amBonuses[profession]];
    let avMinusOnes = [...CARD_IDS.amPlayerMinusOnes];
    let selected;

    if (pc.combatDeck) {
      // TODO remove bonuses of another profession.
      selected = pc.combatDeck;

      const cids = new Set(selected);

      avBase = base.filter((cid) => !cids.has(cid));
      avBonuses = avBonuses.filter((cid) => !cids.has(cid));
      avMinusOnes = avMinusOnes.filter((cid) => !cids.has(cid));
    } else {
      selected = base;
    }

    this.state = {
      selected,
      avBase,
      avBonuses,
      avMinusOnes,
    };
  }

  handleReset = () => {
    const { pc } = this.props;
    const { profession } = pc;

    const base = [...CARD_IDS.amPlayerBase];
    let avBase = [];
    let avBonuses = [...CARD_IDS.amBonuses[profession]];
    let avMinusOnes = [...CARD_IDS.amPlayerMinusOnes];
    let selected = base;
    this.setState({
      selected,
      avBase,
      avBonuses,
      avMinusOnes,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      const { pc } = this.props;
      const { selected } = this.state;
      this.props.onChange({
        ...pc,
        combatDeck: selected,
      });
    }
  }

  removeCard = (cid) => {
    let destination;
    if (cid.includes('-minone-')) {
      destination = 'avMinusOnes';
    } else if (cid.includes('-p-')) {
      destination = 'avBase';
    } else {
      destination = 'avBonuses';
    }
    this.setState((state) => ({
      [destination]: [...state[destination], cid],
      selected: state.selected.filter((that) => that !== cid),
    }));
  };

  addFromBase = (cid) => {
    this.addFrom(cid, 'avBase');
  };

  addFromBonuses = (cid) => {
    this.addFrom(cid, 'avBonuses');
  };

  addFromMinusOnes = (cid) => {
    this.addFrom(cid, 'avMinusOnes');
  };

  addFrom(cid, source) {
    this.setState((state) => ({
      selected: [...state.selected, cid],
      [source]: state[source].filter((that) => that !== cid),
    }));
  }

  render() {
    return (
      <div className="columns">
        <div className="column">
          <section>
            <h2>Combat deck</h2>
            <AmZone onCardClick={this.removeCard} cids={this.state.selected} />
          </section>
          <section className="buttons">
            <Button isSuccess onClick={this.props.onClose}>
              Done
            </Button>
            <Button isDanger onClick={this.handleReset}>
              Reset
            </Button>
          </section>
        </div>
        <div className="column">
          <h2>Available cards</h2>
          <AmZone
            onCardClick={this.addFromBonuses}
            cids={this.state.avBonuses}
          />
          <AmZone onCardClick={this.addFromBase} cids={this.state.avBase} />
          <AmZone
            onCardClick={this.addFromMinusOnes}
            cids={this.state.avMinusOnes}
          />
        </div>
      </div>
    );
  }
}

export class PartyEditor extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      placeholder: generatePartyTitle(),
      subeditor: null,
    };
  }

  componentWillMount() {
    const { party } = this.props;
    this.setState({ party });
    setSubtitle(party.title);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.party !== this.state.party) {
      this.persistParty();
    }
  }

  persistParty = () => {
    const { party } = this.state;
    const key = `party:${party.id}`;
    localStorage.setItem(key, JSON.stringify(party));
  };

  handleAddPc = () => {
    const { party } = this.state;
    const existingProfessions = party.pcs.map((pc) => pc.profession);

    const professions = Object.values(allProfessionsMap).filter(
      (cc) => existingProfessions.indexOf(cc.name) === -1
    );

    const profession =
      professions.length > 0
        ? professions[0]
        : Object.values(allProfessionsMap)[0];

    const newPc = {
      id: generateId(),
      name: profession.defaultPcName,
      profession: profession.name,
      level: 1,
    };
    const newParty = {
      ...party,
      pcs: [...party.pcs, newPc],
    };
    this.setState({ party: newParty });
  };

  handleChangePc = (index, newPc) => {
    const { party } = this.state;
    const pcs = [...party.pcs];
    pcs[index] = newPc;
    const newParty = {
      ...party,
      pcs,
    };

    this.setState({ party: newParty });
  };

  handleRemovePc = (index) => {
    const { party } = this.state;
    const pcs = [...party.pcs];
    pcs.splice(index, 1);
    const newParty = {
      ...party,
      pcs,
    };
    this.setState({ party: newParty });
  };

  handleChangeTitle = (event) => {
    const { party } = this.state;
    const title = event.target.value;
    const newParty = {
      ...party,
      title,
    };
    this.setState({ party: newParty });
    setSubtitle(title);
  };

  handleOpenSubeditor = (index, subeditorType) => {
    this.setState({
      subeditor: {
        subeditorType,
        index,
      },
    });
  };

  renderModalContent = () => {
    const { party, subeditor } = this.state;

    if (!subeditor) {
      return null;
    }

    const { subeditorType, index } = subeditor;

    const pc = party.pcs[index];
    if (!pc) {
      console.warn(
        'Trying to edit deck of unexistent character',
        party.pcs,
        subeditor
      );
      return null;
    }

    if (subeditorType === 'combatDeck') {
      return (
        <CombatDeckEditor
          pc={pc}
          onClose={this.closeModal}
          onChange={(c) => this.handleChangePc(index, c)}
        />
      );
    } else if (subeditorType === 'abilityHand') {
      return (
        <AbilityHandEditor
          pc={pc}
          onClose={this.closeModal}
          onChange={(c) => this.handleChangePc(index, c)}
        />
      );
    } else {
      console.warn(
        'Trying to edit deck of unrecognized subeditorType',
        subeditor
      );
      return null;
    }
  };

  closeModal = () => {
    this.setState({
      subeditor: null,
    });
  };

  render() {
    const { title, pcs } = this.state.party;

    const canSave = sanitizeTitle(title).length > 0;

    const titleClassName = classNames('input is-large is-rounded', {
      'is-danger': !canSave,
    });
    return (
      <div className="PartyEditor container">
        <BulmaModal
          isOpen={this.state.subeditor !== null}
          onRequestClose={this.closeModal}
        >
          {this.renderModalContent()}
        </BulmaModal>

        <h1>Edit Party</h1>
        <div className="field has-addons">
          <div className="control">
            <input
              className={titleClassName}
              placeholder={this.state.placeholder}
              type="text"
              value={title}
              onChange={this.handleChangeTitle}
            />
          </div>
        </div>

        <div className="pcs columns is-multiline">
          {pcs.map((pc, index) => (
            <PcEditor
              key={pc.id}
              pc={pc}
              onChangePc={(c) => this.handleChangePc(index, c)}
              onRemovePc={() => this.handleRemovePc(index)}
              onEditCombatDeck={() =>
                this.handleOpenSubeditor(index, 'combatDeck')
              }
              onEditAbilityHand={() =>
                this.handleOpenSubeditor(index, 'abilityHand')
              }
            />
          ))}
        </div>
        <div className="buttons">
          <button className="button is-primary" onClick={this.handleAddPc}>
            Add character
          </button>
        </div>
        <Link
          className="button is-success"
          onClick={(e) => !canSave && e.preventDefault()}
          disabled={!canSave}
          to="/"
        >
          Done
        </Link>
      </div>
    );
  }
}
