import { createStore, applyMiddleware } from 'redux';
import { reducer as scenarioViewer } from './ScenarioViewer/reducer';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from './sagas';

const logger = (store) => (next) => (action) => {
  console.group(action.type);
  console.info('dispatching', action);
  let result = next(action);
  console.log('next state', store.getState());
  console.groupEnd();
  return result;
};

function rootReducer(state = {}, action) {
  return {
    scenarioViewer: scenarioViewer(state.scenarioViewer, action),
  };
}

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  rootReducer,
  applyMiddleware(logger, sagaMiddleware)
);

sagaMiddleware.run(rootSaga);
