export const tokens = [
  { name: 'chest', thingType: 'chest', displayName: 'Treasure Tile' },

  { name: 'coin', thingType: 'coin', displayName: 'Coin' },

  { name: 'entrance', thingType: 'entrance', displayName: 'Entrance' },

  {
    name: 'corridor-earth-1h',
    thingType: 'corridor',
    displayName: 'Corridor - Earth',
  },
  {
    name: 'corridor-man-made-stone-1h',
    thingType: 'corridor',
    displayName: 'Corridor - Man-Made Stone',
  },
  {
    name: 'corridor-natural-stone-1h',
    thingType: 'corridor',
    displayName: 'Corridor - Natural Stone',
  },
  {
    name: 'corridor-wood-1h',
    thingType: 'corridor',
    displayName: 'Corridor - Wood',
  },

  {
    name: 'pressure-plate',
    thingType: 'pressure-plate',
    displayName: 'Pressure Plate',
  },

  {
    name: 'corridor-earth-2h',
    thingType: 'corridor-long',
    displayName: 'Corridor - Earth 2h',
  },
  {
    name: 'corridor-man-made-stone-2h',
    thingType: 'corridor-long',
    displayName: 'Corridor - Man-Made Stone 2h',
  },
  {
    name: 'corridor-natural-stone-2h',
    thingType: 'corridor-long',
    displayName: 'Corridor - Natural Stone 2h',
  },
  {
    name: 'corridor-wood-2h',
    thingType: 'corridor-long',
    displayName: 'Corridor - Wood 2h',
  },

  {
    name: 'stone-door-horizontal',
    thingType: 'door',
    displayName: 'Stone Door - Hor.',
  },
  {
    name: 'stone-door-vertical',
    thingType: 'door',
    displayName: 'Stone Door - Vertical',
  },
  {
    name: 'wood-door-horizontal',
    thingType: 'door',
    displayName: 'Wood Door - Hor.',
  },
  {
    name: 'wood-door-vertical',
    thingType: 'door',
    displayName: 'Wood Door - Vertical',
  },
  {
    name: 'wood-door-horizontal-open',
    thingType: 'door',
    displayName: 'Open Wood Door - Hor.',
  },
  { name: 'dark-fog', thingType: 'door', displayName: 'Dark Fog' },
  { name: 'light-fog', thingType: 'door', displayName: 'Light Fog' },

  {
    name: 'altar-horizontal',
    thingType: 'obstacle',
    displayName: 'Altar - Hor.',
  },
  {
    name: 'altar-vertical',
    thingType: 'obstacle',
    displayName: 'Altar - Vertical',
  },
  { name: 'barrel', thingType: 'obstacle', displayName: 'Barrel' },
  { name: 'boulder-1h', thingType: 'obstacle', displayName: 'Boulder' },
  { name: 'bush', thingType: 'obstacle', displayName: 'Bush' },
  { name: 'cabinet', thingType: 'obstacle', displayName: 'Cabinet' },
  { name: 'crate', thingType: 'obstacle', displayName: 'Crate' },
  { name: 'crystal', thingType: 'obstacle', displayName: 'Crystal' },
  { name: 'fountain', thingType: 'obstacle', displayName: 'Fountain' },
  { name: 'nest', thingType: 'obstacle', displayName: 'Nest' },
  { name: 'rock-column', thingType: 'obstacle', displayName: 'Rock Column' },
  { name: 'stalagmites', thingType: 'obstacle', displayName: 'Stalagmites' },
  { name: 'stone-pillar', thingType: 'obstacle', displayName: 'Stone Pillar' },
  { name: 'stump', thingType: 'obstacle', displayName: 'Stump' },
  { name: 'totem', thingType: 'obstacle', displayName: 'Totem' },

  { name: 'bookcase-2h', thingType: 'obstacle-long', displayName: 'Bookcase' },
  {
    name: 'boulder-2h',
    thingType: 'obstacle-long',
    displayName: 'Large Boulders',
  },
  { name: 'dark-pit-2h', thingType: 'obstacle-long', displayName: 'Dark Pit' },
  {
    name: 'sarcophagus-2h',
    thingType: 'obstacle-long',
    displayName: 'Sarcophagus',
  },
  { name: 'shelf-2h', thingType: 'obstacle-long', displayName: 'Shelf' },
  { name: 'table-2h', thingType: 'obstacle-long', displayName: 'Table' },
  {
    name: 'wall-section-2h',
    thingType: 'obstacle-long',
    displayName: 'Wall Section',
  },

  {
    name: 'boulder-3h',
    thingType: 'obstacle-triangle',
    displayName: 'Huge Boulders',
  },
  { name: 'tree-3h', thingType: 'obstacle-triangle', displayName: 'Tree' },

  {
    name: 'hot-coals',
    thingType: 'hazardous-terrain',
    displayName: 'Hot Coals',
  },
  { name: 'thorns', thingType: 'hazardous-terrain', displayName: 'Thorns' },

  { name: 'rubble', thingType: 'difficult-terrain', displayName: 'Rubble' },
  {
    name: 'stairs-horizontal',
    thingType: 'difficult-terrain',
    displayName: 'Stairs - Hor.',
  },
  {
    name: 'stairs-vertical',
    thingType: 'difficult-terrain',
    displayName: 'Stairs - Ver.',
  },
  { name: 'water', thingType: 'difficult-terrain', displayName: 'Water' },
  { name: 'log-2h', thingType: 'difficult-terrain-long', displayName: 'Log' },

  { name: 'bear-trap', thingType: 'trap', displayName: 'Bear Trap' },
  {
    name: 'poison-gas-trap',
    thingType: 'trap',
    displayName: 'Poison Gas Trap',
  },
  { name: 'spike-trap', thingType: 'trap', displayName: 'Spike Trap' },

  {
    name: 'custom-red-circle',
    thingType: 'custom-red-circle',
    limit: 100,
    displayName: 'Custom Red Circle',
  },
  {
    name: 'red-circle-1',
    thingType: 'red-circle',
    limit: 1,
    displayName: 'Red Circle 1',
  },
  {
    name: 'red-circle-2',
    thingType: 'red-circle',
    limit: 1,
    displayName: 'Red Circle 2',
  },
  {
    name: 'red-circle-3',
    thingType: 'red-circle',
    limit: 1,
    displayName: 'Red Circle 3',
  },
  {
    name: 'red-circle-4',
    thingType: 'red-circle',
    limit: 1,
    displayName: 'Red Circle 4',
  },
  {
    name: 'red-circle-5',
    thingType: 'red-circle',
    limit: 1,
    displayName: 'Red Circle 5',
  },
  {
    name: 'red-circle-6',
    thingType: 'red-circle',
    limit: 1,
    displayName: 'Red Circle 6',
  },
  {
    name: 'red-circle-7',
    thingType: 'red-circle',
    limit: 1,
    displayName: 'Red Circle 7',
  },
  {
    name: 'red-circle-8',
    thingType: 'red-circle',
    limit: 1,
    displayName: 'Red Circle 8',
  },
  {
    name: 'red-circle-9',
    thingType: 'red-circle',
    limit: 1,
    displayName: 'Red Circle 9',
  },
  {
    name: 'red-circle-10',
    thingType: 'red-circle',
    limit: 1,
    displayName: 'Red Circle 10',
  },
  {
    name: 'red-circle-11',
    thingType: 'red-circle',
    limit: 1,
    displayName: 'Red Circle 11',
  },
  {
    name: 'red-circle-12',
    thingType: 'red-circle',
    limit: 1,
    displayName: 'Red Circle 12',
  },
  {
    name: 'red-circle-a',
    thingType: 'red-circle',
    limit: 1,
    displayName: 'Red Circle A',
  },
  {
    name: 'red-circle-b',
    thingType: 'red-circle',
    limit: 1,
    displayName: 'Red Circle B',
  },
  {
    name: 'red-circle-c',
    thingType: 'red-circle',
    limit: 1,
    displayName: 'Red Circle C',
  },
  {
    name: 'red-circle-d',
    thingType: 'red-circle',
    limit: 1,
    displayName: 'Red Circle D',
  },
  {
    name: 'red-circle-e',
    thingType: 'red-circle',
    limit: 1,
    displayName: 'Red Circle E',
  },
  {
    name: 'red-circle-f',
    thingType: 'red-circle',
    limit: 1,
    displayName: 'Red Circle F',
  },
  {
    name: 'red-circle-g',
    thingType: 'red-circle',
    limit: 1,
    displayName: 'Red Circle G',
  },
  {
    name: 'red-circle-h',
    thingType: 'red-circle',
    limit: 1,
    displayName: 'Red Circle H',
  },
  {
    name: 'red-circle-i',
    thingType: 'red-circle',
    limit: 1,
    displayName: 'Red Circle I',
  },
  {
    name: 'red-circle-j',
    thingType: 'red-circle',
    limit: 1,
    displayName: 'Red Circle J',
  },
  {
    name: 'red-circle-k',
    thingType: 'red-circle',
    limit: 1,
    displayName: 'Red Circle K',
  },
  {
    name: 'red-circle-l',
    thingType: 'red-circle',
    limit: 1,
    displayName: 'Red Circle L',
  },
  {
    name: 'red-circle-m',
    thingType: 'red-circle',
    limit: 1,
    displayName: 'Red Circle M',
  },
  {
    name: 'red-circle-n',
    thingType: 'red-circle',
    limit: 1,
    displayName: 'Red Circle N',
  },
];

export const tokenByName = {};
for (const token of tokens) {
  tokenByName[token.name] = token;
}
