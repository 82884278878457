import { pick } from '../utils';

const prefixes = [
  'Dark',
  'Ghostly',
  'Chaotic',
  'Hidden',
  'Midnight',
  'Lost',
  'Shifting',
  'Forgotten',
  'Decaying',
];

const mains = [
  'Dungeon',
  'Crypt',
  'Fountain',
  'Fortress',
  'Palace',
  'Swamp',
  'Forest',
  'Well',
  'Vault',
  'Peak',
  'Cave',
  'Temple',
  'Tower',
  'Rift',
];

const suffixes = [
  'Death',
  'Youth',
  'Terrors',
  'Fortune',
  'the Riches',
  'Power',
  'Sorrow',
  'Darkness',
  'Mystery',
  'Silence',
  'the Elements',
];

export function generateScenarioTitle() {
  const method = Math.random() < 0.5 ? 'prefixed' : 'suffixed';
  if (method === 'prefixed') {
    const prefix = pick(prefixes);
    const main = pick(mains);
    return `${prefix} ${main}`;
  } else {
    const suffix = pick(suffixes);
    const main = pick(mains);
    return `${main} of ${suffix}`;
  }
}
