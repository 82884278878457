import React from 'react';
import classNames from 'classnames';

function Card(props) {
  const { cardType, src, cid, label, isSelected } = props;

  const className = classNames(cardType, { isSelected });

  return (
    <div className={className} data-cid={cid}>
      <img alt={`${cid}`} src={src} data-cid={cid} />
      {label !== undefined && <div className="Label">{label}</div>}
    </div>
  );
}

export function AmCard(props) {
  const { cid } = props;
  const src = `/gh/attack-modifier/${cid}.png`;
  return <Card cardType="AmCard" src={src} {...props} />;
}

class Zone extends React.PureComponent {
  handleClick = (event) => {
    if (!this.props.onCardClick) {
      return;
    }
    const { cid } = event.target.dataset;
    if (!cid) {
      return;
    }
    this.props.onCardClick(cid);
  };

  render() {
    const { props } = this;
    const { CardComponent, className, cids = [], selectedCidsMap = {} } = props;
    const events = {
      onClick: props.onCardClick ? this.handleClick : undefined,
    };

    return (
      <div className={className} {...events}>
        {cids.map((cid) => {
          return (
            <CardComponent
              key={cid}
              cid={cid}
              isSelected={selectedCidsMap[cid]}
            />
          );
        })}
      </div>
    );
  }
}

export function AmZone(props) {
  const className = classNames('AmZone', { Discard: props.isDiscard });
  return <Zone CardComponent={AmCard} className={className} {...props} />;
}

export function AmPile(props) {
  return (
    <div className="AmZone Pile">
      <AmCard cid="am-back" label={`${props.size}`} />
    </div>
  );
}

export function AbilityZone(props) {
  const { Pile, Two, isSquished, isTopRow } = props;

  const className = classNames('AbilityZone', {
    Pile,
    Two,
    isSquished,
    isTopRow,
  });

  return <Zone CardComponent={AbilityCard} className={className} {...props} />;
}

export function AbilityCard(props) {
  const { cid } = props;
  const src = `/gh/ability/${cid}.png`;
  return <Card cardType="AbilityCard" src={src} {...props} />;
}
