import { defaultScenarioBody } from '../data/scenario';

export const predefinedScenarios = [];

function addScenario(scenario) {
  predefinedScenarios.push(scenario);
}

(function() {
  const scenario = {
    id: 'gh1',
    shortReference: 'GH1',
    title: 'Black Barrow',
    location: 'G-10',
    requirements: 'None',
    goal: 'Kill all enemies',
    body: defaultScenarioBody,
    gridHexTops: 'flat',
    tids: [
      'L1a',
      'I1b',
      'G1b',
      'coin:1',
      'coin:2',
      'coin:3',
      'coin:4',
      'coin:5',
      'chest:1',
      'stone-door-horizontal:1',
      'stone-door-vertical:1',
      'table-2h:1',
      'table-2h:2',
      'bandit-archer:1',
      'bandit-archer:2',
      'bandit-archer:3',
      'bandit-archer:4',
      'living-bones:1',
      'living-bones:2',
      'living-bones:3',
      'living-bones:4',
      'bandit-archer:5',
      'bandit-archer:6',
      'bandit-archer:7',
      'bandit-guard:1',
      'bandit-guard:2',
      'bandit-guard:3',
      'spike-trap:1',
      'spike-trap:2',
      'bandit-guard:4',
      'bandit-guard:5',
      'bandit-guard:6',
      'bandit-guard:7',
      'bandit-guard:8',
      'bandit-guard:9',
      'bandit-guard:10',
      'entrance:1',
      'entrance:2',
      'entrance:3',
      'entrance:4',
      'entrance:5',
      'entrance:6',
      'entrance:7',
    ],
    tidToConfig: {
      L1a: {
        thingType: 'tile',
        imageName: 'L1a',
        q: 8,
        r: 1,
        rotation: 0,
      },
      I1b: {
        thingType: 'tile',
        imageName: 'I1b',
        q: -2,
        r: 7,
        rotation: 0,
      },
      G1b: {
        thingType: 'tile',
        imageName: 'G1b',
        q: 5,
        r: 5,
        rotation: 3,
      },
      'coin:1': {
        thingType: 'coin',
        imageName: 'coin',
        q: -4,
        r: 11,
      },
      'coin:2': {
        thingType: 'coin',
        imageName: 'coin',
        q: -3,
        r: 10,
      },
      'coin:3': {
        thingType: 'coin',
        imageName: 'coin',
        q: -3,
        r: 9,
      },
      'coin:4': {
        thingType: 'coin',
        imageName: 'coin',
        q: 1,
        r: 10,
      },
      'coin:5': {
        thingType: 'coin',
        imageName: 'coin',
        q: 2,
        r: 9,
      },
      'chest:1': {
        thingType: 'chest',
        imageName: 'chest',
        q: 1,
        r: 11,
        label: 'xxx',
      },
      'stone-door-horizontal:1': {
        thingType: 'door',
        imageName: 'stone-door-horizontal',
        q: 1,
        r: 6,
        rotation: 0,
      },
      'stone-door-vertical:1': {
        thingType: 'door',
        imageName: 'stone-door-vertical',
        q: 6,
        r: 4,
        rotation: 0,
      },
      'table-2h:1': {
        thingType: 'obstacle-long',
        imageName: 'table-2h',
        q: -1,
        r: 8,
        rotation: 3,
      },
      'table-2h:2': {
        thingType: 'obstacle-long',
        imageName: 'table-2h',
        q: 2,
        r: 8,
        rotation: 3,
      },
      'bandit-archer:1': {
        thingType: 'monster',
        imageName: 'bandit-archer',
        q: -3,
        r: 11,
        pattern: 'bby',
      },
      'bandit-archer:2': {
        thingType: 'monster',
        imageName: 'bandit-archer',
        q: -2,
        r: 11,
        pattern: 'wyw',
      },
      'bandit-archer:3': {
        thingType: 'monster',
        imageName: 'bandit-archer',
        q: -1,
        r: 11,
        pattern: 'www',
      },
      'bandit-archer:4': {
        thingType: 'monster',
        imageName: 'bandit-archer',
        q: 0,
        r: 11,
        pattern: 'bbw',
      },
      'living-bones:1': {
        thingType: 'monster',
        imageName: 'living-bones',
        q: -1,
        r: 9,
        pattern: 'www',
      },
      'living-bones:2': {
        thingType: 'monster',
        imageName: 'living-bones',
        q: 0,
        r: 9,
        pattern: 'wyw',
      },
      'living-bones:3': {
        thingType: 'monster',
        imageName: 'living-bones',
        q: 0,
        r: 7,
        pattern: 'bbw',
      },
      'living-bones:4': {
        thingType: 'monster',
        imageName: 'living-bones',
        q: 1,
        r: 7,
        pattern: 'bbw',
      },
      'bandit-archer:5': {
        thingType: 'monster',
        imageName: 'bandit-archer',
        q: -2,
        r: 5,
        pattern: 'bww',
      },
      'bandit-archer:6': {
        thingType: 'monster',
        imageName: 'bandit-archer',
        q: -1,
        r: 4,
        pattern: 'yyy',
      },
      'bandit-archer:7': {
        thingType: 'monster',
        imageName: 'bandit-archer',
        q: -1,
        r: 3,
        pattern: 'bww',
      },
      'bandit-guard:1': {
        thingType: 'monster',
        imageName: 'bandit-guard',
        q: 3,
        r: 5,
        pattern: 'wbw',
      },
      'bandit-guard:2': {
        thingType: 'monster',
        imageName: 'bandit-guard',
        q: 4,
        r: 4,
        pattern: 'byy',
      },
      'bandit-guard:3': {
        thingType: 'monster',
        imageName: 'bandit-guard',
        q: 4,
        r: 3,
        pattern: 'wbw',
      },
      'spike-trap:1': {
        thingType: 'trap',
        imageName: 'spike-trap',
        q: 1,
        r: 5,
        rotation: 5,
      },
      'spike-trap:2': {
        thingType: 'trap',
        imageName: 'spike-trap',
        q: 2,
        r: 5,
        rotation: 5,
      },
      'bandit-guard:4': {
        thingType: 'monster',
        imageName: 'bandit-guard',
        q: 5,
        r: 7,
        pattern: 'bww',
      },
      'bandit-guard:5': {
        thingType: 'monster',
        imageName: 'bandit-guard',
        q: 6,
        r: 6,
        pattern: 'bwy',
      },
      'bandit-guard:6': {
        thingType: 'monster',
        imageName: 'bandit-guard',
        q: 6,
        r: 5,
        pattern: 'www',
      },
      'bandit-guard:7': {
        thingType: 'monster',
        imageName: 'bandit-guard',
        q: 7,
        r: 4,
        pattern: 'ybb',
      },
      'bandit-guard:8': {
        thingType: 'monster',
        imageName: 'bandit-guard',
        q: 7,
        r: 3,
        pattern: 'www',
      },
      'bandit-guard:9': {
        thingType: 'monster',
        imageName: 'bandit-guard',
        q: 8,
        r: 2,
        pattern: 'bwy',
      },
      'bandit-guard:10': {
        thingType: 'monster',
        imageName: 'bandit-guard',
        q: 8,
        r: 1,
        pattern: 'bww',
      },
      'entrance:1': {
        thingType: 'entrance',
        imageName: 'entrance',
        q: 9,
        r: 6,
      },
      'entrance:2': {
        thingType: 'entrance',
        imageName: 'entrance',
        q: 9,
        r: 5,
      },
      'entrance:3': {
        thingType: 'entrance',
        imageName: 'entrance',
        q: 10,
        r: 5,
      },
      'entrance:4': {
        thingType: 'entrance',
        imageName: 'entrance',
        q: 10,
        r: 4,
      },
      'entrance:5': {
        thingType: 'entrance',
        imageName: 'entrance',
        q: 10,
        r: 3,
      },
      'entrance:6': {
        thingType: 'entrance',
        imageName: 'entrance',
        q: 11,
        r: 3,
      },
      'entrance:7': {
        thingType: 'entrance',
        imageName: 'entrance',
        q: 11,
        r: 2,
      },
    },
  };
  addScenario(scenario);
})();
