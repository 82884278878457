import { memoized, getHexTopsOfOrientation, getHexId } from '../utils';
import { hexSize } from '../data/sizes';

const SQRT3 = Math.sqrt(3);

const v = {
  full: SQRT3,
  big: 3 / 2,
  small: SQRT3 / 2,
  none: 0,
  '-full': -SQRT3,
  '-big': -3 / 2,
  '-small': -SQRT3 / 2,
};

const contributions = [
  { qx: 'full', rx: 'small', qy: 'none', ry: 'big' },
  { qx: 'big', rx: 'none', qy: 'small', ry: 'full' },

  { qx: 'small', rx: '-small', qy: 'big', ry: 'big' },
  { qx: 'none', rx: '-big', qy: 'full', ry: 'small' },

  { qx: '-small', rx: '-full', qy: 'big', ry: 'none' },
  { qx: '-big', rx: '-big', qy: 'small', ry: '-small' },

  { qx: '-full', rx: '-small', qy: 'none', ry: '-big' },
  { qx: '-big', rx: 'none', qy: '-small', ry: '-full' },

  { qx: '-small', rx: 'small', qy: '-big', ry: '-big' },
  { qx: 'none', rx: 'big', qy: '-full', ry: '-small' },

  { qx: 'small', rx: 'full', qy: '-big', ry: 'none' },
  { qx: 'big', rx: 'big', qy: '-small', ry: 'small' },
];

function hexRound(q, r) {
  const x = q;
  const z = r;
  const y = -(x + z);

  let rx = Math.round(x),
    ry = Math.round(y),
    rz = Math.round(z);

  const xd = Math.abs(rx - x);
  const yd = Math.abs(ry - y);
  const zd = Math.abs(rz - z);

  if (xd > yd && xd > zd) {
    rx = -ry - rz;
  } else if (yd > zd) {
    ry = -rx - rz;
  } else {
    rz = -rx - ry;
  }

  const qq = rx;
  const rr = rz;
  return [qq, rr];
}

export const getHexCoordsFromPoint = (x, y, orientation) => {
  const hexTops = getHexTopsOfOrientation(orientation);
  x -= hexSize / 2;
  y -= hexSize / 4;

  const s = hexSize / 2;
  let q, r;
  if (hexTops === 'pointy') {
    q = ((SQRT3 / 3) * x - (1 / 3) * y) / s;
    r = ((2 / 3) * y) / s;
  } else {
    q = ((2 / 3) * x) / s;
    r = ((-1 / 3) * x + (SQRT3 / 3) * y) / s;
  }
  const [qq, rr] = hexRound(q, r);

  const n = 6 - Math.floor(orientation / 2);
  return rotateHexVector(qq, rr, n);
};

export const getSimpleHexCoords = memoized((q, r, orientation) => {
  // thanks to Amit: https://www.redblobgames.com/grids/hexagons/#hex-to-pixel.

  /*
       We measure hexSize as a diameter instead of radius (to avoid the 112.5
       constant which is given to us by the tile images), hence everywhere we
       divide it by 2.

       The grid has a single canonical "north" direction, with pointy tops, and
       with q vector going to east, and r vector going to south-south-east.
       The "orientation" argument specifies the user's point of view (0..11,
       corresponding to clock hours) where this north is put.  The even
       orientations are the pointy-top, while the odd are flat-top.

       The contributions table below defines the contribution of q and r
       vectors to the viewport x and y, for each possible orientation.  The
       contributions are "full" (hex width: vectors aligns with coordinate
       axis), "none" (vector is perpendicular), "big" or "small" (vector is
       rotated).
     */

  /*     ____
   *      ^  |\  ,rotated
   *  big :  | \/  q or r
   *      :  |  \   vector
   *     _v__|___\
   *           `-- small
   */
  const c = contributions[orientation];
  return {
    x: (hexSize / 2) * (v[c.qx] * q + v[c.rx] * r),
    y: (hexSize / 2) * (v[c.qy] * q + v[c.ry] * r),
  };
});

let HEX_TRUTHS = {};
do {
  const size = hexSize / 2;
  let w, h;

  w = SQRT3 * size;
  h = hexSize;

  HEX_TRUTHS['pointy'] = {
    waypoints: [
      [w / 2, 0],
      [w, h / 4],
      [w, (h * 3) / 4],
      [w / 2, h],
      [0, (h * 3) / 4],
      [0, h / 4],
    ],
    width: w,
    height: h,
    size,
  };

  w = hexSize;
  h = SQRT3 * size;
  HEX_TRUTHS['flat'] = {
    waypoints: [
      [0, size],
      [w / 4, size - h / 2],
      [(3 * w) / 4, size + -h / 2],
      [w, size + 0],
      [(3 * w) / 4, size + h / 2],
      [w / 4, size + h / 2],
    ],
    width: w,
    height: h,
    size,
  };
} while (0);

export const getHexMeasurements = memoized((x, y, hexTops) => {
  const { waypoints, width, height } = HEX_TRUTHS[hexTops];
  const coords = waypoints.map(([wx, wy]) => [x + wx, y + wy].join(','));

  const points = coords.join(' ');
  const textX = x + width / 2;
  const textY = y + (height * 5) / 6;
  const textStyle = {
    fontSize: '42px',
  };
  return {
    points,
    textX,
    textY,
    textStyle,
  };
});

export function convertToCubeCoords(q, r) {
  const x = q,
    z = r,
    y = -(x + z);
  return [x, y, z];
}

export function convertFromCubeCoords(x, y, z) {
  const q = x,
    r = z;
  return [q, r];
}

function rotateHexVectorCubeToCube(x, y, z, n) {
  let xx = x,
    yy = y,
    zz = z;

  n = (n + 6) % 6;

  for (var i = 0; i < n; ++i) {
    const sx = xx,
      sy = yy,
      sz = zz;
    xx = -sz;
    yy = -sx;
    zz = -sy;
  }
  return [xx, yy, zz];
}

export function rotateHexVector(q, r, n) {
  const [x, y, z] = convertToCubeCoords(q, r);

  const [xx, yy, zz] = rotateHexVectorCubeToCube(x, y, z, n);

  return convertFromCubeCoords(xx, yy, zz);
}

export function rotateHexCoordsAroundAnotherHex(q, r, n, pivotQ, pivotR) {
  const [x, y, z] = convertToCubeCoords(q, r);
  const [px, py, pz] = convertToCubeCoords(pivotQ, pivotR);
  const vx = x - px,
    vy = y - py,
    vz = z - pz;

  const [xx, yy, zz] = rotateHexVectorCubeToCube(vx, vy, vz, n);
  return convertFromCubeCoords(xx + px, yy + py, zz + pz);
}

function createHexes() {
  const hexes = [];
  const mapOriginQ = -10,
    mapOriginR = -10,
    mapHeight = 50,
    mapWidth = 50;

  for (let rr = 0; rr < mapHeight; ++rr) {
    const nShifted = Math.floor(rr / 2);
    for (let qq = -nShifted; qq < mapWidth - nShifted; ++qq) {
      const q = qq + mapOriginQ;
      const r = rr + mapOriginR;

      const hex = {
        id: getHexId(q, r),
        q,
        r,
      };
      hexes.push(hex);
    }
  }

  return hexes;
}

export const hexes = createHexes();

export const setOfAllGridHexIds = new Set(hexes.map((hex) => hex.id));
