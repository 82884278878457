import PDFDocument from 'pdfkit';
import blobStream from 'blob-stream';
import './register-files';
import { loadAllImages, getImageDataUrlSync } from '../imageLoader/imageLoader';
import {
  createPictureIntermediary,
  getScenarioManifestData,
} from '../ScenarioViewer/createPictureIntermediary';
import { createPngCanvasSync } from '../png/generatePng';
import { tokenByName } from '../data/tokens';
import { englishMonsterNames } from '../data/monsters';
import { thingTypeDescriptors } from '../data/thingTypes';
import { defaultScenarioBody } from '../data/scenario';

const ph = 841.89;
const pw = 595.28;
const bm = 40;
const sm = 34;
const gutter = 6;
const pageContentWidth = pw - (bm + sm);
const columnWidth = (pageContentWidth - 2 * gutter) / 3;
const baseSize = 46; // XXX renamed to something related to manifest

const topMargins = {
  begin: 120,
  cont: 60,
};

const bottomMargin = 60;

const ruleImageSize = { width: 721, height: 120 };

// pageKind is one of: 'begin', 'cont', 'half', 'general'.
function getBackgroundImageSrc(pageKind, pageFacing) {
  return `/gh/bg/bg-${pageKind}-${pageFacing}.jpg`;
}

const named = {
  blue: '#1C467C',
  dark: '#392215',
  green: '#5E8251',
  brown: '#533821',
  red: '#821520',
  faded: '#4E321C',
};

const colors = {
  pageNumber: named.faded,
  credit: named.faded,
  bodyCopy: named.dark,
  campaignTitle: 'black',
  scenarioIntroduction: named.green,
  scenarioReward: named.brown,
  scenarioHighlightedBox: '#BFB1A7',
  scenarioConclusion: named.red,
  scenarioMetaKey: named.blue,
  scenarioMetaValue: named.dark,
  scenarioRuleLabel: named.red,
  scenarioManifest: named.dark,
};

const fontSizes = {
  bodyCopy: 10,
  campaignTitle: 40,
  credit: 9,
  pageNumber: 22,
  scenarioLocation: 16,
  scenarioMetaKey: 16,
  scenarioMetaValue: 16,
  scenarioBodyHeading: 16,
  scenarioRuleLabel: 16,
};

function defineStyle(fontSize, color, fontName = 'hightower') {
  return {
    fontSize,
    color,
    fontName,
  };
}

const styles = {
  bodyCopy: defineStyle(10, named.dark, 'hightower'),
  campaignTitle: defineStyle(40, 'black', 'pirata'),
  credit: defineStyle(9, named.faded, 'hightower'),
  pageNumber: defineStyle(22, named.faded, 'pirata'),
  scenarioTitle: defineStyle(32, named.blue, 'pirata'),
  scenarioLocation: defineStyle(16, named.blue, 'pirata'),
  scenarioMetaKey: defineStyle(16, named.blue, 'pirata'),
  scenarioMetaValue: defineStyle(16, named.dark, 'hightower'),
  scenarioBodyHeading: defineStyle(16, named.dark, 'pirata'),
  scenarioRuleLabel: defineStyle(16, named.red, 'pirata'),
  scenarioManifest: defineStyle(9, named.dark, 'hightower'),
  scenarioTilesHeader: defineStyle(16, named.dark, 'pirata'),
  scenarioTilesBody: defineStyle(10, named.dark, 'hightower'),
  scenarioAuthor: defineStyle(10, named.dark, 'hightower'),
};

function addPageNumber(doc, pageNumberValue, pageFacing) {
  doc
    .font('pirata')
    .fontSize(fontSizes.pageNumber)
    .fillColor(colors.pageNumber);

  const txt = pageNumberValue.toString();

  const x = pageFacing === 'left' ? 50 : pw - 56;
  const align = pageFacing === 'left' ? 'right' : 'left';
  const y = ph - 52;
  doc.text(txt, x, y, {
    align,
    width: 0,
  });
}

const appUrl = 'https://silent-bridge.chantry-games.com';
const credit = `Created with Silent Bridge — ${appUrl}`;

function addCredit(doc) {
  doc.fillColor(colors.credit);
  doc.font('hightower');
  doc.fontSize(fontSizes.credit);

  // TODO direct scenario link
  const y = ph - 48;
  doc.text(credit, 0, y, {
    align: 'center',
    width: pw,
    link: appUrl,
  });
}

function pageFacingOfPageNumber(pageNumberValue) {
  return pageNumberValue % 2 === 1 ? 'right' : 'left';
}

function currentPageFacing(ctx) {
  return pageFacingOfPageNumber(ctx.currentPageNumber);
}

function addPage(ctx, pageKind) {
  ctx.currentPageNumber++;

  const { doc, currentPageNumber } = ctx;

  doc.addPage();

  const pageFacing = pageFacingOfPageNumber(currentPageNumber);
  const src = getBackgroundImageSrc(pageKind, pageFacing);

  const imageDataUrl = getImageDataUrlSync(src);
  doc.image(imageDataUrl, 0, 0, {
    width: doc.page.width,
    height: doc.page.height,
  });

  addPageNumber(
    doc,
    currentPageNumber,
    pageFacingOfPageNumber(currentPageNumber)
  );
  addCredit(doc);
}

const defaultCampaignIntro = 'No campaign intro yet.';

function getBodyStartX(ctx) {
  return currentPageFacing(ctx) === 'left' ? sm : bm;
}

function typesetCampaignIntro(ctx, campaign) {
  const { doc } = ctx;
  addPage(ctx, 'general');

  doc.fillColor(colors.campaignTitle);
  doc.fontSize(fontSizes.campaignTitle);
  doc.font('pirata');
  doc.text(campaign.title, 0, 45, { align: 'center', width: pw });

  const setFont = () => {
    doc.fillColor(colors.bodyCopy);
    doc.fontSize(fontSizes.bodyCopy);
    doc.font('hightower');
  };
  setFont();
  const txt = campaign.campaignSettings.campaignIntro || defaultCampaignIntro;

  const paragraphs = txt.split('\n\n');
  const lineHeight = doc.heightOfString(' ');
  let curY = 100;
  const cutoff = doc.page.height - bottomMargin;

  for (const paragraph of paragraphs) {
    const opts = { align: 'justify', width: pw - (sm + bm) };

    const paraHeight = doc.heightOfString(paragraph);

    // XXX should be able to split mid-paragraph
    if (curY + paraHeight > cutoff) {
      addPage(ctx, 'general');

      curY = 45;
      setFont();
    }

    const startX = getBodyStartX(ctx);
    doc.text(paragraph, startX, curY, opts);
    curY += paraHeight + lineHeight;
  }
}

function addScenarioImage(
  ctx,
  scenario,
  tilesBoxDimensions,
  horizontalManifestDimensions
) {
  /*
       We try to add the image at a comfortable scale, and decrease scale only
       in case the comfortable scale will lead to image not fitting.

       Then at the measured scale, we detect the image extent vertically (so if
       it fits within the "half" page, we'll do it) and horizontally (1, 2 or 3
       columns, so we know where a text cutoff is for these columns).  We then
       place the image into bottom-right corner of the space where it fits, and
       flow the text around it.  (The text is always in whole columns; and we
       don't measure image's blank spaces in order to fit something in there,
       as opposed to the intelligent typesetting of the base game scenarios.)

     */
  const { doc } = ctx;

  const fakeScenarioViewerState = {
    scenario,
    tids: scenario.tids,
    tidToConfig: scenario.tidToConfig,
    mode: 'editor',
  };
  const intermediary = createPictureIntermediary(fakeScenarioViewerState);
  const { size } = intermediary;
  const imageCanvas = createPngCanvasSync(intermediary);
  const imageDataUrl = imageCanvas.toDataURL();

  const widths = {
    one: columnWidth,
    two: columnWidth * 2 + gutter,
    three: columnWidth * 3 + gutter * 2,
  };

  const bottomPartHeight = Math.max(
    tilesBoxDimensions.height,
    horizontalManifestDimensions.height
  );

  // XXX can be different depending on the page: begin, cont, or half.
  const topMargin = topMargins.begin;
  const availableHeight = ph - (bottomMargin + topMargin) - bottomPartHeight;

  const heights = {
    // TODO measure the half-page and extract magic number.
    one: (availableHeight - 100) / 2,
    two: availableHeight,
  };

  const baseScale = 0.1463;

  let scaled;
  let scale;

  function setScale(value) {
    scale = value;
    scaled = {
      width: Math.floor(scale * size.width),
      height: Math.floor(scale * size.height),
    };
  }

  setScale(baseScale);

  if (scaled.height > availableHeight) {
    setScale(availableHeight / size.height);
  }

  if (scaled.width > pageContentWidth) {
    setScale(pageContentWidth / size.width);
  }

  let takenColumns;
  if (scaled.width < widths.one) {
    takenColumns = 1;
  } else if (scaled.width < widths.two) {
    takenColumns = 2;
  } else {
    takenColumns = 3;
  }

  let vExtent;
  if (scaled.height < heights.one) {
    vExtent = 1;
  } else {
    vExtent = 2;
  }

  const x = getBodyStartX(ctx) + widths.three - scaled.width;
  const y = ph - bottomMargin - bottomPartHeight - scaled.height;
  doc.image(imageDataUrl, x, y, {
    width: scaled.width,
    height: scaled.height,
  });

  const imageDimensions = {
    takenColumns,
    vExtent,
    scaled,
    scale,
  };
  return imageDimensions;
}

function getColumnStartX(ctx, curColumn) {
  return getBodyStartX(ctx) + curColumn * (columnWidth + gutter);
}

function getColumnCutoff(
  ctx,
  curColumn,
  tilesBoxDimensions,
  imageDimensions,
  horizontalManifestDimensions,
  continued
) {
  const { doc } = ctx;
  const { scaled, takenColumns } = imageDimensions;

  const baseCutoff = doc.page.height - bottomMargin;
  if (continued) {
    return baseCutoff;
  }

  const bottomPartHeight = Math.max(
    tilesBoxDimensions.height,
    horizontalManifestDimensions.height
  );

  const onColumnWithImage = curColumn > 2 - takenColumns;
  if (onColumnWithImage) {
    return baseCutoff - (bottomPartHeight + scaled.height);
  }

  return baseCutoff - bottomPartHeight;
}

const boxSize = 4;

function typesetScenarioBody(
  ctx,
  scenario,
  imageDimensions,
  tilesBoxDimensions,
  horizontalManifestDimensions
) {
  const { doc } = ctx;
  const { body = defaultScenarioBody } = scenario;

  doc.fillColor(colors.bodyCopy);
  doc.fontSize(fontSizes.bodyCopy);
  doc.font('hightower');

  const txt = body;

  const blocks = txt.split('\n\n');

  const oneLineHeight = doc.heightOfString('test');

  let inBox = false;
  function measureBodyParagraph(text) {
    doc.fillColor(colors.bodyCopy);
    doc.fontSize(fontSizes.bodyCopy);
    doc.font('hightower');

    const height = doc.heightOfString(text, {
      width: columnWidth,
      align: 'justify',
    });
    return {
      type: 'bodyParagraph',
      height,
      nLines: height / oneLineHeight,
      inBox,
      value: text,
    };
  }

  // Return a value that divides oneLineHeight without a remainder, so that the
  // height increases are all in the same oneLineHeight increments, for even
  // bottom edges of columns.
  function matchHeight(height) {
    let result = oneLineHeight;
    while (!(result >= height)) {
      result += oneLineHeight;
    }
    return result;
  }

  function analyzeBlock(block) {
    let match;
    if (!!(match = block.match(/^%(\w+) ?(.*)?/))) {
      const [_, keyword, value] = match; // eslint-disable-line no-unused-vars
      if (['green', 'blue', 'brown', 'red'].includes(keyword)) {
        const color = keyword;
        doc.fillColor(named[color]);
        doc.fontSize(fontSizes.scenarioBodyHeading);
        doc.font('pirata');
        const height = matchHeight(
          doc.heightOfString(value, {
            width: columnWidth,
            align: 'center',
          })
        );
        return {
          type: 'heading',
          color,
          value,
          height,
        };
      } else if (keyword === 'box') {
        inBox = true;
        return {
          type: 'startBox',
          height: oneLineHeight / 2, // not even with other lines, but looks better
        };
      } else if (keyword === 'endbox') {
        inBox = false;
        return {
          type: 'endBox',
          height: 0,
        };
      } else if (keyword === 'rule') {
        const height =
          ruleImageSize.height * (columnWidth / ruleImageSize.width);
        return {
          type: 'rule',
          value,
          height: matchHeight(height),
        };
      } else {
        console.warn(
          'Unrecognized keyword in scenario body',
          keyword,
          scenario
        );
        return measureBodyParagraph(block);
      }
    } else {
      return measureBodyParagraph(block);
    }
  }

  const parts = blocks.map(analyzeBlock);

  let curColumn = 0;

  let continued = false;
  function getStartY() {
    return continued ? topMargins.cont : topMargins.begin;
  }

  let curY = getStartY();

  function setCurY(value) {
    curY = value;
  }

  function increaseY(increase) {
    setCurY(curY + increase);
  }

  function getCurrentColumnCutoff() {
    return getColumnCutoff(
      ctx,
      curColumn,
      tilesBoxDimensions,
      imageDimensions,
      horizontalManifestDimensions,
      continued
    );
  }

  function startNewColumn() {
    curColumn++;
    if (curColumn === 3) {
      addPage(ctx, 'cont');
      curColumn = 0;
      continued = true;
    }
    setCurY(getStartY());
  }

  function checkColumnOverflow(height, onNewColumn = null) {
    const cutoff = getCurrentColumnCutoff();
    if (curY + height > cutoff) {
      startNewColumn();
      if (onNewColumn) {
        onNewColumn();
      }
    }
  }

  function getX() {
    return getColumnStartX(ctx, curColumn);
  }

  function setBodyParagraphStyling() {
    doc.fillColor(colors.bodyCopy);
    doc.fontSize(fontSizes.bodyCopy);
    doc.font('hightower');
  }

  parts.forEach((part, index) => {
    const { type, value, height, inBox } = part;
    if (type === 'bodyParagraph') {
      // TODO inline red-circle images
      const x = getX();

      if (inBox) {
        const nextPart = parts[index + 1];
        const boxHeight =
          nextPart && nextPart.type === 'endBox'
            ? height
            : height + oneLineHeight;

        doc.fillColor(colors.scenarioHighlightedBox);
        const y = curY;
        doc
          .moveTo(x, y)
          .lineTo(x + columnWidth, y)
          .lineTo(x + columnWidth, y + boxHeight)
          .lineTo(x, y + boxHeight)
          .lineTo(x, y)
          .fill();
      }

      setBodyParagraphStyling();
      const opts = {
        align: inBox ? 'center' : 'justify',
        width: columnWidth,
      };

      checkColumnOverflow(oneLineHeight);

      // Using undocumented internal pdfkit API to do columns of different
      // heights.  pdfkit tracks doc.x and doc.y for text layout, so we use
      // them when we need to go to the next column.
      doc._text(value, getX(), curY, opts, (line, options, wrapper) => {
        doc._line(line, options, wrapper);
        increaseY(oneLineHeight);
        checkColumnOverflow(oneLineHeight, () => {
          doc.x = getX();
          doc.y = curY;
          setBodyParagraphStyling();
        });
      });
      // Insert an empty line after a paragraph.
      increaseY(oneLineHeight);
    } else if (type === 'heading') {
      checkColumnOverflow(height);

      const { color } = part;
      doc.fillColor(named[color]);
      doc.fontSize(fontSizes.scenarioBodyHeading);
      doc.font('pirata');

      const x = getX();
      const y = curY;
      doc.text(value, x, y, {
        align: 'center',
        width: columnWidth,
      });
      increaseY(height);
    } else if (type === 'startBox') {
      const size = boxSize;
      doc.fillColor(colors.scenarioHighlightedBox);
      const x = getX();
      const y = curY + (height - boxSize);
      doc
        .moveTo(x + size, y)
        .lineTo(x + columnWidth - size, y)
        .lineTo(x + columnWidth, y + size)
        .lineTo(x, y + size)
        .lineTo(x + size, y)
        .fill();
      increaseY(height);
    } else if (type === 'rule') {
      checkColumnOverflow(height);

      const x = getX();
      const y = curY;
      const imageDataUrl = getImageDataUrlSync('/gh/bg/rule-with-slot.png');

      doc.image(imageDataUrl, x, y, {
        width: columnWidth,
      });
      doc.fillColor(colors.scenarioRuleLabel);
      doc.fontSize(fontSizes.scenarioRuleLabel);
      doc.font('pirata');

      doc.text(value, x, y + height / 2 - 6, {
        width: columnWidth,
        align: 'center',
        baseline: 'middle',
      });
      increaseY(height);
    }
  });
}

function isTriangle(thingType) {
  return thingType === 'obstacle-triangle';
}

function isLarge(thingType) {
  const { offsetPolicy } = thingTypeDescriptors[thingType];
  return offsetPolicy === 'triangle' || offsetPolicy === 'long';
}

const manifestOneLine = 14;

function measureManifest(manifestData) {
  let totalWidth = 0;
  const { thingCounters } = manifestData;

  let hasTriangle = false;

  const widths = [];
  const heights = [];

  for (const thingCounter of thingCounters) {
    const { thingType, widthMultiplier, heightMultiplier } = thingCounter;
    const width = baseSize * widthMultiplier;

    let xOffset = 0;
    const height = baseSize * heightMultiplier;
    if (isTriangle(thingType)) {
      xOffset = -(baseSize / 4);
    }
    const addedWidth = width + xOffset * 2;
    heights.push(height + manifestOneLine * 1.8);
    widths.push(addedWidth);
    totalWidth += addedWidth;
  }

  const height = Math.max(...heights);

  return {
    widths,
    heights,
    height,
    totalWidth,
    hasTriangle,
  };
}

function getThingCounterText(thingCounter) {
  const { thingType, imageName, count } = thingCounter;
  let baseText;
  if (thingType === 'monster') {
    baseText = englishMonsterNames[imageName];
  } else if (thingType === 'trap') {
    // TODO trap name from effects
    baseText = tokenByName[imageName].displayName;
  } else {
    baseText = tokenByName[imageName].displayName;
  }

  const countText = thingType === 'monster' ? '' : ` (x${count})`;

  const text = `${baseText}${countText}`;
  return text;
}

function setStyle(ctx, styleName) {
  const { doc } = ctx;
  let style = styles[styleName];
  if (!style) {
    console.warn(`Unrecognized style: '${styleName}', will set default`);
    style = defineStyle(16, named.red, 'hightower');
  }
  doc.fillColor(style.color);
  doc.fontSize(style.fontSize);
  doc.font(style.fontName);
}

function addHorizontalManifest(ctx, thingCounters, x, y) {
  const { doc } = ctx;
  let i = 0;
  let flip = false;

  setStyle(ctx, 'scenarioManifest');

  let curX = x;
  for (const thingCounter of thingCounters) {
    const {
      thingType,
      widthMultiplier,
      heightMultiplier,
      src,
      alternateSrc,
    } = thingCounter;

    let usedSrc = src;

    if (
      i > 0 &&
      thingType === 'obstacle-triangle' &&
      thingCounters[i - 1].thingType === 'obstacle-triangle'
    ) {
      flip = !flip;
      usedSrc = flip ? alternateSrc : src;
    }

    const imageDataUrl = getImageDataUrlSync(usedSrc);

    const width = baseSize * widthMultiplier;
    const height = baseSize * heightMultiplier;

    // XXX duplication with the horizontal measurement
    let xOffset = 0;
    if (isTriangle(thingType)) {
      xOffset = -(baseSize / 4);
    }

    doc.image(imageDataUrl, curX + xOffset, y, {
      width,
    });

    const text = getThingCounterText(thingCounter);
    doc.text(text, curX + xOffset, y + height, {
      width,
      align: 'center',
      lineGap: -2,
    });
    curX += width + xOffset * 2;
    i++;
  }
}

// Adds the images with their texts bottom to top.
function addVerticalManifestBtt(ctx, manifestData, x, y, options = {}) {
  const { doc } = ctx;

  setStyle(ctx, 'scenarioManifest');
  let i = 0;

  const { thingCounters } = manifestData;
  const reversed = [...thingCounters].reverse();

  for (const thingCounter of reversed) {
    const { thingType, widthMultiplier, heightMultiplier, src } = thingCounter;

    const usedSrc = src;
    const isEven = i % 2 === 0;

    let xOffset = options.isSquished ? (isEven ? 0 : baseSize / 2) : 0;
    if (isTriangle(thingType)) {
      xOffset = 0;
    }

    const imageDataUrl = getImageDataUrlSync(usedSrc);

    const width = baseSize * widthMultiplier;
    const height = baseSize * heightMultiplier;

    const text = getThingCounterText(thingCounter);

    let imageHeightContribution = height;
    if (options.isSquished && !isTriangle(thingType)) {
      imageHeightContribution = height * 0.75;
    }
    if (isLarge(thingType)) {
      y -= imageHeightContribution + manifestOneLine;

      doc.image(imageDataUrl, x + xOffset, y, {
        width,
      });

      doc.text(text, x + xOffset, y + height, {
        width,
        align: 'center',
      });
    } else {
      y -= imageHeightContribution;
      const opts = {
        width: baseSize,
        height,
        align: 'center',
      };
      const textHeight = doc.heightOfString(text, opts);

      doc.image(imageDataUrl, x + xOffset, y, {
        width,
      });

      doc.text(
        text,
        x + xOffset + width * 0.9,
        y + (height - textHeight) / 2,
        opts
      );
    }

    i++;
    if (isTriangle(thingType)) {
      i++;
    }
  }
}

function tryAddHorizontalManifest(ctx, manifestData, tilesBoxDimensions) {
  const { doc } = ctx;

  doc.fillColor(colors.bodyCopy);
  doc.fontSize(fontSizes.bodyCopy);
  doc.font('hightower');

  const dimensions = measureManifest(manifestData);
  const { totalWidth, height } = dimensions;

  const thingCounters = [...manifestData.thingCounters];

  const availableWidth = pageContentWidth - tilesBoxDimensions.width;

  const startX = getBodyStartX(ctx) + tilesBoxDimensions.width;

  let totalHeight = 0;
  if (totalWidth <= availableWidth) {
    const x = startX + (availableWidth - totalWidth) / 2;
    const y = ph - bottomMargin - height;
    addHorizontalManifest(ctx, thingCounters, x, y);
    totalHeight = height;
  } else {
    const { widths, heights } = dimensions;
    let curY = ph - bottomMargin;

    const nRows = Math.floor(totalWidth / availableWidth) + 1;
    const nInRow = Math.ceil(widths.length / nRows);

    let chunkWidth = 0;
    let chunkHeight = 0;
    const chunk = [];

    function renderChunk() {
      const x = startX + (availableWidth - chunkWidth) / 2;
      curY -= chunkHeight;
      totalHeight += chunkHeight;
      addHorizontalManifest(ctx, chunk, x, curY);
      chunk.splice(0, chunk.length);
      chunkWidth = 0;
      chunkHeight = 0;
    }

    let i = 0;
    while (thingCounters.length > 0) {
      const oneWidth = widths.pop();
      const oneHeight = heights.pop();
      const oneCounter = thingCounters.pop();
      if (i === nInRow) {
        renderChunk();
        i = 0;
      }

      chunk.unshift(oneCounter);
      chunkHeight = Math.max(chunkHeight, oneHeight);
      chunkWidth += oneWidth;

      i++;
    }
    renderChunk();
  }

  return {
    added: true,
    totalWidth,
    height: totalHeight,
  };
}

function tryAddVerticalManifest(
  ctx,
  tilesBoxDimensions,
  horizontalManifestDimensions,
  manifestData,
  imageDimensions
) {
  if (horizontalManifestDimensions.added) {
    return {
      added: false,
    };
  }

  // TODO typeset squished in a single column if fits; otherwise typeset
  // non-squished in several columns (non-squished fits two manifest columns
  // in one text column).

  const column = 2;
  const startY = getColumnCutoff(
    ctx,
    column,
    tilesBoxDimensions,
    imageDimensions,
    horizontalManifestDimensions,
    false
  );
  const startX = getColumnStartX(ctx, column);

  addVerticalManifestBtt(
    ctx,
    manifestData,
    startX + columnWidth - baseSize * 1.8,
    startY,
    { isSquished: false }
  );

  return {
    added: true,
  };
}

function drawBox(ctx, x, y, width, height) {
  const { doc } = ctx;
  const size = boxSize;
  doc.fillColor(colors.scenarioHighlightedBox);
  doc
    .moveTo(x + size, y)
    .lineTo(x + width - size, y)
    .lineTo(x + width, y + size)
    .lineTo(x + width, y + size + height)
    .lineTo(x, y + size + height)
    .lineTo(x, y + size)
    .lineTo(x + size, y)
    .fill();
}

function addTilesBox(ctx, manifestData) {
  const { doc } = ctx;
  const { tiles } = manifestData;

  const headerText = 'Maps:';
  const imageNames = tiles.map((thingCounter) => thingCounter.imageName);

  setStyle(ctx, 'scenarioTilesHeader');
  const headerWidth = doc.widthOfString(headerText);
  const headerHeight = doc.heightOfString(headerText);

  setStyle(ctx, 'scenarioTilesBody');
  const bodyWidth = Math.max(
    ...imageNames.map((txt) => doc.widthOfString(txt))
  );
  const lineHeight = doc.heightOfString(imageNames[0]);
  const bodyHeight = (imageNames.length + 1) * lineHeight;

  const width = Math.max(headerWidth, bodyWidth);
  const height = headerHeight + bodyHeight;

  const margin = 8;
  const x = getBodyStartX(ctx) + margin;
  const y = doc.page.height - bottomMargin - height - margin;

  setStyle(ctx, 'scenarioTilesHeader');
  const headerX = x;
  const headerY = y;
  doc.text(headerText, headerX, headerY, {
    align: 'center',
    width,
  });

  drawBox(
    ctx,
    x,
    y + headerHeight + lineHeight * 0.4,
    width,
    bodyHeight - lineHeight * 0.2
  );

  setStyle(ctx, 'scenarioTilesBody');
  const bodyX = x;
  let bodyY = y + headerHeight + lineHeight;
  imageNames.forEach((txt) => {
    doc.text(txt, bodyX, bodyY, {
      align: 'center',
      width,
    });
    bodyY += lineHeight;
  });

  return {
    width: width + margin,
    height: height + margin,
  };
}

function typesetScenario(ctx, scenario) {
  addPage(ctx, 'begin');

  const { doc } = ctx;

  setStyle(ctx, 'scenarioTitle');

  const { shortReference, title, location } = scenario;
  const y = 28;
  const startX = getBodyStartX(ctx);
  doc.text(title, startX + 90, y);

  const x = startX,
    w = 64;
  doc.text(shortReference, x, y, { align: 'center', width: w });

  setStyle(ctx, 'scenarioLocation');
  doc.text(location, startX + 38, 38, { align: 'center', width: w });

  function drawMeta(keyText, y, valueText) {
    doc.fillColor(colors.scenarioMetaKey);
    doc.fontSize(fontSizes.scenarioMetaKey);
    doc.font('pirata');

    const x = getBodyStartX(ctx) - 6;
    doc.text(keyText, x, y, { baseline: 'alphabetic' });

    const w = doc.widthOfString(keyText);

    doc.fillColor(colors.scenarioMetaValue);
    doc.fontSize(fontSizes.scenarioMetaValue);
    doc.font('hightower');
    doc.text(valueText, x + w, y, { baseline: 'alphabetic' });
  }
  drawMeta('Requirements: ', 86, scenario.requirements);
  drawMeta('Goal: ', 106, scenario.goal);

  if (scenario.author) {
    setStyle(ctx, 'scenarioAuthor');
    const authorText = `Author: ${scenario.author}`;
    const w = doc.widthOfString(authorText);
    const x = getBodyStartX(ctx) + pageContentWidth - w;
    doc.text(authorText, x, 106, { baseline: 'alphabetic' });
  }

  /*

       Current layout of a scenario:

       The first page always contains

       - tiles box ("Maps:" and tiles names) in the bottom-left corner

       - horizontal manifest (significant images with names and counts) in the
         several rows at bottom, centering in the space not taken by the tiles
         box

       - image, bottom-aligned with the top of the bottom part (tiles box /
         horizontal manifest, whichever is higher)

       - description, in columns which are cut off either at the top of the
         bottom part (if the column is not on the image), or at the top of the
         image.

       The rest of the description goes to three full columns on the next
       pages.

       Possible improvements:

       - Layout the manifest vertically if it fits

       - Detect empty spaces within the map image to fit more text

       - Scatter manifest images and the tiles box to whatever empty spaces
         there are

    */

  const manifestData = getScenarioManifestData(scenario);
  const tilesBoxDimensions = addTilesBox(ctx, manifestData);
  const horizontalManifestDimensions = tryAddHorizontalManifest(
    ctx,
    manifestData,
    tilesBoxDimensions
  );
  const imageDimensions = addScenarioImage(
    ctx,
    scenario,
    tilesBoxDimensions,
    horizontalManifestDimensions
  );
  const verticalManifestDimensions = tryAddVerticalManifest(
    ctx,
    tilesBoxDimensions,
    horizontalManifestDimensions,
    manifestData,
    imageDimensions
  );
  typesetScenarioBody(
    ctx,
    scenario,
    imageDimensions,
    tilesBoxDimensions,
    horizontalManifestDimensions,
    verticalManifestDimensions
  );
}

function typesetCampaign(ctx, campaign) {
  typesetCampaignIntro(ctx, campaign);

  const { scenarios } = campaign;
  for (const scenario of scenarios) {
    typesetScenario(ctx, scenario);
  }
}

function prepareScenarioImageSrcs(array, scenario) {
  const manifestData = getScenarioManifestData(scenario);
  for (const thingCounter of manifestData.thingCounters) {
    const { src, alternateSrc } = thingCounter;
    array.push(src);
    if (alternateSrc) {
      array.push(alternateSrc);
    }
  }
}

function prepareCampaignImageSrcs(array, campaign) {
  for (const scenario of campaign.scenarios) {
    prepareScenarioImageSrcs(array, scenario);
  }
}

// Returns a promise resolving to a data url suitable for embedding.
function generatePdf(tag, arg) {
  return new Promise(function(resolve, reject) {
    const doc = new PDFDocument({
      font: '',
      size: 'A4',
      margin: 0,
      autoFirstPage: false,
    });
    const stream = doc.pipe(blobStream());

    try {
      doc.registerFont('hightower', 'fonts/HighTower.ttf');
      doc.registerFont('pirata', 'fonts/PirataOne-Regular.ttf');

      const ctx = {
        doc,
        currentPageNumber: 0,
      };

      const srcs = ['/gh/bg/rule-with-slot.png'];
      for (const pageKind of ['begin', 'cont', 'general']) {
        for (const pageFacing of ['left', 'right']) {
          srcs.push(getBackgroundImageSrc(pageKind, pageFacing));
        }
      }
      const prepare =
        tag === 'campaign'
          ? prepareCampaignImageSrcs
          : prepareScenarioImageSrcs;
      prepare(srcs, arg);

      loadAllImages(srcs).then(() => {
        const typeset = tag === 'campaign' ? typesetCampaign : typesetScenario;
        typeset(ctx, arg);
        doc.end();
      });
    } catch (e) {
      reject(e);
    }

    stream.on('finish', function() {
      const blob = stream.toBlob('application/pdf');
      const blobUrl = stream.toBlobURL('application/pdf');
      resolve({ blob, blobUrl });
    });
  });
}

export function generateCampaignPdf(campaign) {
  return generatePdf('campaign', campaign);
}

export function generateSingleScenarioPdf(scenario) {
  return generatePdf('scenario', scenario);
}
