import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  setThingSetting,
  performThingAction,
  setGlobalSetting,
  setScenarioSetting,
  setPlaythroughSetting,
  applyThingChanger,
} from './actions';
import { Button, numberValueOfEvent } from '../common';
import {
  thingTypeDescriptors,
  getSettingsForThingType,
} from '../data/thingTypes';
import { defaultScenarioBody, defaultScenarioTitle } from '../data/scenario';
import { getThingDisplayName } from '../utils';
import { changerDefinitions } from '../data/changers';
import { selectTidToConfig, selectSelectedThingChangers } from './selectors';

function WrapControl(props) {
  return (
    <div className="field-body">
      <div className="field">
        <div className="control is-expanded">{props.children}</div>
      </div>
    </div>
  );
}

function renderTypeSpecificInput(setting, value, setValue) {
  const { type } = setting;
  if (type === 'number') {
    return (
      <WrapControl>
        <input
          type="number"
          className="input"
          data-setting={setting.attribute}
          min={setting.min}
          max={setting.max}
          step={setting.step}
          value={value}
          onChange={(e) => setValue(setting.attribute, numberValueOfEvent(e))}
        />
      </WrapControl>
    );
  } else if (type === 'pattern') {
    return (
      <WrapControl>
        <input
          type="text"
          minLength="3"
          maxLength="3"
          pattern="[wby]{3}"
          className="input pattern"
          placeholder="bwy"
          value={value}
          onChange={(e) => setValue(setting.attribute, e.target.value)}
        />
      </WrapControl>
    );
  } else if (type === 'choice') {
    return (
      <WrapControl>
        <div className="select">
          <select
            value={value}
            onChange={(e) => setValue(setting.attribute, e.target.value)}
          >
            {setting.options.map(({ value, label = value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
      </WrapControl>
    );
  } else if (type === 'bool') {
    return (
      <input
        type="checkbox"
        checked={value}
        onChange={(e) => setValue(setting.attribute, e.target.checked)}
      />
    );
  } else if (type === 'string') {
    return (
      <WrapControl>
        <input
          type="text"
          maxLength={setting.maxLength || 40}
          value={value}
          className="input"
          placeholder={setting.placeholder}
          onChange={(e) => setValue(setting.attribute, e.target.value)}
        />
      </WrapControl>
    );
  } else if (type === 'text') {
    // TODO represent it maybe with a button to open a modal
    return null;
  } else if (type === 'button') {
    return (
      <WrapControl>
        <Button onClick={() => setValue('*action*', setting.action)}>
          {setting.label}
        </Button>
      </WrapControl>
    );
  } else {
    console.error('Unrecognized setting type', type, setting);
    return <div />;
  }
}

function renderReadOnly(setting, value) {
  const { type } = setting;
  if (type === 'bool') {
    return (
      <span>
        <label>{setting.label}</label>
        {': '}
        {value ? 'Yes' : 'No'}
      </span>
    );
  } else {
    return (
      <span>
        <label>{setting.label}</label>
        {': '}
        {value}
      </span>
    );
  }
}

function ChangerTd(props) {
  const { changer } = props;

  const arrow = '➔';
  const definition = changerDefinitions[changer];

  const { symbol = arrow, rotate, localizedDescription, shortcut } = definition;

  const title = `${localizedDescription} (${shortcut})`;
  let style;
  if (rotate) {
    style = { transform: `rotate(${rotate}deg)` };
  }
  return (
    <td className="active" onClick={() => props.onClick(changer)} title={title}>
      <div className="int" style={style}>
        {symbol}
      </div>
    </td>
  );
}

function SelectedThingChangers(props) {
  const { changers, onChangerClicked } = props;
  if (!changers || changers.size === 0) {
    return null;
  }

  function changerTd(...potentialChangers) {
    for (let changer of potentialChangers) {
      if (changers.has(changer)) {
        return <ChangerTd changer={changer} onClick={onChangerClicked} />;
      }
    }
    return <td />;
  }

  function changerButton(changer, props) {
    if (!changers.has(changer)) {
      return null;
    }
    const definition = changerDefinitions[changer];
    const { localizedDescription, shortcut } = definition;
    const title = `(Shortcut: ${shortcut})`;
    return (
      <Button
        {...props}
        title={title}
        onClick={() => onChangerClicked(changer)}
      >
        {localizedDescription}
      </Button>
    );
  }

  const hasClone = changers.has('clone');
  const hasRemove = changers.has('remove');
  const hasButtons = hasRemove || hasClone;

  return (
    <>
      <table className="SelectedThingChangers">
        <tbody>
          <tr>
            {changerTd('flat-move-nw', 'pointy-move-nw')}
            {changerTd('flat-move-n', 'free-move-t')}
            {changerTd('flat-move-ne', 'pointy-move-ne')}
            {changerTd('rotate-r')}
            {changerTd('rotate-l')}
          </tr>
          <tr>
            {changerTd('pointy-move-w', 'free-move-l')}
            {changerTd('flat-move-s', 'free-move-b')}
            {changerTd('pointy-move-e', 'free-move-r')}
            {changerTd('flip')}
          </tr>
          <tr>
            {changerTd('flat-move-sw', 'pointy-move-sw')}
            <td />
            {changerTd('flat-move-se', 'pointy-move-se')}
          </tr>
        </tbody>
      </table>
      {hasButtons && (
        <div className="buttons">
          {changerButton('clone', { isSuccess: true })}
          {changerButton('remove', { isDanger: true })}
        </div>
      )}
    </>
  );
}

function getDefaultValueForSetting(setting) {
  if (setting.defaultValue !== undefined) {
    return setting.defaultValue;
  }
  switch (setting.type) {
    case 'number':
      return 0;
    case 'pattern':
      return '';
    case 'choice':
      return setting.options[0].value;
    case 'bool':
      return false;
    case 'string':
      return '';
    case 'button':
      return null;
    case 'text':
      return '';
    default: {
      console.error('Unrecognized setting type:', setting.type, setting);
      return null;
    }
  }
}

function renderLabelWithColon(setting) {
  const { label } = setting;
  if (!label) {
    return null;
  }
  const text = label.replace(' ', '\u00A0'); // don't split labels
  return (
    <div className="field-label is-normal">
      <label className="label">
        {text}
        {': '}
      </label>
    </div>
  );
}

function renderSettingLine(setting, config, setValue, textAttrs, textCallback) {
  let value = config[setting.attribute];
  if (value === undefined) {
    value = getDefaultValueForSetting(setting);
  }

  if (setting.readOnly) {
    return renderReadOnly(setting, value);
  }

  switch (setting.type) {
    case 'bool':
      return (
        <label className="checkbox">
          {renderTypeSpecificInput(setting, value, setValue)}
          {setting.label}
        </label>
      );
    case 'button':
      return renderTypeSpecificInput(setting, value, setValue);
    case 'text': {
      if (textAttrs === 'skip') {
        return null;
      } else if (textAttrs === 'full') {
        // TODO auto-focus textarea (assumed it's only once on page)
        return (
          <>
            <label className="label">{setting.label}</label>
            <textarea
              className="textarea"
              value={value}
              onChange={(e) => setValue(setting.attribute, e.target.value)}
            />
          </>
        );
      } else if (textAttrs === 'popup') {
        return (
          <>
            {renderLabelWithColon(setting)}
            <Button isInfo onClick={() => textCallback()}>
              Edit
            </Button>
          </>
        );
      } else {
        console.warn(
          'Unrecognized textAttrs when rendering text setting',
          setting,
          textAttrs
        );
        return null;
      }
    }
    default:
      return (
        <>
          {renderLabelWithColon(setting)}
          {renderTypeSpecificInput(setting, value, setValue)}
        </>
      );
  }
}

export function EditConfigU(props) {
  const {
    tid,
    settings,
    config,
    setValue,
    onlyTextAttrs,
    textAttrs,
    textCallback,
  } = props;

  if (!config) {
    return null;
  }

  return (
    <>
      {tid && (
        <h3 className="settingHeader">{getThingDisplayName(tid, config)}</h3>
      )}
      <SelectedThingChangers
        changers={props.changers}
        onChangerClicked={props.onChangerClicked}
      />
      {settings.map((setting) => {
        if (onlyTextAttrs && setting.type !== 'text') {
          return null;
        }
        const className = classNames('Setting field', {
          'is-horizontal': !(setting.type === 'text' && textAttrs === 'full'),
        });
        return (
          <div key={setting.attribute} className={className}>
            {renderSettingLine(
              setting,
              config,
              setValue,
              textAttrs,
              textCallback
            )}
          </div>
        );
      })}
      {props.renderLinkedEditor && props.renderLinkedEditor()}
    </>
  );
}

export const scenarioSettings = [
  {
    attribute: 'shortReference',
    label: 'Short reference',
    type: 'string',
    defaultValue: 'CC1',
    placeholder: 'CC1',
    maxLength: 5,
  },
  {
    attribute: 'title',
    label: 'Title',
    type: 'string',
    placeholder: defaultScenarioTitle,
    isRequired: true,
  },
  {
    attribute: 'author',
    label: 'Author',
    type: 'string',
    placeholder: 'Johnny Cigar',
  },
  {
    attribute: 'location',
    label: 'Location',
    type: 'string',
    placeholder: 'G10',
    maxLength: 5,
  },
  {
    attribute: 'requirements',
    label: 'Requirements',
    type: 'string',
    placeholder: 'None',
    defaultValue: 'None',
    maxLength: 120,
  },
  {
    attribute: 'goal',
    label: 'Goal',
    type: 'string',
    placeholder: 'Kill all enemies',
    defaultValue: 'Kill all enemies',
    maxLength: 120,
  },
  {
    attribute: 'body',
    label: 'Body',
    type: 'text',
    defaultValue: defaultScenarioBody,
  },
  {
    attribute: 'gridHexTops',
    label: 'Hex tops',
    type: 'choice',
    defaultValue: 'pointy',
    options: [
      { value: 'pointy', label: 'Pointy' },
      { value: 'flat', label: 'Flat' },
    ],
  },
];

const globalSettings = [
  {
    attribute: 'scale',
    label: 'Scale',
    min: 0.05,
    max: 1,
    step: 0.05,
    type: 'number',
  },
  {
    attribute: 'hexLabels',
    label: 'Hex labels',
    type: 'choice',
    options: [
      { value: 'numbers', label: 'Numbers' },
      { value: 'words', label: 'Words' },
      { value: 'roomNames', label: 'Room names' },
    ],
  },
  {
    attribute: 'gridProminence',
    label: 'Grid prominence',
    type: 'choice',
    options: [
      { value: 'none', label: 'None' },
      { value: 'low', label: 'Low' },
      { value: 'high', label: 'High' },
    ],
  },
  { attribute: 'highlightExtent', label: 'Highlight extent', type: 'bool' },
];

const playthroughSettings = [
  { attribute: 'hideEntrance', label: 'Hide entrance', type: 'bool' },
  { attribute: 'level', label: 'Level', type: 'number', min: 0, max: 7 },
  {
    attribute: '_reset',
    label: 'Reset',
    type: 'button',
    action: 'RESET_PLAYTHROUGH',
  },
];

function getSettingsForThing(tid, mode, config) {
  const { thingType } = config;

  let result = getSettingsForThingType(thingType, mode);

  const thingTypeDescriptor = thingTypeDescriptors[thingType];
  if (thingTypeDescriptor.isRevealable && config.isRevealed) {
    result = result.filter((setting) => setting.attribute !== '_reveal');
  }

  return result;
}

export const EditThingConfig = (function() {
  function mapStateToProps(state, ownProps) {
    const { tid, mode } = ownProps;

    const tidToConfig = selectTidToConfig(state);
    const changers = selectSelectedThingChangers(state);

    const config = tidToConfig[tid];
    const settings = getSettingsForThing(tid, mode, config);

    let renderLinkedEditor;
    if (config.roomLink) {
      renderLinkedEditor = () => (
        <EditThingConfig tid={config.roomLink} mode={mode} />
      );
    }

    return {
      tid,
      settings,
      config,
      changers,
      renderLinkedEditor,
    };
  }

  function mapDispatchToProps(dispatch, ownProps) {
    const tid = ownProps.tid;
    return {
      setValue: function(attribute, value) {
        if (attribute === '*action*') {
          dispatch(performThingAction(tid, value));
        } else {
          dispatch(setThingSetting(tid, attribute, value));
        }
      },
      onChangerClicked: function(changer) {
        dispatch(applyThingChanger(tid, changer));
      },
    };
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditConfigU);
})();

export const EditScenarioConfig = (function() {
  function mapStateToProps(state, ownProps) {
    const { tid, onlyTextAttrs, textAttrs = 'skip', textCallback } = ownProps;

    const config = state.scenarioViewer.scenario;

    return {
      tid,
      config,
      settings: scenarioSettings,
      onlyTextAttrs,
      textAttrs,
      textCallback,
    };
  }

  function mapDispatchToProps(dispatch, ownProps) {
    const { onAfterChange } = ownProps;
    return {
      setValue: function(attribute, value) {
        dispatch(setScenarioSetting(attribute, value));
        if (onAfterChange) {
          onAfterChange();
        }
      },
    };
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditConfigU);
})();

export const EditGlobalConfig = (function() {
  function mapStateToProps(state, ownProps) {
    const tid = ownProps.tid;

    const config = state.scenarioViewer.global;

    return {
      tid,
      config,
      settings: globalSettings,
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      setValue: function(attribute, value) {
        dispatch(setGlobalSetting(attribute, value));
      },
    };
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditConfigU);
})();

export const EditPlaythroughConfig = (function() {
  function mapStateToProps(state, ownProps) {
    const tid = ownProps.tid;

    const config = state.scenarioViewer.playthrough;

    return {
      tid,
      config,
      settings: playthroughSettings,
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      setValue: function(attribute, value) {
        if (attribute === '*action*') {
          dispatch({
            type: value,
          });
        } else {
          dispatch(setPlaythroughSetting(attribute, value));
        }
      },
    };
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditConfigU);
})();
