import React, { useState } from 'react';
import {
  OnOffLoader,
  Button,
  getCurrentTimestamp,
  generateId,
} from '../common';
import { sbVersion } from '../data/version';
import { scenarioSettings } from '../ScenarioViewer/EditConfig';

function validateScenarioJson(text, setValidation) {
  try {
    const obj = JSON.parse(text);
    if (!obj.sbVersion) {
      setValidation({ isValid: false, oneSimpleError: 'not-sb-file' });
      return;
    }

    if (!(obj.sbVersion <= sbVersion)) {
      setValidation({ isValid: false, oneSimpleError: 'future-version' });
      return;
    }

    const scenario = {
      id: generateId(),
      createdAt: getCurrentTimestamp(),
    };

    const errors = [];
    if (!obj.tidToConfig || typeof obj.tidToConfig !== 'object') {
      errors.push(['invalid-field', 'tidToConfig']);
    } else {
      // TODO check them further.
      scenario.tidToConfig = obj.tidToConfig;
    }

    if (!obj.tids || !Array.isArray(obj.tids)) {
      errors.push(['invalid-field', 'tids']);
    } else {
      scenario.tids = obj.tids;
    }

    for (const setting of scenarioSettings) {
      const { type, attribute, isRequired } = setting;

      const value = obj[attribute];

      if (value === null || value === undefined) {
        if (isRequired) {
          errors.push(['missing-required-field', attribute]);
        } else {
          scenario[attribute] = value;
        }
        continue;
      }

      if (type === 'string' || type === 'text') {
        const { maxLength } = setting;
        if (maxLength && !(value.length <= maxLength)) {
          errors.push(['too-long', attribute, value.length, maxLength]);
        }
      } else if (type === 'choice') {
        const values = setting.options.map((o) => o.value);

        if (!values.find((v) => v === value)) {
          errors.push(['not-in-choice', attribute]);
        }
      } else {
        console.warn('Invalid type in validation:', type, attribute);
        errors.push(['internal-error']);
      }
      scenario[attribute] = value;
    }
    if (errors.length > 0) {
      setValidation({ isValid: false, errors });
    } else {
      setValidation({ isValid: true, scenario });
    }
  } catch (e) {
    setValidation({ isValid: false, oneSimpleError: 'invalid-json' });
  }
}

function translateSimpleErrorForHumans(errorTag) {
  switch (errorTag) {
    case 'invalid-json':
      return 'The file provided is not a valid JSON file.';
    case 'not-sb-file':
      return 'The file provided appears to not have been exported from Silent Bridge.';
    case 'future-version':
      return 'The file was saved with a future version from Silent Bridge.';
    default: {
      console.warn('Unrecognized errorTag: ', errorTag);
      return 'Internal error';
    }
  }
}

function translateErrorForHumans(error) {
  const [errorTag, ...args] = error;
  switch (errorTag) {
    case 'invalid-field': {
      return `Invalid field value: '${args[0]}'`;
    }
    case 'missing-required-field': {
      return `Missing required field '${args[0]}'`;
    }
    case 'too-long': {
      return `Field '${args[0]}' is too long: it has '${
        args[1]
      }' character while maximum is '${args[2]}'`;
    }
    case 'not-in-choice': {
      return `Field '${args[0]}' is not in the allowed choices.`;
    }
    default: {
      console.warn('Unrecognized errorTag: ', errorTag);
      return 'Internal error';
    }
  }
}

function Validation(props) {
  const { validation, onUploadScenario } = props;
  const { isValid, oneSimpleError, errors, scenario } = validation;
  if (isValid) {
    return (
      <div className="section">
        <div className="notification is-success">
          Importing scenario: "{scenario.title}".
        </div>
        <Button isSuccess onClick={() => onUploadScenario(scenario)}>
          Save and edit
        </Button>
      </div>
    );
  }
  return (
    <div className="section">
      <div className="message is-danger">
        <div className="message-header">There were some errors:</div>
        <div class-name="message-body">
          <ul>
            {oneSimpleError && (
              <li>{translateSimpleErrorForHumans(oneSimpleError)}</li>
            )}
            {errors &&
              errors.map((error) => {
                return <li key={error}>{translateErrorForHumans(error)}</li>;
              })}
          </ul>
        </div>
      </div>
    </div>
  );
}

function ImportModalContent(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [validation, setValidation] = useState(null);

  const handleUploadFile = (e) => {
    const file = e.target.files[0];
    var reader = new FileReader();
    reader.onload = function(event) {
      const text = event.target.result;
      setIsLoading(false);
      validateScenarioJson(text, setValidation);
    };
    reader.readAsText(file);
    setIsLoading(true);
  };

  return (
    <section className="section">
      <div>
        <label>
          Choose a JSON file to import:
          {` `}
          <input
            type="file"
            name="jsonFile"
            accept="application/json"
            onChange={handleUploadFile}
          />
        </label>
      </div>
      <div>
        <OnOffLoader isActive={isLoading} />
      </div>
      {validation && (
        <Validation
          validation={validation}
          onUploadScenario={props.onUploadScenario}
        />
      )}
    </section>
  );
}

export default ImportModalContent;
