import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ScenarioViewer } from './ScenarioViewer/view';
import { Home } from './Home/view';
import { NotFound, createNewScenario, createNewParty } from './common';
import { PartyEditor } from './PartyEditor/view';
import { preparePlaythrough } from './ScenarioViewer/preparePlaythrough';
import {
  initScenarioViewer,
  closeScenarioViewer,
} from './ScenarioViewer/actions';
import { Secret, SecretContext, SecretConstants } from './secret/secret';
import ScrollToTop from './ScrollToTop';

function loadScenario(id) {
  let entry;
  if ((entry = localStorage.getItem(`scenario:${id}`))) {
    return JSON.parse(entry);
  } else {
    console.warn(`Cannot find saved scenario by id ${id}, creating a new one`);
    return createNewScenario(id);
  }
}

function loadParty(id) {
  let entry;
  if ((entry = localStorage.getItem(`party:${id}`))) {
    return JSON.parse(entry);
  } else {
    console.warn(`Cannot find saved party by id ${id}, creating a new one`);
    return createNewParty(id);
  }
}

function loadPlaythrough(id) {
  let entry;
  if ((entry = localStorage.getItem(`playthrough:${id}`))) {
    return JSON.parse(entry);
  } else {
    return null;
  }
}

const ScenarioViewerLoader = (function() {
  class ScenarioViewerLoader extends React.PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        initialized: false,
      };
    }

    componentWillMount() {
      const { mode } = this.props;
      const global = JSON.parse(localStorage.getItem('global'));

      if (mode === 'editor') {
        const { scenario } = this.props;
        const { tids, tidToConfig } = scenario;
        this.props.init({
          mode,
          scenario,
          tids,
          tidToConfig,
          global,
        });
      } else if (mode === 'gameplay') {
        const { playthrough } = this.props;
        const scenario = playthrough.scenario;
        let initValue;

        if (playthrough.tids) {
          const { tids, tidToConfig } = playthrough;

          initValue = {
            mode,
            scenario,
            playthrough,
            tids,
            tidToConfig,
            global,
          };
        } else {
          const startedPlaythrough = preparePlaythrough(playthrough);
          const { tids, tidToConfig } = startedPlaythrough;
          initValue = {
            mode,
            scenario,
            playthrough: startedPlaythrough,
            tids,
            tidToConfig,
            global,
          };
        }
        this.props.init(initValue);
      } else {
        console.warn('Unknown ScenarioViewer mode:', mode);
      }
      this.setState({ initialized: true });
    }

    componentWillUnmount() {
      this.props.close();
    }

    render() {
      return <ScenarioViewer mode={this.props.mode} />;
    }
  }

  function mapDispatchToProps(dispatch) {
    return {
      init: (payload) => dispatch(initScenarioViewer(payload)),
      close: () => dispatch(closeScenarioViewer()),
    };
  }

  return connect(
    null,
    mapDispatchToProps
  )(ScenarioViewerLoader);
})();

function SavedLoader(props) {
  const scenario = loadScenario(props.match.params.id);
  return <ScenarioViewerLoader mode="editor" scenario={scenario} />;
}

function PlayLoader(props) {
  const playthrough = loadPlaythrough(props.match.params.id);

  if (!playthrough) {
    return <NotFound />;
  }

  return (
    <Secret notFound={true}>
      <ScenarioViewerLoader mode="gameplay" playthrough={playthrough} />
    </Secret>
  );
}

function PartyEditorLoader(props) {
  const party = loadParty(props.match.params.id);
  return (
    <Secret notFound={true}>
      <PartyEditor party={party} />
    </Secret>
  );
}

export class App extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      secret: localStorage.getItem('secret') ? SecretConstants.open : null,
    };
  }

  render() {
    return (
      <SecretContext.Provider value={this.state.secret}>
        <Router>
          <ScrollToTop>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/edit-scenario/:id" component={SavedLoader} />
              <Route path="/edit-party/:id" component={PartyEditorLoader} />
              <Route path="/play/:id" component={PlayLoader} />
              <Route component={NotFound} />
            </Switch>
          </ScrollToTop>
        </Router>
      </SecretContext.Provider>
    );
  }
}
