import { shuffle, pickRandom } from '../utils';
import { activateTabByName, setGlobalSetting } from './actions';

const defaultGlobal = {
  scale: 0.25,
  hexLabels: 'numbers',
  gridProminence: 'low',
  highlightExtent: false,
  activeTabName: 'map',
};

const defaultState = {
  global: defaultGlobal,
  scenario: {},
  tids: [],
  tidToConfig: {},
  selectedTid: null,
  modal: null,
};

function getGameplayTabNames(playthrough) {
  if (!playthrough) {
    return ['map'];
  }
  return ['map'].concat(playthrough.party.pcs.map((pc) => pc.id));
}

function initialActiveTab(activeTabName, mode, playthrough) {
  if (mode === 'gameplay') {
    const tabNames = getGameplayTabNames(playthrough);
    if (tabNames.find((tabName) => tabName === activeTabName)) {
      return activeTabName;
    }
  }
  return 'map';
}

function manageAmCards(pcId, pcArea, action, reserve) {
  const { amDeck = [], amActive = [], amDiscard = [] } = pcArea;
  const { amPlayerBlesses = [], amPlayerCurses = [] } = reserve;

  // TODO copy only the arrays that are modified

  const deck = [...amDeck];
  const active = [...amActive];
  const discard = [...amDiscard];
  const blesses = [...amPlayerBlesses];
  const curses = [...amPlayerCurses];

  function moveAll(source, destination) {
    const cids = source.splice(0, source.length);
    destination.splice(destination.length, 0, ...cids);
  }

  function discardActive() {
    const cids = active.splice(0, active.length);

    for (const cid of cids) {
      let destination;
      if (cid.includes('-bless-')) {
        destination = blesses;
      } else if (cid.includes('-pcurse-')) {
        destination = curses;
      } else {
        destination = discard;
      }
      destination.push(cid);
    }
  }

  function shuffleDeck() {
    shuffle(deck);
  }

  function reshuffleDiscard() {
    moveAll(discard, deck);
    shuffleDeck();
  }

  function moveOne(source, destination) {
    const cid = source.shift();
    destination.push(cid);
    return cid;
  }

  function drawOne() {
    if (deck.length === 0) {
      reshuffleDiscard();
    }
    if (deck.length === 0) {
      console.warn('When drawing, deck is empty even after reshuffle');
      return;
    }
    const cid = moveOne(deck, active);
    return cid;
  }

  switch (action.type) {
    case 'DRAW_ONE_WITH_ALL_ROLLING': {
      discardActive();
      drawOne();
      // const cid = drawOne();
      // TODO check cid: if rolling, draw more
      break;
    }
    case 'DRAW_TWO_NEW': {
      discardActive();
      drawOne();
      drawOne();
      break;
    }
    case 'SHUFFLE': {
      discardActive();
      reshuffleDiscard();
      break;
    }
    case 'DISCARD': {
      discardActive();
      break;
    }
    case 'ADD_BLESS': {
      if (blesses.length === 0) {
        break;
      }

      moveOne(blesses, deck);
      shuffleDeck();
      break;
    }
    case 'ADD_CURSE': {
      if (curses.length === 0) {
        break;
      }
      moveOne(curses, deck);
      shuffleDeck();
      break;
    }
    default: {
      console.error(
        'Unrecognized pc area subaction',
        pcId,
        action.type,
        action
      );
      return null;
    }
  }

  return {
    pcArea: {
      ...pcArea,
      amDeck: deck,
      amActive: active,
      amDiscard: discard,
    },
    reserve: {
      amPlayerBlesses: blesses,
      amPlayerCurses: curses,
    },
  };
}

function manageAbilityCards(pcId, pcArea, action, reserve) {
  function selectOrUnselect(cid, selectionName, limit) {
    const selection = { ...(pcArea[selectionName] || {}) };
    if (selection[cid]) {
      delete selection[cid];
    } else if (limit) {
      const cids = Object.keys(selection);
      if (cids.length === limit) {
        delete selection[cids[0]];
      }
      selection[cid] = true;
    } else {
      selection[cid] = true;
    }
    return {
      pcArea: {
        ...pcArea,
        [selectionName]: selection,
      },
    };
  }

  function moveSelected(pcArea, fromName, selectionName, toName) {
    const fromCids = pcArea[fromName] || [];
    const fromSelection = { ...(pcArea[selectionName] || {}) };
    const toCids = pcArea[toName] || [];

    return {
      ...pcArea,
      [fromName]: fromCids.filter((cid) => !fromSelection[cid]),
      [selectionName]: {},
      [toName]: [...toCids, ...Object.keys(fromSelection)],
    };
  }

  function moveSelectedMultiple(pcArea, descriptors, toName) {
    return Object.entries(descriptors).reduce((currentPcArea, descriptor) => {
      const [fromName, selectionName] = descriptor;
      return moveSelected(currentPcArea, fromName, selectionName, toName);
    }, pcArea);
  }

  function moveAll(pcArea, fromName, toName) {
    const fromCids = pcArea[fromName] || [];
    const toCids = pcArea[toName] || [];

    return {
      ...pcArea,
      [fromName]: [],
      [toName]: [...toCids, ...fromCids],
    };
  }

  switch (action.type) {
    case 'HAND_CARD_CLICKED': {
      const { cid } = action;
      return selectOrUnselect(cid, 'handSelection', 2);
    }
    case 'CURRENT_CARD_CLICKED': {
      const { cid } = action;
      return selectOrUnselect(cid, 'currentSelection');
    }
    case 'ACTIVE_CARD_CLICKED': {
      const { cid } = action;
      return selectOrUnselect(cid, 'activeSelection');
    }
    case 'DISCARD_CARD_CLICKED': {
      const { cid } = action;
      return selectOrUnselect(cid, 'discardSelection');
    }
    case 'LOST_CARD_CLICKED': {
      const { cid } = action;
      return selectOrUnselect(cid, 'lostSelection');
    }
    case 'PLAY': {
      return {
        pcArea: moveSelected(
          pcArea,
          'abilityHand',
          'handSelection',
          'abilityCurrent'
        ),
      };
    }
    case 'LOSE_FROM_HAND': {
      return {
        pcArea: moveSelected(
          pcArea,
          'abilityHand',
          'handSelection',
          'abilityLost'
        ),
      };
    }
    case 'DISCARD_FROM_HAND': {
      return {
        pcArea: moveSelected(
          pcArea,
          'abilityHand',
          'handSelection',
          'abilityDiscard'
        ),
      };
    }
    case 'LOSE_FROM_DISCARD': {
      return {
        pcArea: moveSelected(
          pcArea,
          'abilityDiscard',
          'discardSelection',
          'abilityLost'
        ),
      };
    }
    case 'ACTIVATE_CURRENT': {
      return {
        pcArea: moveSelected(
          pcArea,
          'abilityCurrent',
          'currentSelection',
          'abilityActive'
        ),
      };
    }
    case 'DISCARD_PLAYED': {
      return {
        pcArea: moveSelectedMultiple(
          pcArea,
          {
            abilityCurrent: 'currentSelection',
            abilityActive: 'activeSelection',
          },
          'abilityDiscard'
        ),
      };
    }
    case 'LOSE_PLAYED': {
      return {
        pcArea: moveSelectedMultiple(
          pcArea,
          {
            abilityCurrent: 'currentSelection',
            abilityActive: 'activeSelection',
          },
          'abilityLost'
        ),
      };
    }
    case 'RECOVER_FROM_DISCARD': {
      return {
        pcArea: moveSelected(
          pcArea,
          'abilityDiscard',
          'discardSelection',
          'abilityHand'
        ),
      };
    }
    case 'RECOVER_FROM_LOST': {
      return {
        pcArea: moveSelected(
          pcArea,
          'abilityLost',
          'lostSelection',
          'abilityHand'
        ),
      };
    }
    case 'REST': {
      return {
        pcArea: moveAll(
          moveSelected(
            pcArea,
            'abilityDiscard',
            'discardSelection',
            'abilityLost'
          ),
          'abilityDiscard',
          'abilityHand'
        ),
      };
    }
    case 'SELECT_RANDOM': {
      const { abilityDiscard = [], discardSelection = {} } = pcArea;

      const cids = Object.keys(discardSelection);
      const toPick =
        cids.length === 1
          ? abilityDiscard.filter((cid) => cid !== cids[0])
          : abilityDiscard;
      return {
        pcArea: {
          ...pcArea,
          discardSelection:
            toPick.length === 0 ? {} : { [pickRandom(toPick)]: true },
        },
      };
    }
    default: {
      console.error(
        'Unrecognized type of ability card subaction',
        pcId,
        action
      );
      return {
        pcArea,
      };
    }
  }
}

function applyPcAreaSubaction(pcId, pcArea, action, reserve) {
  switch (action.part) {
    case 'am':
      return manageAmCards(pcId, pcArea, action, reserve);
    case 'ability':
      return manageAbilityCards(pcId, pcArea, action, reserve);
    default: {
      console.error('Pc area subaction has unrecognized part', action.part);
      return null;
    }
  }
}

export function reducer(state = defaultState, action) {
  switch (action.type) {
    case 'INIT_SCENARIO_VIEWER': {
      const {
        mode,
        scenario,
        playthrough,
        global,
        tids,
        tidToConfig,
      } = action.payload;

      const newGlobal = { ...(global || defaultGlobal) };
      newGlobal.activeTabName = initialActiveTab(
        newGlobal.activeTabName,
        mode,
        playthrough
      );
      return {
        ...state,
        mode,
        scenario,
        playthrough,
        tids,
        tidToConfig,
        selectedTid: null,
        global: newGlobal,
      };
    }
    case 'CLOSE_SCENARIO_VIEWER': {
      return defaultState;
    }
    case 'SELECT_THING': {
      const { tid } = action;
      return {
        ...state,
        selectedTid: tid,
      };
    }
    case 'UNSELECT_THING': {
      return {
        ...state,
        selectedTid: null,
      };
    }
    case 'SET_THING_SETTING': {
      const { tid, attribute, newValue } = action;
      const { tidToConfig } = state;
      const config = tidToConfig[tid];

      return {
        ...state,
        tidToConfig: {
          ...tidToConfig,
          [tid]: {
            ...config,
            [attribute]: newValue,
          },
        },
      };
    }
    case 'SET_SEVERAL_THING_SETTINGS': {
      const { tid, propToValue } = action;
      const { tidToConfig } = state;
      const config = tidToConfig[tid];

      const newConfig = { ...config, ...propToValue };
      return {
        ...state,
        tidToConfig: {
          ...tidToConfig,
          [tid]: newConfig,
        },
      };
    }
    case 'SET_SETTINGS_OF_THINGS': {
      const { tidToPropToValue } = action;
      const { tidToConfig } = state;
      let newTidToConfig = { ...tidToConfig };
      for (const [tid, propToValue] of Object.entries(tidToPropToValue)) {
        const config = tidToConfig[tid];
        newTidToConfig = {
          ...newTidToConfig,
          [tid]: {
            ...config,
            ...propToValue,
          },
        };
      }
      return {
        ...state,
        tidToConfig: newTidToConfig,
      };
    }
    case 'PERFORM_THING_ACTION': {
      // handled in saga
      return state;
    }
    case 'SET_SCENARIO_SETTING': {
      const { attribute, newValue } = action;
      return {
        ...state,
        scenario: {
          ...state.scenario,
          [attribute]: newValue,
        },
      };
    }
    case 'SET_PLAYTHROUGH_SETTING': {
      const { attribute, newValue } = action;
      return {
        ...state,
        playthrough: {
          ...state.playthrough,
          [attribute]: newValue,
        },
      };
    }
    case 'SET_GLOBAL_SETTING': {
      const { attribute, newValue } = action;
      return {
        ...state,
        global: {
          ...state.global,
          [attribute]: newValue,
        },
      };
    }
    case 'START_ADDING_THING': {
      const { kindForAdding } = action;
      return {
        ...state,
        modal: {
          modalKind: 'ADD_THING',
          kindForAdding,
        },
      };
    }
    case 'SHOW_MODAL': {
      const { modal } = action;
      return {
        ...state,
        modal,
      };
    }
    case 'CLOSE_MODAL': {
      return {
        ...state,
        modal: null,
      };
    }
    case 'FLIP_TILE': {
      return state;
    }
    case 'ADD_THING': {
      const { tid, config, options = {} } = action;
      const { tids, tidToConfig } = state;

      let newTids = [...tids];
      if (options.indexInTids) {
        newTids.splice(options.indexInTids, 0, tid);
      } else {
        newTids.push(tid);
      }

      return {
        ...state,
        tids: newTids,
        tidToConfig: {
          ...tidToConfig,
          [tid]: config,
        },
        selectedTid: tid,
      };
    }
    case 'ADD_SEVERAL_THINGS': {
      const { addedTids, addedTidToConfig } = action;
      const { tids, tidToConfig } = state;

      const newTids = [...tids, ...addedTids];
      const newTidToConfig = {
        ...tidToConfig,
        ...addedTidToConfig,
      };
      return {
        ...state,
        tids: newTids,
        tidToConfig: newTidToConfig,
      };
    }
    case 'ADD_THING_AUTO': {
      // handled in saga
      return state;
    }
    case 'EXPORT_JSON': {
      // handled in saga
      return state;
    }
    case 'APPLY_THING_CHANGER': {
      // handled in saga
      return state;
    }
    case 'REMOVE_THING': {
      const { tid: byeTid } = action;

      const { selectedTid, tids, tidToConfig } = state;

      const newTids = tids.filter((tid) => tid !== byeTid);

      const newTidToConfig = { ...tidToConfig };
      delete newTidToConfig[byeTid];

      const newSelectedTid = selectedTid === byeTid ? null : selectedTid;

      return {
        ...state,
        tids: newTids,
        tidToConfig: newTidToConfig,
        selectedTid: newSelectedTid,
      };
    }
    case 'ON_KEY_PRESSED': {
      return state;
    }
    case 'PC_AREA_SUBACTION': {
      const { pcId, subaction } = action;

      const { playthrough } = state;
      const { pcAreas, reserve } = playthrough;

      const ret = applyPcAreaSubaction(pcId, pcAreas[pcId], subaction, reserve);
      if (!ret) {
        return state;
      }

      const { pcArea: newPcArea, reserve: returnedReserve } = ret;

      const newReserve =
        returnedReserve !== undefined ? returnedReserve : reserve;
      return {
        ...state,
        playthrough: {
          ...playthrough,
          pcAreas: {
            ...pcAreas,
            [pcId]: newPcArea,
          },
          reserve: newReserve,
        },
      };
    }
    case 'ACTIVATE_TAB_BY_NAME': {
      const { tabName } = action;
      return reducer(state, setGlobalSetting('activeTabName', tabName));
    }
    case 'ACTIVATE_TAB_BY_INDEX': {
      const { index } = action;
      const tabNames = getGameplayTabNames(state.playthrough);
      if (0 <= index && index < tabNames.length) {
        return reducer(state, activateTabByName(tabNames[index]));
      }
      return state;
    }
    case 'SHOW_PDF_MODAL': {
      return {
        ...state,
        modal: {
          modalKind: 'PDF_MODAL',
        },
      };
    }
    case 'START_GENERATING_PDF': {
      return {
        ...state,
        isGeneratingPdf: true,
      };
    }
    case 'REGENERATE_PDF': {
      // handled in saga
      return state;
    }
    case 'DOWNLOAD_PDF': {
      // handled in saga
      return state;
    }
    case 'PDF_DATA_AVAILABLE': {
      const { pdfData } = action;
      return {
        ...state,
        pdfData,
        isGeneratingPdf: false,
      };
    }
    case 'START_EXPORT_PNG': {
      return {
        ...state,
        isExportingPng: true,
      };
    }
    case 'DONE_EXPORT_PNG': {
      return {
        ...state,
        isExportingPng: false,
      };
    }
    case 'FOCUS_HITPOINTS': {
      const { value = true } = action;
      return {
        ...state,
        shouldFocusHitpoints: value,
      };
    }
    case 'SHOW_CANNOT_CHANGE_TILE': {
      const { tid, show } = action;
      return {
        ...state,
        showCannotChangeTile: { tid, show },
      };
    }
    default: {
      if (action.type[0] !== '@') {
        console.warn('Unrecognized type of action: ', action);
      }
      return state;
    }
  }
}
