import React from 'react';
import classNames from 'classnames';
import { predefinedScenarios } from '../data/predefinedScenarios';
import {
  setSubtitle,
  generateId,
  Button,
  createNewParty,
  createNewScenario,
  getCurrentTimestamp,
  BulmaModal,
  getScenarioEditLink,
} from '../common';
import { Secret } from '../secret/secret';
import ImportModalContent from './ImportModalContent';
import { saveScenarioInStorage } from '../storage';

function Editable(props) {
  const { editable } = props;
  const isSelected = editable === props.selectedEditable;

  function defaultOnEdit(urlPart) {
    props.history.push(`/${props.urlPart}/${id}`);
  }

  const { id } = editable;

  const onEdit = props.onEdit ? () => props.onEdit(id) : defaultOnEdit;

  return (
    <tr className={classNames({ 'is-selected': isSelected })}>
      <td>{editable.title}</td>
      <td>
        <div className="buttons">
          <Button onClick={onEdit}>{props.editButtonText || 'Edit'}</Button>
          {props.startPlaythrough && (
            <Secret>
              <Button
                isPrimary
                onClick={() => props.startPlaythrough(editable)}
              >
                Play
              </Button>
            </Secret>
          )}
          {props.onSelect && (
            <Button
              isInfo
              disabled={isSelected}
              onClick={() => props.onSelect(editable)}
            >
              Select
            </Button>
          )}
          {props.onDelete && (
            <Button isDanger onClick={() => props.onDelete(id)}>
              Delete
            </Button>
          )}
        </div>
      </td>
    </tr>
  );
}

function Editables(props) {
  const editables = props.editables;
  return (
    <table className="table is-narrow is-striped">
      <tbody>
        {editables.map((editable) => (
          <Editable
            history={props.history}
            key={editable.id}
            editable={editable}
            selectedEditable={props.selectedEditable}
            onEdit={props.onEdit}
            onSelect={props.onSelect}
            onDelete={props.onDelete}
            urlPart={props.urlPart}
            startPlaythrough={props.startPlaythrough}
            editButtonText={props.editButtonText}
          />
        ))}
      </tbody>
    </table>
  );
}

function getAutoSelectedParty(currentSelectedParty, parties) {
  if (parties.length === 0) {
    return null;
  } else if (
    currentSelectedParty !== null &&
    parties.find((p) => p.id === currentSelectedParty.id)
  ) {
    return currentSelectedParty;
  } else {
    return parties[0];
  }
}

function Section(props) {
  return (
    <section className="section">
      <h2>{props.title}</h2>
      {props.children}
    </section>
  );
}

function ExternalLink(props) {
  return (
    <>
      {!props.noSpaceBefore && ' '}
      <a href={props.href} target="_blank" rel="noopener noreferrer">
        {props.children}
      </a>
      {!props.noSpaceAfter && ' '}
    </>
  );
}

function compareOptionalTimestamp(a, b) {
  const aTimestamp = a.createdAt || 0;
  const bTimestamp = b.createdAt || 0;
  return aTimestamp - bTimestamp;
}

function transformScenario(scenario) {
  // This is a stub for the case when scenario representation changes to
  // transform the old representation to the new one for backwards
  // compatibility.

  let transformed = false;
  return [scenario, transformed];
}

function Intro(props) {
  return (
    <div className="section content">
      <h2>What it is</h2>
      <p>A tool to create custom scenarios for Gloomhaven.</p>
      <ul>
        <li>create scenarios with base game tiles and (non-boss) monsters</li>
        <li>
          scenarios are saved so you can reopen them when visiting from the same
          device in the future
        </li>
        <li>
          export to JSON for later import (on another device of your own, or to
          share with somebody)
        </li>
        <li>save a single high-resolution picture</li>
        <li>save a PDF for printing or sharing</li>
      </ul>
      <h2>What it isn't</h2>
      <p>A lot of features are missing.</p>
      <ul>
        <li>saving scenarios on the server</li>
        <li>social features: sharing, voting, commenting</li>
        <li>calculating stats about monster points</li>
        <li>Forgotten Circles-style multi-page layouts</li>
        <li>checking tile overlap and component limits</li>
        <li>organizing scenarios in a custom campaign</li>
        <li>editing with drag and drop</li>
      </ul>
    </div>
  );
}

function Dot(props) {
  return ' · ';
}

export class Home extends React.PureComponent {
  constructor(props) {
    super(props);
    const introHidden = !!localStorage.getItem('introHidden');
    this.state = { introHidden };
  }

  componentWillMount() {
    setSubtitle(null);
    this.loadStorageEditables();
  }

  loadStorageEditables() {
    const storageScenarios = [];
    const storageParties = [];
    const storagePlaythroughs = [];

    for (let i = 0; i < localStorage.length; ++i) {
      const key = localStorage.key(i);
      if (key.match(/^scenario:/)) {
        const [editable, transformed] = transformScenario(
          JSON.parse(localStorage.getItem(key))
        );
        if (transformed) {
          localStorage.setItem(key, JSON.stringify(editable));
        }
        storageScenarios.push(editable);
      } else if (key.match(/^party:/)) {
        const editable = JSON.parse(localStorage.getItem(key));
        storageParties.push(editable);
      } else if (key.match(/^playthrough:/)) {
        const editable = JSON.parse(localStorage.getItem(key));
        storagePlaythroughs.push(editable);
      }
    }

    for (const coll of [
      storageScenarios,
      storageParties,
      storagePlaythroughs,
    ]) {
      coll.sort((a, b) => compareOptionalTimestamp(a, b));
    }

    this.setState({
      storageScenarios,
      storageParties,
      selectedParty: getAutoSelectedParty(null, storageParties),
      storagePlaythroughs,
    });
  }

  deleteScenarioById = (id) => {
    const { storageScenarios } = this.state;

    const key = `scenario:${id}`;
    localStorage.removeItem(key);

    const newStorageScenarios = storageScenarios.filter(
      (editable) => editable.id !== id
    );

    this.setState({ storageScenarios: newStorageScenarios });
  };

  deletePlaythroughById = (id) => {
    const { storagePlaythroughs } = this.state;

    const key = `playthrough:${id}`;
    localStorage.removeItem(key);

    const newStoragePlaythroughs = storagePlaythroughs.filter(
      (editable) => editable.id !== id
    );

    this.setState({ storagePlaythroughs: newStoragePlaythroughs });
  };

  deletePartyById = (id) => {
    this.setState((state) => {
      const { storageParties } = state;

      const key = `party:${id}`;
      localStorage.removeItem(key);

      const newStorageParties = storageParties.filter(
        (editable) => editable.id !== id
      );

      return {
        storageParties: newStorageParties,
        selectedParty: getAutoSelectedParty(
          state.selectedParty,
          newStorageParties
        ),
      };
    });
  };

  createAndEditScenario = () => {
    const scenario = createNewScenario();
    saveScenarioInStorage(scenario);
    this.props.history.push(getScenarioEditLink(scenario));
  };

  createAndEditParty = () => {
    const party = createNewParty();
    localStorage.setItem(`party:${party.id}`, JSON.stringify(party));
    this.props.history.push(`/edit-party/${party.id}`);
  };

  canStartPlaythrough = () => {
    return this.state.selectedParty !== null;
  };

  startPlaythrough = (scenario) => {
    const party = this.state.selectedParty;

    const id = generateId();
    const playthrough = {
      id,
      title: `${party.title} at ${scenario.title}`,
      scenario,
      party,
      createdAt: getCurrentTimestamp(),
    };
    localStorage.setItem(`playthrough:${id}`, JSON.stringify(playthrough));

    this.props.history.push(`/play/${id}`);
  };

  handleSelectParty = (party) => {
    this.setState({
      selectedParty: party,
    });
  };

  cloneAndEditPredef = (id) => {
    const scenario = predefinedScenarios.find((s) => s.id === id);
    const newId = generateId();
    const newScenario = {
      ...scenario,
      id: newId,
      createdAt: getCurrentTimestamp(),
    };
    const key = `scenario:${newId}`;
    localStorage.setItem(key, JSON.stringify(newScenario));
    this.props.history.push(`/edit-scenario/${newId}`);
  };

  startImportScenario = () => {
    this.setState({
      showingImportModal: true,
    });
  };

  closeModal = () => {
    this.setState({
      showingImportModal: null,
    });
  };

  handleUploadScenario = (scenario) => {
    saveScenarioInStorage(scenario);
    this.closeModal();
    // XXX this is a hack to make sure the modal is closed (and class
    // name on document removed).
    window.requestAnimationFrame(() =>
      this.props.history.push(getScenarioEditLink(scenario))
    );
  };

  hideIntro = () => {
    localStorage.setItem('introHidden', true);
    this.setState({ introHidden: true });
  };

  showIntro = () => {
    localStorage.setItem('introHidden', false);
    this.setState({ introHidden: false });
  };

  render() {
    const { history } = this.props;
    const { introHidden } = this.state;
    return (
      <div className="Home">
        <section id="hero" className="hero is-dark is-bold">
          <div className="hero-body">
            <div className="container">
              <h1 className="title is-1">Silent Bridge</h1>
              <h2 className="subtitle is-2">Gloomhaven scenario editor</h2>
            </div>
          </div>
        </section>
        <div className="Content">
          <div className="container">
            <div className="section-small">
              {introHidden ? (
                <Button isInfo onClick={this.showIntro}>
                  Show intro
                </Button>
              ) : (
                <>
                  <Intro />
                  <Button isInfo onClick={this.hideIntro}>
                    Hide intro
                  </Button>
                </>
              )}
            </div>

            <Section title="Predefined scenarios">
              <Editables
                history={history}
                editables={predefinedScenarios}
                startPlaythrough={
                  this.canStartPlaythrough() ? this.startPlaythrough : null
                }
                editButtonText="Clone and edit"
                onEdit={this.cloneAndEditPredef}
              />
            </Section>
            <Section title="Stored scenarios">
              <div className="buttons">
                <Button isSuccess onClick={this.createAndEditScenario}>
                  New scenario
                </Button>
                <Button onClick={this.startImportScenario}>
                  Import scenario
                </Button>
              </div>
              <Editables
                history={history}
                urlPart="edit-scenario"
                onDelete={this.deleteScenarioById}
                editables={this.state.storageScenarios}
                startPlaythrough={
                  this.canStartPlaythrough() ? this.startPlaythrough : null
                }
              />
            </Section>
            <Secret>
              <Section title="Stored parties">
                <Editables
                  history={history}
                  urlPart="edit-party"
                  onDelete={this.deletePartyById}
                  editables={this.state.storageParties}
                  selectedEditable={this.state.selectedParty}
                  onSelect={this.handleSelectParty}
                />
                <Button isPrimary onClick={this.createAndEditParty}>
                  New party
                </Button>
              </Section>
              <Section title="Stored playthroughs">
                <Editables
                  history={history}
                  urlPart="play"
                  onDelete={this.deletePlaythroughById}
                  editables={this.state.storagePlaythroughs}
                  editButtonText="Continue"
                />
              </Section>
            </Secret>
          </div>
        </div>
        <footer className="hero is-dark is-bold">
          <div className="hero-body">
            <div className="container has-text-centered">
              <p>Made by arry with lots of love.</p>
              <p>
                Contribute at
                <ExternalLink
                  noSpaceAfter={true}
                  href="https://bitbucket.org/arry/silent-bridge/"
                >
                  Bitbucket
                </ExternalLink>
                <Dot />
                Support on
                <ExternalLink
                  noSpaceAfter={true}
                  href="https://www.patreon.com/arry_maker_of_chantry"
                >
                  Patreon
                </ExternalLink>
                <Dot /> Contact via
                <ExternalLink
                  noSpaceAfter={true}
                  href="https://boardgamegeek.com/user/arry1"
                >
                  BGG
                </ExternalLink>
              </p>
              <p>
                No relation to the publisher of Gloomhaven,
                <ExternalLink
                  noSpaceAfter={true}
                  href="http://www.cephalofair.com/"
                >
                  Cephalofair Games
                </ExternalLink>
                .
              </p>
              <p>
                <ExternalLink
                  href="https://boardgamegeek.com/thread/1733586/files-creation/"
                  noSpaceBefore={true}
                >
                  The assets
                </ExternalLink>
                are used under the
                <ExternalLink href="https://creativecommons.org/licenses/by-nc-sa/4.0/">
                  Creative Commons BY-NC-SA 4.0
                </ExternalLink>
                license.
              </p>
            </div>
          </div>
        </footer>
        <BulmaModal
          isOpen={this.state.showingImportModal}
          onRequestClose={this.closeModal}
        >
          <ImportModalContent onUploadScenario={this.handleUploadScenario} />
        </BulmaModal>
      </div>
    );
  }
}
