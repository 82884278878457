import { hexSize } from '../data/sizes';
import { getImageSync, loadAllImages } from '../imageLoader/imageLoader';

function collectSrcs(representations) {
  const result = new Set();
  function add(src) {
    result.add(src);
  }

  for (const representation of representations) {
    add(representation.src);
    for (const addition of representation.contents) {
      if (addition.src) {
        add(addition.src);
      }
    }
  }
  return result;
}

export function createPngCanvasSync(intermediary) {
  const { bounds, size, representations } = intermediary;
  const canvas = document.createElement('canvas');

  canvas.width = size.width;
  canvas.height = size.height;

  const ctx = canvas.getContext('2d');
  ctx.fillStyle = 'rgba(255, 255, 255, 0.5)';

  ctx.translate(-bounds.minX, -bounds.minY);

  for (const representation of representations) {
    const {
      src,
      left,
      top,
      degrees = 0,
      contents,
      transformOrigin,
    } = representation;

    const drawable = getImageSync(src);
    if (!drawable) {
      continue;
    }

    ctx.save();

    const [toLeft, toTop] = transformOrigin;
    ctx.translate(left + toLeft, top + toTop);
    ctx.rotate((degrees * Math.PI) / 180);
    ctx.translate(-(left + toLeft), -(top + toTop));

    ctx.drawImage(drawable, left, top);

    ctx.restore();

    for (const addition of contents) {
      const { additionType } = addition;
      if (additionType === 'image') {
        const { src, x = 0, y = 0, width = 225, height = 225 } = addition;
        const drawable = getImageSync(src);
        if (!drawable) {
          continue;
        }
        ctx.drawImage(drawable, left + x, top + y, width, height);
      } else if (additionType === 'text') {
        const { text } = addition;
        // XXX duplicates .thing-container .label: need to distinguish text types, remove duplication, and add support for non-label texts
        ctx.fillStyle = 'black'; // XXX $dark
        ctx.font = '58px hightower';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'top';
        ctx.fillText(text, left + hexSize / 2, top + 32, hexSize);
      } else if (additionType === 'redCircleText') {
        const { text } = addition;
        ctx.fillStyle = 'white';
        ctx.font = '84px pirataone';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'top';
        ctx.fillText(text, left + hexSize / 2, top + 32 + 45, hexSize);
      }
    }
  }

  return canvas;
}

export function createPngDataUrl(intermediary) {
  const srcs = collectSrcs(intermediary.representations);

  return new Promise((resolve, reject) => {
    window.setTimeout(() => {
      loadAllImages(srcs)
        .then(() => {
          const canvas = createPngCanvasSync(intermediary);
          const result = canvas.toDataURL();
          resolve(result);
        })
        .catch((e) => {
          reject(e);
        });
    }, 100);
  });
}

export function createPngBlob(intermediary) {
  const srcs = collectSrcs(intermediary.representations);

  return new Promise((resolve, reject) => {
    window.setTimeout(() => {
      loadAllImages(srcs)
        .then(() => {
          const canvas = createPngCanvasSync(intermediary);

          canvas.toBlob((blob) => {
            resolve(blob);
          });
        })
        .catch((e) => {
          reject(e);
        });
    }, 100);
  });
}
