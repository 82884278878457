import { getHexId, getRotatedHexCoordinates } from '../utils';

/**
 * Number means (imagining a pointy grid) "add a horizontal row of N hexes
 * starting at pen". Letter means "move the pen".
 */
const nameToPattern = {
  A: '4z5',
  B: '4c3z4c3',
  C: '2z3z4c3',
  D: '3z4z5c4c3',
  E: '4z5c4z5c4',
  F: '3c2z3c2z3c2z3c2z3',
  G: '8c7z8',
  H: '6z7cdd2z3c2z3c2',
  I: '6c5z6c5z6',
  J: '1z3z3z3z3c2z3c3c3c3c1',
  K: '4z5z6z3dddd3aaaaz3ddddd3aaaaz2ddddd2',
  L: '5c4z5c4z5c4z5',
  M: '4z5z6c5z6c5c4',
  N: '8c7z8c7z8c7z8',
  'one-hex': '1',
  long: '2',
  triangle: '1z2',
};

// An imaginary pen hovering over a hex grid.
class Pen {
  constructor(q, r, rotation) {
    this.q = q;
    this.r = r;
    this.rotation = rotation;
  }

  move(direction) {
    switch (direction) {
      case 'd': {
        this.q += 1;
        break;
      }
      case 'z': {
        this.q -= 1;
        this.r += 1;
        break;
      }
      case 'c': {
        this.r += 1;
        break;
      }
      case 'a': {
        this.q -= 1;
        break;
      }
      default: {
        console.warn('Unrecognized direction:', direction);
      }
    }
  }

  addTo(resultSet) {
    resultSet.add(getHexId(this.q, this.r));
  }

  addOffset(result) {
    result.push([this.q, this.r]);
  }
}

function convertPatternToOffsets(pattern) {
  let pen = new Pen(0, 0, 0);

  let result = [];
  function applyPiece(piece) {
    const match = /\d/.exec(piece);
    if (match) {
      const n = Number(piece) - 1;
      pen.addOffset(result);

      for (let i = 0; i < n; ++i) {
        pen.move('d');
        pen.addOffset(result);
      }
      for (let i = 0; i < n; ++i) {
        pen.move('a');
      }
    } else {
      pen.move(piece);
    }
  }

  for (let piece of pattern) {
    applyPiece(piece);
  }

  return result;
}

function initOffsets() {
  const result = {};
  for (const [name, pattern] of Object.entries(nameToPattern)) {
    const offsets = convertPatternToOffsets(pattern);
    result[name] = offsets;
  }
  return result;
}

const nameToOffsets = initOffsets();

export function getHexesCoveredByPattern(
  patternName,
  tileQ,
  tileR,
  tileRotation
) {
  const offsets = nameToOffsets[patternName];

  if (!offsets) {
    throw new Error(`Unrecognized patternName '${patternName}'`);
  }

  const result = new Set();

  for (const [q, r] of offsets) {
    const [qq, rr] = getRotatedHexCoordinates(q, r, tileRotation);
    const hexId = getHexId(tileQ + qq, tileR + rr);
    result.add(hexId);
  }
  return result;
}
