export const CARD_IDS = {
  amPlayerBase: [
    'player/am-p-01',
    'player/am-p-02',
    'player/am-p-03',
    'player/am-p-04',
    'player/am-p-05',
    'player/am-p-06',
    'player/am-p-07',
    'player/am-p-08',
    'player/am-p-09',
    'player/am-p-10',
    'player/am-p-11',
    'player/am-p-12',
    'player/am-p-13',
    'player/am-p-14',
    'player/am-p-15',
    'player/am-p-16',
    'player/am-p-17',
    'player/am-p-18',
    'player/am-p-19',
    'player/am-p-20',
  ],
  amPlayerBlesses: [
    'player/am-bless-01',
    'player/am-bless-02',
    'player/am-bless-03',
    'player/am-bless-04',
    'player/am-bless-05',
    'player/am-bless-06',
    'player/am-bless-07',
    'player/am-bless-08',
    'player/am-bless-09',
    'player/am-bless-10',
  ],
  amPlayerCurses: [
    'player/am-pcurse-01',
    'player/am-pcurse-02',
    'player/am-pcurse-03',
    'player/am-pcurse-04',
    'player/am-pcurse-05',
    'player/am-pcurse-06',
    'player/am-pcurse-07',
    'player/am-pcurse-08',
    'player/am-pcurse-09',
    'player/am-pcurse-10',
  ],
  amPlayerMinusOnes: [
    'player/am-minone-01',
    'player/am-minone-02',
    'player/am-minone-03',
    'player/am-minone-04',
    'player/am-minone-05',
    'player/am-minone-06',
    'player/am-minone-07',
    'player/am-minone-08',
    'player/am-minone-09',
    'player/am-minone-10',
    'player/am-minone-11',
    'player/am-minone-12',
    'player/am-minone-13',
    'player/am-minone-14',
    'player/am-minone-15',
  ],
  amBonuses: {
    brute: [
      'brute/am-br-01',
      'brute/am-br-02',
      'brute/am-br-03',
      'brute/am-br-04',
      'brute/am-br-05',
      'brute/am-br-06',
      'brute/am-br-07',
      'brute/am-br-08',
      'brute/am-br-09',
      'brute/am-br-10',
      'brute/am-br-11',
      'brute/am-br-12',
      'brute/am-br-13',
      'brute/am-br-14',
      'brute/am-br-15',
      'brute/am-br-16',
      'brute/am-br-17',
      'brute/am-br-18',
      'brute/am-br-19',
      'brute/am-br-20',
      'brute/am-br-21',
      'brute/am-br-22',
    ],
    cragheart: [
      'cragheart/am-ch-01',
      'cragheart/am-ch-02',
      'cragheart/am-ch-03',
      'cragheart/am-ch-04',
      'cragheart/am-ch-05',
      'cragheart/am-ch-06',
      'cragheart/am-ch-07',
      'cragheart/am-ch-08',
      'cragheart/am-ch-09',
      'cragheart/am-ch-10',
      'cragheart/am-ch-11',
      'cragheart/am-ch-12',
      'cragheart/am-ch-13',
      'cragheart/am-ch-14',
      'cragheart/am-ch-15',
      'cragheart/am-ch-16',
      'cragheart/am-ch-17',
      'cragheart/am-ch-18',
    ],
    mindthief: [
      'mindthief/am-mt-01',
      'mindthief/am-mt-02',
      'mindthief/am-mt-03',
      'mindthief/am-mt-04',
      'mindthief/am-mt-05',
      'mindthief/am-mt-06',
      'mindthief/am-mt-07',
      'mindthief/am-mt-08',
      'mindthief/am-mt-09',
      'mindthief/am-mt-10',
      'mindthief/am-mt-11',
      'mindthief/am-mt-12',
      'mindthief/am-mt-13',
      'mindthief/am-mt-14',
      'mindthief/am-mt-15',
      'mindthief/am-mt-16',
      'mindthief/am-mt-17',
      'mindthief/am-mt-18',
      'mindthief/am-mt-19',
      'mindthief/am-mt-20',
    ],
    scoundrel: [
      'scoundrel/am-sc-01',
      'scoundrel/am-sc-02',
      'scoundrel/am-sc-03',
      'scoundrel/am-sc-04',
      'scoundrel/am-sc-05',
      'scoundrel/am-sc-06',
      'scoundrel/am-sc-07',
      'scoundrel/am-sc-08',
      'scoundrel/am-sc-09',
      'scoundrel/am-sc-10',
      'scoundrel/am-sc-11',
      'scoundrel/am-sc-12',
      'scoundrel/am-sc-13',
      'scoundrel/am-sc-14',
      'scoundrel/am-sc-15',
      'scoundrel/am-sc-16',
      'scoundrel/am-sc-17',
    ],
    spellweaver: [
      'spellweaver/am-sw-01',
      'spellweaver/am-sw-02',
      'spellweaver/am-sw-03',
      'spellweaver/am-sw-04',
      'spellweaver/am-sw-05',
      'spellweaver/am-sw-06',
      'spellweaver/am-sw-07',
      'spellweaver/am-sw-08',
      'spellweaver/am-sw-09',
      'spellweaver/am-sw-10',
      'spellweaver/am-sw-11',
      'spellweaver/am-sw-12',
      'spellweaver/am-sw-13',
      'spellweaver/am-sw-14',
      'spellweaver/am-sw-15',
      'spellweaver/am-sw-16',
      'spellweaver/am-sw-17',
      'spellweaver/am-sw-18',
    ],
    tinkerer: [
      'tinkerer/am-ti-01',
      'tinkerer/am-ti-02',
      'tinkerer/am-ti-03',
      'tinkerer/am-ti-04',
      'tinkerer/am-ti-05',
      'tinkerer/am-ti-06',
      'tinkerer/am-ti-07',
      'tinkerer/am-ti-08',
      'tinkerer/am-ti-09',
      'tinkerer/am-ti-10',
      'tinkerer/am-ti-11',
      'tinkerer/am-ti-12',
      'tinkerer/am-ti-13',
      'tinkerer/am-ti-14',
      'tinkerer/am-ti-15',
      'tinkerer/am-ti-16',
    ],
  },
  ability: {
    brute: [
      'brute/balanced-measure',
      'brute/br-back',
      'brute/brute-force',
      'brute/crippling-offensive',
      'brute/defensive-tactics',
      'brute/devastating-hack',
      'brute/eye-for-an-eye',
      'brute/face-your-end',
      'brute/fatal-advance',
      'brute/frenzied-onslaught',
      'brute/grab-and-go',
      'brute/hook-and-chain',
      'brute/immovable-phalanx',
      'brute/juggernaut',
      'brute/king-of-the-hill',
      'brute/leaping-cleave',
      'brute/overwhelming-assault',
      'brute/provoking-roar',
      'brute/quietus',
      'brute/selfish-retribution',
      'brute/shield-bash',
      'brute/skewer',
      'brute/skirmishing-maneuver',
      'brute/spare-dagger',
      'brute/sweeping-blow',
      'brute/trample',
      'brute/unstoppable-charge',
      'brute/wall-of-doom',
      'brute/warding-strength',
      'brute/whirlwind',
    ],

    cragheart: [
      'cragheart/avalanche',
      'cragheart/backup-ammunition',
      'cragheart/blind-destruction',
      'cragheart/blunt-force',
      'cragheart/brutal-momentum',
      'cragheart/cataclysm',
      'cragheart/ch-back',
      'cragheart/clear-the-way',
      'cragheart/crater',
      'cragheart/crushing-grasp',
      'cragheart/dig-pit',
      'cragheart/dirt-tornado',
      'cragheart/earthen-clod',
      'cragheart/explosive-punch',
      'cragheart/forceful-storm',
      'cragheart/heaving-swing',
      'cragheart/kinetic-assault',
      'cragheart/lumbering-bash',
      'cragheart/massive-boulder',
      'cragheart/meteor',
      'cragheart/natures-lift',
      'cragheart/opposing-strike',
      'cragheart/petrify',
      'cragheart/pulverize',
      'cragheart/rock-slide',
      'cragheart/rock-tunnel',
      'cragheart/rocky-end',
      'cragheart/rumbling-advance',
      'cragheart/sentient-growth',
      'cragheart/stone-pummel',
      'cragheart/unstable-upheaval',
    ],

    mindthief: [
      'mindthief/brain-leech',
      'mindthief/corrupting-embrace',
      'mindthief/cranium-overload',
      'mindthief/dark-frenzy',
      'mindthief/domination',
      'mindthief/empathetic-assault',
      'mindthief/fearsome-blade',
      'mindthief/feedback-loop',
      'mindthief/frigid-apparition',
      'mindthief/frozen-mind',
      'mindthief/gnawing-horde',
      'mindthief/hostile-takeover',
      'mindthief/into-the-night',
      'mindthief/many-as-one',
      'mindthief/mass-hysteria',
      'mindthief/mt-back',
      'mindthief/parasitic-influence',
      'mindthief/perverse-edge',
      'mindthief/phantasmal-killer',
      'mindthief/pilfer',
      'mindthief/possession',
      'mindthief/psychic-projection',
      'mindthief/scurry',
      'mindthief/shared-nightmare',
      'mindthief/silent-scream',
      'mindthief/submissive-affliction',
      'mindthief/the-minds-weakness',
      'mindthief/vicious-blood',
      'mindthief/withering-claw',
      'mindthief/wretched-creature',
    ],

    scoundrel: [
      'scoundrel/backstab',
      'scoundrel/burning-oil',
      'scoundrel/crippling-poison',
      'scoundrel/cull-the-weak',
      'scoundrel/duelists-advance',
      'scoundrel/flanking-strike',
      'scoundrel/flintlock',
      'scoundrel/flurry-of-blades',
      'scoundrel/gruesome-advantage',
      'scoundrel/hidden-daggers',
      'scoundrel/long-con',
      'scoundrel/open-wound',
      'scoundrel/pains-end',
      'scoundrel/quick-hands',
      'scoundrel/sc-back',
      'scoundrel/single-out',
      'scoundrel/sinister-opportunity',
      'scoundrel/smoke-bomb',
      'scoundrel/special-mixture',
      'scoundrel/spring-the-trap',
      'scoundrel/stick-to-the-shadows',
      'scoundrel/stiletto-storm',
      'scoundrel/swift-bow',
      'scoundrel/thiefs-knack',
      'scoundrel/throwing-knives',
      'scoundrel/tricksters-reversal',
      'scoundrel/venom-shiv',
      'scoundrel/visage-of-the-inevitable',
      'scoundrel/watch-it-burn',
    ],

    spellweaver: [
      'spellweaver/aid-from-the-ether',
      'spellweaver/black-hole',
      'spellweaver/chromatic-explosion',
      'spellweaver/cold-fire',
      'spellweaver/cold-front',
      'spellweaver/crackling-air',
      'spellweaver/elemental-aid',
      'spellweaver/engulfed-in-flames',
      'spellweaver/fire-orbs',
      'spellweaver/flame-strike',
      'spellweaver/flashing-burst',
      'spellweaver/forked-beam',
      'spellweaver/freezing-nova',
      'spellweaver/frost-armor',
      'spellweaver/frozen-night',
      'spellweaver/hardened-spikes',
      'spellweaver/icy-blast',
      'spellweaver/impaling-eruption',
      'spellweaver/inferno',
      'spellweaver/living-torch',
      'spellweaver/mana-bolt',
      'spellweaver/reviving-ether',
      'spellweaver/ride-the-wind',
      'spellweaver/spirit-of-doom',
      'spellweaver/stone-fists',
      'spellweaver/sw-back',
      'spellweaver/twin-restoration',
      'spellweaver/zephyr-wings',
    ],

    tinkerer: [
      'tinkerer/auto-turret',
      'tinkerer/chimeric-formula',
      'tinkerer/crank-bow',
      'tinkerer/curative-aerosol',
      'tinkerer/dangerous-contraption',
      'tinkerer/disintegration-beam',
      'tinkerer/disorienting-flash',
      'tinkerer/energizing-tonic',
      'tinkerer/enhancement-field',
      'tinkerer/flamethrower',
      'tinkerer/gas-canister',
      'tinkerer/harmless-contraption',
      'tinkerer/harsh-stimulants',
      'tinkerer/hook-gun',
      'tinkerer/ink-bomb',
      'tinkerer/jet-propulsion',
      'tinkerer/lethal-injection',
      'tinkerer/micro-bots',
      'tinkerer/murderous-contraption',
      'tinkerer/net-shooter',
      'tinkerer/noxious-vials',
      'tinkerer/potent-potables',
      'tinkerer/proximity-mine',
      'tinkerer/reinvigorating-elixir',
      'tinkerer/restorative-mist',
      'tinkerer/reviving-shock',
      'tinkerer/stamina-booster',
      'tinkerer/stun-shot',
      'tinkerer/ti-back',
      'tinkerer/tinkerers-tools',
      'tinkerer/toxic-bolt',
      'tinkerer/volatile-concoction',
    ],
  },
};
