export const allMonsterNames = [
  'ancient-artillery',
  'bandit-archer',
  'bandit-guard',
  'black-imp',
  'cave-bear',
  'city-archer',
  'city-guard',
  'cultist',
  'deep-terror',
  'earth-demon',
  'flame-demon',
  'forest-imp',
  'frost-demon',
  'giant-viper',
  'harrower-infester',
  'hound',
  'inox-archer',
  'inox-bodyguard',
  'inox-guard',
  'inox-shaman',
  'living-bones',
  'living-corpse',
  'living-spirit',
  'lurker',
  'night-demon',
  'ooze',
  'rending-drake',
  'savvas-icestorm',
  'savvas-lavaflow',
  'spitting-drake',
  'stone-golem',
  'sun-demon',
  'vermling-scout',
  'vermling-shaman',
  'wind-demon',
];

export const englishMonsterNames = {
  'ancient-artillery': 'Ancient Artillery',
  'bandit-archer': 'Bandit Archer',
  'bandit-guard': 'Bandit Guard',
  'black-imp': 'Black Imp',
  'cave-bear': 'Cave Bear',
  'city-archer': 'City Archer',
  'city-guard': 'City Guard',
  cultist: 'Cultist',
  'deep-terror': 'Deep Terror',
  'earth-demon': 'Earth Demon',
  'flame-demon': 'Flame Demon',
  'forest-imp': 'Forest Imp',
  'frost-demon': 'Frost Demon',
  'giant-viper': 'Giant Viper',
  'harrower-infester': 'Harrower Infester',
  hound: 'Hound',
  'inox-archer': 'Inox Archer',
  'inox-bodyguard': 'Inox Bodyguard',
  'inox-guard': 'Inox Guard',
  'inox-shaman': 'Inox Shaman',
  'living-bones': 'Living Bones',
  'living-corpse': 'Living Corpse',
  'living-spirit': 'Living Spirit',
  lurker: 'Lurker',
  'night-demon': 'Night Demon',
  ooze: 'Ooze',
  'rending-drake': 'Rending Drake',
  'savvas-icestorm': 'Savvas Icestorm',
  'savvas-lavaflow': 'Savvas Lavaflow',
  'spitting-drake': 'Spitting Drake',
  'stone-golem': 'Stone Golem',
  'sun-demon': 'Sun Demon',
  'vermling-scout': 'Vermling Scout',
  'vermling-shaman': 'Vermling Shaman',
  'wind-demon': 'Wind Demon',
};

const stats = {
  'bandit-guard': {
    nhp: [5, 6, 6, 9, 10, 11, 14, 16],
    ehp: [9, 9, 10, 10, 11, 12, 14, 14],
    standees: 6,
  },
  'bandit-archer': {
    nhp: [4, 5, 6, 6, 8, 10, 10, 13],
    ehp: [6, 7, 9, 10, 10, 12, 13, 17],
    standees: 6,
  },
  cultist: {
    nhp: [4, 5, 7, 9, 10, 11, 14, 15],
    ehp: [7, 9, 12, 13, 15, 18, 22, 25],
    standees: 6,
  },
  'earth-demon': {
    nhp: [7, 9, 12, 13, 15, 17, 20, 22],
    ehp: [10, 13, 18, 20, 21, 25, 27, 32],
    standees: 6,
  },
  'living-bones': {
    nhp: [5, 5, 5, 7, 7, 9, 10, 13],
    ehp: [6, 6, 7, 10, 11, 11, 11, 14],
    standees: 10,
  },
  'wind-demon': {
    nhp: [3, 3, 4, 5, 7, 9, 10, 11],
    ehp: [5, 5, 7, 8, 8, 11, 12, 13],
    standees: 6,
  },
  'sun-demon': {
    nhp: [5, 7, 9, 10, 11, 11, 12, 15],
    ehp: [9, 12, 13, 15, 16, 16, 18, 22],
    standees: 6,
  },
  ooze: {
    nhp: [4, 5, 7, 8, 9, 10, 12, 14],
    ehp: [8, 9, 1, 11, 13, 15, 16, 18],
    standees: 10,
  },
  'cave-bear': {
    nhp: [7, 9, 11, 13, 16, 17, 19, 22],
    ehp: [11, 14, 17, 20, 21, 24, 28, 33],
    standees: 6,
  },
  'city-archer': {
    nhp: [4, 5, 6, 6, 8, 9, 9, 10],
    ehp: [6, 6, 7, 8, 10, 11, 12, 13],
    standees: 6,
  },
  'city-guard': {
    nhp: [5, 5, 7, 8, 9, 10, 11, 13],
    ehp: [6, 6, 9, 9, 10, 12, 13, 14],
    standees: 6,
  },
  'stone-golem': {
    nhp: [10, 10, 11, 11, 12, 13, 16, 16],
    ehp: [10, 11, 14, 15, 17, 19, 20, 21],
    standees: 6,
  },
  'vermling-shaman': {
    nhp: [2, 2, 3, 3, 3, 4, 5, 7],
    ehp: [3, 3, 4, 5, 5, 6, 6, 8],
    standees: 6,
  },
};

export function getMonsterMaxHp(monsterType, version, level) {
  const statSheet = stats[monsterType];
  if (!statSheet) {
    console.warn('Unrecognized monsterType:', monsterType);
    return 0;
  }
  if (version === 'normal') {
    return statSheet.nhp[level];
  } else if (version === 'elite') {
    return statSheet.ehp[level];
  } else {
    console.error('Invalid monster version', version);
    return 0;
  }
}
