import fs from 'fs';
import { pirataOne, highTower } from './fonts';

// https://stackoverflow.com/questions/21797299/convert-base64-string-to-arraybuffer
function base64ToArrayBuffer(base64) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

function registerBase64(fileName, base64) {
  const arrayBuffer = base64ToArrayBuffer(base64);
  fs.writeFileSync(fileName, arrayBuffer);
}

registerBase64('fonts/HighTower.ttf', highTower);
registerBase64('fonts/PirataOne-Regular.ttf', pirataOne);
