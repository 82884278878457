import { createSelector } from 'reselect';
import {
  getHexIdsCoveredByThing,
  getHexTopsOfOrientation,
  setAddAll,
  getHexId,
} from '../utils';
import { sbVersion } from '../data/version';
import { thingTypeDescriptors } from '../data/thingTypes';

export const selectScenarioViewer = (state) => state.scenarioViewer;

export const selectTidToConfig = createSelector(
  [selectScenarioViewer],
  (scenarioViewer) => scenarioViewer.tidToConfig
);

export const selectTids = createSelector(
  [selectScenarioViewer],
  (scenarioViewer) => scenarioViewer.tids
);

export const selectExtent = createSelector(
  [selectTidToConfig],
  (tidToConfig) => {
    const walkableHexIds = new Set();
    const allHexIds = new Set();
    const tileCoveredHexIds = new Set();

    for (const config of Object.values(tidToConfig)) {
      const { thingType } = config;

      const thingTypeDescriptor = thingTypeDescriptors[thingType];
      if (thingTypeDescriptor.positioning !== 'hex-positioning') {
        continue;
      }
      const coveredHexes = getHexIdsCoveredByThing(config);

      setAddAll(allHexIds, coveredHexes);

      if (thingTypeDescriptor.isFloor || thingTypeDescriptor.isDoor) {
        setAddAll(walkableHexIds, coveredHexes);
      }

      if (thingType === 'tile') {
        setAddAll(tileCoveredHexIds, coveredHexes);
      }
    }

    return { walkableHexIds, allHexIds, tileCoveredHexIds };
  }
);

export const selectSelectedTid = createSelector(
  [selectScenarioViewer],
  (scenarioViewer) => scenarioViewer.selectedTid
);

export const selectScenario = createSelector(
  [selectScenarioViewer],
  (scenarioViewer) => scenarioViewer.scenario
);

export const selectPlaythrough = createSelector(
  [selectScenarioViewer],
  (scenarioViewer) => scenarioViewer.playthrough
);

export const selectMode = createSelector(
  [selectScenarioViewer],
  (scenarioViewer) => scenarioViewer.mode
);

function collectThingChangers(tid, tidToConfig, scenario, orientation, mode) {
  if (tid === null || tid === undefined) {
    return null;
  }

  const config = tidToConfig[tid];

  if (!config) {
    console.error('Cannot find config for thing', tid, tidToConfig);
    return null;
  }

  const { thingType } = config;
  const thingTypeDescriptor = thingTypeDescriptors[thingType];

  let moveChangers;
  if (thingTypeDescriptor.positioning === 'freeform') {
    moveChangers = ['free-move-l', 'free-move-t', 'free-move-r', 'free-move-b'];
  } else {
    const hexTops = getHexTopsOfOrientation(orientation);
    moveChangers =
      hexTops === 'flat'
        ? [
            'flat-move-nw',
            'flat-move-n',
            'flat-move-ne',
            'flat-move-se',
            'flat-move-s',
            'flat-move-sw',
          ]
        : [
            'pointy-move-nw',
            'pointy-move-ne',
            'pointy-move-e',
            'pointy-move-se',
            'pointy-move-sw',
            'pointy-move-w',
          ];
  }

  let rotateChangers = thingTypeDescriptor.rotatable
    ? ['rotate-r', 'rotate-l']
    : [];
  let flipChangers = thingTypeDescriptor.flippable ? ['flip'] : [];
  let cloneChangers = thingTypeDescriptor.nonClonable ? [] : ['clone'];
  let removeChangers = ['remove'];

  if (mode === 'gameplay') {
    cloneChangers = [];
    if (!thingTypeDescriptor.isDynamic) {
      moveChangers = [];
      rotateChangers = [];
      flipChangers = [];
      removeChangers = [];
    }
  }

  const changers = new Set([
    ...moveChangers,
    ...rotateChangers,
    ...flipChangers,
    ...cloneChangers,
    ...removeChangers,
  ]);

  return changers;
}

export const selectGridHexTops = createSelector(
  [selectScenario],
  (scenario) => scenario.gridHexTops
);

export const selectOrientation = createSelector(
  [selectGridHexTops],
  (hexTops) => {
    return hexTops === 'pointy' ? 0 : 3;
  }
);

export const selectSelectedThingChangers = createSelector(
  [
    selectSelectedTid,
    selectTidToConfig,
    selectScenario,
    selectOrientation,
    selectMode,
  ],
  (...args) => collectThingChangers(...args)
);

export function selectThingConfig(state, tid) {
  const tidToConfig = selectTidToConfig(state);
  return tidToConfig[tid];
}

export const selectScenarioToPersist = createSelector(
  [selectTids, selectTidToConfig, selectScenario],
  (tids, tidToConfig, scenario) => {
    const result = {
      ...scenario,
      tids,
      tidToConfig,
      sbVersion,
    };
    return result;
  }
);

export const selectPlaythroughToPersist = createSelector(
  [selectTids, selectTidToConfig, selectPlaythrough],
  (tids, tidToConfig, playthrough) => {
    const result = {
      ...playthrough,
      tids,
      tidToConfig,
    };
    return result;
  }
);

export const selectPivotHexId = createSelector(
  [selectSelectedTid, selectTidToConfig],
  (tid, tidToConfig) => {
    if (!tid) {
      return null;
    }
    const config = tidToConfig[tid];
    const { thingType, q, r } = config;
    if (thingType !== 'tile') {
      return null;
    }
    return getHexId(q, r);
  }
);
