export const changerDefinitions = {
  'flat-move-nw': {
    rotate: 210,
    localizedDescription: 'Move north-west',
    shortcut: 'q',
  },
  'pointy-move-nw': {
    rotate: 240,
    localizedDescription: 'Move north-west',
    shortcut: 'q',
  },
  'flat-move-n': {
    rotate: 270,
    localizedDescription: 'Move north',
    shortcut: 'w',
  },
  'free-move-t': {
    rotate: 270,
    localizedDescription: 'Move up',
    shortcut: 'w',
  },
  'flat-move-ne': {
    rotate: 330,
    localizedDescription: 'Move north-east',
    shortcut: 'e',
  },
  'pointy-move-ne': {
    rotate: 300,
    localizedDescription: 'Move north-east',
    shortcut: 'e',
  },
  'rotate-r': {
    symbol: '↻',
    localizedDescription: 'Rotate right',
    shortcut: 'r',
  },
  'rotate-l': {
    symbol: '↺',
    localizedDescription: 'Rotate left',
    shortcut: 't',
  },
  'pointy-move-w': {
    rotate: 180,
    localizedDescription: 'Move west',
    shortcut: 'a',
  },
  'free-move-l': {
    rotate: 180,
    localizedDescription: 'Move left',
    shortcut: 'a',
  },
  flip: { symbol: '↷', localizedDescription: 'Flip', shortcut: 'f' },
  'pointy-move-e': {
    rotate: 0,
    localizedDescription: 'Move east',
    shortcut: 'd',
  },
  'free-move-r': {
    rotate: 0,
    localizedDescription: 'Move right',
    shortcut: 'd',
  },
  clone: { symbol: '⊕', localizedDescription: 'Clone', shortcut: 'v' },
  'flat-move-sw': {
    rotate: 150,
    localizedDescription: 'Move south-west',
    shortcut: 'z',
  },
  'pointy-move-sw': {
    rotate: 120,
    localizedDescription: 'Move south-west',
    shortcut: 'z',
  },
  'flat-move-s': {
    rotate: 90,
    localizedDescription: 'Move south',
    shortcut: 's',
  },
  'free-move-b': {
    rotate: 90,
    localizedDescription: 'Move down',
    shortcut: 's',
  },
  'flat-move-se': {
    rotate: 30,
    localizedDescription: 'Move south-east',
    shortcut: 'c',
  },
  'pointy-move-se': {
    rotate: 60,
    localizedDescription: 'Move south-east',
    shortcut: 'c',
  },
  remove: { symbol: '☒', localizedDescription: 'Remove', shortcut: 'X' },
};
