import { thingTypeDescriptors } from './data/thingTypes';
import { getHexesCoveredByPattern } from './algorithms/hexes-covered-by-pattern';
import { englishMonsterNames } from './data/monsters';
import { tokenByName } from './data/tokens';

export function getHexId(q, r) {
  return `${q},${r}`;
}

export function getHexCoordsFromId(hexId) {
  return hexId.split(',').map((part) => Number.parseInt(part));
}

export function getTileLetter(imageName) {
  return imageName[0];
}

export function getRotatedHexCoordinates(q, r, rotation = 0) {
  switch (rotation) {
    case 0:
      return [q, r];
    case 1:
      return [-r, r + q];
    case 2:
      return [-(r + q), q];
    case 3:
      return [-q, -r];
    case 4:
      return [r, -(r + q)];
    case 5:
      return [r + q, -q];
    default:
      throw new Error(`Unrecognized rotation '${rotation}'`);
  }
}

function getPatternNameByThingConfig(config) {
  const { thingType, imageName } = config;
  if (thingType === 'tile') {
    return getTileLetter(imageName);
  } else {
    const thingTypeDescriptor = thingTypeDescriptors[thingType];
    return thingTypeDescriptor.offsetPolicy;
  }
}

export function getHexIdsCoveredByThing(config) {
  const patternName = getPatternNameByThingConfig(config);
  const { q, r, rotation } = config;
  const hexes = getHexesCoveredByPattern(patternName, q, r, rotation);
  return hexes;
}

export function shuffle(array) {
  // https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export function shuffled(array) {
  const result = [...array];
  shuffle(result);
  return result;
}

export function objectLength(obj) {
  return Object.keys(obj).length;
}

export function pickRandom(array) {
  return array[Math.floor(Math.random() * array.length)];
}

export function getHexTopsOfOrientation(orientation) {
  return orientation % 2 === 0 ? 'pointy' : 'flat';
}

export function memoized(fn) {
  const memo = new Map();
  return function(...args) {
    const key = args.map((x) => x.toString()).join(',');
    if (!memo.has(key)) {
      const result = fn(...args);
      memo.set(key, result);
      return result;
    }
    return memo.get(key);
  };
}

export function getDegrees(thingType, orientation, rotation) {
  const descriptor = thingTypeDescriptors[thingType];

  if (!descriptor.isGridRelative) {
    return 0;
  }

  return (orientation * 30 + rotation * 60) % 360;
}

export function getThingImageSource(thingType, imageName, orientation) {
  const thingTypeDescriptor = thingTypeDescriptors[thingType];
  const { imageStorageMethod, imageDir } = thingTypeDescriptor;
  const baseDir = imageDir || thingType;
  let dir;
  const hexTops = getHexTopsOfOrientation(orientation);
  switch (imageStorageMethod) {
    case 'flat-and-pointy':
      dir = `${baseDir}.${hexTops}`;
      break;
    case 'in-tile-dir':
      dir = 'tile';
      break;
    default:
      dir = baseDir;
      break;
  }
  return `/gh/${dir}/${imageName}.png`;
}

export function getThingDisplayName(tid, config) {
  if (config.name) {
    return config.name;
  }
  const match = /(.*):(\d+)/.exec(tid);

  if (match) {
    const thingType = match[1];
    const number = match[2];
    let base;
    if (englishMonsterNames[thingType]) {
      base = englishMonsterNames[thingType];
    } else if (tokenByName[thingType]) {
      base = tokenByName[thingType].displayName;
    } else {
      base = tid;
    }
    const result = `${base} #${number}`;
    return result;
  }
  return tid;
}

export function getCurrentDayIsoFormatted() {
  const date = new Date();
  return date.toISOString().split('T')[0];
}

export function setAddAll(bigSet, items) {
  for (const item of items) {
    bigSet.add(item);
  }
}

export function getThingsPlacedOnHexes(hexesSet, tidToConfig) {
  const result = [];

  for (const [tid, config] of Object.entries(tidToConfig)) {
    const { thingType } = config;
    if (thingType === 'tile') {
      continue;
    }
    const thingTypeDescriptor = thingTypeDescriptors[thingType];
    if (thingTypeDescriptor.positioning !== 'hex-positioning') {
      continue;
    }
    const thingHexes = getHexIdsCoveredByThing(config);
    let touches = false;
    for (const hex of thingHexes) {
      if (hexesSet.has(hex)) {
        touches = true;
        break;
      }
    }
    if (touches) {
      result.push(tid);
    }
  }

  return result;
}

export const isTruthy = (x) => (x ? true : false);

export function pick(array) {
  return array[Math.floor(Math.random() * array.length)];
}
