import React from 'react';
import { NotFound } from '../common';

export const SecretConstants = {
  locked: 'locked',
  open: 'open',
};

export const SecretContext = React.createContext(SecretConstants.locked);

export function Secret(props) {
  return (
    <SecretContext.Consumer>
    { value => {
      const hasAccess = value === SecretConstants.open;
      if (hasAccess) {
        return props.children;
      }
      if (props.notFound) {
        return <NotFound />;
      }
      return null;

    } }
    </SecretContext.Consumer>
  );
}
