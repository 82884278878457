import { pick } from '../utils';

// with thanks to https://online-generator.com/name-generator/team-name-generator.php

const nouns = [
  'Alliance',
  'Angels',
  'Beasts',
  'Brigade',
  'Brothers',
  'Buddies',
  'Bunch',
  'Conquerors',
  'Creatures',
  'Division',
  'Dogs',
  'Eagles',
  'Falcons',
  'Fiends',
  'Fighters',
  'Force',
  'Friends',
  'Horde',
  'Knights',
  'Legion',
  'Lords',
  'Masters',
  'Protectors',
  'Reapers',
  'Rebels',
  'Renegades',
  'Riders',
  'Runners',
  'Savages',
  'Slayers',
  'Spirits',
  'Squad',
  'Warriors',
];

const prefixes = [
  'Dark',
  'East',
  'Future',
  'Golden',
  'Midnight',
  'Mountain',
  'Night',
  'North',
  'Power',
  'South',
  'Sunset',
  'Stone',
  'West',
  'Wood',
];

const suffixes = [
  'Day',
  'Destruction',
  'the Kingdom',
  'Law',
  'Light',
  'Myth',
  'Peace',
  'Silence',
  'Steel',
  'the Temple',
  'the Tower',
  'the Trees',
  'the Wilderness',
  'the World',
  'the Heart',
  'Order',
];

export function generatePartyTitle() {
  if (Math.random() < 0.5) {
    return `${pick(prefixes)} ${pick(nouns)}`;
  } else {
    return `${pick(nouns)} of ${pick(suffixes)}`;
  }
}
