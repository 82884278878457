const imageCache = {};

const dataUrlCache = {};

// return a promise that resolves to an img element
function loadImage(src) {
  return new Promise((resolve, reject) => {
    if (imageCache[src]) {
      resolve(imageCache[src]);
      return;
    }

    const img = document.createElement('img');
    img.src = src;
    img.onload = function() {
      imageCache[src] = img;
      resolve(img);
    };
  });
}

export function loadAllImages(srcs) {
  return Promise.all([...srcs].map(loadImage));
}

export function addFromDomSync(containerElement) {
  if (!containerElement) {
    console.warn('No containerElement in addFromDomSync');
    return;
  }

  for (const img of containerElement.querySelectorAll('img')) {
    const src = img.dataset.src;
    if (!src) {
      continue;
    }
    imageCache[src] = img;
  }
}

export function getImageSync(src) {
  const result = imageCache[src];
  if (!result) {
    console.warn('getImageSync: No image loaded for src', src);
    return null;
  }
  return result;
}

function imgElementToDataUrl(img) {
  var c = document.createElement('canvas');
  c.width = img.width;
  c.height = img.height;
  const ctx = c.getContext('2d');
  ctx.drawImage(img, 0, 0);
  const result = c.toDataURL();
  return result;
}

export function getImageDataUrlSync(src) {
  const cached = dataUrlCache[src];
  if (cached) {
    return cached;
  }
  const img = imageCache[src];
  if (!img) {
    console.warn('getImageDataUrlSync: No image loaded for src', src);
    return;
  }
  const result = imgElementToDataUrl(img);
  dataUrlCache[src] = result;
  return result;
}
