import { thingTypeDescriptors } from '../data/thingTypes';
import { getSimpleHexCoords } from '../algorithms/hexUtils';
import {
  getHexTopsOfOrientation,
  getTileLetter,
  getDegrees,
  getThingImageSource,
  getHexCoordsFromId,
} from '../utils';
import { hexSize } from '../data/sizes';
import { selectExtent, selectOrientation } from './selectors';

// XXX should be calculated from hexSize.
const offsetByHexTops = {
  tile: {
    flat: { offsetX: -41, offsetY: -107 },
    pointy: { offsetX: -54, offsetY: -111 },
  },
  A: {
    flat: { offsetX: -141, offsetY: -107 },
    pointy: { offsetX: -155, offsetY: -111 },
  },
  C: {
    flat: { offsetX: -334, offsetY: -107 },
    pointy: { offsetX: -344, offsetY: -111 },
  },
  D: {
    flat: { offsetX: -329, offsetY: -107 },
    pointy: { offsetX: -350, offsetY: -111 },
  },
  E: {
    flat: { offsetX: -141, offsetY: -107 },
    pointy: { offsetX: -155, offsetY: -111 },
  },
  H: {
    flat: { offsetX: -141, offsetY: -107 },
    pointy: { offsetX: -155, offsetY: -111 },
  },
  J: {
    flat: { offsetX: -379, offsetY: -57 },
    pointy: { offsetX: -393, offsetY: -57 },
  },
  K: {
    flat: { offsetX: -535, offsetY: -110 },
    pointy: { offsetX: -551, offsetY: -111 },
  },
  M: {
    flat: { offsetX: -231, offsetY: -107 },
    pointy: { offsetX: -250, offsetY: -111 },
  },
  oneHex: {
    flat: { offsetX: 0, offsetY: 0 },
    pointy: { offsetX: -15, offsetY: -2 },
  },
  long: {
    flat: { offsetX: -16, offsetY: -5 },
    pointy: { offsetX: -36, offsetY: 0 },
  },
  triangle: {
    flat: { offsetX: -114, offsetY: -29 },
    pointy: { offsetX: -127, offsetY: -29 },
  },
};

const transformOrigins = {
  tile: [153, 224],
  A: [250, 224],
  C: [444, 224],
  D: [444, 224],
  E: [250, 224],
  H: [250, 224],
  J: [490, 166],
  K: [648, 226],
  M: [347, 224],
  long: [128, 116],
  triangle: [225, 142],
  oneHex: [hexSize / 2, hexSize / 2],
};

export const LAYERS = [
  { thingType: 'live-room', mode: 'gameplay', selectorOnly: true },
  { thingType: 'tile', mode: 'both-modes' },
  { thingType: 'corridor', mode: 'both-modes' },
  { thingType: 'corridor-long', mode: 'both-modes' },
  { thingType: 'difficult-terrain', mode: 'both-modes' },
  { thingType: 'difficult-terrain-long', mode: 'both-modes' },
  { thingType: 'hazardous-terrain', mode: 'both-modes' },
  { thingType: 'pressure-plate', mode: 'both-modes' },

  { thingType: 'door', mode: 'editor' },
  { thingType: 'trap', mode: 'editor' },
  { thingType: 'obstacle', mode: 'editor' },
  { thingType: 'obstacle-long', mode: 'editor' },
  { thingType: 'obstacle-triangle', mode: 'editor', manifestRank: 2 },
  { thingType: 'chest', mode: 'editor' },
  { thingType: 'coin', mode: 'editor' },

  { thingType: 'live-door', mode: 'gameplay' },
  { thingType: 'live-trap', mode: 'gameplay' },
  { thingType: 'live-obstacle', mode: 'gameplay' },
  { thingType: 'live-obstacle-long', mode: 'gameplay' },
  { thingType: 'live-obstacle-triangle', mode: 'gameplay' },
  { thingType: 'live-chest', mode: 'gameplay' },
  { thingType: 'live-coin', mode: 'gameplay' },

  { thingType: 'entrance', mode: 'both-modes' },
  { thingType: 'red-circle', mode: 'both-modes' },
  { thingType: 'custom-red-circle', mode: 'both-modes' },

  { thingType: 'monster', mode: 'editor', manifestRank: 0 },

  { thingType: 'live-monster', mode: 'gameplay' },
  { thingType: 'live-summons', mode: 'gameplay' },
  { thingType: 'live-pc', mode: 'gameplay' },
];

function createRepresentations(
  hideEntrance,
  mode,
  tids,
  tidToConfig,
  scenario,
  orientation
) {
  const conditions = {
    withDamage: {
      name: 'damage',
      x: 75,
      y: 75,
    },
    withPoison: {
      name: 'poison',
      x: 25,
      y: 25,
    },
    withWound: {
      name: 'wound',
      x: 125,
      y: 25,
    },
    withImmobilize: {
      name: 'immobilize',
      x: 125,
      y: 125,
    },
    withDisarm: {
      name: 'disarm',
      x: 25,
      y: 125,
    },
    withStun: {
      name: 'stun',
      x: 75,
      y: 150,
    },
    withMuddle: {
      name: 'muddle',
      x: 0,
      y: 75,
    },
    withInvisible: {
      name: 'invisible',
      x: 75,
      y: 50,
    },
    withStrengthen: {
      name: 'strengthen',
      x: 75,
      y: 100,
    },
  };

  function fits(layer) {
    if (!(layer.mode === mode || layer.mode === 'both-modes')) {
      return false;
    }
    if (hideEntrance && layer.thingType === 'entrance') {
      return false;
    }
    if (layer.selectorOnly) {
      return false;
    }
    return true;
  }

  const result = [];

  const hexTops = getHexTopsOfOrientation(orientation);
  for (const layer of LAYERS) {
    if (!fits(layer)) {
      continue;
    }
    for (const tid of tids) {
      const config = tidToConfig[tid];
      const { imageName, thingType } = config;
      if (thingType !== layer.thingType) {
        continue;
      }
      const descriptor = thingTypeDescriptors[thingType];
      if (!descriptor) {
        console.error('Unrecognized thingType: ', thingType, tid, config);
        continue;
      }
      const { q = 0, r = 0, isOpen, rotation } = config;
      const hexCoords = getSimpleHexCoords(q, r, orientation);

      const { offsetPolicy } = descriptor;

      let position, offset, transformOrigin;
      const additionalClassNames = [];

      if (offsetPolicy === 'lettered') {
        const letter = getTileLetter(imageName);
        offset =
          offsetByHexTops[letter] !== undefined
            ? offsetByHexTops[letter][hexTops]
            : offsetByHexTops.tile[hexTops];
        transformOrigin = transformOrigins[letter] || transformOrigins.tile;
      } else if (offsetPolicy === 'long') {
        offset = offsetByHexTops.long[hexTops];
        transformOrigin = transformOrigins.long;
      } else if (offsetPolicy === 'triangle') {
        offset = offsetByHexTops.triangle[hexTops];
        transformOrigin = transformOrigins.triangle;
      } else {
        offset = offsetByHexTops.oneHex[hexTops];
        transformOrigin = transformOrigins.oneHex;
      }
      const { offsetX = 0, offsetY = 0 } = offset;

      position = {
        left: hexCoords.x + offsetX,
        top: hexCoords.y + offsetY,
      };

      if (isOpen) {
        additionalClassNames.push('open-door');
      }

      const additionalClassName = additionalClassNames.join(' ');
      const degrees = getDegrees(thingType, orientation, rotation);
      const contents = [];

      function checkPattern(pattern) {
        if (!pattern) {
          return;
        }

        const imageNames = pattern
          .split('')
          .map((letter, index) => `mp-${letter}${index + 1}`);
        const dir = `monster-pattern.${hexTops}`;
        imageNames.forEach((imageName) => {
          contents.push({
            additionType: 'image',
            key: imageName,
            src: `/gh/${dir}/${imageName}.png`,
          });
        });
      }

      function checkVersion(version) {
        if (!version) {
          return;
        }
        const letter = version === 'normal' ? 'w' : 'y';
        const imageName = `mp-${letter}3`;
        const dir = `monster-pattern.${hexTops}`;
        contents.push({
          additionType: 'image',
          key: imageName,
          src: `/gh/${dir}/${imageName}.png`,
        });
      }

      function checkStandeeNumber(standeeNumber) {
        if (!standeeNumber) {
          return;
        }
        contents.push({
          key: 'standeeNumber',
          additionType: 'image',
          additionalClassName: 'standeeNumber',
          src: `/gh/red-circle/red-circle-${standeeNumber}.png`,
        });
      }

      function checkText(additionId, validator, options = {}) {
        const value = config[additionId];
        if (!value) {
          return;
        }
        if (validator && !validator(value)) {
          return;
        }
        contents.push({
          key: additionId,
          additionType: options.additionType || 'text',
          text: value,
          additionalClassName: additionId,
        });
      }

      function checkIcon(attr, props) {
        const value = config[attr];
        if (!value) {
          return;
        }
        const { name, x, y } = props;
        contents.push({
          key: attr,
          additionType: 'image',
          additionalClassName: 'icon',
          src: `/gh/icon-outlined/${name}.png`,
          x,
          y,
          width: 75,
          height: 75,
        });
      }

      checkPattern(config['pattern']);
      checkVersion(config['version']);
      checkStandeeNumber(config['standeeNumber']);
      checkText('label');
      checkText('hp');
      checkText('amount', (v) => v > 1);
      checkText('redCircleLabel', () => true, {
        additionType: 'redCircleText',
      });

      for (const [attr, props] of Object.entries(conditions)) {
        checkIcon(attr, props);
      }

      const src = getThingImageSource(thingType, imageName, orientation);
      result.push({
        src,
        tid,
        additionalClassName,
        ...position,
        transformOrigin,
        degrees,
        contents,
      });
    }
  }

  return result;
}

export function createPictureIntermediary(scenarioViewer) {
  const { scenario, playthrough, tids, tidToConfig, mode } = scenarioViewer;
  const fakeState = {
    scenarioViewer,
  };

  const orientation = selectOrientation(fakeState);

  const hideEntrance =
    mode === 'gameplay' && playthrough && playthrough.hideEntrance;

  const representations = createRepresentations(
    hideEntrance,
    mode,
    tids,
    tidToConfig,
    scenario,
    orientation
  );

  const extent = selectExtent(fakeState);
  const xs = [],
    ys = [];
  for (const hexId of extent.allHexIds) {
    const [q, r] = getHexCoordsFromId(hexId);
    const { x, y } = getSimpleHexCoords(q, r, orientation);
    xs.push(x);
    ys.push(y);
  }

  const hexTops = getHexTopsOfOrientation(orientation);
  let dmx, dmy, dxx, dxy;
  if (hexTops === 'pointy') {
    dmx = -hexSize / 4;
    dxx = hexSize + hexSize / 2;
    dmy = -hexSize / 2;
    dxy = hexSize + hexSize / 2;
  } else {
    dmx = -hexSize / 2;
    dxx = hexSize + hexSize / 2;
    dmy = -hexSize / 4;
    dxy = hexSize + hexSize / 2;
  }

  let minX = dmx,
    minY = dmy,
    maxX = dxx,
    maxY = dxy;

  if (xs.length > 0) {
    minX = Math.min(...xs) + dmx;
    minY = Math.min(...ys) + dmy;
    maxX = Math.max(...xs) + dxx;
    maxY = Math.max(...ys) + dxy;
  }

  const bounds = { minX, maxX, minY, maxY };
  const size = {
    width: maxX - minX,
    height: maxY - minY,
  };

  return {
    size,
    bounds,
    representations,
  };
}

export function getScenarioManifestData(scenario) {
  const { tids, tidToConfig } = scenario;

  const mode = 'editor';

  function fits(layer) {
    if (!(layer.mode === mode || layer.mode === 'both-modes')) {
      return false;
    }
    if (layer.selectorOnly) {
      return false;
    }
    if (
      layer.thingType === 'door' ||
      layer.thingType === 'corridor' ||
      layer.thingType === 'corridor-long' ||
      layer.thingType === 'coin' ||
      layer.thingType === 'red-circle' ||
      layer.thingType === 'custom-red-circle' ||
      layer.thingType === 'entrance'
    ) {
      return false;
    }
    return true;
  }

  const perLayer = [];
  for (const layer of LAYERS) {
    if (!fits(layer)) {
      continue;
    }

    const { thingType, manifestRank = 1 } = layer;
    const counts = {};
    for (const tid of tids) {
      const config = tidToConfig[tid];
      if (config.thingType !== thingType) {
        continue;
      }

      const { imageName } = config;
      if (!counts[imageName]) {
        counts[imageName] = 0;
      }
      counts[imageName]++;
    }

    const thingCounters = [];

    for (const [imageName, count] of Object.entries(counts)) {
      let widthMultiplier = 1;
      let heightMultiplier = 1;
      const { offsetPolicy } = thingTypeDescriptors[thingType];
      if (offsetPolicy === 'long') {
        widthMultiplier = 2;
      } else if (offsetPolicy === 'triangle') {
        widthMultiplier = 2;
        heightMultiplier = 2;
      }

      const src = getThingImageSource(thingType, imageName, 0);
      const alternateSrc =
        offsetPolicy === 'triangle'
          ? getThingImageSource(thingType, `${imageName}.rot`, 0)
          : null;

      const thingCounter = {
        imageName,
        thingType,
        count,
        widthMultiplier,
        heightMultiplier,
        src,
        alternateSrc,
      };
      thingCounters.push(thingCounter);
    }
    thingCounters.sort((a, b) => a.imageName.localeCompare(b.imageName));
    perLayer.push({
      thingCounters,
      thingType,
      manifestRank,
    });
  }

  perLayer.sort((a, b) => a.manifestRank - b.manifestRank);

  const flat = [];
  const tiles = [];
  for (const { thingCounters, thingType } of perLayer) {
    for (const thingCounter of thingCounters) {
      if (thingType === 'tile') {
        tiles.push(thingCounter);
      } else {
        flat.push(thingCounter);
      }
    }
  }
  return {
    tiles,
    thingCounters: flat,
  };
}
