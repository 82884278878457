import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
  selectThing,
  unselectThing,
  startAddingThing,
  closeModal,
  addThingAuto,
  exportJson,
  startExportPng,
  onKeyPressed,
  showModal,
  activateTabByName,
  showPdfModal,
  regeneratePdf,
  downloadPdf,
  showCannotChangeTile,
} from './actions';
import { CustomLink, Button, BulmaModal, Loader } from '../common';
import {
  EditThingConfig,
  EditGlobalConfig,
  EditScenarioConfig,
  EditPlaythroughConfig,
} from './EditConfig';
import { tileNames as allTileNames } from '../data/tileNames';
import { allMonsterNames, englishMonsterNames } from '../data/monsters';
import { tokens } from '../data/tokens';
import {
  getHexId,
  objectLength,
  getDegrees,
  getThingImageSource,
  getThingDisplayName,
  memoized,
  getHexTopsOfOrientation,
} from '../utils';
import { hexNames } from '../data/hexNames';
import { getMonsterMaxHp } from '../data/monsters';
import { allProfessionsMap } from '../data/professions';
import { AmZone, AmPile, AbilityZone } from '../cardViews';
import { LAYERS, createPictureIntermediary } from './createPictureIntermediary';
import {
  getSimpleHexCoords,
  getHexMeasurements,
  hexes,
} from '../algorithms/hexUtils';
import { addFromDomSync } from '../imageLoader/imageLoader';
import {
  selectExtent,
  selectScenarioViewer,
  selectPivotHexId,
  selectOrientation,
} from './selectors';
import { defaultGridSize } from '../data/sizes';

// subtransform is either falsy; a string of a form `rotate(90deg)`; or an
// object like `{ translate: [-10, 10] }` with a subset of all transforms
// recognized.
function subtransformToArrayOfCssStrings(subtransform) {
  if (!subtransform) {
    return [];
  }

  if (typeof subtransform === 'string') {
    return [subtransform];
  }

  const result = [];
  for (const [tag, value] of Object.entries(subtransform)) {
    if (!value) {
      continue;
    }
    let string;
    switch (tag) {
      case 'rotate': {
        string = `rotate(${value}deg)`;
        break;
      }
      case 'scale': {
        string = `scale(${value})`;
        break;
      }
      case 'translate': {
        string = `translate(${value[0]}px, ${value[1]}px)`;
        break;
      }
      default: {
        console.warn('Unrecognized tag of subtransform', tag, subtransform);
        break;
      }
    }
    if (!string) {
      continue;
    }
    result.push(string);
  }
  return result;
}

const makeTransformStyle = (...subtransforms) => {
  const arrayOfArrayOfStrings = subtransforms.map(
    subtransformToArrayOfCssStrings
  );
  const arrayOfStrings = [].concat(...arrayOfArrayOfStrings);
  const transform = arrayOfStrings.join(' ');
  return {
    transform,
  };
};

const EditConfigForSelectedThing = (function() {
  function EditConfigForSelectedThing(props) {
    if (!props.tid) {
      return null;
    }

    return <EditThingConfig tid={props.tid} mode={props.mode} />;
  }

  const mapStateToProps = (state) => ({
    tid: state.scenarioViewer.selectedTid,
  });
  return connect(mapStateToProps)(EditConfigForSelectedThing);
})();

function HexU(props) {
  const { gridOpacity, highlight, label, isPivot, measurements } = props;

  let fillOpacity = 0;
  let stroke = 'blue';
  let fill = 'none';

  if (highlight) {
    fill = 'yellow';
    fillOpacity = 0.2;
  }

  if (isPivot) {
    fill = 'green';
    fillOpacity = 0.3;
  }

  return (
    <g>
      <polygon
        opacity={fillOpacity}
        fill={fill}
        strokeWidth="0"
        points={measurements.points}
      />
      <polygon
        opacity={gridOpacity}
        fill="none"
        stroke={stroke}
        strokeWidth="2"
        points={measurements.points}
      />
      <text
        x={measurements.textX}
        y={measurements.textY}
        stroke="black"
        opacity={gridOpacity}
        style={measurements.textStyle}
        textAnchor="middle"
      >
        {label}
      </text>
    </g>
  );
}

const Hex = React.memo(HexU);

const createGridHexes = memoized((orientation) => {
  const result = [];

  const hexTops = getHexTopsOfOrientation(orientation);

  for (const hex of hexes) {
    const { q, r } = hex;
    const hexCoords = getSimpleHexCoords(q, r, orientation);
    const { x, y } = hexCoords;
    const measurements = getHexMeasurements(x, y, hexTops);

    const gridHex = {
      id: getHexId(q, r),
      q,
      r,
      ...hexCoords,
      measurements,
    };
    result.push(gridHex);
  }

  return result;
});

function shouldHighlight(id, highlightExtent, extent) {
  return highlightExtent && extent && extent.walkableHexIds.has(id);
}

const getHexLabel = (id, hexLabels, hexIdToRoom) => {
  let label = '';
  switch (hexLabels) {
    case 'numbers':
      label = id;
      break;
    case 'words':
      label = hexNames[id];
      break;
    case 'roomNames': {
      if (hexIdToRoom) {
        label = hexIdToRoom[id];
      }
      break;
    }
    default: {
      console.warn('Unrecognized hexLabels setting:', hexLabels);
    }
  }
  return label;
};

const getGridOpacityFromProminence = memoized((gridProminence) => {
  let gridOpacity;
  if (gridProminence === 'none') {
    gridOpacity = 0.0;
  } else if (gridProminence === 'low') {
    gridOpacity = 0.3;
  } else if (gridProminence === 'high') {
    gridOpacity = 0.8;
  } else {
    gridOpacity = 0.8;
  }
  return gridOpacity;
});

const HexGrid = (function() {
  function HexGrid(props) {
    const { orientation, hexIdToRoom, global, extent, pivotHexId } = props;
    const { gridProminence, highlightExtent, hexLabels } = global;

    const hexes = createGridHexes(orientation);

    const gridOpacity = getGridOpacityFromProminence(gridProminence);

    return (
      <React.Fragment>
        {hexes.map((hex) => {
          const { id, measurements } = hex;
          const label = getHexLabel(id, hexLabels, hexIdToRoom);
          const highlight = shouldHighlight(id, highlightExtent, extent);
          const isPivot = id === pivotHexId;
          return (
            <Hex
              key={id}
              id={id}
              measurements={measurements}
              label={label}
              highlight={highlight}
              isPivot={isPivot}
              gridOpacity={gridOpacity}
            />
          );
        })}
      </React.Fragment>
    );
  }

  function mapStateToProps(state) {
    const scenarioViewer = selectScenarioViewer(state);
    const { scenario, playthrough, global } = scenarioViewer;
    const { highlightExtent } = global;
    const pivotHexId = selectPivotHexId(state);
    const orientation = selectOrientation(state);

    const extent = highlightExtent ? selectExtent(state) : null;
    let hexIdToRoom;
    if (playthrough) {
      hexIdToRoom = playthrough.hexIdToRoom;
    }

    return {
      scenario,
      orientation,
      hexIdToRoom,
      global,
      extent,
      pivotHexId,
    };
  }

  return connect(mapStateToProps)(HexGrid);
})();

const ThingSelector = (function() {
  function Things(props) {
    return props.tids.map((tid) => {
      const className = classNames('ThingSelector_Thing', {
        'is-active': tid === props.selectedTid,
      });
      const config = props.tidToConfig[tid];
      return (
        <li
          key={tid}
          onClick={() => props.selectThing(tid)}
          className={className}
        >
          {getThingDisplayName(tid, config)}
        </li>
      );
    });
  }

  function ThingSelector(props) {
    let addButtons;
    if (props.mode === 'editor') {
      addButtons = ['add-tile', 'add-token', 'add-monster'];
    } else if (props.mode === 'gameplay') {
      addButtons = ['add-live'];
    } else {
      console.error('Unrecognized ThingSelector mode:', props.mode);
      return null;
    }

    return (
      <div className="ThingSelector">
        <div className="vertical-buttons">
          {addButtons.map((kindForAdding) => {
            const descriptor = addButtonDescriptors[kindForAdding];
            const title = `(Shortcut: ${descriptor.shortcut})`;
            return (
              <Button
                key={kindForAdding}
                isSuccess
                onClick={() => props.onAddClicked(kindForAdding)}
                title={title}
              >
                {translateKindForAddingForHumans(kindForAdding)}
              </Button>
            );
          })}
        </div>
        <ul>
          {props.categories.map(({ type, tids }) => (
            <li key={type}>
              {type /* TODO for humans */}
              <ul>
                <Things
                  key={type}
                  tids={tids}
                  tidToConfig={props.tidToConfig}
                  selectThing={props.selectThing}
                  selectedTid={props.selectedTid}
                />
              </ul>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  function mapStateToProps(state, ownProps) {
    const { mode } = ownProps;
    const thingTypes = LAYERS.filter(
      (l) => l.mode === mode || l.mode === 'both-modes'
    ).map((l) => l.thingType);
    const byType = {};

    for (let thingType of thingTypes) {
      byType[thingType] = [];
    }

    const { tids, tidToConfig, selectedTid } = state.scenarioViewer;

    for (let tid of tids) {
      const config = tidToConfig[tid];
      const { thingType } = config;
      if (byType[thingType]) {
        byType[thingType].push(tid);
      } else {
        console.warn(
          'Thing type is not in layers by type:',
          thingType,
          tid,
          config
        );
      }
    }

    const categories = thingTypes
      .map((type) => ({
        type: type,
        tids: byType[type],
      }))
      .filter((category) => category.tids.length > 0);

    return {
      categories,
      selectedTid,
      tidToConfig,
    };
  }

  const mapDispatchToProps = (dispatch) => ({
    selectThing: (tid) => dispatch(selectThing(tid)),
    onAddClicked: (kindForAdding) => {
      dispatch(startAddingThing(kindForAdding));
    },
  });

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(ThingSelector);
})();

const ThingFromRepresentation = (function() {
  // Note: adding data-src to have full control over the value.  Getting
  // img.src might return an absolute url, while we're always querying by a
  // relative one.
  function renderBase(src, className, style, tid, props) {
    return (
      <img
        src={src}
        data-src={src}
        alt=""
        className={className}
        style={style}
      />
    );
  }

  function renderAddition(addition, tid) {
    const { additionType, key, additionalClassName } = addition;
    const className = classNames('addition', additionalClassName);
    if (additionType === 'image') {
      const { src, x, y } = addition;
      let style;
      if (x || y) {
        style = {
          left: x,
          top: y,
        };
      }
      return (
        <img
          key={key}
          data-src={`${src}`}
          style={style}
          alt=""
          className={className}
          src={src}
        />
      );
    } else if (additionType === 'text' || additionType === 'redCircleText') {
      const { text } = addition;
      return (
        <div key={key} className={className}>
          {text}
        </div>
      );
    }

    console.warn('Unrecognized additionType', additionType, addition);
    return null;
  }

  function ThingFromRepresentation(props) {
    const { representation, selectedTid } = props;
    const {
      src,
      tid,
      additionalClassName,
      left,
      top,
      degrees,
      contents,
      transformOrigin,
    } = representation;

    const containerClassName = classNames('thing-container', {
      'is-selected': tid === selectedTid,
    });

    const transformStyle = makeTransformStyle({
      translate: [left, top],
      rotate: degrees,
    });

    const style = {
      ...transformStyle,
      transformOrigin: transformOrigin.map((x) => `${x}px`).join(' '),
    };

    const counterRotateStyle = makeTransformStyle({
      rotate: -degrees,
    });

    return (
      <div
        className={containerClassName}
        data-tid={tid}
        onClick={(e) => {
          e.stopPropagation();
          props.onThingClicked(tid);
        }}
        style={style}
      >
        {renderBase(src, additionalClassName, {}, tid, props)}
        <div className="contents" style={counterRotateStyle}>
          {contents.map((addition) => renderAddition(addition, tid))}
        </div>
      </div>
    );
  }

  return ThingFromRepresentation;
})();

const PdfModalContent = (function() {
  function PdfModalContent(props) {
    const { pdfData, isGeneratingPdf, regeneratePdf, downloadPdf } = props;

    const embedStyle = { width: '100%', height: '100%', minHeight: 720 };

    const blobUrl = pdfData && pdfData.blobUrl;

    return (
      <div className="columns">
        <div className="column">
          <EditScenarioConfig
            onlyTextAttrs={true}
            textAttrs="full"
            onAfterChange={regeneratePdf}
          />
          <div className="buttons">
            <CustomLink
              className="button is-dark"
              href={`#pdf`}
              onClick={downloadPdf}
              disabled={isGeneratingPdf}
            >
              Download PDF
            </CustomLink>
          </div>
        </div>
        <div className="column">
          {isGeneratingPdf && <Loader />}
          {blobUrl && (
            <embed
              style={embedStyle}
              src={blobUrl}
              className="pdf-preview"
              type="application/pdf"
            />
          )}
        </div>
      </div>
    );
  }

  function mapStateToProps(state) {
    const { scenarioViewer } = state;
    const { isGeneratingPdf, pdfData } = scenarioViewer;
    return {
      isGeneratingPdf,
      pdfData,
    };
  }

  const mapDispatchToProps = { regeneratePdf, downloadPdf };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(PdfModalContent);
})();

const Main = (function() {
  const Main = (props) => {
    const {
      scale,
      unselectThing,
      intermediary,
      orientation,
      onThingClicked,
      selectedTid,
    } = props;
    const { size, bounds, representations } = intermediary;
    const { width, height } = size;
    const { minX, minY } = bounds;
    const contentStyle = makeTransformStyle({ scale });
    const viewportStyle = {
      width: Math.max(defaultGridSize * scale, width * scale),
      height: Math.max(defaultGridSize * scale, height * scale),
    };
    const thingsStyle = makeTransformStyle({ translate: [-minX, -minY] });

    return (
      <div className="Main tile is-child" onClick={unselectThing}>
        <div className="Container" onClick={props.onClick}>
          <div className="Viewport" style={viewportStyle}>
            <div className="Content" style={contentStyle}>
              <div className="Things" style={thingsStyle}>
                {representations.map((rep) => (
                  <ThingFromRepresentation
                    key={rep.tid}
                    representation={rep}
                    orientation={orientation}
                    selectedTid={selectedTid}
                    onThingClicked={onThingClicked}
                  />
                ))}
              </div>
              <svg
                width={viewportStyle.width}
                height={viewportStyle.height}
                viewBox={`${minX} ${minY} ${viewportStyle.width} ${viewportStyle.height}`}
              >
                <HexGrid />
              </svg>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const mapStateToProps = (state, ownProps) => {
    const scenarioViewer = selectScenarioViewer(state);
    const intermediary = createPictureIntermediary(scenarioViewer);

    const { selectedTid, global } = scenarioViewer;
    const { scale } = global;
    const orientation = selectOrientation(state);

    return {
      intermediary,
      selectedTid,
      scale,
      orientation,
    };
  };

  const mapDispatchToProps = (dispatch) => ({
    onThingClicked: (tid) => dispatch(selectThing(tid)),
    unselectThing: () => dispatch(unselectThing()),
  });

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(Main);
})();

function Sidebar(props) {
  const className = classNames(
    'Sidebar tile is-child',
    props.isBig ? 'is-3' : 'is-2'
  );
  return <div className={className}>{props.children}</div>;
}

// Tile ids are [LNs], where L is letter, N is number, side is [ab].  This
// function returns the same tid on opposite side.
function getFlippedTid(tid) {
  return tid[0] + tid[1] + (tid[2] === 'a' ? 'b' : 'a');
}

const addButtonDescriptors = {
  'add-tile': {
    localizedName: 'Add tile',
    shortcut: 'A',
  },
  'add-token': {
    localizedName: 'Add token',
    shortcut: 'T',
  },
  'add-monster': {
    localizedName: 'Add monster',
    shortcut: 'M',
  },
  'add-live': {
    localizedName: 'Add thing',
    shortcut: 'A',
  },
};

function translateKindForAddingForHumans(kindForAdding) {
  const descriptor = addButtonDescriptors[kindForAdding];
  if (!descriptor) {
    console.error('Unrecognized kind for adding: ', kindForAdding);
    return '?';
  }
  return descriptor.localizedName;
}

const AddThingModalContent = (function() {
  function getThingDisplayName(thing) {
    return thing.displayName || thing.name;
  }

  class AddThingModalContent extends React.PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        searchText: '',
        filteredThings: this.props.things,
      };
    }

    calculateFilteredThings(searchText) {
      searchText = searchText.trim().toLowerCase();

      function matches(thing) {
        return (
          getThingDisplayName(thing)
            .toLowerCase()
            .indexOf(searchText) !== -1
        );
      }

      const result =
        searchText.length === 0
          ? this.props.things
          : this.props.things.filter(matches);

      return result;
    }

    setSearchText = (e) => {
      const searchText = e.target.value;

      const filteredThings = this.calculateFilteredThings(searchText);
      this.setState({
        searchText,
        filteredThings,
      });
    };

    thingIsDisabled = (thing) => {
      const { disabledThingNames } = this.props;
      if (!disabledThingNames) {
        return false;
      }
      return disabledThingNames.has(thing.name);
    };

    renderThing(thing) {
      const isDisabled = this.thingIsDisabled(thing);
      const onClick = isDisabled
        ? undefined
        : () => this.props.onThingSelected(thing);
      const className = classNames({ disabled: isDisabled });
      return (
        <li key={thing.name} onClick={onClick} className={className}>
          {this.props.renderPreviewContent(thing, this.props.orientation)}
          <div className="thing-name">{getThingDisplayName(thing)}</div>
        </li>
      );
    }

    handleSubmit = (e) => {
      e.preventDefault();
      const { filteredThings } = this.state;

      for (let thing of filteredThings) {
        if (!this.thingIsDisabled(thing)) {
          this.props.onThingSelected(thing);
          break;
        }
      }
    };

    render() {
      const things = this.state.filteredThings;

      return (
        <div className="NewThingSelector">
          <div className="controls">
            <div className="header">
              {translateKindForAddingForHumans(this.props.kindForAdding)}
            </div>
            <form onSubmit={this.handleSubmit}>
              <input
                type="text"
                placeholder="name"
                autoFocus
                value={this.state.searchText}
                onChange={this.setSearchText}
              />
            </form>
          </div>
          {things.length === 0 ? (
            <div>No matching things found.</div>
          ) : (
            <ul>{things.map((thing) => this.renderThing(thing))}</ul>
          )}
        </div>
      );
    }
  }

  return AddThingModalContent;
})();

function renderTilePreviewContent(thing, orientation) {
  const { name } = thing;
  const degrees = getDegrees('tile', orientation, 0);
  const style = makeTransformStyle({ rotate: degrees });
  return (
    <img style={style} alt={`${name}`} src={`/gh/tile.preview/${name}.png`} />
  );
}

const AddTileModalContent = (function() {
  function AddTileModalContent(props) {
    const things = allTileNames.map((tileName) => {
      return {
        name: tileName,
        thingType: 'tile',
      };
    });

    return (
      <AddThingModalContent
        orientation={props.orientation}
        kindForAdding="add-tile"
        things={things}
        disabledThingNames={props.disabledTids}
        renderPreviewContent={renderTilePreviewContent}
        onThingSelected={props.onTileSelected}
      />
    );
  }

  function mapStateToProps(state) {
    const { tids, tidToConfig } = state.scenarioViewer;

    const existingTids = tids.filter(
      (tid) => tidToConfig[tid].thingType === 'tile'
    );

    const disabledTids = new Set();

    for (let tid of existingTids) {
      disabledTids.add(tid);
      disabledTids.add(getFlippedTid(tid));
    }

    return {
      disabledTids,
    };
  }

  return connect(mapStateToProps)(AddTileModalContent);
})();

function renderGenericPreviewContent(thing, orientation) {
  const { thingType, name } = thing;
  const degrees = getDegrees(thingType, orientation, 0);

  let style = undefined;

  if (degrees) {
    style = makeTransformStyle({ rotate: degrees });
  }

  return (
    <img
      alt={`${name}`}
      style={style}
      src={getThingImageSource(thingType, name, orientation)}
    />
  );
}

function AddMonsterModalContent(props) {
  const things = allMonsterNames.map((name) => {
    return {
      thingType: 'monster',
      name,
      displayName: englishMonsterNames[name],
    };
  });
  return (
    <AddThingModalContent
      orientation={props.orientation}
      kindForAdding="add-monster"
      things={things}
      renderPreviewContent={renderGenericPreviewContent}
      onThingSelected={props.onSelected}
    />
  );
}

function AddTokenModalContent(props) {
  // TODO consider component limits.
  return (
    <AddThingModalContent
      orientation={props.orientation}
      kindForAdding="add-token"
      things={tokens}
      renderPreviewContent={renderGenericPreviewContent}
      onThingSelected={props.onSelected}
    />
  );
}

function GeneralIcon(props) {
  const { iconName } = props;
  return (
    <span className="icon is-small">
      <img alt={`${iconName} icon`} src={`/gh/icon/${iconName}.png`} />
    </span>
  );
}

function Section(props) {
  return <section className="PcAreaSection">{props.children}</section>;
}

const PcAreaContent = (function() {
  function PcAreaContent(props) {
    const { pcArea } = props;

    if (!pcArea) {
      return <div>n/a</div>;
    }

    const {
      abilityHand = [],
      abilityCurrent = [],
      abilityActive = [],
      abilityDiscard = [],
      abilityLost = [],
      handSelection = {},
      currentSelection = {},
      activeSelection = {},
      discardSelection = {},
      lostSelection = {},
    } = pcArea;
    const { amDeck = [], amActive = [], amDiscard = [] } = pcArea;

    const nSelectedHand = objectLength(handSelection);
    const disablePlay =
      nSelectedHand === 0 || nSelectedHand + abilityCurrent.length > 2;

    const disableLoseFromHand = nSelectedHand !== 1;
    const disableDiscardFromHand = nSelectedHand === 0;

    const nSelectedCurrent = objectLength(currentSelection);
    const nSelectedActive = objectLength(activeSelection);
    const nSelectedPlayed = nSelectedCurrent + nSelectedActive;

    const nSelectedDiscard = objectLength(discardSelection);
    const nSelectedLost = objectLength(lostSelection);

    const disableActivateCurrent = nSelectedCurrent === 0;

    const disableDiscardPlayed = nSelectedPlayed === 0;
    const disableLosePlayed = nSelectedPlayed === 0;

    const disableIncreaseCharge = nSelectedActive === 0;
    const disableDecreaseCharge = nSelectedActive === 0;

    const disableRecoverFromDiscard = nSelectedDiscard === 0;
    const disableRecoverFromLost = nSelectedLost === 0;

    const disableRest = !(nSelectedDiscard === 1 && abilityDiscard.length >= 2);
    const disableLoseFromDiscard = nSelectedDiscard !== 2;

    const disableSelectRandom = abilityDiscard.length === 0;

    return (
      <div className="PcAreaContent container is-fluid columns">
        <div className="column Abilities">
          <Section>
            <AbilityZone
              isTopRow
              cids={abilityHand}
              selectedCidsMap={handSelection}
              onCardClick={props.onAbilityHandCardClicked}
            />
            <div className="buttons is-centered">
              <Button
                isPrimary
                disabled={disablePlay}
                onClick={props.onAbilityPlay}
              >
                Play
              </Button>
              <Button
                isDanger
                disabled={disableLoseFromHand}
                onClick={props.onAbilityLoseFromHand}
              >
                Lose to damage
              </Button>
              <Button
                isWarning
                disabled={disableDiscardFromHand}
                onClick={props.onAbilityDiscardFromHand}
              >
                Discard
              </Button>
            </div>
          </Section>
          <Section>
            <div className="columns">
              <div className="column is-narrow">
                <AbilityZone
                  Two
                  cids={abilityCurrent}
                  selectedCidsMap={currentSelection}
                  onCardClick={props.onAbilityCurrentCardClicked}
                />
                <div className="buttons is-centered">
                  <Button
                    isInfo
                    disabled={disableActivateCurrent}
                    onClick={props.onAbilityActivateCurrent}
                  >
                    Activate
                  </Button>
                </div>
              </div>
              <div className="centered-in-row column is-narrow">
                <div className="vertical-buttons">
                  <Button
                    isPrimary
                    disabled={disableDiscardPlayed}
                    onClick={props.onAbilityDiscardPlayed}
                  >
                    Discard
                  </Button>
                  <Button
                    isPrimary
                    disabled={disableLosePlayed}
                    onClick={props.onAbilityLosePlayed}
                  >
                    Lose
                  </Button>
                </div>
              </div>
              <div className="column">
                <AbilityZone
                  cids={abilityActive}
                  selectedCidsMap={activeSelection}
                  onCardClick={props.onAbilityActiveCardClicked}
                />
                <div className="buttons is-centered">
                  <Button
                    disabled={disableIncreaseCharge}
                    onClick={props.onAbilityIncreaseCharge}
                  >
                    + Charge
                  </Button>
                  <Button
                    disabled={disableDecreaseCharge}
                    onClick={props.onAbilityDecreaseCharge}
                  >
                    - Charge
                  </Button>
                </div>
              </div>
            </div>
          </Section>
          <Section>
            <div className="columns">
              <div className="column">
                <AbilityZone
                  isSquished
                  cids={abilityDiscard}
                  selectedCidsMap={discardSelection}
                  onCardClick={props.onAbilityDiscardCardClicked}
                />
                <div className="buttons is-centered">
                  <Button
                    disabled={disableSelectRandom}
                    onClick={props.onAbilitySelectRandom}
                  >
                    Select random
                  </Button>
                  <Button
                    isPrimary
                    disabled={disableRest}
                    onClick={props.onAbilityRest}
                  >
                    Rest
                  </Button>
                  <Button
                    isDanger
                    disabled={disableLoseFromDiscard}
                    onClick={props.onAbilityLoseFromDiscard}
                  >
                    Lose 2 to damage
                  </Button>
                  <Button
                    isWarning
                    disabled={disableRecoverFromDiscard}
                    onClick={props.onAbilityRecoverFromDiscard}
                  >
                    Recover
                  </Button>
                </div>
              </div>
              <div className="column">
                <AbilityZone
                  isSquished
                  cids={abilityLost}
                  selectedCidsMap={lostSelection}
                  onCardClick={props.onAbilityLostCardClicked}
                />
                <div className="buttons is-centered">
                  <Button
                    isWarning
                    disabled={disableRecoverFromLost}
                    onClick={props.onAbilityRecoverFromLost}
                  >
                    Recover
                  </Button>
                </div>
              </div>
            </div>
          </Section>
        </div>
        <div className="column is-one-third AttackModifiers">
          <Section>
            <AmPile size={amDeck.length} />
            <div className="buttons">
              <Button isPrimary onClick={props.drawOneWithAllRolling}>
                Attack with 1
              </Button>
              <Button isPrimary onClick={props.drawTwoNew}>
                Attack with 2
              </Button>
              <Button isInfo onClick={props.shuffle}>
                Shuffle
              </Button>
            </div>
            <div className="buttons">
              <Button isSuccess onClick={props.addBless}>
                <GeneralIcon iconName="bless" />
                <span>Add Bless</span>
              </Button>
              <Button isSuccess onClick={props.addCurse}>
                <GeneralIcon iconName="curse" />
                <span>Add Curse</span>
              </Button>
              <Button isInfo onClick={props.discard}>
                Discard
              </Button>
            </div>
            <AmZone cids={amActive} />
            <AmZone isDiscard cids={amDiscard} />
          </Section>
        </div>
      </div>
    );
  }

  function mapStateToProps(state, ownProps) {
    const { pcId } = ownProps;

    const playthrough = state.scenarioViewer.playthrough;
    const { pcAreas } = playthrough;
    const pcArea = pcAreas[pcId];

    return {
      pcArea,
    };
  }

  function mapDispatchToProps(dispatch, ownProps) {
    const { pcId } = ownProps;

    const dispatchSubaction = (subaction) => {
      dispatch({
        type: 'PC_AREA_SUBACTION',
        pcId,
        subaction,
      });
    };

    return {
      drawOneWithAllRolling: () =>
        dispatchSubaction({
          part: 'am',
          type: 'DRAW_ONE_WITH_ALL_ROLLING',
        }),
      drawTwoNew: () =>
        dispatchSubaction({
          part: 'am',
          type: 'DRAW_TWO_NEW',
        }),
      drawOneMore: () =>
        dispatchSubaction({
          part: 'am',
          type: 'DRAW_ONE_MORE',
        }),
      discard: () =>
        dispatchSubaction({
          part: 'am',
          type: 'DISCARD',
        }),
      shuffle: () =>
        dispatchSubaction({
          part: 'am',
          type: 'SHUFFLE',
        }),
      addBless: () =>
        dispatchSubaction({
          part: 'am',
          type: 'ADD_BLESS',
        }),
      addCurse: () =>
        dispatchSubaction({
          part: 'am',
          type: 'ADD_CURSE',
        }),

      onAbilityHandCardClicked: (cid) =>
        dispatchSubaction({
          part: 'ability',
          type: 'HAND_CARD_CLICKED',
          cid,
        }),
      onAbilityPlay: () =>
        dispatchSubaction({
          part: 'ability',
          type: 'PLAY',
        }),
      onAbilityDiscardFromHand: () =>
        dispatchSubaction({
          part: 'ability',
          type: 'DISCARD_FROM_HAND',
        }),
      onAbilityLoseFromHand: () =>
        dispatchSubaction({
          part: 'ability',
          type: 'LOSE_FROM_HAND',
        }),
      onAbilityCurrentCardClicked: (cid) =>
        dispatchSubaction({
          part: 'ability',
          type: 'CURRENT_CARD_CLICKED',
          cid,
        }),
      onAbilityActiveCardClicked: (cid) =>
        dispatchSubaction({
          part: 'ability',
          type: 'ACTIVE_CARD_CLICKED',
          cid,
        }),
      onAbilityDiscardCardClicked: (cid) =>
        dispatchSubaction({
          part: 'ability',
          type: 'DISCARD_CARD_CLICKED',
          cid,
        }),
      onAbilityLostCardClicked: (cid) =>
        dispatchSubaction({
          part: 'ability',
          type: 'LOST_CARD_CLICKED',
          cid,
        }),
      onAbilityActivateCurrent: () =>
        dispatchSubaction({
          part: 'ability',
          type: 'ACTIVATE_CURRENT',
        }),
      onAbilityDiscardPlayed: () =>
        dispatchSubaction({
          part: 'ability',
          type: 'DISCARD_PLAYED',
        }),
      onAbilityLosePlayed: () =>
        dispatchSubaction({
          part: 'ability',
          type: 'LOSE_PLAYED',
        }),
      onAbilityIncreaseCharge: () =>
        dispatchSubaction({
          part: 'ability',
          type: 'INCREASE_CHARGE',
        }),
      onAbilityDecreaseCharge: () =>
        dispatchSubaction({
          part: 'ability',
          type: 'DECREASE_CHARGE',
        }),
      onAbilityRecoverFromDiscard: () =>
        dispatchSubaction({
          part: 'ability',
          type: 'RECOVER_FROM_DISCARD',
        }),
      onAbilityRecoverFromLost: () =>
        dispatchSubaction({
          part: 'ability',
          type: 'RECOVER_FROM_LOST',
        }),
      onAbilitySelectRandom: () =>
        dispatchSubaction({
          part: 'ability',
          type: 'SELECT_RANDOM',
        }),
      onAbilityRest: () =>
        dispatchSubaction({
          part: 'ability',
          type: 'REST',
        }),
      onAbilityLoseFromDiscard: () =>
        dispatchSubaction({
          part: 'ability',
          type: 'LOSE_FROM_DISCARD',
        }),
    };
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(PcAreaContent);
})();

function NavbarLinkItem(props) {
  if (props.isVisible !== undefined && !props.isVisible) {
    return null;
  }

  return (
    <CustomLink
      href={props.href}
      className="navbar-item"
      title={props.title}
      onClick={props.onClick}
    >
      {props.text}
    </CustomLink>
  );
}

function NavbarTab(props) {
  return (
    <CustomLink
      href={`#${props.name}`}
      onClick={() => props.onActivateTab(props.name)}
      className={classNames('navbar-item is-tab', {
        'is-active': props.name === props.activeTabName,
      })}
    >
      {props.children}
    </CustomLink>
  );
}

function TabContent(props) {
  const { tabName, activeTabName } = props;
  const className = classNames({ 'is-hidden': tabName !== activeTabName });
  return <div className={className}>{props.children}</div>;
}

const ScenarioConfigAndExport = (function() {
  function onPdfClick(props) {
    addFromDomSync(props.mainRef.current);
    props.showPdfModal();
  }

  function ScenarioConfigAndExport(props) {
    const pngClassName = classNames('button is-light', {
      'is-loading': props.isExportingPng,
    });
    const { textAttrs, textCallback, exportJson, startExportPng } = props;
    return (
      <>
        <EditScenarioConfig textAttrs={textAttrs} textCallback={textCallback} />
        <div className="vertical-buttons">
          <CustomLink
            className="button is-light"
            href={`#json`}
            onClick={exportJson}
          >
            Download JSON
          </CustomLink>
          <CustomLink
            className={pngClassName}
            href={`#png`}
            onClick={startExportPng}
          >
            Download PNG
          </CustomLink>
          <CustomLink
            className="button is-info"
            onClick={() => onPdfClick(props)}
          >
            Show PDF preview
          </CustomLink>
        </div>
      </>
    );
  }

  function mapStateToProps(state) {
    return {
      isExportingPng: state.scenarioViewer.isExportingPng,
    };
  }

  const mapDispatchToProps = { exportJson, startExportPng, showPdfModal };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(ScenarioConfigAndExport);
})();

export const ScenarioViewer = (function() {
  class ScenarioViewer extends React.PureComponent {
    constructor(props) {
      super(props);
      this.mainRef = React.createRef();
      this.state = {
        menuActive: false,
      };
      this.mainRef = React.createRef();
    }

    renderAddThingModalContent(modal) {
      const { orientation } = this.props;

      const { kindForAdding } = modal;

      if (
        kindForAdding === 'add-tile' ||
        kindForAdding === 'add-tile-freeform'
      ) {
        return (
          <AddTileModalContent
            orientation={orientation}
            onTileSelected={this.handleAddTileSelected}
          />
        );
      } else if (kindForAdding === 'add-monster') {
        return (
          <AddMonsterModalContent
            orientation={orientation}
            onSelected={this.handleAddThingSelected}
          />
        );
      } else if (kindForAdding === 'add-token') {
        return (
          <AddTokenModalContent
            orientation={orientation}
            onSelected={this.handleAddThingSelected}
          />
        );
      } else if (kindForAdding === 'add-live') {
        // TODO move this code to some place where it belongs
        const { level } = this.props.playthrough;
        const monsterTemplates = {};
        for (const config of Object.values(
          this.props.stateScenario.tidToConfig
        )) {
          if (config.thingType !== 'monster') {
            continue;
          }
          const { imageName: name } = config;
          if (monsterTemplates[name]) {
            continue;
          }
          const version = 'normal';
          const template = {
            thingType: 'live-monster',
            name,
            version,
            hp: getMonsterMaxHp(name, version, level),
            // TODO get available standee; remove standee from pool on add
            standeeNumber: 1,
          };
          monsterTemplates[name] = template;
        }
        const smt = Object.values(monsterTemplates).sort(
          (a, b) => a.name < b.name
        );

        const things = [
          { thingType: 'live-summons', name: 'summons' },
          { thingType: 'live-obstacle', name: 'boulder-1h' },
          // TODO go over scenario traps and copy the default values
          { thingType: 'live-trap', name: 'spike-trap' },
          { thingType: 'live-coin', name: 'coin' },
          ...smt,
        ];

        return (
          <AddThingModalContent
            orientation={orientation}
            kindForAdding="add-live"
            things={things}
            renderPreviewContent={renderGenericPreviewContent}
            onThingSelected={this.handleAddThingSelected}
          />
        );
      } else {
        console.warn(
          'Unsupported kindForAdding of ADD_THING modal',
          kindForAdding,
          modal
        );
        return <div>Not implemented yet.</div>;
      }
    }

    renderGlobalConfigModalContent() {
      return (
        <>
          <div className="columns">
            <div className="column is-narrow-desktop">
              <EditGlobalConfig />
            </div>
          </div>
        </>
      );
    }

    renderScenarioConfigModalContent(modal) {
      const { options = {} } = modal;
      const { isBodyOnly = false } = options;
      return (
        <>
          <div className="columns">
            {!isBodyOnly && (
              <div className="column is-narrow-desktop">
                <ScenarioConfigAndExport
                  textAttrs="skip"
                  mainRef={this.mainRef}
                />
              </div>
            )}
            <div className="column">
              <EditScenarioConfig onlyTextAttrs={true} textAttrs="full" />
            </div>
          </div>
        </>
      );
    }

    renderPlaythroughConfigModalContent() {
      return (
        <>
          <div className="columns">
            <div className="column is-narrow-desktop">
              <EditPlaythroughConfig />
            </div>
          </div>
        </>
      );
    }

    renderPdfModalContent() {
      return <PdfModalContent />;
    }

    renderModalContent() {
      const { modal } = this.props;
      if (modal === null) {
        return;
      }
      const { modalKind } = modal;
      switch (modalKind) {
        case 'ADD_THING':
          return this.renderAddThingModalContent(modal);
        case 'GLOBAL_CONFIG':
          return this.renderGlobalConfigModalContent(modal);
        case 'SCENARIO_CONFIG':
          return this.renderScenarioConfigModalContent(modal);
        case 'PLAYTHROUGH_CONFIG':
          return this.renderPlaythroughConfigModalContent(modal);
        case 'PDF_MODAL':
          return this.renderPdfModalContent(modal);
        default: {
          console.warn('Unrecognized modalKind of modal', modal);
          return <div>Warning: unrecognized modal kind: {modalKind}.</div>;
        }
      }
    }

    renderNavbarTabs = () => {
      const { activeTabName, playthrough } = this.props;
      const { party } = playthrough;

      return (
        <>
          <NavbarTab
            name="map"
            activeTabName={activeTabName}
            onActivateTab={this.props.onActivateTab}
          >
            Map
          </NavbarTab>
          {party.pcs.map((pc) => {
            const profession = allProfessionsMap[pc.profession];
            return (
              <NavbarTab
                key={pc.id}
                name={pc.id}
                activeTabName={activeTabName}
                onActivateTab={this.props.onActivateTab}
              >
                <span className="icon is-small is-inverted cc">
                  <img
                    alt={profession.name}
                    src={`/gh/class-icons/${profession.iconName}`}
                  />
                </span>
                <span> </span>
                {pc.name}
              </NavbarTab>
            );
          })}
        </>
      );
    };

    renderPcTabContents = () => {
      const { activeTabName, playthrough } = this.props;
      const { party } = playthrough;

      return (
        <>
          {party.pcs.map((pc) => {
            const { id } = pc;
            return (
              <TabContent key={id} tabName={id} activeTabName={activeTabName}>
                <PcAreaContent pcId={id} />
              </TabContent>
            );
          })}
        </>
      );
    };

    handleKeyPress = (event) => {
      const nodeName = event.target.nodeName;
      if (nodeName === 'INPUT' || nodeName === 'TEXTAREA') {
        return;
      }
      if (event.altKey || event.ctrlKey || event.metaKey) {
        return;
      }

      this.props.onKeyPressed(event.key);
    };

    componentDidMount() {
      document
        .getElementsByTagName('body')[0]
        .classList.add('has-navbar-fixed-top');
      document.addEventListener('keypress', this.handleKeyPress, false);
    }

    componentWillUnmount() {
      document
        .getElementsByTagName('body')[0]
        .classList.remove('has-navbar-fixed-top');
      document.removeEventListener('keypress', this.handleKeyPress, false);
    }

    componentDidUpdate(prevProps, prevState) {
      if (
        prevProps.shouldFocusHitpoints !== this.props.shouldFocusHitpoints &&
        this.props.shouldFocusHitpoints
      ) {
        const inputs = this.mainRef.current.getElementsByClassName('input');
        const input = [...inputs].find((el) => el.dataset.setting === 'hp');
        if (input) {
          input.focus();
        }
        this.props.doneFocusHitpoints();
      }

      if (prevProps.showCannotChangeTile !== this.props.showCannotChangeTile) {
        const { tid, show } = this.props.showCannotChangeTile;

        const elements = this.mainRef.current.getElementsByClassName(
          'thing-container'
        );
        const element = [...elements].find((el) => el.dataset.tid === tid);

        if (element) {
          const fnName = show ? 'add' : 'remove';
          element.classList[fnName]('deny');
        }

        if (show) {
          window.setTimeout(
            () => this.props.doneShowCannotChangeTile(tid),
            300 // XXX duplication with .deny animation in css
          );
        }
      }
    }

    toggleMenu = (e) => {
      e.preventDefault();
      this.setState((state) => ({ menuActive: !state.menuActive }));
    };

    onMenuItemClicked = (handlerFnName) => {
      this.setState((state) => ({ menuActive: false }));
      this.props[handlerFnName]();
    };

    render() {
      if (!this.props.initialized) {
        return <Loader />;
      }

      return (
        <div className="ScenarioViewer" ref={this.mainRef}>
          <nav
            className="navbar is-dark is-fixed-top"
            role="navigation"
            aria-label="main navigation"
          >
            <div className="navbar-brand">
              <h1 className="logo navbar-item is-marginless">
                <Link to="/" className="">
                  Silent Bridge
                </Link>
              </h1>
              <a
                href="#menu"
                onClick={this.toggleMenu}
                className={classNames('navbar-burger burger', {
                  'is-active': this.state.menuActive,
                })}
              >
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </a>
            </div>
            <div
              className={classNames('navbar-menu', {
                'is-active': this.state.menuActive,
              })}
            >
              <div className="navbar-start">
                {this.props.mode === 'gameplay' && this.renderNavbarTabs()}
              </div>
              <div className="navbar-end">
                <NavbarLinkItem
                  href="#global"
                  title="Show global editor settings"
                  onClick={() =>
                    this.onMenuItemClicked('showGlobalConfigModal')
                  }
                  text="Global"
                />
                <NavbarLinkItem
                  href="#scenario"
                  title="Show scenario settings"
                  onClick={() =>
                    this.onMenuItemClicked('showScenarioConfigModal')
                  }
                  text="Scenario"
                  isVisible={this.props.mode === 'editor'}
                />
                <NavbarLinkItem
                  href="#playthrough"
                  title="Show playthrough settings"
                  onClick={() =>
                    this.onMenuItemClicked('showPlaythroughConfigModal')
                  }
                  text="Playthrough"
                  isVisible={this.props.mode === 'gameplay'}
                />
              </div>
            </div>
          </nav>
          <TabContent tabName="map" activeTabName={this.props.activeTabName}>
            <div className="tile is-ancestor is-parent is-marginless is-paddingless">
              <Sidebar>
                <ThingSelector mode={this.props.mode} />
              </Sidebar>
              <Main mode={this.props.mode} />
              <Sidebar isBig>
                {this.props.selectedTid ? (
                  <EditConfigForSelectedThing mode={this.props.mode} />
                ) : (
                  <ScenarioConfigAndExport
                    mainRef={this.mainRef}
                    textAttrs="popup"
                    textCallback={() =>
                      this.props.showScenarioConfigModal({ isBodyOnly: true })
                    }
                  />
                )}
              </Sidebar>
            </div>
          </TabContent>

          {this.props.mode === 'gameplay' && this.renderPcTabContents()}

          <BulmaModal
            isOpen={!!this.props.modal}
            onRequestClose={this.props.closeModal}
          >
            {this.renderModalContent()}
          </BulmaModal>
        </div>
      );
    }

    handleAddTileSelected = (tileThing) => {
      this.props.closeModal();
      this.props.addTile(tileThing.name);
    };

    handleAddThingSelected = (thing) => {
      this.props.closeModal();
      this.props.addThing(thing);
    };
  }

  function mapStateToProps(state, ownProps) {
    let initialized = false;

    const mode = ownProps.mode;
    if (mode === 'editor') {
      initialized = !!state.scenarioViewer.scenario;
    } else if (mode === 'gameplay') {
      initialized = !!state.scenarioViewer.playthrough;
    } else {
      console.warn('Unknown ScenarioViewer mode:', mode);
    }

    const orientation = selectOrientation(state);

    const {
      modal,
      playthrough,
      activeTabName = 'map',
      selectedTid,
      shouldFocusHitpoints,
      showCannotChangeTile,
    } = state.scenarioViewer;

    return {
      initialized,
      modal,
      playthrough,
      orientation,
      stateScenario: state.scenarioViewer.scenario,
      activeTabName,
      selectedTid,
      shouldFocusHitpoints,
      showCannotChangeTile,
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      closeModal: () => dispatch(closeModal()),
      addTile: (tileName) =>
        dispatch(addThingAuto({ thingType: 'tile', name: tileName })),
      addThing: (thing) => dispatch(addThingAuto(thing)),
      exportJson: () => dispatch(exportJson()),
      onKeyPressed: (key) => dispatch(onKeyPressed(key)),
      showGlobalConfigModal: () =>
        dispatch(
          showModal({
            modalKind: 'GLOBAL_CONFIG',
          })
        ),
      showScenarioConfigModal: (options) =>
        dispatch(
          showModal({
            modalKind: 'SCENARIO_CONFIG',
            options,
          })
        ),
      showPlaythroughConfigModal: () =>
        dispatch(
          showModal({
            modalKind: 'PLAYTHROUGH_CONFIG',
          })
        ),
      onActivateTab: (tabName) => dispatch(activateTabByName(tabName)),
      showPdfModal: () => {
        dispatch(showPdfModal());
      },
      doneFocusHitpoints: () => {
        dispatch({ type: 'FOCUS_HITPOINTS', value: false });
      },
      doneShowCannotChangeTile: (tid) => {
        dispatch(showCannotChangeTile(tid, false));
      },
    };
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(ScenarioViewer);
})();
