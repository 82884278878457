import React from 'react';
import shortid from 'shortid';
import classNames from 'classnames';
import { generatePartyTitle } from './data/party';
import { generateScenarioTitle } from './data/randomScenarioTitle';
import { func } from 'prop-types';
import { defaultScenarioBody } from './data/scenario';

export function replaceLocation(path) {
  window.history.replaceState({ path: path }, null, path);
}

export function CustomLink(props) {
  const { onClick, ...rest } = props;

  function handleClick(e) {
    e.preventDefault();
    onClick && onClick();
  }

  return (
    <a onClick={handleClick} {...rest}>
      {props.children}
    </a>
  );
}

CustomLink.propTypes = {
  onClick: func.isRequired,
};

export function Button(props) {
  const {
    isPrimary,
    isInfo,
    isSuccess,
    isWarning,
    isDanger,
    isWhite,
    isLight,
    isDark,
    isBlack,
    isText,
    ...rest
  } = props;
  const className = classNames({
    button: true,
    'is-primary': isPrimary,
    'is-info': isInfo,
    'is-success': isSuccess,
    'is-warning': isWarning,
    'is-danger': isDanger,
    'is-white': isWhite,
    'is-light': isLight,
    'is-dark': isDark,
    'is-black': isBlack,
    'is-text': isText,
  });
  return <button className={className} {...rest} />;
}

export function numberValueOfEvent(e) {
  return Number(e.target.value);
}

export function generateId() {
  return shortid.generate();
}

export function getCurrentTimestamp() {
  return Date.now();
}

export function createNewScenario(id) {
  if (id === undefined) {
    id = generateId();
  }
  const title = generateScenarioTitle();
  return {
    id,
    shortReference: 'AB',
    title,
    location: '',
    requirements: 'None',
    goal: 'Kill all enemies',
    body: defaultScenarioBody,
    gridHexTops: 'pointy',
    tids: [],
    tidToConfig: {},
    createdAt: getCurrentTimestamp(),
  };
}

export function setSubtitle(subtitle) {
  const siteName = 'Silent Bridge';
  const title = subtitle ? `${subtitle} - ${siteName}` : siteName;
  document.title = title;
}

export function createNewParty(id) {
  if (id === undefined) {
    id = generateId();
  }
  return {
    id,
    title: generatePartyTitle(),
    pcs: [
      {
        id: generateId(),
        name: 'Skullcrusher',
        profession: 'brute',
        level: 1,
        // TODO support other properties
        /* ticks: 0,
         * gold: 0,
         * xp: 0,
         * perks: [],
         * cardChoices: [], */
      },
    ],
    createdAt: getCurrentTimestamp(),
  };
}

export class BulmaModal extends React.PureComponent {
  handleKeyDown = (event) => {
    if (event.keyCode === 27 && this.props.isOpen) {
      this.props.onRequestClose();
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown, false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isOpen !== this.props.isOpen) {
      const html = document.getElementsByTagName('html')[0];
      if (this.props.isOpen) {
        html.classList.add('is-clipped');
      } else {
        html.classList.remove('is-clipped');
      }
    }
  }

  render() {
    const props = this.props;

    // The props are compatible with react-modal.
    const className = classNames('modal', { 'is-active': props.isOpen });
    return (
      <div className={className}>
        <div className="modal-background" onClick={props.onRequestClose} />
        <div className="modal-card">
          <section className="modal-card-body">{props.children}</section>
        </div>
        <button
          className="modal-close is-large"
          onClick={props.onRequestClose}
          aria-label="close"
        />
      </div>
    );
  }
}

export function NotFound(props) {
  return <div>Not found</div>;
}

export function Loader(props) {
  return <div className="loader" style={props.style} />;
}

export function OnOffLoader(props) {
  const className = classNames('loader', { 'is-hidden': !props.isActive });
  return <div className={className} />;
}

export function getScenarioEditLink(scenario) {
  return `/edit-scenario/${scenario.id}`;
}
